<template>
  <div>
    <div class="settings-box">
      <div class="section-title">
        <i class="fa-regular fa-envelope-open-dollar pr-1" />
        {{ $t('settings.paymentGateways') }}
      </div>
      <PaymentGateways />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentGateways from '@/components/settings/payments/PaymentGateways';

export default {
  data: () => ({
    gateway: null,
    pending: false,
    updatePending: false,
    error: null,
    enabled: false,
    payeePaysCommission: false,
  }),
  components: {
    PaymentGateways,
  },
  computed: {
    ...mapGetters(['payee']),
  },
};
</script>

<style>

</style>
