<template>
  <div class="h-100">
    <div class="page-title-wrapper">
      <div
        class="page-title"
        @click="showPayments"
      >
        {{ $t('menu.payments') }}
      </div>
      <div
        class="page-title active ml-4"
      >
        {{ $t('bankStatements.title') }}
      </div>
    </div>

    <div
      v-if="!focus"
      class="d-flex align-items-center justify-content-between"
    >
      <div class="d-flex flex-wrap">
        <YearDropdown
          :selected.sync="year"
          class="mr-3 mb-1"
        />
        <BankStatementDropdown
          ref="statements"
          :selected.sync="bankStatement"
          :year="year"
          class="mr-3 mb-1"
        />
        <TransferStatusDropdown
          :selected.sync="transferStatus"
          class="mr-3 mb-1"
        />
        <SwitchWithDescription
          :title="$t('bankStatements.suggestSettlement')"
          small
          class="mb-1"
          style="min-height: 44px; margin-top: 0"
          :value="autoSettle"
          @switch="autoSettle = !autoSettle"
        />
      </div>
    </div>
    <hr
      v-if="!focus"
      class="mt-2 mb-3"
    >

    <FileUpload
      v-if="!uploadPending"
      class="upload"
      @upload="upload"
    />
    <div v-else>
      <Loader />
    </div>
    <div
      v-if="uploadError"
      class="text-danger mt-2"
      style="max-width: 800px"
      v-html="$t('import.unableToImport')"
    />
    <hr>

    <div v-if="transfers.length === 0 && haveAllData">
      <div
        class="text-center mt-3"
      >
        <i
          v-if="transferStatus === 'Unsettled'"
          class="fa-regular fa-clipboard-check text-secondary"
          style="font-size: 30px"
        />
        <div
          class="small text-secondary mt-2"
          style="max-width: 500px; margin: auto"
        >
          {{ transferStatus === 'Unsettled' ? $t('import.allSettled') : $t('import.noTransfers') }}
        </div>
      </div>
    </div>
    <div class="d-flex w-100">
      <div
        ref="wrapper"
        class="page-content"
        style="min-width: 40%; width: 40%; max-width: 800px; padding-left: 5px;"
      >
        <TransferSummaryRow
          v-for="(t, i) in transfers"
          :key="t.id"
          v-bind="t"
          :selected="edited && edited.id === t.id"
          @click.native="edited = t; editedIndex = i"
        />
        <InfinityScrollCursor
          ref="cursor"
          @request="request"
        />
        <div v-if="pending">
          <Loader />
        </div>
        <div
          v-else-if="getError"
          class="text-danger text-center"
        >
          <i
            class="fas far fa-xmark"
            style="font-size: 30px"
          />
          <div style="max-width: 500px; margin: auto">
            {{ $t('error.fail') }}
          </div>
        </div>
      </div>
      <div
        v-if="edited"
        class="flex-grow-1 p-2 page-content"
      >
        <CloseButton
          v-if="!focus"
          style="position: absolute; right: 0px; top: 0px; z-index: 100"
          @click.native.stop="edited = null"
        />
        <Transition
          name="details"
          mode="out-in"
        >
          <TransferSettlement
            v-bind="edited"
            :auto-settle="autoSettle"
            @focus="focus = $event"
            @close="edited = null; focus = false"
            @settled="settled"
            @skipped="skipped"
          />
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import FileUpload from '@/components/FileUpload';
import YearDropdown from '@/components/YearDropdown';
import BankStatementDropdown from '@/components/bankStatements/BankStatementDropdown';
import TransferStatusDropdown from '@/components/bankStatements/TransferStatusDropdown';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import TransferSettlement from '@/components/bankStatements/TransferSettlement';
import TransferSummaryRow from '@/components/bankStatements/TransferSummaryRow';

const takeMonths = 3;

export default {
  data: () => ({
    edited: null,
    editedIndex: null,
    focus: false,
    bankStatement: null,
    transferStatus: 'Unsettled',
    year: moment().format('YYYY'),
    transfers: [],
    from: moment.utc().add(1, 'year').startOf('year').subtract(takeMonths, 'months'),
    to: moment.utc().add(1, 'year').startOf('year'),
    haveAllData: false,
    uploadPending: false,
    uploadError: null,
    getError: false,
    pending: false,
    autoSettle: true,
  }),
  components: {
    FileUpload,
    YearDropdown,
    TransferSummaryRow,
    TransferSettlement,
    BankStatementDropdown,
    TransferStatusDropdown,
    InfinityScrollCursor,
    SwitchWithDescription,
  },
  watch: {
    year() {
      this.reset();
    },
    bankStatement() {
      this.reset();
    },
    transferStatus() {
      this.reset();
    },
  },
  methods: {
    ...mapActions([
      'getTransfers',
      'importBankStatement',
    ]),
    settled(paymentId) {
      const transferId = this.edited.id;
      this.edited.status = 'Settled';
      this.edited.paymentId = paymentId;
      this.edited = null;
      if (this.transferStatus === 'Unsettled' || this.transferStatus === 'Skipped') {
        this.transfers = this.transfers.filter((x) => x.id !== transferId);
      } else {
        this.editedIndex += 1;
      }
      this.next();
    },
    skipped() {
      const transferId = this.edited.id;
      this.edited.status = 'Skipped';
      this.edited = null;
      if (this.transferStatus === 'Unsettled' || this.transferStatus === 'Settled') {
        this.transfers = this.transfers.filter((x) => x.id !== transferId);
      } else {
        this.editedIndex += 1;
      }
      this.next();
    },
    next() {
      if (this.transfers.length > this.editedIndex) {
        this.edited = this.transfers[this.editedIndex];
      }
    },
    reset() {
      this.edited = null;
      this.haveAllData = false;
      this.getError = false;
      this.pending = false;
      this.transfers = [];
      this.from = moment.utc(this.year, 'YYYY').add(1, 'year').startOf('year').subtract(takeMonths, 'months');
      this.to = moment.utc(this.year, 'YYYY').add(1, 'year').startOf('year');
      this.request();
    },
    showPayments() {
      this.$router.push('../payments');
    },
    upload(file) {
      this.uploadPending = true;
      this.uploadError = null;
      const formData = new FormData();
      formData.append('file', file);

      this.importBankStatement({
        data: formData,
      })
        .then(({ data }) => {
          this.year = data[0].year.toString();
          this.$refs.statements.add(data.filter((x) => x.year.toString() === this.year));
          this.bankStatement = data[0].id;
        })
        .catch(({ response: { data } }) => {
          this.uploadError = data;
        })
        .finally(() => {
          this.uploadPending = false;
        });
    },
    request() {
      if (this.haveAllData || this.pending) return;
      this.pending = true;

      this.getTransfers({
        params: {
          query: {
            from: this.from.format(),
            to: this.to.format(),
            bankStatementId: this.bankStatement,
            status: this.transferStatus,
          },
        },
      })
        .then(({ data }) => {
          const uniqueTransfers = data.filter((x) => !this.transfers.some((y) => y.id === x.id));
          this.transfers = [...this.transfers, ...uniqueTransfers];

          if (moment.utc(this.year, 'YYYY').startOf('year').isSameOrAfter(this.from)) {
            this.haveAllData = true;
          } else {
            this.from = this.from.subtract(takeMonths, 'month');
            this.to = this.to.subtract(takeMonths, 'month');
          }

          setTimeout(() => {
            this.pending = false;
            if (!this.$refs.cursor || !this.$refs.cursor.isBottom()) {
              this.request();
            }
          }, 200);
        })
        .catch(() => {
          this.getError = true;
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .upload {
    min-height: 20px;
    padding: 5px 0;
    max-width: 600px;
  }

  .upload ::v-deep .dz-default {
    margin: 1em 0;
  }

  .page-content {
    height: calc(100vh - #{$navbarHeight} - 251px);
    overflow-y: scroll;
    position: relative;
    padding-bottom: 50px;
  }
</style>
