<template>
  <div
    class="row no-gutters position-relative"
    style="padding-bottom: 100px; padding-top: 20px"
  >
    <div
      v-if="pending"
      class="overlay"
    >
      <Loader />
    </div>

    <div class="col-12 col-md-6 pr-3">
      <Calendar
        :available-days="availableDays"
        :selected-days="daysToAdd"
        :day-status="dayStatus"
        single-day
        @dates="dates = $event"
        @highlighted="highlighted = $event"
      />
    </div>

    <div
      v-if="highlighted.length === 0"
      class="p-4 text-secondary col-12 col-md-6 text-center"
    >
      <i
        class="fa-solid fa-circle-question mb-3"
        style="font-size: 20px"
      />
      <div v-html="$t('shop.shopPayerHelp')" />
    </div>
    <div
      v-else
      class="col-12 col-md-6 pl-2 pt-2"
    >
      <template v-if="highlighted.length > 0">
        <div
          v-if="availableSets.length > 0"
          class="font-weight-bold my-2"
        >
          {{ $t('shop.noteToOrder') }}:
        </div>
        <BFormTextarea
          :value="comments[highlightedDay]"
          :placeholder="`${$t('shop.noteToOrder')}...`"
          @update="updateComment"
        />
      </template>

      <div
        v-if="availableSets.length > 0"
        class="font-weight-bold my-2"
      >
        {{ $t('shop.orderSet') }}:
      </div>

      <CompanySetRow
        v-for="set in availableSets"
        :key="set.availabilityId"
        v-bind="set"
        @update="update(set, $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Calendar from '../Calendar';
import CompanySetRow from './CompanySetRow';

export default {
  props: {
    payerId: String,
    payeeId: String,
  },
  data: () => ({
    highlighted: [],
    cancelModal: false,
    pending: false,
    offer: {
      products: [],
      productSets: [],
      calendar: [],
    },
    ordered: {
      orders: [],
      products: [],
      productSets: [],
    },
    selectedAvailabilityId: null,
    dates: null,
  }),
  components: {
    CompanySetRow,
    Calendar,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'itemInBasket', 'billingScopeName']),
    ...mapState({
      basket: (state) => state.userBills.basket,
      comments: (state) => state.userBills.comments,
    }),
    highlightedDay() {
      return this.highlighted[0];
    },
    daysToAdd() {
      if (!this.selectedAvailabilityId) return [];
      return [this.offerDay]
        .filter((x) => x.productSets.some((s) => s.availabilityId === this.selectedAvailabilityId))
        .map((x) => x.day);
    },
    daysToRemove() {
      const days = this.highlighted
        .map((day) => {
          const value = this.dayStatus[day]?.new || 0;
          return { day, value };
        })
        .filter((x) => x.value > 0);

      return {
        days: days.map((x) => x.day),
        sum: days.reduce((acc, curr) => acc + curr.value, 0),
      };
    },
    availableSets() {
      const sets = this.offerDay.productSets
        .map((curr) => ({
          minPrice: curr.price,
          maxPrice: curr.price,
          currency: curr.currency,
          id: curr.productSetId,
          comments: curr.orders.map((x) => x.comment).filter((x) => x.length > 0),
          ordered: curr.ordered || 0,
          selected: this.highlightedAvailabilities[curr.availabilityId] || 0,
          paid: curr.paidCount >= ((curr.ordered || 0) + (this.highlightedAvailabilities[curr.availabilityId] || 0)),
          maxCount: curr.maxCount,
          paidCount: curr.paidCount,
          availabilityId: curr.availabilityId,
          showProducts: true,
          products: curr.productIds.map((id) => this.offer.products.find((y) => y.id === id)),
          productSet: this.offer.productSets.find((x) => x.id === curr.productSetId)
            || this.ordered.productSets.find((x) => x.id === curr.productSetId),
        }));

      return sets
        .sort((a, b) => (a.productSet?.name || '').localeCompare(b.productSet?.name || ''));
    },
    offerDay() {
      const day = this.highlighted[0];

      const dayOffer = this.offer.calendar.find((y) => y.day === day);
      const productSets = (dayOffer?.productSets || []);
      const orders = this.ordered.orders.filter((x) => x.day === day);

      const ordersWithNoOffer = orders
        .filter((x) => !productSets.some((y) => y.availabilityId === x.availabilityId))
        .reduce((acc, curr) => {
          if (!acc[curr.availabilityId]) {
            this.$set(acc, curr.availabilityId, []);
          }

          acc[curr.availabilityId].push(curr);
          return acc;
        }, {});

      const additionalSets = Object.entries(ordersWithNoOffer)
        .map(([key, o]) => ({
          availabilityId: key,
          cancelTo: o[0].cancelTo,
          currency: 'pln',
          orderTo: null,
          payTo: null,
          maxCount: o.reduce((acc, curr) => acc + curr.count, 0),
          price: o[0].unitPrice,
          productIds: o[0].productIds,
          productSetId: o[0].productSetId,
          productSetTypeId: o[0].productSetTypeId,
        }));

      return {
        day,
        ...dayOffer,
        productSets: productSets.concat(additionalSets)
          .map((ps) => ({
            ...ps,
            orders: orders.filter((x) => x.availabilityId === ps.availabilityId),
          }))
          .map((x) => ({
            ...x,
            paidCount: x.orders.filter((y) => y.isPaid).reduce((acc, curr) => acc + curr.count, 0),
            ordered: x.orders.reduce((acc, curr) => acc + curr.count, 0),
          })),
      };
    },
    highlightedAvailabilities() {
      return this.highlighted.reduce((acc, day) => {
        const dayProducts = this.dayStatus[day]?.basket;
        if (!dayProducts) return acc;

        dayProducts
          .forEach((p) => {
            if (acc[p.availabilityId]) {
              acc[p.availabilityId] += (p.count || 1);
            } else {
              acc[p.availabilityId] = (p.count || 1);
            }
          });

        return acc;
      }, {});
    },
    availableDays() {
      return this.offer.calendar.map((x) => x.day)
        .concat(this.ordered.orders.map((x) => x.day));
    },
    highlightedDaysWithNoOrders() {
      return this.highlighted
        .filter((x) => {
          const orderedNotRemoved = this.dayStatus[x]?.ordered;
          if (!orderedNotRemoved) return true;

          return orderedNotRemoved.filter((y) => !this.itemInBasket({ ...y, isReturned: true })).length === 0;
        });
    },
    dayStatus() {
      const days = Object.keys(this.basket)
        .filter((k) => k.startsWith('productset'))
        .map((k) => this.basket[k])
        .filter((x) => x.payerId === this.payerId)
        .flatMap((x) => x.days.map((d) => ({ ...x, ...d })))
        .reduce((acc, curr) => {
          if (!acc[curr.day]) {
            acc[curr.day] = {
              new: 0,
              cancel: 0,
              paid: 0,
              waiting: 0,
              basket: [],
              ordered: [],
            };
          }

          if (curr.price >= 0) {
            acc[curr.day].new += curr.count;
          } else {
            acc[curr.day].cancel += curr.count;
          }

          acc[curr.day].basket.push({
            ...curr,
            productSetTypeId: this.offer.productSets.find((x) => x.id === curr.productSetId)?.typeId,
          });

          return acc;
        }, {});

      this.ordered.orders.forEach((o) => {
        if (!days[o.day]) {
          days[o.day] = {
            new: 0,
            paid: 0,
            waiting: 0,
            basket: [],
            ordered: [],
          };
        }

        days[o.day].ordered.push(o);

        if (o.isPaid) {
          days[o.day].paid += o.count;
        } else {
          days[o.day].waiting += o.count;
        }
      });

      return days;
    },
  },
  watch: {
    dates() {
      this.request();
    },
  },
  methods: {
    ...mapActions([
      'getUserShop',
      'getOrdered',
    ]),
    updateComment(value) {
      this.$store.commit('updateComment', {
        day: this.highlightedDay,
        comment: value,
      });
    },
    request() {
      if (!this.dates) return;
      this.pending = true;

      const p1 = this.getUserShop({
        params: {
          query: {
            payerId: this.payerId,
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data }) => {
          this.offer = data[0].offer;
        });

      const p2 = this.getOrdered({
        params: {
          query: {
            payerId: this.payerId,
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data }) => {
          this.ordered = data;
        });

      Promise.all([p1, p2])
        .finally(() => {
          this.pending = false;
        });
    },
    update({ availabilityId }, count) {
      const item = this.offerDay;
      const productSet = item.productSets.find((s) => s.availabilityId === availabilityId);

      const set = this.offer.productSets.find((x) => x.id === productSet.productSetId)
        || this.ordered.productSets.find((x) => x.id === productSet.productSetId);

      this.$store.commit('setItemBasket', {
        payerId: this.payerId,
        productSetName: set.name,
        timeFrame: item.scopeFrom,
        timeFrameName: this.billingScopeName({
          type: item.scopeType,
          from: item.scopeFrom,
          to: item.scopeTo,
        }),
        productSetId: productSet.productSetId,
        currency: productSet.currency,
        products: productSet.productIds.map((id) =>
          this.offer.products.find((y) => y.id === id)
          || this.ordered.products.find((y) => y.id === id)),
        payeeId: this.payeeId,
        availabilityId: productSet.availabilityId,
        price: productSet.price * (count - productSet.ordered),
        day: item.day,
        count: (count - productSet.ordered),
      });
    },
  },
  created() {
    this.refreshOrders = () => this.request();
    this.$root.$on('order-created', this.refreshOrders);
  },
  destroyed() {
    this.$root.$off('order-created', this.refreshOrders);
  },
};
</script>

<style lang="scss" scoped>
  .overlay {
    background-color: rgba(#eee, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;

    > div {
      margin-top: 50px;
    }
  }
</style>
