import { render, staticRenderFns } from "./ColumnEdit.vue?vue&type=template&id=664e6afc&scoped=true&"
import script from "./ColumnEdit.vue?vue&type=script&lang=js&"
export * from "./ColumnEdit.vue?vue&type=script&lang=js&"
import style0 from "./ColumnEdit.vue?vue&type=style&index=0&id=664e6afc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664e6afc",
  null
  
)

export default component.exports