<template>
  <div>
    <BDropdown
      ref="dropdown"
      variant="link"
      right
      no-caret
      class="navbar-dropdown"
      data-test="user-dropdown-btn"
    >
      <template
        slot="button-content"
      >
        <div class="user-icon">
          <i class="far fa-user-circle" />
        </div>
      </template>
      <div v-if="oidcUser">
        <div class="user-email text-center">
          {{ oidcUser.email }}
        </div>
        <hr class="mt-0 mb-2">
      </div>
      <li>
        <RouterLink
          v-if="payerView"
          to="/payer/settings"
          class="dropdown-item"
          @click.native="close"
        >
          {{ $t('menu.settings') }}
        </RouterLink>
      </li>
      <BDropdownItem
        href="/usersettings"
      >
        {{ $t('menu.changePassword') }}
      </BDropdownItem>
      <BDropdownItem
        v-if="payees && payees.payees.length > 0"
        href="/?stay=1"
      >
        {{ $t('company.companies') }}
      </BDropdownItem>
      <BDropdownItem
        v-if="!payerView"
        href="/payer"
        data-test="client-view"
      >
        {{ $t('menu.clientView') }}
      </BDropdownItem>
      <BDropdownItem
        @click.stop.prevent="logout"
      >
        {{ $t('menu.logout') }}
      </BDropdownItem>
      <div style="margin-bottom: -5px">
        <hr class="mt-2 mb-2">
        <div class="user-email text-center">
          {{ $t('menu.version') }}:
          {{ version }}
        </div>
      </div>
    </BDropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data: () => ({
    version: process.env.VUE_APP_VERSION,
  }),
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    ...mapState({
      payees: (state) => state.payee.payees,
    }),
    payerView() {
      return this.$route.matched.some((x) => x.name === 'payerRoot');
    },
  },
  methods: {
    ...mapActions('oidc', ['signOutOidc']),
    logout() {
      this.signOutOidc();
    },
    close() {
      this.$refs.dropdown.hide();
    },
  },
};
</script>

<style lang="scss" scoped>

  .navbar-dropdown ::v-deep .dropdown-toggle {
    box-shadow: none !important;
  }
  .navbar-dropdown ::v-deep .dropdown-menu {
    z-index: 1030;
  }

  .user-profile {
    z-index: 1500;
  }

  .client-dropdown ::v-deep .btn-link {
    text-decoration: none;
    padding: 0;
    max-height: 40px;
    line-height: 1;
    cursor: pointer;

    i {
      color: #fff;
      font-size: 28px;
    }
    &:hover i {
      color: #333;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .user-email {
    font-size: 11px;
    padding-bottom: 10px;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 1;
  }

</style>
