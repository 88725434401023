<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    max-width="800"
    placement="bottom"
    @show="opened"
  >
    <template #trigger>
      <slot
        name="trigger"
        :selected="selected"
      >
        <div class="sort-option-selected">
          <div
            class="font-weight-bold text-secondary"
            style="font-size: 10px; min-width: 180px"
          >
            {{ $t('menu.payers') }}
          </div>
          <div
            v-if="selected.length === 0"
            style="font-size: 14px;"
          >
            -
          </div>
          <div
            v-if="selected.length <= showInline"
            class="small"
          >
            <span
              v-for="(s, i) in selected"
              :key="s.id"
            ><span v-if="i > 0">, </span>{{ s.name }}</span>
          </div>
          <div
            v-else
            class="first d-flex pr-2"
            style="font-size: 14px"
          >
            <div class="flex-grow-1">
              {{ selected.length }}
            </div>
          </div>
          <i
            v-if="selected.length > 0"
            class="far fa-close close-btn"
            @click.stop="clear"
          />
        </div>
      </slot>
    </template>

    <div
      style="width: 400px"
    >
      <div style="padding-bottom: 5.5px">
        <SearchBox
          ref="search"
          v-model="search"
          :height="30"
          constant-width
        />
      </div>
      <div
        style="max-height: 400px; overflow: auto"
      >
        <div
          v-for="s in payers"
          :key="s.key"
          class="sort-option text-left d-flex pl-0"
          :class="{
            active: isSelected(s.id)
          }"
          @click="toggle(s)"
        >
          <Checkbox
            :value="isSelected(s.id)"
          />
          <div class="flex-grow-1 pl-1">
            {{ s.name }}

            <small
              v-if="s.code"
              class="text-secondary"
            >| {{ s.code }}</small>
          </div>
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import Checkbox from '@/components/utils/Checkbox';

export default {
  props: {
    payerIds: Array,
    payers: Array,
    initOpen: Boolean,
    showInline: {
      default: 3,
      type: Number,
    },
  },
  data: () => ({
    selected: [],
    search: '',
  }),
  components: {
    Checkbox,
  },
  watch: {
    payerIds(ids) {
      this.selected = this.payers.filter((x) => ids.includes(x.id));
    },
    search() {
      this.skip = 0;
    },
  },
  methods: {
    clear() {
      this.selected = [];
      this.$emit('selected', this.selected);
    },
    open() {
      this.$refs.popover.tippy().show();
    },
    toggle(s) {
      if (this.isSelected(s.id)) {
        this.selected = this.selected.filter((x) => x.id !== s.id);
        this.$emit('selected', this.selected);
      } else {
        this.selected.push(s);
        this.$emit('selected', this.selected);
      }
    },
    isSelected(id) {
      return this.selected.some((x) => x.id === id);
    },
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
  },
  mounted() {
    this.selected = this.payers.filter((x) => this.payerIds.includes(x.id));

    if (this.initOpen && this.payerIds.length === 0) {
      this.open();
    }
  },
};
</script>

<style lang="scss" scoped>
 @import './style.scss';

  .sort-option-selected {
    position: relative;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    font-size: 20px;
    width: 24px;
    right: 10px;
    top: 11px;
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    color: #888;

    &:hover {
      color: #333;
      background-color: #ddd;
    }
  }
</style>
