<template>
  <div>
    <div class="col-6 scroll-form">
      <Draggable
        v-model="layout"
        class="draggable row no-gutters align-items-center"
        ghost-class="ghost"
        :class="{ moving }"
        @start="moving = true"
        @end="moving = false"
      >
        <div
          v-for="field in layout"
          :key="field.id"
          class="field-section"
          :class="{
            selected: selectedField && field.id === selectedField.id
          }"
        >
          <div class="d-flex align-items-center">
            <div
              v-if="field.type == 'statictext'"
              style="width: 100%"
              :style="{
                'font-weight': field.bold ? '500' : undefined,
              }"
            >
              <span style="white-space: pre-wrap;">{{ field.title }}</span>
              <hr
                v-if="field.separator"
                class="mb-0"
              >
            </div>
            <div
              v-else
              class="flex-grow-1"
            >
              <div class="d-flex justify-content-between">
                <label>
                  <span v-if="field.type !== 'checkbox'">
                    {{ field.title }}
                  </span>
                </label>
                <div class="small">
                  {{ field.code }}
                </div>
              </div>
              <div v-if="field.type === 'textinput'">
                <BFormInput
                  v-model="exampleData[field.id]"
                  size="sm"
                  :placeholder="field.title"
                />
              </div>
              <div v-else-if="field.type === 'textareainput'">
                <BFormTextarea
                  v-model="exampleData[field.id]"
                  size="sm"
                  :placeholder="field.title"
                />
              </div>
              <div v-else-if="field.type === 'checkbox'">
                <BFormCheckbox
                  v-model="exampleData[field.id]"
                  class="form-checkbox"
                  :placeholder="field.title"
                  style="margin-left: -10px"
                >
                  {{ field.title }}
                </BFormCheckbox>
              </div>
              <div v-else-if="field.type === 'dropdown'">
                <BFormSelect
                  :key="(field.options || []).length"
                  v-model="exampleData[field.id]"
                  :placeholder="field.title"
                  :options="field.options || []"
                />
              </div>
            </div>
            <div
              v-if="selectedField && field.id === selectedField.id"
              class="field-edit selected"
            >
              <i class="fas fa-arrow-right" />
            </div>
            <div
              v-else
              class="field-edit"
              @click="selectedField = field"
            >
              <i class="fas fa-pen" />
            </div>
          </div>
        </div>
      </Draggable>
      <div class="mt-3">
        <button
          class="btn btn-sm btn-block btn-primary field-add"
          @click="addField"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('general.add') }}
        </button>
      </div>
    </div>
    <div class="col-6 scroll-form">
      <div
        v-if="selectedField"
        class="edit-section"
      >
        <div class="d-flex justify-content-between">
          <div class="font-weight-bold">
            {{ $t('forms.forms.editField') }}
          </div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            style="font-size: 30px"
            @click="selectedField = null"
          >
            ×
          </button>
        </div>

        <label
          v-if="selectedField.type != 'statictext'"
          class="pt-3"
        >
          {{ $t('forms.forms.fieldCode') }}
        </label>
        <BFormInput
          v-if="selectedField.type != 'statictext'"
          v-model="selectedField.code"
          :placeholder="$t('forms.forms.fieldCode')"
        />
        <label class="pt-3">
          {{ $t('forms.forms.fieldDescription') }}
        </label>
        <BFormTextarea
          v-if="selectedField.type == 'statictext'"
          v-model="selectedField.title"
          :placeholder="$t('forms.forms.fieldDescription')"
        />
        <BFormInput
          v-else
          v-model="selectedField.title"
          :placeholder="$t('forms.forms.fieldDescription')"
        />
        <label class="pt-3">
          {{ $t('forms.forms.fieldType') }}
        </label>
        <BFormSelect
          v-model="selectedField.type"
          :options="fieldTypes"
          :placeholder="$t('forms.forms.fieldType')"
        />
        <template v-if="selectedField.type === 'dropdown'">
          <label class="pt-3">
            {{ $t('forms.forms.options') }}
          </label>
          <BFormTextarea
            :value="(selectedField.options || []).join('\n')"
            :placeholder="$t('forms.forms.options')"
            @update="$set(selectedField, 'options', $event.split('\n'))"
          />
        </template>

        <template v-if="selectedField.type == 'statictext'">
          <BFormCheckbox
            v-model="selectedField.bold"
            class="pt-3"
          >
            {{ $t('general.title') }}
          </BFormCheckbox>
          <BFormCheckbox
            v-model="selectedField.separator"
            class="pt-3"
          >
            {{ $t('forms.separator') }}
          </BFormCheckbox>
        </template>
        <template v-if="selectedField.type != 'statictext'">
          <hr>
          <label>
            {{ $t('forms.forms.validationScript') }}
          </label>
          <BFormInput
            v-model="selectedField.validationScript"
            size="sm"
            :placeholder="$t('forms.forms.validationScript')"
          />
          <label
            class="pt-3"
          >
            {{ $t('forms.forms.validationMessage') }}
          </label>
          <BFormInput
            v-model="selectedField.validationMessage"
            size="sm"
            :placeholder="$t('forms.forms.validationMessage')"
          />
        </template>
        <hr>
        <div>
          <button
            class="btn btn-secondary btn-sm"
            @click="layout = layout.filter(x => x.id !== selectedField.id); selectedField = null"
          >
            <i class="fa-solid fa-trash-can pr-1" />
            {{ $t('general.remove') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Draggable from 'vuedraggable';

export default {
  props: {
    value: Array,
  },
  data: () => ({
    layout: [],
    nextId: 1,
    moving: false,
    exampleData: {},
    layoutOrder: [],
    selectedField: null,
  }),
  components: {
    Draggable,
  },
  computed: {
    fieldTypes() {
      return [
        'textinput',
        'textareainput',
        'checkbox',
        'dropdown',
        'statictext',
      ]
        .map((t) => ({
          value: t,
          text: this.$t(`forms.forms.${t}`),
        }));
    },
  },
  watch: {
    layout(v) {
      this.$emit('update:value', v);
    },
  },
  methods: {
    addField() {
      const entry = {
        id: this.nextId,
        title: '',
        type: 'textinput',
      };

      this.nextId += 1;
      this.layout.push(entry);
      this.selectedField = entry;
    },
  },
  created() {
    this.layout = this.value;
    this.nextId = this.layout.reduce((acc, curr) => Math.max(curr.id || 0, acc), 0) + 1;
  },
};
</script>

<style lang="scss" scoped>
  .field-add {
    max-width: 200px;
    margin: auto;
  }

  .edit-section {
    border: 1px solid #eee;
    padding: 15px;
    margin-left: 30px;
    border-radius: 15px;
  }

  .field-edit {
    padding-left: 5px;
    align-self: center;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 15px;
    background-color: #f6f6f6;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;

    &:hover {
      background-color: #ddd;
    }

    &.selected {
      background-color: #ddd;
    }
  }

  .form-checkbox::v-deep .custom-control-label {
    font-weight: normal;
    text-transform: none;
    padding-top: 2px;
  }

  .ghost {
    opacity: 0.5;
    background: rgba($blue, 0.2);
    cursor: grabbing;
  }

  .moving * {
    cursor: grabbing;
  }

  .scroll-form {
    height: calc(100vh - 220px);
    padding-bottom: 40px;
    overflow-y: auto;
  }

  .field-section {
    padding: 10px;
    width: 100%;
    border-left: 2px solid transparent;

    &.selected {
      background-color: rgba($blue, 0.1);
      border-left: 2px solid $blue;
    }
  }
</style>
