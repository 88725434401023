<template>
  <div class="text-left">
    <div class="font-weight-bold">
      {{ entry.productSetName }}
    </div>
    <hr class="my-1">
    <div
      v-if="parsed.discountName"
      class="d-flex"
    >
      <div class="key">
        {{ $t('shop.discount') }}:
      </div>
      <span class="value">
        {{ parsed.discountName }}
      </span>
    </div>
    <div class="d-flex">
      <div class="key">
        {{ $t('shop.cancelTo') }}:
      </div>
      <span class="value">
        {{ formatDate(parsed.cancelTo) }}
      </span>
    </div>
    <div class="d-flex">
      <div class="key">
        {{ $t('shop.payTo') }}:
      </div>

      <span class="value">
        {{ formatDate(parsed.payTo) }}
      </span>
    </div>
    <div
      v-if="parsed.comment"
      class="d-flex"
    >
      <div class="key">
        {{ $t('shop.comment') }}:
      </div>

      <span class="value">
        {{ parsed.comment }}
      </span>
    </div>
    <div>
      <div class="key">
        {{ $t('shop.products') }}:
      </div>

      <li
        v-for="n in productNames"
        :key="n"
        class="value"
      >
        {{ n }}
      </li>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    entry: Object,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    ...mapGetters('shop', ['products']),
    productNames() {
      return this.products
        .filter((x) => this.parsed?.productIds.includes(x.id))
        .map((x) => x.name);
    },
    parsed() {
      return this.entry.operationDetails;
    },
  },
  methods: {
    formatDate(v) {
      return moment(v).format('HH:mm DD MMM YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.key {
  width: 120px;
}
</style>
