<template>
  <div class="px-2">
    <PayerName v-bind="payer" />
    <div class="pt-2">
      <div
        v-if="billSettlement.length > 0"
        class="small text-secondary"
      >
        <i class="far fa-money-bills" />
        {{ $t('menu.bills') }}
      </div>
      <div
        v-for="b in billSettlement"
        :key="b.billId"
        class="d-flex justify-content-between align-items-center pl-2 settlement-row"
      >
        <div class="pl-2">
          <div
            class="color-box"
            :style="{
              'background-color': getColor(b.billId),
            }"
          />
        </div>
        <div class="flex-grow-1 pl-2">
          {{ getBillTitle(b.billId) }}
        </div>
        <div class="money-font">
          {{ formatCurrency(b.value, currency) }}
        </div>
      </div>
      <div
        v-if="overpaymentSettlement.length > 0"
        class="small text-secondary mt-2"
      >
        <i class="far fa-circle-dollar-to-slot" />
        {{ $t('payment.overpayment') }}
      </div>
      <div
        v-for="b in overpaymentSettlement"
        :key="b.categoryId"
        class="d-flex justify-content-between align-items-center pl-2 settlement-row"
      >
        <div class="pl-2">
          <div
            class="color-box"
            :style="{
              'background-color': colors(getCategory(b.categoryId).colorCode),
            }"
          />
        </div>
        <div class="pl-2 flex-grow-1">
          {{ getCategory(b.categoryId).name }}
        </div>
        <div class="money-font">
          {{ formatCurrency(b.value, currency) }}
        </div>
      </div>
      <div
        v-if="refundSettlement.length > 0"
        class="small text-secondary mt-2"
      >
        <i class="fa-regular fa-arrow-turn-down-left" />
        {{ $t('payment.creditReturn') }}
      </div>
      <div
        v-for="b in refundSettlement"
        :key="b.payerId"
        class="pl-2 d-flex settlement-row"
      >
        <div class="flex-grow-1">
          {{ $t('payment.creditReturn') }}
        </div>
        <div class="money-font">
          {{ formatCurrency(b.value, currency) }}
        </div>
      </div>
      <div
        v-if="donationSettlement.length > 0"
        class="small text-secondary mt-2"
      >
        <i class="far fa-hand-holding-heart" />
        {{ $t('payment.donation') }}
      </div>
      <div
        v-for="b in donationSettlement"
        :key="b.payerId"
        class="pl-2 d-flex settlement-row"
      >
        <div class="flex-grow-1">
          {{ getDonationName(b.donationId) }}
        </div>
        <div class="money-font">
          {{ formatCurrency(b.value, currency) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import colors from '@/utils/colors';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    payer: Object,
    currency: String,
    bills: Array,
    billSettlement: Array,
    overpaymentSettlement: Array,
    refundSettlement: Array,
    donationSettlement: Array,
  },
  components: {
    PayerName,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'payee', 'donations']),
  },
  methods: {
    ...mapActions(['getDonations']),
    colors,
    getBillTitle(billId) {
      return this.bills.find((x) => x.billId === billId)?.title;
    },
    getBillCategory(billId) {
      const bill = this.bills.find((x) => x.billId === billId);
      return this.getCategory(bill?.categoryId);
    },
    getCategory(categoryId) {
      return this.payee?.categories.find((x) => x.id === categoryId);
    },
    getColor(billId) {
      return colors(this.getBillCategory(billId)?.colorCode);
    },
    getDonationName(donationId) {
      return this.donations?.find((x) => x.id === donationId)?.name ?? '?';
    },
  },
  created() {
    if (!this.donations) {
      this.getDonations();
    }
  },
};
</script>

<style lang="scss" scoped>
.settlement-row {
  background-color: #fbfbfb;
  border-radius: 12px;
  padding: 5px 12px;
  margin-top: 5px;
}

.icon-wrapper {
  min-width: 24px;
  text-align: center;
  position: relative;
  line-height: 24px;

  i {
    z-index: 1;
    position: relative;
    color: white;
    font-size: 13px;
  }
}

.color-box {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  // position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
</style>
