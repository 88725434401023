<template>
  <div class="section-with-fields">
    <div class="payer-section d-flex align-items-center">
      <div class="flex-grow-1">
        <div class="font-weight-bold">
          {{ payer.payerName }}
        </div>
        {{ payer.payeeName }}
        <div class="d-flex mt-1">
          <div
            v-if="payer.isInvite"
            class="check-icon check-icon-warning"
          >
            <i class="fa-solid fa-user-plus pr-1" />
            {{ $t('client.pendingInvitation') }}
          </div>
          <div
            v-else
            class="check-icon check-icon-success"
          >
            <i class="fa-solid fa-check pr-1" />
            {{ $t('client.connected') }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <button
          v-if="payer.isInvite"
          class="btn btn-sm btn-outline-secondary"
          :disabled="pending"
          @click="connect(payer)"
        >
          {{ $t('general.add') }}
        </button>

        <Tippy
          trigger="click"
          interactive
          theme="light"
        >
          <template #trigger>
            <button
              class="btn btn-sm btn-outline-secondary ml-2"
              :disabled="pending"
            >
              {{ $t('client.disconnect') }}
            </button>
          </template>

          <button
            class="btn btn-sm btn-outline-danger"
            :disabled="pending"
            @click="disconnect(payer)"
          >
            {{ $t('general.confirmRemove') }}
          </button>
        </Tippy>
      </div>
    </div>

    <div
      v-if="payer.properties && payer.properties.length > 0"
      class="my-2 mx-3"
    >
      <PayerProperties
        :properties-definitions="payer.properties"
        :property-values.sync="values"
        live
      />
      <hr>
      <div class="d-flex justify-content-end mb-3">
        <div>
          <button
            class="btn btn-sm btn-primary text-nowrap btn-block"
            :disabled="pending || !valuesChanged"
            style="width: 200px"
            @click="update"
          >
            <span v-if="!pending">
              <i class="fas fa-check pr-1" />
              {{ $t('general.save') }}
            </span>
            <span v-else>
              <Loader
                color="#fff"
                size="15px"
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayerProperties from '@/components/payers/form/PayerProperties';
import { mapActions } from 'vuex';

export default {
  props: {
    payer: Object,
  },
  data: () => ({
    pending: false,
    values: [],
  }),
  components: {
    PayerProperties,
  },
  computed: {
    valuesChanged() {
      const a = this.payer.values
        .some((x) => {
          const updated = this.values.find((v) => v.key === x.key);
          return updated?.value !== x.value;
        });

      const b = this.values
        .some((x) => {
          const updated = this.payer.values.find((v) => v.key === x.key);
          return updated?.value !== x.value;
        });

      return a || b;
    },
    payerValues() {
      return this.payer.values;
    },
  },
  watch: {
    payerValues: {
      deep: true,
      handler(v) {
        this.values = v;
      },
    },
  },
  methods: {
    ...mapActions([
      'getUserPayers',
      'removeUserRole',
      'acceptUserRoleInvite',
      'rejectUserRoleInvite',
      'setUserPayerProperties',
    ]),
    update() {
      this.pending = true;
      this.setUserPayerProperties({
        params: {
          query: {
            payerId: this.payer.payerId,
          },
        },
        data: {
          values: this.values,
        },
      })
        .then(() => {
          this.$emit('updated', this.values);
          this.$emit('refresh');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    connect({ payeeId, payerId }) {
      this.pending = true;
      this.acceptUserRoleInvite({
        data: {
          payeeId,
          payerId,
        },
      })
        .then(() => {
          this.refreshUsers();
        })
        .catch(() => {
          this.pending = false;
        });
    },
    disconnect({ payeeId, payerId, isInvite }) {
      if (isInvite) {
        this.pending = true;
        this.rejectUserRoleInvite({
          data: {
            payeeId,
            payerId,
          },
        })
          .then(() => {
            this.refreshUsers();
          })
          .catch(() => {
            this.pending = false;
          });
      } else {
        this.removeUserRole({
          data: {
            payeeId,
            payerId,
          },
        })
          .then(() => {
            this.refreshUsers();
          })
          .catch(() => {
            this.pending = false;
          });
      }
    },
    refreshUsers() {
      this.$emit('refresh');
      this.getUserPayers()
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.values = this.payer.values.map((x) => ({ ...x }));
  },
};
</script>

<style lang="scss" scoped>
.section-with-fields {
  max-width: 800px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 15px;
}

.section-with-fields {
  border: 1px solid #f6f6f6;
}

.payer-section {
  font-size: 0.8rem;
  background-color: #f6f6f6;
  padding: 10px 15px;
  border-radius: 15px;
}

.check-icon {
  font-size: 0.5rem;
  font-weight: 500;
  border-radius: 10px;
  padding: 1px 6px;
  margin-top: 3px;
}

.check-icon-success {
  border-color: $green;
  background-color: rgba($green, 0.1);
  color: $green;
}
.check-icon-warning {
  border-color: $orange;
  background-color: rgba($orange, 0.1);
  color: $orange;
}
</style>
