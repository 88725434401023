<template>
  <div>
    <div class="settings-box">
      <div class="section-title">
        <i class="fa-regular fa-file-contract pr-1" />
        {{ $t('settings.forms.forms') }}
      </div>
      <FormSettings />
    </div>
  </div>
</template>

<script>
import FormSettings from '@/components/forms/FormSettings';

export default {
  components: {
    FormSettings,
  },
  created() {
    this.$emit('page', 'forms');
  },
};
</script>
