<template>
  <div>
    {{ $t('settings.currencies.clickCurrencyInfo') }}
    <div
      v-for="p in availableCurrencies"
      :key="p"
      class="client-property d-flex justify-content-between"
      :class=" { selected: chosenCurrency.some((e) => e === p) }"
      @click="addCurrencySupport(p)"
    >
      <div :row="p">
        {{ p }}
      </div>
    </div>

    <div
      v-if="availableCurrencies.length === 0"
      class="text-secondary small"
    >
      {{ $t('settings.currencies.currencyInfo') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: {
    pending: Boolean,
  },
  data: () => ({
    chosenCurrency: [],
  }),
  computed: {
    ...mapGetters(['companyId']),
    ...mapState({
      supportedCurrencies: (state) => state.currency.companyCurrencies,
      allCurrencies: (state) => state.currency.currencies,
    }),
    availableCurrencies() {
      return this.allCurrencies.filter((val) => !this.supportedCurrencies.includes(val));
    },
  },
  methods: {
    ...mapActions([
      'setCurrency',
    ]),

    addCurrencySupport(currName) {
      if (this.chosenCurrency.some((e) => e === currName)) {
        this.chosenCurrency = this.chosenCurrency.filter((e) => e !== currName);
      } else {
        this.chosenCurrency.push(currName);
      }
    },
    submit() {
      this.$emit('update:pending', true);
      this.setCurrency({
        params: {
          companyId: this.companyId,
        },
        data: {
          supportedCurrencies: this.chosenCurrency.concat(this.supportedCurrencies),
        },
      }).then(() => {
        this.$emit('update:pending', false);
        if (this.chosenCurrency[0]) {
          this.$emit('update:selected', this.chosenCurrency[0]);
        }
      }).finally(() => {
        this.$emit('update:pending', false);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .client-property {
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }

    &.selected {
      background-color: rgba($blue, 0.1);
      border-color: $darkblue;
    }
  }
</style>
