<template>
  <div class="notification-client-row">
    <div
      class="d-flex align-items-center justify-content-between client-name-row"
      @click="$emit('toggle')"
    >
      <div style="font-size: 14px">
        {{ header }}
      </div>
      <div
        class="text-right"
        :class="{ delivered }"
      >
        <i
          v-if="smsDelivery"
          class="fas fa-sms p-1"
          :class="smsDelivery.isSuccess ? 'green' : 'red'"
        />
        <i
          v-if="emailDelivery"
          class="fas fa-envelope p-1"
          :class="emailDelivery.isSuccess ? 'green' : 'red'"
        />
        <i
          v-if="pushDelivery"
          class="fas fa-mobile-alt p-1"
          :class="pushDelivery.isSuccess ? 'green' : 'red'"
        />
      </div>
    </div>
    <Transition name="appear">
      <div
        v-if="selected"
        class="px-3 pb-1"
      >
        <div
          v-for="d in delivery"
          :key="d.deliveryAddress"
        >
          <div
            v-if="d.deliveryType === 'Email'"
            class="py-2"
          >
            <div
              class="d-flex align-items-center pt-2"
              style="font-size: 14px"
            >
              <i class="fas fa-envelope pr-2" />
              <div class="flex-grow-1">
                {{ d.deliveryAddress }}
              </div>
              <div>
                <span
                  v-if="delivered && d.isSuccess"
                  class="badge badge-success"
                >
                  <i class="fas fa-check" />
                  {{ $t('notify.delivered') }}
                </span>
                <span
                  v-else-if="delivered"
                  class="badge badge-danger"
                >
                  <i class="fas fa-xmark" />
                  {{ $t('notify.notDelivered') }}
                </span>
              </div>
            </div>
            <template v-if="delivered">
              <div
                class="email-content"
                v-html="d.content"
              />
            </template>
            <template v-else>
              <div
                class="font-weight-bold pt-1"
                style="font-size: 14px; padding-left: 8px"
              >
                {{ d.content.Title }}
              </div>
              <div
                class="email-content"
                v-html="d.content.Body"
              />
            </template>
          </div>

          <div
            v-if="d.deliveryType === 'Sms'"
            class="py-2"
          >
            <div
              class="d-flex align-items-center"
              style="font-size: 14px"
            >
              <i class="fas fa-sms pr-2" />
              <div class="flex-grow-1">
                {{ d.deliveryAddress }}
              </div>
              <div>
                <span
                  v-if="delivered && d.isSuccess"
                  class="badge badge-success"
                >
                  <i class="fas fa-check" />
                  {{ $t('notify.delivered') }}
                </span>
                <span
                  v-else-if="delivered"
                  class="badge badge-danger"
                >
                  <i class="fas fa-xmark" />
                  {{ $t('notify.notDelivered') }}
                </span>
              </div>
            </div>

            <div
              v-if="delivered"
              class="email-content"
              v-html="d.content"
            />
            <div
              v-else
              class="email-content"
            >
              {{ d.content.Body }}
            </div>
          </div>
          <div
            v-if="d.deliveryType === 'Push'"
            class="py-2"
          >
            <div
              class="d-flex align-items-center"
              style="font-size: 14px"
            >
              <i class="fas fa-mobile-alt pr-2" />
              <div class="flex-grow-1">
                {{ $t('notify.push') }}
              </div>
              <div>
                <span
                  v-if="delivered && d.isSuccess"
                  class="badge badge-success"
                >
                  <i class="fas fa-check" />
                  {{ $t('notify.delivered') }}
                </span>
                <span
                  v-else-if="delivered"
                  class="badge badge-danger"
                >
                  <i class="fas fa-xmark" />
                  {{ $t('notify.notDelivered') }}
                </span>
              </div>
            </div>

            <div
              v-if="delivered"
              class="email-content"
              v-html="d.content"
            />
            <div
              v-else
              class="email-content"
            >
              {{ d.content.Body }}
            </div>
          </div>
        </div>
        <div
          v-if="delivery.length === 0"
          class="p-2 small text-center"
        >
          <div>
            <i class="fa-solid fa-bell-slash" />
          </div>
          {{ $t('notify.willNotReceive') }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    payers: Array,
    receiverIdentifier: String,
    delivery: Array,
    selected: Boolean,
    delivered: Boolean,
    skipped: Boolean,
  },
  computed: {
    header() {
      if (this.payers.filter((x) => x).length > 0) {
        return this.payers[0].name;
      }
      return this.receiverIdentifier;
    },
    smsDelivery() {
      return this.delivery.find((x) => x.deliveryType === 'Sms');
    },
    emailDelivery() {
      return this.delivery.find((x) => x.deliveryType === 'Email');
    },
    pushDelivery() {
      return this.delivery.find((x) => x.deliveryType === 'Push');
    },
  },
};
</script>

<style lang="scss" scoped>
.delivered {
  .red {
    color: $red;
  }
  .green {
    color: $green;
  }
}

  .notification-client-row {
    border: 1px solid #eee;
    margin-top: 5px;
    border-radius: 10px;

    .client-name-row {
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 10px;
      background-color: #fbfbfb;
      cursor: pointer;

      &.success {
        background-color: rgba($green, 0.1);
      }

      &.failure {
        background-color: rgba($red, 0.1);
      }

      &:hover {
        background-color: rgba($blue, 0.1);
      }
    }

    &.selected {
      .client-name-row {
        &.success {
          background-color: rgba($green, 0.5);
        }

        &.failure {
          background-color: rgba($red, 0.5);
        }
      }
    }

    &.selected:hover {
      .client-name-row {
        &.success {
          background-color: rgba($green, 0.7);
        }

        &.failure {
          background-color: rgba($red, 0.7);
        }
      }
    }
  }

  .email-content {
    font-size: 12px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 8px;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  .email-content ::v-deep table {
    width: 100%;

    td {
      padding: .25rem;
    }
  }

  .content {
    white-space: pre-line;
    background-color: #f8f9fa;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    font-size: 14px;
  }
</style>
