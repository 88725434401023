<template>
  <div class="col-sm-12 px-0">
    <div class="settings-box">
      <div class="settings-title mb-2 d-flex">
        {{ $t('client.import') }}
      </div>
      <div>
        <BButton
          variant="primary"
          @click="showImport"
        >
          <i class="fas fa-file-upload pr-2" />
          {{ $t('client.import') }}
        </BButton>
      </div>
    </div>
    <div class="settings-box">
      <div class="mb-2 settings-title d-flex align-items-center">
        <i class="far fa-tags pr-2" />
        {{ $t('settings.clientsProperties') }}
        <TooltipHelp class="pl-3">
          Dodaj pole z mailem oraz numerem telefonu by Pagaspot mogło komunikować się z Twoimi klientami.
          Możesz dodawać inne pola takie jak<br>np. „numer konta” w przypadku korzystania z indywidualnych
          rachunków bankowych czy „notatka”, w której zapiszesz informacje na temat klienta.
          Dodatkowe pola pojawią się po kliknięciu w wybraną osobę na ekranie „Klienci”.
        </TooltipHelp>
      </div>
      <PayersProperties />
    </div>
    <div class="settings-box">
      <div class="section-title d-flex align-items-center">
        <i class="far fa-users pr-2" />
        {{ $t('settings.clientGroups') }}
        <TooltipHelp class="pl-3">
          Grupuj Twoich klientów ze względu na określoną cechę (np. płacących czesne czy ulgowe składki).
          Możesz samodzielnie dodać nowe grupy oraz nadać im nazwę. Pojawią się one na ekranie “Klienci”.
          Grupy ułatwiają naliczanie należności i wysyłkę wiadomości do określonej grupy klientów.
        </TooltipHelp>
      </div>
      <PayersGroups />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PayersGroups from './payers/PayersGroups';
import PayersProperties from './payers/PayersProperties';

export default {
  components: {
    PayersGroups,
    PayersProperties,
  },
  computed: mapGetters(['payeeId']),
  methods: {
    showImport() {
      this.$router.push(`/payee/${this.payeeId}/import/payers`);
    },
  },
  created() {
    this.$emit('page', 'payers');
  },
};
</script>
