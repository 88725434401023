<template>
  <div
    class="small px-2 py-1 badge text-white"
    :class="isEnabled ? '' : 'badge-secondary'"
    :style="{
      'background-color': isEnabled ? color : '',
    }"
  >
    <i :class="isEnabled ? 'fas fa-circle-check pr-1' : 'fas fa-circle-xmark pr-1'" />
    {{ isEnabled ? $t('billingPlan.enabled') : $t('billingPlan.disabled') }}
  </div>
</template>

<script>
export default {
  props: {
    isEnabled: Boolean,
    color: String,
  },
};
</script>

<style>

</style>
