<template>
  <div
    class="transfer-row mr-1"
    :class="{
      selected,
      settled: isSettled,
      skipped: isSkipped,
    }"
  >
    <div class="status">
      <div v-if="isSettled">
        {{ $t('bankStatements.statuses.single.settled') }}
      </div>
      <div v-else-if="isSkipped">
        {{ $t('bankStatements.statuses.single.skipped') }}
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="text-secondary">
        {{ formatDate(transactionDate) }}
      </div>
      <div class="money-font text-nowrap text-primary">
        +{{ formatCurrency(value, currency) }}
      </div>
    </div>
    <div>
      <div class="title text-truncate">
        {{ title }}
      </div>
      <div class="small text-truncate">
        {{ fromName }}
      </div>
    </div>

    <div
      v-if="references && references.length > 0"
      class="duplicates"
    >
      +{{ references.length }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  props: {
    title: String,
    value: Number,
    currency: String,
    fromName: String,
    transactionDate: String,
    status: String,
    references: Array,
    selected: Boolean,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    isSettled() {
      return this.status === 'Settled' || this.status === 'DuplicateSettled';
    },
    isSkipped() {
      return this.status === 'Skipped' || this.status === 'DuplicateSkipped';
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM');
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-row {
  padding: 5px 10px;
  border: 2px solid rgba(100, 100, 100, 0.1);
  border-radius: 15px;
  height: 75px;
  margin-top: 9px;
  transform: rotate(90);
  position: relative;
  cursor: pointer;
  transition: box-shadow 200px;
  position: relative;

  &.skipped {
    background-color: rgba($orange, 0.1);
    border-color: rgba($orange, 0.2);
    padding-left: 20px;
    margin-left: -3px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;

    .status {
      background-color: $orange;
    }

    &:hover {
      background-color: rgba($orange, 0.2);
    }
  }

  &.settled {
    background-color: rgba($blue, 0.1);
    border-color: rgba($blue, 0.2);
    padding-left: 20px;
    margin-left: -3px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;

    .status {
      background-color: $blue;
    }

    &:hover {
      background-color: rgba($blue, 0.2);
    }
  }

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  &.selected {
    border-color: #333;
  }
}

.status {
  position: absolute;
  top: -2px;
  left: -2px;
  line-height: 1;
  z-index: 10;
  font-weight: 400;
  color: white;
  border-radius: 10px;
  font-size: 9px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 13px;

  > div {
    transform: rotate(-90deg);
  }
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
}

.duplicates {
  font-weight: 400;
  border-radius: 50%;
  position: absolute;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  top: -4px;
  right: -4px;
  background-color: $yellow;
}
</style>
