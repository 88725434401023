<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header">
          <h1>
            {{ $t('accounting.generateBillingNotes') }}
          </h1>
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </div>
      </div>
      <hr class="mt-1">
      <div class="row align-items-center">
        <StepSelect
          class="col-9"
          :page="page"
          :enabled="filledPages"
          :list="steps"
          :previous-page="previousPage"
          :previous-bill="previousBill"
          @select="page = $event"
        />
        <div class="col-3 text-right">
          <button
            v-if="page === 'preview'"
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="creatingDocuments || previews.length === 0"
            @click="approve"
          >
            {{ $t('accounting.approve') }}
            <i class="fa-solid fa-check-to-slot px-2" />
          </button>
          <button
            v-else-if="page !== 'summary'"
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="disabled"
            @click="next"
          >
            {{ $t('general.next') }}
            <i class="fas fa-arrow-right px-2" />
          </button>
        </div>
      </div>
      <hr class="mb-2">
    </div>

    <div
      v-if="page === 'payers'"
      class="flex-grow-1"
      style="overflow: hidden"
    >
      <PayersList
        :selected.sync="payers"
      />
    </div>
    <div
      v-else-if="page === 'preview'"
      style="overflow: auto"
    >
      <BillingDocumentsPreview
        :date.sync="date"
        :payers="payers"
        @previews="previews = $event"
      />
    </div>
    <div
      v-else-if="page === 'summary'"
      class="overflow"
    >
      <BillingDocumentsSummary
        :payers="payers"
        :count="previews.length"
        :job-id="jobId"
      />
    </div>
  </div>
</template>

<script>
import BillingDocumentsPreview from '@/components/accounting/BillingDocumentsPreview';
import BillingDocumentsSummary from '@/components/accounting/BillingDocumentsSummary';
import PayersList from '@/components/bills/form/PayersList';
import StepSelect from '@/components/bills/form/StepSelect';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    page: 'payers',
    payers: [],
    bill: {
      isPlan: false,
      isEnabled: true,
      masterAmount: null,
      accountName: '',
      currency: 'pln', // todo: make default currency selectable
      title: '',
      description: '',
      repeatType: 'Month',
      bankAccountId: null,
      paymentTime: '14',
      repeatNumber: '1',
      earlyExecutionDays: 1,
      category: null,
      notify: true,
      from: moment.utc().format('YYYY-MM-DD'),
      to: null,
    },
    jobId: null,
    previews: [],
    pending: false,
    previousBill: null,
    previousPage: null,
    date: null,
    results: null,
    creatingDocuments: false,
  }),
  components: {
    BillingDocumentsPreview,
    BillingDocumentsSummary,
    StepSelect,
    PayersList,
  },
  computed: {
    ...mapGetters([
      'payeeId',
      'billingScopeName',
      'payee',
    ]),
    scopeName() {
      return this.billingScopeName({
        from: this.date,
        type: this.payee?.billingScopeType,
      });
    },
    steps() {
      return [
        {
          key: 'payers',
          text: this.$t('form.selectPayers'),
        },
        {
          key: 'preview',
          text: this.$t('accounting.preview'),
        },
        {
          key: 'summary',
          text: this.$t('form.summary'),
        },
      ];
    },
    disabled() {
      switch (this.page) {
        case 'payers':
          return this.payers.length === 0;
        // case 'price':
        //   return !this.bill.payers.every((x) => x.amount !== null && x.amount !== undefined) || !this.bill.currency;
        // case 'title':
        //   return !this.bill.title.length > 0 && this.bill.category;
        default:
          return false;
      }
    },
    filledPages() {
      const index = this.steps.findIndex((x) => x.key === this.page);
      return this.steps.flatMap((x, i) => (i <= index ? [x.key] : []));
    },
  },
  methods: {
    ...mapActions(['approveBillingDocuments']),
    approve() {
      this.creatingDocuments = true;
      this.approveBillingDocuments({
        data: {
          date: this.date,
          payerIds: this.payers.map((x) => x.id),
        },
      })
        .then(({ data }) => {
          this.page = 'summary';
          this.jobId = data.jobId;
        })
        .finally(() => {
          this.creatingDocuments = false;
        });
    },
    save() {

    },
    next() {
      const index = this.steps.findIndex((x) => x.key === this.page);
      this.page = this.steps[index + 1].key;
      this.filledPages.push(this.page);
    },
    cancel() {
      this.$router.push(`/payee/${this.payeeId}/bills/documents`);
    },
  },
  created() {
    this.$emit('page', 'form');
  },
};
</script>

<style lang="scss" scoped>
  .close {
    font-size: 40px;
  }

  .overflow {
    height: calc(100vh - 200px);
    overflow: auto;
    padding-bottom: 50px;
  }

  h3 {
    font-weight: 500;
  }

  .next-btn {
    border-radius: 25px;
    padding-left: 30px;
    padding-right: 30x;
  }
  @media only screen and (max-width: 768px) {
    .overflow {
      overflow: auto;
    }
  }
</style>
