<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t('general.sortBy') }}
        </div>
        <div
          class="first"
          style="font-size: 14px"
        >
          <i :class="`${sortBy.icon} pr-1`" />
          {{ sortBy.text }}
        </div>
      </div>
    </template>

    <div style="min-width: 180px">
      <div
        v-for="(s, i) in sortOptions"
        :key="s.key"
        class="sort-option text-left"
        :class="{ first: i === 0, active: sortKey === s.key }"
        @click="$emit('update:sortKey', s.key); $refs.popover.tippy().hide()"
      >
        <i :class="`${s.icon} pr-1`" />
        {{ s.text }}
      </div>
    </div>
  </Tippy>
</template>

<script>
export default {
  props: {
    sortKey: String,
  },
  computed: {
    sortBy() {
      return this.sortOptions.find((x) => x.key === this.sortKey);
    },
    sortOptions() {
      return [
        {
          key: 'name',
          icon: 'fal fa-arrow-down-a-z',
          text: this.$t('general.name'),
        },
        {
          key: 'activation',
          icon: 'fal fa-paper-plane',
          text: this.$t('client.activation'),
        },
        {
          key: 'bills',
          icon: 'fal fa-money-bill-wave',
          text: this.$t('menu.bills'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
 @import './style.scss';
</style>
