<template>
  <div
    class="message"
    :class="{
      'company-message': sender.type === 'PayeeId',
      unseen: !seen,
    }"
  >
    <span class="sender-name">
      {{ senderName }}
    </span>
    <div
      class="text-secondary pull-right"
      style="font-size: 12px;"
    >
      {{ timestamp | formatDate }}
    </div>
    <div class="content">
      <RichText
        :value="content"
        style="white-space: pre-line; width: 100%"
      />
    </div>
    <div
      v-if="!seen"
      class="unseen-bouble"
    />
  </div>
</template>

<script>
import RichText from '@/modules/forms/RichText';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    timestamp: String,
    content: String,
    clientId: String,
    sender: Object,
    seen: Boolean,
  },
  components: {
    RichText,
  },
  filters: {
    formatDate(d) {
      return moment(d).format('HH:mm D MMM');
    },
  },
  computed: {
    ...mapGetters(['userPayers']),
    senderName() {
      if (this.sender.type === 'UserId') {
        return this.$t('client.me');
      }
      const payer = this.userPayers?.find((x) => x.payeeId === this.sender.id);
      return payer?.payeeName;
    },
  },
};
</script>

<style lang="scss" scoped>

  .sender-name {
    margin: 0 0 2px;
    font-size: 14px;
    font-weight: 500;
  }

  .unseen-bouble {
    background-color: $red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }

  .message {
    box-shadow: 0 0 5px rgba(100,100,100,0.1);
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 20px;
    font-size: 15px;
    position: relative;

    .content {
      font-size: 13px;
    }

    &.company-message {
      margin-left: 20px;

      .sender-name {
        color: $blue;
      }
    }
  }
</style>
