<template>
  <span>
    <span v-if="repeatType === 'Day'">
      {{ $tc('time.desc.day', repeatNumber, { n: repeatNumber }) }}
    </span>
    <span v-else-if="repeatType === 'Week'">
      {{
        $tc(
          `time.desc.week.${getPluralType(repeatNumber)}`,
          repeatNumber,
          { weekday, n: repeatNumber }
        )
      }}
    </span>
    <span v-else-if="repeatType === 'Month'">
      {{
        $tc(
          `time.desc.month.${getPluralType(repeatNumber)}`,
          repeatNumber,
          { day: monthDay, n: repeatNumber }
        )
      }}
    </span>
  </span>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    repeatType: String,
    repeatNumber: [String, Number],
    from: String,
  },
  computed: {
    weekday() {
      return moment.utc(this.from, 'YYYY-MM-DD').format('dddd');
    },
    monthDay() {
      return moment.utc(this.from, 'YYYY-MM-DD').format('Do');
    },
  },
  methods: {
    getPluralType(m) {
      const n = parseInt(m, 10);
      if (Number.isNaN(n)) return 'zero';
      if (n === 0) return 'zero';
      if (n === 1) return 'one';
      if (n < 5) return 'few';
      return 'many';
    },
  },
};
</script>

<style>

</style>
