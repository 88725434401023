<template>
  <div>
    <div
      v-if="balanceHistory.length > 0"
      class="d-flex justify-content-end text-secondary small text-right pb-1"
      style="font-size: 11px; padding: 0 10px"
    >
      <div style="width: 150px">
        {{ $t('report.balance.paid') }}
      </div>
      <div style="width: 150px">
        {{ $t('report.balance.due') }}
      </div>
      <div style="width: 150px">
        {{ $t('payers.balance') }}
      </div>
    </div>
    <div
      v-for="b in balanceHistory"
      :key="b.id"
    >
      <BillPayerRow
        :name="billingScopeName({
          type: payee.billingScopeType,
          from: b.from,
        })"
      >
        <template #content>
          <div>
            <div class="text-right d-flex align-items-center no-gutters">
              <Tippy
                ref="popover"
                :trigger="b.currencyNumber > 0 ? 'mouseenter focus' : 'manual'"
                interactive
                theme="light"
                placement="bottom"
              >
                <template #trigger>
                  <div
                    class="money-font text-primary small position-relative"
                    style="width: 150px"
                  >
                    <span v-if="b.totalPaid > 0">
                      + {{ formatMoney(b.totalPaid, b.currency) }}
                    </span>
                    <span
                      v-else
                      style="opacity: 0.3"
                    >
                      {{ formatMoney(0, b.currency) }}
                    </span>
                    <span
                      v-if="b.currencyNumber > 0"
                      class="currency-count"
                    >+{{ b.currencyNumber }}</span>
                  </div>
                </template>

                <div
                  v-for="(ob, currency) in b.otherCurrencies"
                  :key="currency"
                  class="money-font text-primary small"
                >
                  + {{ formatMoney(ob.totalPaid, currency) }}
                </div>
              </Tippy>

              <Tippy
                ref="popover"
                :trigger="b.currencyNumber > 0 ? 'mouseenter focus' : 'manual'"
                interactive
                theme="light"
                placement="bottom"
              >
                <template #trigger>
                  <div
                    class="money-font small position-relative"
                    style="width: 150px"
                  >
                    <span v-if="b.totalDue > 0">
                      - {{ formatMoney(b.totalDue, b.currency) }}
                    </span>
                    <span
                      v-else
                      style="opacity: 0.3"
                    >
                      {{ formatMoney(0, b.currency) }}
                    </span>
                    <span
                      v-if="b.currencyNumber > 0"
                      class="currency-count"
                    >+{{ b.currencyNumber }}</span>
                  </div>
                </template>
                <div
                  v-for="(ob, currency) in b.otherCurrencies"
                  :key="currency"
                  class="money-font small"
                >
                  - {{ formatMoney(ob.totalDue, currency) }}
                </div>
              </Tippy>
              <Tippy
                ref="popover"
                :trigger="b.currencyNumber > 0 ? 'mouseenter focus' : 'manual'"
                interactive
                theme="light"
                placement="bottom"
              >
                <template #trigger>
                  <div
                    class="money-font small position-relative"
                    style="width: 150px"
                    :class="{
                      'text-primary': b.balanceAfter > 0,
                      'text-secondary': b.balanceAfter == 0,
                    }"
                  >
                    <span v-if="b.balanceAfter > 0">+</span>
                    {{ formatMoney(b.balanceAfter || 0, b.currency) }}
                    <span
                      v-if="b.currencyNumber > 0"
                      class="currency-count"
                    >+{{ b.currencyNumber }}</span>
                  </div>
                </template>
                <div
                  v-for="(ob, currency) in b.otherCurrencies"
                  :key="currency"
                  class="money-font small"
                >
                  - {{ formatMoney(ob.balanceAfter, currency) }}
                </div>
              </Tippy>
              <!-- <div
                class="money-font p-1"
                style="width: 80px"
              >
                <div
                  v-if="b.totalDue"
                  class="d-flex justify-content-end align-items-center small"
                >
                  <CircleChart
                    :value="b.totalSettled / b.totalDue * 100"
                    :stroke-width="7"
                    style="height: 20px; width: 20px; margin-top: -8px; margin-bottom: -8px;"
                    class="mr-2"
                  />
                  <div style="width: 30px">
                    {{ Math.round(b.totalSettled / b.totalDue * 100) }}%
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </template>
      </BillPayerRow>
    </div>
    <div
      v-if="!more || firstYear <= year"
      class="d-flex justify-content-center"
      @click="showMore"
    >
      <div class="small see-more px-4 font-weight-bold">
        <i class="fas fa-chevron-down pr-2" />
        <span v-if="more">
          {{ year }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BillPayerRow from '@/components/bills/edit/BillPayerRow';
// import CircleChart from '@/components/charts/CircleChart';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const takeMonths = 3;

export default {
  props: {
    payerId: String,
    balance: Array,
    more: Boolean,
  },
  data: () => ({
    selected: null,
    to: moment().startOf('month').add(1, 'months'),
    balanceHistory: [],
    takeMoreYears: 0,
  }),
  components: {
    BillPayerRow,
    // CircleChart,
  },
  computed: {
    ...mapGetters(['formatMoney', 'billingScopeName', 'payee']),
    firstYear() {
      return moment.utc(this.payee.oldestBillingScope).year();
    },
    from() {
      if (!this.more) {
        return moment()
          .startOf('month')
          .subtract(takeMonths, 'months');
      }

      return moment()
        .startOf('month')
        .subtract(takeMonths, 'months')
        .startOf('year')
        .subtract(this.takeMoreYears, 'years');
    },
    year() {
      return moment(this.from).subtract(1, 'year').year();
    },
  },
  watch: {
    more(v) {
      if (!v) {
        this.balanceHistory = this.balanceHistory.slice(0, takeMonths);
        this.takeMoreYears = 0;
      } else {
        this.requestBills();
      }
    },
    takeMoreYears(v) {
      if (v > 0) {
        this.requestBills();
      }
    },
  },
  methods: {
    ...mapActions([
      'getPayerBillingTimeline',
    ]),
    showMore() {
      if (this.more) {
        this.takeMoreYears += 1;
      } else {
        this.$emit('more');
      }
    },
    select(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
      }
    },
    requestBills() {
      this.getPayerBillingTimeline({
        params: {
          payerId: this.payerId,
          query: {
            from: this.from.format('YYYY-MM-DD'),
            to: this.to.format('YYYY-MM-DD'),
          },
        },
      })
        .then(({ data }) => {
          const history = data.balanceHistory
            .flatMap((x) => x.entries.map((e) => ({ ...e, currency: x.currency })));

          const groupByPeriod = history.reduce((acc, curr) => {
            if (curr.totalDue > 0 || curr.totalPaid > 0 || curr.balanceAfter !== 0) {
              if (acc[curr.billingScope.from]) {
                acc[curr.billingScope.from].push(curr);
              } else {
                acc[curr.billingScope.from] = [curr];
              }
            }

            return acc;
          }, {});

          this.balanceHistory = Object.keys(groupByPeriod)
            .sort((a, b) => (moment(b, 'YYYY-MM-DD').isBefore(moment(a, 'YYYY-MM-DD')) ? -1 : 1))
            .map((date) => {
              const byCurrency = groupByPeriod[date]
                .reduce((acc, curr) => {
                  acc[curr.currency] = curr;
                  return acc;
                }, {});

              const base = (byCurrency.pln || byCurrency.eur || byCurrency.usd);
              delete byCurrency[base.currency];

              return {
                from: date,
                ...base,
                otherCurrencies: byCurrency,
                currencyNumber: Object.keys(byCurrency).length,
              };
            });
        });
    },
  },
  created() {
    this.requestBills();
  },
};
</script>

<style lang="scss" scoped>
  .see-more {
    padding: 2px 15px;
    margin-top: 2px;
    cursor: pointer;
    background-color: #fbfbfb;
    border-radius: 15px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
  .currency-count {
    position: absolute;
    white-space: nowrap;
    font-size: 0.6rem;
    right: -12px;
    top: -5px;
  }

  .money-font {
    font-size: 0.8rem;
  }

  .payer-bill-row {
    margin: 0 0 5px 0;
    z-index: 2;
    padding: 5px 10px;
    background-color: #fbfbfb;
    border-radius: 10px;
    position: relative;
    transition: transform 500ms;

    .client-name {
      font-weight: 500;
    }
  }

  .payer-row {
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }
</style>
