<template>
  <div
    class="d-flex"
    :class="{ focused }"
  >
    <div
      class="width-transition w-100 h-100"
      :style="{
        'flex-shrink': 0,
        'flex-basis': listWidth,
        'max-width': edit ? '50%' : '100%',
        'opacity': focused ? 0 : 1,
        'margin-left': focused ? '-70%' : null,
      }"
    >
      <slot />
    </div>
    <div class="focus-margin" />
    <div
      class="width-transition h-100"
      :style="{
        'flex-basis': edit ? (focused ? '80%' : '50%') : 0,
        'width': edit ? (focused ? '80%' : '50%') : 0,
        'min-width': edit ? (focused ? '80%' : '50%') : 0,
      }"
    >
      <slot name="edit" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    focused: Boolean,
    edit: Boolean,
    modal: Boolean,
  },
  computed: {
    listWidth() {
      if (this.edit && !this.modal) {
        return '50%';
      }
      return '100%';
    },
  },
};
</script>

<style lang="scss" scoped>
  .focus-margin {
    flex-basis: 0;
    transition: flex-basis 150ms;
  }

  .focused > .focus-margin {
    flex-basis: 30%;
  }

  .width-transition {
    transition: flex 100ms, opacity 150ms, margin 150ms, max-width 200ms;
  }

</style>
