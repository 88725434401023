<template>
  <div class="thread-form">
    <div>
      <div class="d-flex justify-content-center font-weight-bold">
        {{ $t('paymentStatus.reportTitle') }}
      </div>
      <div class="col-12 col-md-2 px-0">
        <button
          class="btn btn-sm btn-block btn-outline-secondary"
          style="border-radius: 15px; padding-left: 18px; padding-right: 18px"
          @click="$emit('close')"
        >
          <i class="fas fa-angle-left pr-2" />
          {{ $t('paymentStatus.goBack') }}
        </button>
      </div>
      <div
        v-if="!success"
        class="reply-box mt-2"
      >
        <div class="position-relative">
          <span class="char-no">({{ text.length }} {{ $t('messages.characters') }})</span>
          <textarea
            v-model="text"
            class="form-control pt-3"
            rows="6"
            data-test="company-message-content"
            :placeholder="`${$t('messages.message')}...`"
          />
        </div>
        <div
          class="d-flex justify-content-end"
          style="border-top: 1px solid #eee"
        >
          <div class="text-right actions">
            <button
              v-if="!pending"
              class="btn btn-success"
              data-test="company-send-message"
              @click="send"
            >
              <i class="fas fa-paper-plane pr-2" />
              {{ $t('general.send') }}
            </button>
            <div v-else>
              <Loader size="25px" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center mt-3"
      >
        <div style="font-size: 40px; margin-bottom: 20px;">
          <i class="fas fa-check" />
        </div>
        <div
          style="max-width: 500px; margin: auto"
          v-html="$t('paymentStatus.messageSent')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/api/axios';

export default {
  props: {
    id: String,
    date: String,
    hash: String,
  },
  data: () => ({
    text: '',
    pending: false,
    error: false,
    success: false,
  }),
  methods: {
    send() {
      this.pending = true;
      axios
        .post(`feedback/message/${this.id}/${this.date}/${this.hash}`, {
          message: this.text,
        })
        .then(() => {
          this.pending = false;
          this.success = true;
        })
        .catch(() => {
          this.pending = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    border: none;
    height: 50px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .form-control:focus {
    border-color: none;
    box-shadow: none;
  }

  .char-no {
    position: absolute;
    bottom: 3px;
    right: 10px;
    font-size: 10px;
    color: #888;
  }

  .recipient {
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 10px;
    margin: 0px 5px;
    background-color: rgba(100,100,100, 0.2);
  }

  .reply-box {
    box-shadow: 0 0 5px rgba(100,100,100,0.3);
    border-radius: 10px;

    .custom-control-label {
      font-weight: 400;
      font-size: 14px;
    }

    .actions {
      padding: 5px;
    }

    textarea {
      border: none;
      border-radius: 0;
      border-top: 1px solid #eee;
      // border-top-left-radius: 10px;
      // border-top-right-radius: 10px;
    }
  }
</style>
