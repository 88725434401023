<template>
  <div class="d-flex align-items-center">
    <div class="client-icon">
      <i
        v-if="type === 'Organization'"
        class="fas fa-building"
        style="width: 20px"
      />
      <i
        v-else-if="type === 'Person'"
        class="fas fa-person-simple"
        style="width: 20px"
      />
    </div>
    <div
      class="client-name"
    >
      {{ name }}
      <div class="text-secondary code">
        {{ code }}
        <template v-if="number">
          <span class="separator">|</span> <span class="number">{{ number }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    name: String,
    code: String,
    number: Number,
  },
};
</script>

<style lang="scss" scoped>
  .client-icon {
    font-size: 1.5625em;
    margin-right: 0.9375em;
    border-radius: 50%;
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    line-height: 1.68em;
    background-color: #efefef;
  }

  .client-name {
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.3;

    .code {
      font-size: 0.75rem;
    }
    .number {
      font-weight: normal;
      color: #ccc;
    }
    .separator {
      font-weight: normal;
      color: #ccc;
    }
  }
</style>
