<template>
  <div>
    <div class="filter-header">
      {{ text }}
    </div>
    <div
      class="sort-option-selected d-flex align-items-center"
      @click.stop="$emit('input', !value)"
    >
      <BFormCheckbox
        switch
        size="md"
        class="white-switch white-switch-sm"
        :checked="value"
        disabled
      />
      <span
        class="text-uppercase small"
        style="margin-left: -15px"
      >
        {{ value ? $t('general.yes') : $t('general.no') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    text: String,
  },
  data: () => ({

  }),
};
</script>

<style lang="scss" scoped>
@import './small-filter.scss';
</style>
