<template>
  <div class="row">
    <div class="col-6">
      <div class="font-weight-bold">
        {{ $t('page.visableColumns') }}
      </div>
      <Draggable
        :value="migratedLayout"
        class="draggable"
        ghost-class="ghost"
        group="columns"
        :class="{ moving }"
        @input="$emit('update:layout', $event)"
        @start="moving = true"
        @end="moving = false"
      >
        <div
          v-for="c in migratedLayout"
          :key="c.key"
          class="column"
        >
          <i :class="icon(c.key)" />
          {{ name(c.key) }}
        </div>
      </Draggable>
    </div>
    <div class="col-6">
      <div class="font-weight-bold">
        {{ $t('page.hiddenColumns') }}
      </div>
      <Draggable
        v-model="hiddenLayout"
        class="draggable"
        ghost-class="ghost"
        group="columns"
        :class="{ moving }"
        @start="moving = true"
        @end="moving = false"
      >
        <div
          v-for="c in hiddenLayout"
          :key="c.key"
          class="column"
        >
          <i :class="icon(c.key)" />
          {{ name(c.key) }}
        </div>
      </Draggable>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

export default {
  props: {
    layout: Array,
    noCategories: Boolean,
  },
  data: () => ({
    moving: false,
    hiddenLayout: [],
  }),
  components: {
    Draggable,
  },
  computed: {
    ...mapGetters([
      'payee',
      'payeeProperty',
      'userGroups',
      'properties',
    ]),
    // Remove obsolete fields, migrate old ones
    migratedLayout() {
      return this.layout
        .filter((x) => x.type !== 'property' || this.payerProperties.some((y) => y.key === x.key))
        .filter((x) => x.type !== 'group' || this.payerGroups.some((y) => y.key === x.key))
        .filter((x) => x.type !== 'category' || this.categories.some((y) => y.key === x.key))
        .map((x) => {
          if (x.key === 'clientCode') {
            return { ...x, key: 'code' };
          }

          return x;
        });
    },
    categories() {
      if (this.noCategories) return [];
      return (this.payee.categories || [])
        .map((x) => ({
          name: x.name,
          key: x.id,
          type: 'category',
          colorCode: x.colorCode,
        }));
    },
    payerGroups() {
      return this.userGroups.map((x) => ({
        name: x.path,
        key: x.code,
        type: 'group',
      }));
    },
    payerProperties() {
      return this.properties.map((x) => ({
        name: x.name,
        key: x.code,
        type: 'property',
      }));
    },
    defaultColumns() {
      return [{
        name: this.$t('general.name'),
        key: 'name',
      },
      {
        name: this.$t('client.clientCode'),
        key: 'code',
      },
      {
        name: this.$t('client.number'),
        key: 'number',
      },
      {
        name: this.$t('page.space'),
        key: 'space',
      },
      ...(this.noCategories ? [] : [{
        name: this.$t('settings.billsCategories'),
        key: 'categories',
      }]),
      ];
    },
    columns() {
      return [
        ...this.defaultColumns,
        ...this.categories,
        ...this.payerProperties,
        ...this.payerGroups,
      ];
    },
  },
  methods: {
    name(key) {
      return this.columns.find((x) => x.key === key)?.name;
    },
    icon(key) {
      const column = this.columns.find((x) => x.key === key)?.type;
      if (column === 'category') {
        return 'far fa-box-dollar pr-1';
      }
      if (column === 'group') {
        return 'far fa-users pr-1';
      }
      if (column === 'property') {
        return 'far far fa-tag pr-1';
      }

      return null;
    },
    init() {
      this.hiddenLayout = [];
      this.defaultColumns.forEach((c) => {
        if (!this.migratedLayout.some(({ key }) => key === c.key)) {
          this.hiddenLayout.push({ key: c.key, type: 'default' });
        }
      });
      this.categories.forEach((c) => {
        if (!this.migratedLayout.some(({ key }) => key === c.key)) {
          this.hiddenLayout.push({ key: c.key, type: 'category' });
        }
      });
      this.payerProperties.forEach((c) => {
        if (!this.migratedLayout.some(({ key }) => key === c.key)) {
          this.hiddenLayout.push({ key: c.key, type: 'property' });
        }
      });
      this.payerGroups.forEach((c) => {
        if (!this.migratedLayout.some(({ key }) => key === c.key)) {
          this.hiddenLayout.push({ key: c.key, type: 'group' });
        }
      });
    },
  },
  created() {
    this.init();
  },
};

</script>

<style lang="scss" scoped>

.draggable {
  border: 1px solid transparent;
  border-radius: 10px;
  min-height: 50px;
  background-color: #f8f8f8;
  padding: 5px;
  margin-top: 5px;
  min-height: 200px;
  padding-bottom: 40px;
}

.draggable.moving {
  border: 1px solid #aaa;
}

.moving * {
  cursor: grabbing;
}
.column {
  padding: 0.3rem 0.6rem;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #eee;
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
}
</style>
