<template>
  <div>
    <div class="mt-1">
      <EditableList
        ref="list"
        :list="bankAccountList"
        :pending="pending"
        :valid="isValid"
        :remove-warning="$t('settings.bankAccounts.alertDeleteAcc')"
        :form-add-title="$t('settings.bankAccounts.addBankAccount')"
        :form-edit-title="$t('settings.editField')"
        @submit="$refs.form.submit()"
        @remove="$refs.form.remove()"
      >
        <template #listElement="{ row }">
          <span class="text-truncate flex-grow-1">
            {{ row.name }}
          </span>
          <span class="pr-2">
            <i
              v-if="row.isDefault"
              v-tippy
              :content="$t('general.default')"
              class="fas fa-star text-secondary"
            />
          </span>
          <span>
            <i
              v-if="row.isVerified"
              v-tippy
              :content="$t('settings.bankAccounts.verifyDone')"
              class="fas fa-check text-success"
            />
            <i
              v-else-if="row.isRejected"
              v-tippy
              :content="$t('settings.bankAccounts.rejected')"
              class="fas fa-times text-danger"
            />
            <i
              v-else
              v-tippy
              :content="$t('settings.bankAccounts.waitingToVerify')"
              class="far fa-clock text-secondary"
            />

          </span>
        </template>
        <template #form="{ edited }">
          <div>
            <BankAccountForm
              ref="form"
              :pending.sync="pending"
              :selected="bankAccountList.find(x => x.id === edited)"
              @valid="isValid = $event"
              @close="$refs.list.close()"
            />
          </div>
        </template>
      </EditableList>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EditableList from '@/components/settings/EditableList';
import BankAccountForm from '@/components/settings/BankAccountForm';

export default {
  data: () => ({
    pending: false,
    isValid: true,
  }),
  components: {
    EditableList,
    BankAccountForm,
  },
  computed: {
    ...mapGetters(['bankAccounts']),
    bankAccountList() {
      return this.bankAccounts.map((x) => ({
        id: x.accountNumber,
        ...x,
      }));
    },
  },
  created() {
    this.$emit('page', 'CurrenciesProperties');
  },
};

</script>
<style>

</style>
