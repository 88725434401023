<template>
  <div>
    <PayerProperties
      :property-values="properties"
      read-only
    />
    <div class="row no-gutters align-items-center group-row">
      <div
        class="pr-2 small text-secondary col-4 group-name"
      >
        <i class="fas fa-users" />
        {{ $t('groups.groups') }}:
      </div>
      <div
        class="d-flex flex-wrap col-8"
        style="margin-left: -3px"
      >
        <div
          v-for="g in groupOptions"
          :key="g.code"
          class="font-weight-bold group-label mr-1"
        >
          {{ g.path }}
        </div>
        <div v-if="groupOptions.length === 0">
          -
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PayerProperties from '../form/PayerProperties';

export default {
  props: {
    groupCodes: Array,
    organizationDescription: Object,
    personDescription: Object,
    properties: Array,
    type: String,
  },
  components: {
    PayerProperties,
  },
  computed: {
    ...mapGetters(['groups']),
    groupOptions() {
      return (this.groups || [])
        .filter((x) => this.groupCodes.includes(x.code))
        .filter((x) => x.code !== '#all');
    },
  },
};
</script>

<style lang="scss" scoped>

.group-name {
  i {
    width: 15px;
    margin-right: 4px;
    text-align: center;
  }
}
.group-row {
  min-height: 20px;
  padding: 5px;

  &:hover {
    background-color: #f6f6f6;
  }

  &.edited {
    min-height: 38px;
  }
}
</style>
