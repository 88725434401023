import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueScrollTo from 'vue-scrollto';
import VueTippy, { TippyComponent } from 'vue-tippy';
import PortalVue from 'portal-vue';
import Checkbox from '@/components/utils/Checkbox';
import SearchBox from '@/components/utils/SearchBox';
import TooltipHelp from '@/components/settings/TooltipHelp';
import '@/utils/extensions';

// main scss file
import './styles/app.scss';

import App from './App';
import router from './router';
import store from './store';
import i18n from './lang';
import { money, accountNumber } from './utils/filters';
import setInterceptors from './api/interceptors';

Vue.use(PortalVue);
Vue.use(VueScrollTo);

Vue.use(VueTippy, { arrow: true });

// vue-bootstrap components
Vue.use(BootstrapVue);
Vue.component('Checkbox', Checkbox);
Vue.component('Tippy', TippyComponent);
Vue.component('SearchBox', SearchBox);
Vue.component('TooltipHelp', TooltipHelp);

Vue.filter('money', money);
Vue.filter('accountNumber', accountNumber);

// set interceptors for api calls
setInterceptors(router);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: (h) => h(App),
});
