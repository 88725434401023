<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="filter-header">
        {{ $t('settings.billsCategories') }}
      </div>
      <div class="sort-option-selected">
        <div style="font-size: 14px">
          {{ selected.length }} /
          {{ categoryOptions.length }}
        </div>
      </div>
    </template>

    <div class="dropdown-scroll">
      <div
        v-for="(s, i) in categoryOptions"
        :key="s.key"
        class="sort-option text-left d-flex align-items-center"
        :class="{
          first: i === 0,
          'font-weight-bold': selected.includes(s.key)
        }"
        @click="toggle(s.key);"
      >
        <Checkbox
          :value="selected.includes(s.key)"
          :emit="false"
          :color="categoryColor(s.key)"
          style="margin-left: -0.5rem"
        />
        <div class="flex-grow-1">
          {{ s.text }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: Array,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters(['payee', 'categoryColor']),
    categoryOptions() {
      return (this.payee.categories || [])
        .map((x) => ({
          key: x.id,
          text: x.name,
          colorCode: x.colorCode,
        }))
        .concat([{
          key: '00000000-0000-0000-0000-000000000000',
          text: this.$t('report.balance.refunds'),
        }])
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  methods: {
    toggle(cid) {
      if (this.selected.includes(cid)) {
        this.$emit('update:selected', this.selected.filter((x) => x !== cid));
      } else {
        this.$emit('update:selected', [...this.selected, cid]);
      }
    },
  },
  created() {
    if (!this.selected || this.selected.length === 0) {
      this.$emit('update:selected', this.categoryOptions.map((x) => x.key));
    }
  },
};
</script>

<style lang="scss" scoped>
 @import './small-filter.scss';
</style>
