<template>
  <div>
    <div class="page-title-wrapper">
      <div class="page-title active">
        {{ $t('report.balanceReport') }}
      </div>
      <!-- <div
        class="page-title ml-4"
        @click="$router.push(`/payee/${payeeId}/reports/settlement`)"
      >
        {{ $t('report.settlementReport') }}
      </div> -->
    </div>
    <div>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <BillingScopeDropdown
            :selected.sync="scopes"
            class="mr-3"
          />
          <PayersGroupFilterDropdown
            :selected.sync="groupFilter"
            class="mr-3"
            hide-edit
          />

          <SearchBox
            v-model="search"
            lazy
            class="mr-3"
          />
        </div>
        <div
          class="px-2"
        >
          <i
            v-if="!pendingDownload"
            v-tippy
            class="fas fa-download secondary-icon"
            :content="$t('general.export')"
            @click="downloadReport"
          />
          <Loader v-else />
        </div>
      </div>

      <div class="px-2">
        <hr class="my-2">
        <div class="d-flex justify-content-between align-items-center">
          <BalanceReportConfig
            v-if="payee"
            :config.sync="config"
          />
          <div>
            <Loader
              v-if="pendingLayoutSet"
              size="20px"
            />
            <i
              v-else
              class="secondary-icon mr-2"
              :class="saved ? 'fas fa-check' : 'fas fa-floppy-disk'"
              @click="saveConfig"
            />
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2 mb-3">
    <div style="overflow-x: auto; flex-shrink: 0; flex-basis: 100%;">
      <div
        style="min-width: min-content; width: 100%;"
        class="pl-3 pt-4"
      >
        <div
          class="header d-flex mx-0"
          :style="{ 'padding-right': `${scrollPadding}px`}"
        >
          <template
            v-for="col in config.layout"
          >
            <div
              :key="col.key"
              class="column label border-right"
              :class="{
                'client-code': col.key === 'code',
                'client-code': col.key === 'number',
                'name': col.key === 'name',
              }"
            >
              <template v-if="col.key === 'name'">
                {{ $t('general.name') }}
              </template>
              <template v-if="col.key === 'code'">
                {{ $t('client.clientCode') }}
              </template>
              <template v-if="col.key === 'number'">
                {{ $t('client.number') }}
              </template>
              <template v-else-if="col.type === 'property'">
                {{ propertyName(col.key) }}
              </template>
              <template v-else-if="col.type === 'group'">
                {{ groupName(col.key) }}
              </template>
            </div>
          </template>
          <div class="flex-grow-1" />
          <div class="column label text-right border-right balance-column">
            {{ $t('report.balance.balanceBefore') }}
          </div>
          <div
            v-for="col in dataColumns"
            :key="col.key"
            class="d-flex position-relative"
          >
            <div
              v-if="col.name"
              class="column-group-name"
            >
              <div class="d-flex align-items-center justify-content-center">
                <CategoryInfo
                  v-if="col.categoryId"
                  :category-id="col.categoryId"
                  color-only
                />
                {{ col.name || '&nbsp;' }}
              </div>
              <div
                class="column-group-border"
                :style="{ 'border-color': categoryColor(col.categoryId) }"
              />
            </div>
            <div
              v-if="col.showPaid"
              class="column label text-right border-right"
            >
              {{ $t('report.balance.paid') }}
            </div>
            <div
              v-if="col.showRefund"
              class="column label text-right border-right"
            >
              {{ $t('report.balance.refund') }}
            </div>
            <div
              v-if="col.showDue"
              class="column label text-right border-right"
            >
              {{ $t('report.balance.due') }}
            </div>
          </div>
          <div
            class="column label text-right border-right balance-column"
          >
            {{ $t('report.balance.balanceAfter') }}
          </div>
        </div>
        <div
          ref="reportContent"
          style="overflow-y: scroll; padding-bottom: 50px"
          :style="{ height: `calc(100vh - ${reportContentHeight}px)`}"
        >
          <div
            v-if="error"
            class="text-danger text-center mt-3"
          >
            <i
              class="fas fa-times"
              style="font-size: 25px"
            />
            <div>
              {{ $t('error.error') }}
            </div>
          </div>
          <template v-else-if="report">
            <div
              v-for="payer in visiblePayers"
              :key="payer.id"
              class="d-flex report-row align-items-center text-right mx-0"
            >
              <template v-for="col in config.layout">
                <div
                  :key="col.key"
                  class="column text-left border-right text-truncate"
                  :class="{
                    'name': col.key === 'name',
                    'client-code': col.key === 'name',
                    'client-code': col.key === 'number',
                  }"
                >
                  <template v-if="col.key === 'name'">
                    {{ payer.name.name }}
                  </template>
                  <span
                    v-if="col.key === 'number'"
                    class="small text-secondary"
                  >
                    {{ payer.name.number }}
                  </span>
                  <span
                    v-if="col.key === 'code'"
                    v-tippy="{
                      trigger: (payer.name.code || '').length > 15 ? 'mouseenter' : 'manual',
                      delay: 500
                    }"
                    class="small text-secondary"
                    :content="payer.name.code"
                  >
                    {{ payer.name.code || '&nbsp;' }}
                  </span>
                  <span
                    v-if="col.type === 'property'"
                    v-tippy="{
                      trigger: (payer.properties[col.key] || '').length > 15 ? 'mouseenter' : 'manual',
                      delay: 500
                    }"
                    :content="payer.properties[col.key]"
                    class="small"
                  >
                    {{ payer.properties[col.key] || '&nbsp;' }}
                  </span>
                  <span v-if="col.type === 'group'">
                    <i
                      v-if="payer.groups.includes(col.key)"
                      class="fas fa-square-check text-primary"
                    />
                    <span v-else>&nbsp;</span>
                  </span>
                </div>
              </template>

              <div class="flex-grow-1" />
              <div
                class="column money-font small border-right balance-column"
                :class="{ dimmed: !payer.balanceBefore }"
              >
                {{ formatCurrency(payer.balanceBefore, payer.currency) }}
              </div>
              <div
                v-for="col in payerDataColumns(payer)"
                :key="col.key"
                class="d-flex"
              >
                <div
                  v-if="col.showPaid"
                  :class="{ dimmed: !col.totalPaid }"
                  class="column money-font small text-primary border-right"
                >
                  +{{ formatCurrency(col.totalPaid || 0, payer.currency) }}
                </div>
                <div
                  v-if="col.showRefund"
                  :class="{ dimmed: !col.totalRefund }"
                  class="column money-font small border-right"
                >
                  -{{ formatCurrency(col.totalRefund || 0, payer.currency) }}
                </div>
                <div
                  v-if="col.showDue"
                  :class="{ dimmed: !col.totalDue }"
                  class="column money-font small border-right"
                >
                  -{{ formatCurrency(col.totalDue || 0, payer.currency) }}
                </div>
              </div>
              <div
                class="column money-font small border-right balance-column"
                :class="{ dimmed: !payer.balanceAfter }"
              >
                {{ formatCurrency(payer.balanceAfter, payer.currency) }}
              </div>
            </div>
          </template>
          <div v-else-if="scopes">
            <Loader />
          </div>
          <InfinityScrollCursor
            ref="cursor"
            @request="addPayers"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import BillingScopeDropdown from '@/components/billingScopes/BillingScopeDropdown';
import PayersGroupFilterDropdown from '@/components/payers/PayersGroupFilterDropdown';
import BalanceReportConfig from '@/components/reports/BalanceReportConfig';
import createSearch from '@/utils/search';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const balancePropertyName = 'BALANCE_LAYOUT';

export default {
  data: () => ({
    scopes: null,
    report: null,
    groupFilter: '#all',
    columnWith: 150,
    reportContentHeight: 10,
    error: false,
    visiblePayers: [],
    config: {
      categoryIds: [],
      currency: 'pln',
      layout: null,
      splitByCategory: false,
      splitByBillingScope: false,
    },
    take: 30,
    skip: 0,
    pendingDownload: false,
    pendingLayoutSet: false,
    busy: false,
    saved: false,
    all: false,
    search: '',
  }),
  components: {
    CategoryInfo,
    PayersGroupFilterDropdown,
    BalanceReportConfig,
    BillingScopeDropdown,
    InfinityScrollCursor,
  },
  computed: {
    ...mapGetters([
      'payee',
      'formatCurrency',
      'payeeProperty',
      'payeeId',
      'billingScopeName',
      'scrollPadding',
      'userGroups',
      'properties',
      'categoryColor',
    ]),
    dataColumns() {
      if (!this.report) return [];
      if (this.report.categories.length === 0 && this.report.billingScopes.length === 0) {
        return [
          {
            key: '-',
            name: '',
            showRefund: this.config.categoryIds.some((id) => this.refundCategory(id)),
            showDue: true,
            showPaid: true,
          },
        ];
      }

      const categorySplits = this.report.categories.slice()
        .sort((a, b) => this.refundCategory(a.categoryId) - this.refundCategory(b.categoryId))
        .map((c) => ({
          ...c,
          key: c.categoryId,
          showRefund: this.refundCategory(c.categoryId),
          showDue: !this.refundCategory(c.categoryId),
          showPaid: !this.refundCategory(c.categoryId),
          name: this.refundCategory(c.categoryId)
            ? this.$t('report.balance.refund')
            : this.payee?.categories.find((x) => x.id === c.categoryId)?.name || '?',
        }));

      const billingScopeSplits = this.report.billingScopes.slice()
        .sort((a, b) => (moment(a.from, 'YYYY-MM-DD').isBefore(moment(b.from, 'YYYY-MM-DD')) ? -1 : 1))
        .map((x) => ({
          ...x,
          key: x.from,
          showRefund: this.config.categoryIds.some((id) => this.refundCategory(id)),
          showDue: true,
          showPaid: true,
          name: this.billingScopeName({ from: x.from, type: this.payee.billingScopeType }),
        }));

      if (this.report.categories.length === 0 || this.report.billingScopes.length === 0) {
        return [
          ...categorySplits,
          ...billingScopeSplits,
        ];
      }

      return billingScopeSplits.flatMap((bs) =>
        categorySplits.map((c) => ({
          categoryId: c.categoryId,
          from: bs.from,
          showRefund: c.showRefund && bs.showRefund,
          showDue: c.showDue && bs.showDue,
          showPaid: c.showPaid && bs.showPaid,
          key: `${c.categoryId}_${bs.from}`,
          name: `${c.name} - ${bs.name}`,
        })));
    },
  },
  watch: {
    search() {
      this.busy = false;
      this.skip = 0;
      this.visiblePayers = [];
      this.refresh();
    },
    groupFilter() {
      this.busy = false;
      this.skip = 0;
      this.visiblePayers = [];
      this.refresh();
    },
    config: {
      deep: true,
      handler() {
        this.busy = false;
        this.skip = 0;
        this.visiblePayers = [];
        this.refresh();
      },
    },
    scopes(s) {
      this.busy = false;
      this.skip = 0;
      this.visiblePayers = [];

      if (!s) {
        this.report = null;
        return;
      }

      this.refresh();
    },
  },
  methods: {
    ...mapActions([
      'getBalanceReport',
      'setPayeeProperties',
      'getPayeeProperties',
      'exportBalanceReport',
    ]),
    refundCategory(id) {
      return id === '00000000-0000-0000-0000-000000000000';
    },
    initLayout() {
      const configProperty = this.payeeProperty(balancePropertyName);
      try {
        this.config = JSON.parse(configProperty);
      } catch {
        // Unable to deserialize - use default config
      }
    },
    saveConfig() {
      this.pendingLayoutSet = true;
      this.setPayeeProperties({
        data: {
          name: balancePropertyName,
          value: JSON.stringify(this.config),
        },
      })
        .then(() => {
          this.saved = true;

          setTimeout(() => {
            this.saved = false;
          }, 3000);
        })
        .finally(() => {
          this.pendingLayoutSet = false;
        });
    },
    payerDataColumns({ entries }) {
      return this.dataColumns.map((c) => {
        if (c.categoryId && c.from) {
          return {
            ...c,
            ...(entries.find((x) => x.category?.categoryId === c.categoryId && x.billingScope?.from === c.from) || {}),
          };
        } if (c.categoryId) {
          return {
            ...c,
            ...(entries.find((x) => x.category?.categoryId === c.categoryId) || {}),
          };
        } if (c.from) {
          return {
            ...c,
            ...(entries.find((x) => x.billingScope?.from === c.from) || {}),
          };
        }

        return {
          ...c,
          ...entries[0],
        };
      });
    },
    groupName(k) {
      return this.userGroups.find((x) => x.code === k)?.name;
    },
    propertyName(k) {
      return this.properties.find((x) => x.code === k)?.name;
    },
    refresh() {
      if (!this.scopes || this.config.categoryIds.length === 0) return;

      this.getBalanceReport({
        params: {
          from: this.scopes.from,
          to: this.scopes.to,
          query: {
            search: this.search || undefined,
            currency: this.config.currency,
            splitByCategory: this.config.splitByCategory || undefined,
            splitByBillingScope: this.config.splitByBillingScope || undefined,
            group: this.groupFilter === '#all' ? undefined : this.groupFilter,
            categoryIds: this.config.categoryIds,
            columns: this.config.layout.map((x) => x.key),
          },
        },
      })
        .then(({ data }) => {
          this.report = data;
          this.addPayers();
        })
        .catch(() => {
          this.error = true;
        });
    },
    downloadReport() {
      this.pendingDownload = true;
      this.exportBalanceReport({
        params: {
          from: this.scopes.from,
          to: this.scopes.to,
          query: {
            search: this.search || undefined,
            currency: this.config.currency,
            splitByCategory: this.config.splitByCategory || undefined,
            splitByBillingScope: this.config.splitByBillingScope || undefined,
            group: this.groupFilter === '#all' ? undefined : this.groupFilter,
            categoryIds: this.config.categoryIds,
            columns: this.config.layout.map((x) => x.key),
          },
        },
      })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = `Saldo_${this.scopes.from}_${this.scopes.to}`;
          link.setAttribute('download', `${fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.pendingDownload = false;
        });
    },
    filterPayers(payers) {
      if (!this.search) return payers;
      const search = createSearch(this.search);
      return payers.filter((x) => search(x.name.name) || search(x.name.code));
    },
    addPayers() {
      if (this.busy || !this.report) return;
      this.busy = true;

      const newPayers = this.filterPayers(this.report.payers).slice(this.skip, this.skip + this.take);

      if (newPayers.length === 0) {
        this.busy = true;
        return;
      }

      this.visiblePayers.push(...newPayers);
      this.skip += newPayers.length;

      setTimeout(() => {
        this.busy = false;
        if (!this.$refs.cursor || !this.$refs.cursor.isBottom()) {
          this.addPayers();
        }
      }, 200);
    },
  },
  created() {
    this.$emit('page', 'reports');

    this.getPayeeProperties({
      params: {
        query: {
          names: balancePropertyName,
        },
      },
    })
      .finally(() => {
        this.initLayout();
      });
  },
  mounted() {
    this.updateReportContentHeight = () => {
      const reportContent = this.$refs.reportContent.getBoundingClientRect();
      this.reportContentHeight = reportContent.top;
    };
    this.updateReportContentHeight();
    window.addEventListener('resize', this.updateReportContentHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateReportContentHeight);
  },
};
</script>

<style lang="scss" scoped>
.report-row {
  border-bottom: 1px solid #eee;
  &:hover {
    background-color: rgba(#eee, 0.5)
  }
}

.dimmed {
  opacity: 0.25;
}

.client-code {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: no-wrap;
}

.column.name {
  min-width: 250px;
}

.column {
  min-width: 150px;
  max-width: 150px;
  padding-left: 10px;
  padding-right: 10px;
}

.column-group-name {
  position: absolute;
  text-align: center;
  width: 100%;
  top: -25px;
  font-size: 12px;
  font-weight: 500;

  .column-group-border {
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
    height: 15px;
    margin-top: 2px;
    width: calc(100% + 2px);
    margin-left: -1.5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

.header {
  .column {
    font-size: 12px;
  }
  border-bottom: 1px solid #eee;

  .border-right {
    padding-top: 5px;
  }
}

.border-right {
  border-right: 1px solid #eee;
}

.label {
  margin-bottom: 0;
}

.balance-column {
  background-color: rgba(#aeaeae, 0.1);
  padding: 5px 10px;
}
</style>
