export default (template, valueWithSpaces) => {
  const value = valueWithSpaces.replace(/\s/g, '');

  if (value.length !== template.length) {
    return false;
  }

  for (let i = 0; i < template.length; i += 1) {
    if (value[i] < '0' || value[i] > '9') {
      return false;
    }

    if (template[i] !== '_' && template[i] !== value[i]) {
      return false;
    }
  }

  return true;
};
