<template>
  <div v-if="plan">
    <div
      class="d-flex no-gutters"
      style="max-width: 500px; margin-left: auto; margin-right: 0"
    >
      <div class="col pr-1">
        <button
          v-if="addPayers || edit"
          class="btn btn-secondary btn-block btn-sm"
          @click="$emit('cancel')"
        >
          <i class="fas fa-times pr-2" />
          {{ $t('general.cancel') }}
        </button>
        <button
          v-else
          class="btn btn-secondary btn-block btn-sm"
          :disabled="pending"
          @click="addPayers = true; editAmounts = false"
        >
          <i class="fas fa-arrow-left pl-1" />
          {{ $t('general.back') }}
        </button>
      </div>
      <div class="col pl-1">
        <button
          v-if="addPayers && !edit"
          class="btn btn-primary btn-block btn-sm"
          :disabled="pending"
          @click="addPayers = false; editAmounts = true;"
        >
          {{ $t('general.next') }}
          <i class="fas fa-arrow-right pl-1" />
        </button>
        <button
          v-else
          data-test="save-client-details-button"
          class="btn btn-primary btn-block btn-sm"
          :disabled="pending"
          @click="submit"
        >
          <i class="fas fa-check pr-2" />
          {{ $t('general.save') }}
        </button>
      </div>
    </div>
    <hr>
    <div>
      <div v-if="addPayers && !edit">
        <PayersList
          :selected.sync="payers"
          :exclude-billing-plan-id="plan.id"
        />
      </div>
      <div v-else>
        <PayersAmountList
          v-if="!loadingPayers"
          :billing-plan-id="plan.id"
          :init-bank-account-id="(plan.accountNumber || '').toString()"
          :init-currency="plan.currency"
          :payers="payers"
          :removed.sync="removed"
          :allow-remove="true"
          :init-master-amount="masterAmount"
          class="pb-4"
          @payers="payers = $event"
          @removed="removed = $event"
          @bankAccountId="bankAccountId = $event"
          @masterAmount="masterAmount = $event"
        />
        <Loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PayersList from '../form/PayersList';
import PayersAmountList from '../form/PayersAmountList';

export default {
  props: {
    plan: Object,
    edit: Boolean,
  },
  data: () => ({
    payers: [],
    removed: [],
    addPayers: true,
    pending: false,
    bankAccountId: null,
    masterAmount: null,
    loadingPayers: false,
  }),
  components: {
    PayersList,
    PayersAmountList,
  },
  methods: {
    ...mapActions([
      'getBillingPlanPayers',
      'addBillingPlanPayers',
      'updateBillingPlanPayers',
    ]),
    cancel() {
      this.addPayers = false;
      this.$emit('focus', false);
    },
    submit() {
      this.submitBill();
    },
    submitBill() {
      this.pending = true;
      if (this.edit) {
        this.updateBillingPlanPayers({
          params: {
            billingPlanId: this.plan.id,
          },
          data: {
            masterAmount: this.masterAmount,
            removePayers: this.removed,
            accountNumber: this.bankAccountId,
            updatePayers: this.payers.map((x) => ({
              amount: Math.round(x.amount * x.count),
              count: x.count,
              payerId: x.id,
            })),
          },
        })
          .then(({ data }) => {
            this.$emit('update', data);
          })
          .finally(() => {
            this.pending = false;
          });
      } else {
        this.addBillingPlanPayers({
          params: {
            billingPlanId: this.plan.id,
          },
          data: {
            payers: this.payers.map((x) => ({
              amount: x.amount * x.count,
              count: x.count,
              payerId: x.id,
            })),
          },
        })
          .then(({ data }) => {
            this.$emit('update', data);
          })
          .finally(() => {
            this.pending = false;
          });
      }
    },
    loadPayers() {
      this.loadingPayers = true;
      this.getBillingPlanPayers({
        params: {
          billingPlanId: this.plan.id,
          query: {
            sort: 'Name',
          },
        },
      })
        .then(({ data }) => {
          this.payers = data.map((x) => ({
            ...x,
            count: x.count,
            amount: x.amount / x.count,
          }));
        })
        .finally(() => {
          this.loadingPayers = false;
        });
    },
  },
  created() {
    this.masterAmount = this.plan.masterAmount;
    this.payers = [];
    if (this.edit && this.plan) {
      this.loadPayers();
    }
  },
};
</script>

<style lang="scss" scoped>

  .sort-option-selected  {
    border: 1px solid rgba(100, 100, 100, 0.1);
    cursor: pointer;
    padding: 2px 10px;
    border-radius: 10px;
    &:hover {
      background-color: rgba(100, 100, 100, 0.1);
    }
  }

  .sort-option {
    font-size: 14px;
    padding: 8px 10px;
    border-top: 1px solid #efefef;
    cursor: pointer;

    &.active {
      font-weight: 500;
      color: $blue;
    }

    &.first {
      border-top: none;
    }

    &:hover {
      background-color: #efefef;
    }
  }

  .fixed-height {
    max-height: 500px;
    height: 500px;
    overflow: auto;
    padding: 0 15px 0 15px;
    margin-left: -25px;
    margin-right: -25px;
  }

  .verify {
    height: 18px;
    width: 18px;
    font-size: 18px;
    text-align: center;
    background-color: $blue;
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 5px;
    right: -10px;
    line-height: 1;
    z-index: 100;
    i {
      padding-left: 1px;
    }
  }

</style>
