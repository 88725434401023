<template>
  <div
    v-if="importedCount > 0 || errors.length > 0"
    class="p-3"
  >
    <div class="font-weight-bold text-primary">
      {{ $t('import.imported') }}: {{ importedCount }}
    </div>

    <div class="mt-2">
      <div class="font-weight-bold text-danger">
        {{ $t('import.errors') }}: {{ errors.length }}
      </div>
      <div class="mt-2">
        <div
          v-for="e in errors"
          :key="e.name"
        >
          - {{ e.name }}: <span class="font-weight-bold text-danger"> {{ e.error }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <SwitchWithDescription
          class="mt-0 pr-3"
          :title="$t('import.setGroups')"
          :pending="pending"
          :value="overwriteGroups"
          @switch="overwriteGroups = $event"
        />
      </div>
      <div
        class="d-flex no-gutters flex-grow-1"
        style="max-width: 500px; margin-left: auto; margin-right: 0"
      >
        <div class="col pr-1">
          <button
            class="btn btn-secondary btn-block btn-sm"
            @click="$emit('cancel')"
          >
            <i class="fas fa-times pr-2" />
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="col pl-1">
          <button
            class="btn btn-primary btn-block btn-sm"
            :disabled="pending"
            @click="submit"
          >
            <i class="fas fa-check pr-2" />
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>
    <hr>
    <div
      class="pt-2 pb-4"
      style="overflow: auto; height: calc(100vh - 205px)"
    >
      <table class="table table-sm table-responsive">
        <thead class="import-table-header">
          <tr>
            <th />
            <th style="min-width: 180px">
              {{ $t('client.lastName') }}
            </th>
            <th style="min-width: 180px">
              {{ $t('client.firstName') }}
            </th>
            <th
              v-if="payerCodeImported"
              style="min-width: 180px"
            >
              {{ $t('client.clientCode') }}
            </th>
            <th
              v-for="p in propertyColumns"
              :key="p.code"
              style="min-width: 180px"
            >
              <i
                :class="p.icon"
                class="pr-1"
              />
              {{ p.name }}
            </th>
            <th />
            <template v-if="overwriteGroups">
              <th
                v-for="g in userGroups"
                :key="`group_${g.code}`"
                class="text-center group-name-cell"
              >
                <div class="d-flex justify-content-center">
                  <Checkbox
                    :value="allInGroup(g.code)"
                    @update:value="toggleGroup(g.code, $event)"
                  />
                </div>
                <div class="mt-1">
                  {{ g.path }}
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(payer, index) in payers"
            :key="payer.id"
          >
            <td style="vertical-align: middle">
              <i
                class="fas fa-xmark secondary-icon"
                :content="$t('general.remove')"
                @click="remove(index)"
              />
            </td>
            <td>
              <BFormInput
                v-model="payer.lastName"
                :disabled="pending"
                size="sm"
              />
            </td>
            <td>
              <BFormInput
                v-model="payer.firstName"
                :disabled="pending"
                size="sm"
              />
            </td>
            <td v-if="payerCodeImported">
              <BFormInput
                v-model="payer.code"
                :disabled="pending"
                size="sm"
              />
            </td>
            <td
              v-for="p in propertyColumns"
              :key="p.code"
            >
              <BFormInput
                v-model="payer.properties[p.code]"
                :disabled="pending"
                size="sm"
              />
            </td>

            <td
              style="vertical-align: middle"
            >
              <div
                v-for="c in payer.updateCandidates"
                :key="c.id"
                class="d-flex justify-content-left align-items-center"
              >
                <Checkbox
                  :value="payer.updateId === c.id"
                  @update:value="$set(payer, 'updateId', ($event ? c.id : null))"
                />
                <div
                  class="pl-1 small text-nowrap text-left"
                  style="line-height: 1"
                >
                  <PayerName v-bind="c" />
                </div>
              </div>
            </td>
            <template v-if="overwriteGroups">
              <td
                v-for="g in userGroups"
                :key="`group${g.code}`"
                class="group-name-cell"
                style="vertical-align: middle"
              >
                <div class="d-flex justify-content-center">
                  <Checkbox
                    :value.sync="payer.groups[g.code]"
                  />
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    import: Array,
    columns: Array,
  },
  data: () => ({
    pending: false,
    payers: [],
    overwriteGroups: false,
    errors: [],
    importedCount: 0,
  }),
  components: {
    PayerName,
    SwitchWithDescription,
  },
  computed: {
    ...mapGetters([
      'properties',
      'payersPropertyTypes',
      'userGroups',
    ]),
    propertyColumns() {
      return this.columns.filter((x) => this.properties.some((p) => p.code === x.code));
    },
    payerCodeImported() {
      return this.columns.some((x) => x.code === 'code');
    },
  },
  methods: {
    ...mapActions(['addPayersBatch']),
    submit() {
      this.pending = true;
      this.addPayersBatch({
        data: {
          overwriteGroups: this.overwriteGroups,
          payers: this.payers.map((x) => ({
            updateId: x.updateId,
            type: x.type,
            firstName: x.firstName,
            lastName: x.lastName,
            code: x.code,
            groupCodes: Object.keys(x.groups),
            properties: Object.entries(x.properties)
              .map(([key, value]) => ({ key, value })),
          })),
        },
      })
        .then(({ data }) => {
          this.errors = data.filter((x) => x.error);
          this.importedCount = data.length - this.errors.length;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove(i) {
      this.payers.splice(i, 1);
    },
    toggleAll() {
      const way = this.allChecked;
      Object.keys(this.clients)
        .forEach((i) => {
          this.clients[i].notify = !way;
        });
    },
    toggleAllUpdate() {
      const way = this.allUpdateChecked;
      Object.keys(this.clients)
        .forEach((i) => {
          this.clients[i].update = !way;
        });
    },
    toggleGroup(id, v) {
      this.payers.forEach((x) => {
        this.$set(x.groups, id, v);
      });
    },
    allInGroup(id) {
      return this.payers.reduce((acc, curr) => {
        if (!curr.groups[id]) return false;
        return acc;
      }, true);
    },
  },
  created() {
    this.payers = this.import.map((x) => ({
      ...x,
      ...x.personDescription,
      groups: {},
      id: `${x.personDescription.firstName}_${x.personDescription.lastName}_${x.personDescription.code}`,
      properties: x.properties.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {}),
      updateId: x.updateCandidates[0]?.id,
    }));
  },
};
</script>

<style lang="scss" scoped>
  .import-table-header {
    th {
      font-weight: 500;
      font-size: 12px;
      color: #9A9A9A;
    }
  }

  .group-name-cell {
    border-left: 1px solid #eee;
    font-size: 12px;
    line-height: 1.1;
  }

  th.group-name-cell {
    vertical-align: text-top;
  }
</style>
