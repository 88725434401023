<template>
  <EditableList
    ref="list"
    :list="categories"
    :pending="pending"
    :form-add-title="$t('settings.categories.add')"
    :form-edit-title="$t('settings.categories.edit')"
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
  >
    <template #listElement="{ row }">
      <div
        class="d-flex align-items-center"
        style="overflow: hidden"
      >
        <div
          class="color-box mr-2 flex-shrink-0"
          :style="{
            'background-color': colors(row.colorCode),
          }"
        />
        <span
          class="text-truncate"
          :class="row.isActive ? '' : 'text-strikethrough'"
        >
          {{ row.name }}
        </span>
      </div>
      <span>
        <i
          v-if="row.isDefault"
          v-tippy
          :content="$t('general.default')"
          class="fas fa-star text-secondary"
        />
      </span>
    </template>

    <template #form="{edited}">
      <BillCategoryForm
        ref="form"
        :pending.sync="pending"
        :category="getCategory(edited)"
        @close="$refs.list.close()"
      />
    </template>

    <template #submit="{edited}">
      <div
        v-if="edited && getCategory(edited) && !getCategory(edited).isActive"
        class="text-right"
      >
        <button
          class="btn btn-primary btn-sm"
          :disabled="pendingRestore"
          type="button"
          @click="recover(edited)"
        >
          {{ $t('form.restore') }}
        </button>
      </div>
    </template>
  </EditableList>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BillCategoryForm from '@/components/settings/bills/BillCategoryForm';
import EditableList from '@/components/settings/EditableList';
import colors from '@/utils/colors';

export default {
  data: () => ({
    removePending: null,
    pending: false,
    selected: null,
    add: false,
    pendingRestore: false,
  }),
  components: {
    EditableList,
    BillCategoryForm,
  },
  computed: {
    ...mapGetters(['payee']),
    categories() {
      return (this.payee?.categories || [])
        .slice()
        .sort((a, b) => {
          if (a.isActive && b.isActive) return b.isDefault - a.isDefault;
          return b.isActive - a.isActive;
        });
    },
  },
  methods: {
    ...mapActions(['recoverBillsCategory']),
    colors,
    getCategory(id) {
      return this.categories.find((x) => x.id === id);
    },
    recover(categoryId) {
      this.pendingRestore = true;
      this.recoverBillsCategory({
        params: {
          categoryId,
        },
      })
        .then(({ data }) => {
          this.$store.commit('setCategories', data.categories);
          this.$refs.list.close();
        })
        .finally(() => {
          this.pendingRestore = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>

  .group-border {
    padding: 1rem;
  }

  .color-box {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .text-strikethrough {
    text-decoration: line-through;
  }

  .client-property {
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }
    &.selected {
      margin-right: -20px;
      background-color: rgba($blue, 0.1);
      border-color: $darkblue;
    }
  }

  .add-client-property {
    border: 2px solid $blue;
    background-color: $blue;
    color: white;

    &:hover {
      border-color: $darkblue;
    }

    &.selected {
      margin-right: -20px;
      background-color: $darkblue;
      border-color: $darkblue;
    }
  }

  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }
</style>
