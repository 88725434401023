<template>
  <VueDropzone
    :id="`drop${$uId}`"
    ref="drop"
    class="drop"
    :options="dropzoneOptions"
    @vdropzone-file-added="$emit('upload', $event); clear()"
  />
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import VueDropzone from 'vue2-dropzone';

export default {
  components: {
    VueDropzone,
  },
  computed: {
    dropzoneOptions() {
      return {
        url: 'https://api.pagaspot.com',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: this.$t('import.upload'),
        headers: { },
      };
    },
  },
  methods: {
    clear() {
      this.$refs.drop.removeAllFiles();
    },
  },
};
</script>

<style lang="scss" scoped>
  .drop {
    border-radius: 20px;
    margin-top: 20px;
    font-family: 'Poppins';
  }
</style>
