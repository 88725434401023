<template>
  <div class="full-page">
    <div class="d-flex">
      <div
        class="d-none d-lg-flex"
        style="margin-left: 1rem"
      >
        <PayerMenu :page="page" />
      </div>

      <div
        class="flex-grow-1 px-1 px-md-3 d-flex flex-column hv-100 full-page-width"
      >
        <Navbar />
        <div class="page-cart content-height mx-auto mw-100 position-relative">
          <BToast
            id="order-completed-toast"
            variant="success"
            auto-hide-delay="2000"
            solid
          >
            <template #toast-title>
              <i class="far fa-cart-circle-check pr-1" />
              <span class="font-weight-bold">
                {{ $t('shop.orderSuccessful') }}
              </span>
            </template>
            <div>
              {{ $t('shop.orderCreated') }}
            </div>
          </BToast>

          <RouterView
            @page="page = $event"
          />
        </div>
      </div>
    </div>
    <PayerMenu
      class="d-block d-lg-none"
      :page="page"
      :mobile="true"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Navbar from './Navbar';
import PayerMenu from './PayerMenu';

export default {
  data: () => ({
    page: '',
    pending: false,
    destroyed: false,
  }),
  components: {
    PayerMenu,
    Navbar,
  },
  methods: {
    ...mapActions([
      'getCurrencies',
      'getUnreadThreadCount',
      'getPayees',
    ]),
    loopMessageRequest() {
      if (this.destroyed) return;
      if (process.env.NODE_ENV === 'development') return;
      this.getUnreadThreadCount();

      this.timeout = setTimeout(() => {
        this.loopMessageRequest();
      }, 20 * 1000);
    },
  },
  created() {
    this.getCurrencies();
    this.getPayees();
    this.loopMessageRequest();

    this.showOrderCreated = () => {
      this.$bvToast.show('order-completed-toast');
    };

    this.$root.$on('order-created', this.showOrderCreated);
  },
  destroyed() {
    this.destroyed = true;
    this.$root.$off('order-created', this.showOrderCreated);
  },
};
</script>

<style lang="scss" scoped>
  .full-page-width {
    width: calc(100% - 80px);
  }

  .full-page {
    max-width: 100vw;
    max-height: 100vh;
  }

  .content-height {
    height: calc(100vh - 56px);
    overflow-y: auto;
    padding-bottom: 0;
    width: 100%;
  }

  .order-created {
    color: $green;
    margin-top: 5px;
    background-color: rgba(lighten($green, 40), 0.9);
    padding: 12px 20px;
    border-radius: 10px;
    position: absolute;
    right: 10px;
    top: -40px;
    z-index: 20;

    i {
      font-size: 25px;
      padding-right: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    .full-page-width {
      width: 100%;
    }

    .page-cart {
      padding-bottom: 100px;
    }
  }
</style>
