<template>
  <div class="d-flex mt-2">
    <CurrencySelectDropdown
      :selected.sync="localConfig.currency"
      class="mr-3"
      @update:selected="update"
    />
    <CategoryMultiselectDropdown
      :selected.sync="localConfig.categoryIds"
      class="mr-3"
      @update:selected="update"
    />
    <ReportCheckbox
      v-model="localConfig.splitByCategory"
      :text="$t('report.balance.splitByCategory')"
      class="mr-3"
      @input="update"
    />
    <ReportCheckbox
      v-model="localConfig.splitByBillingScope"
      :text="$t('report.balance.splitByBillingScope')"
      class="mr-3"
      @input="update"
    />

    <BalanceReportPayerFieldsModal
      :layout.sync="localConfig.layout"
      @update:layout="update"
    />
  </div>
</template>

<script>
import BalanceReportPayerFieldsModal from './BalanceReportPayerFieldsModal';
import CategoryMultiselectDropdown from './CategoryMultiselectDropdown';
import CurrencySelectDropdown from './CurrencySelectDropdown';
import ReportCheckbox from './ReportCheckbox';

const defaultLayout = [
  {
    key: 'name',
    type: 'default',
  },
  {
    key: 'code',
    type: 'default',
  },
];

export default {
  props: {
    config: Object,
  },
  data: () => ({
    localConfig: {
      layout: defaultLayout,
      categoryIds: [],
      currency: 'pln',
      splitByCategory: false,
      splitByBillingScope: false,
    },
  }),
  components: {
    ReportCheckbox,
    BalanceReportPayerFieldsModal,
    CurrencySelectDropdown,
    CategoryMultiselectDropdown,
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    update() {
      this.$emit('update:config', { ...this.localConfig });
    },
    init() {
      this.localConfig = {
        layout: this.config?.layout || defaultLayout,
        categoryIds: this.config?.categoryIds || [],
        currency: this.config?.currency || 'pln',
        splitByCategory: this.config?.splitByCategory || false,
        splitByBillingScope: this.config?.splitByBillingScope || false,
      };
    },
  },
  created() {
    this.init();
  },
};
</script>
