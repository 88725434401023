<template>
  <div>
    <div class="row no-gutters">
      <div class="col-12 col-md-12 form-cell">
        <label>{{ $t('general.name') }}</label>
        <BFormInput
          v-model="name"
          :placeholder="$t('general.name')"
        />
      </div>
      <div class="col-12 col-md-12 form-cell pt-2">
        <label>{{ $t('settings.vatNumber') }}</label>
        <BFormInput
          v-model="vatNumber"
          :placeholder="$t('settings.vatNumber')"
        />
      </div>
      <div class="col-12 col-md-12 form-cell pt-2">
        <label>{{ $t('settings.address') }}</label>
        <BFormTextarea
          v-model="address"
          :placeholder="$t('settings.address')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: Object,
  },
  data: () => ({
    name: '',
    vatNumber: '',
    address: '',
  }),
  watch: {
    name() {
      this.update();
    },
    vatNumber() {
      this.update();
    },
    address() {
      this.update();
    },
  },
  methods: {
    update() {
      this.$emit('update:description', {
        organizationName: this.name,
        vatNumber: this.vatNumber,
        address: this.address,
      });
    },
  },
  created() {
    if (this.description) {
      this.name = this.description.name;
      this.vatNumber = this.description.vatNumber;
      this.address = this.description.address;
    }
  },
};
</script>

<style lang="scss" scoped>
.form-cell {
  padding: 5px;
}
</style>
