<template>
  <div class="thread-form">
    <div v-if="!threadId">
      <MessageReceivers
        ref="receivers-dropdown"
        :payer-ids.sync="payerIds"
      />
    </div>
    <div>
      <div class="reply-box mt-2">
        <input
          v-if="!threadId"
          v-model="title"
          class="form-control title"
          data-test="company-message-title"
          :placeholder="`${$t('general.title')}...`"
        >

        <div v-if="threadId && !replyTo">
          <div
            v-if="threadRecipients.length > 1"
            style="max-width: 430px"
            class="py-2 d-flex mx-0"
          >
            <div class="w-50 px-1">
              <div
                class="secondary-icon bill-button px-3"
                :class="{ selected: replyType === 'all' }"
                @click="replyType = 'all'"
              >
                <i class="fas fa-people pr-1" />
                <span class="edit-details">
                  {{ $t('messages.replyAll') }}
                </span>
              </div>
            </div>
            <div class="w-50 px-1">
              <div
                class="secondary-icon bill-button px-3"
                :class="{ selected: replyType === 'selected' }"
                @click="replyType = 'selected'"
              >
                <i class="fas fa-person pr-1" />
                <span class="edit-details">
                  {{ $t('messages.replySelected') }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="px-3 py-2"
          >
            <span
              v-if="threadRecipients.length > 0"
              class="recipient"
            >
              {{ threadRecipients[0].name || '' }}
            </span>
          </div>

          <div v-if="replyType === 'selected'">
            <MessageReceivers
              :payer-ids.sync="payerIds"
              :thread-receivers="threadRecipients"
            />
          </div>
        </div>
        <div
          v-else-if="replyTo"
          class="font-weight-bold text-warning p-2"
        >
          <i class="far fa-message-arrow-up-right px-2" />
          {{ $t('messages.reply') }}:
        </div>

        <div class="position-relative">
          <span class="char-no">({{ text.length }} {{ $t('messages.characters') }})</span>
          <textarea
            ref="input"
            v-model="text"
            class="form-control pt-3"
            rows="6"
            :placeholder="`${$t('messages.message')}...`"
          />
        </div>
        <div
          class="d-flex justify-content-between"
          style="border-top: 1px solid #eee"
        >
          <div style="padding-top: 12px">
            <div
              class="custom-control custom-checkbox ml-2 text-left"
            >
              <input
                id="send-sms"
                v-model="sendAsSms"
                type="checkbox"
                class="custom-control-input"
              >
              <label
                for="send-sms"
                class="custom-control-label"
                style="line-height: 1.5rem"
              >
                {{ $t('messages.sendAsSms') }}
              </label>
            </div>
          </div>
          <div class="text-right actions">
            <button
              v-if="!pending"
              class="btn btn-primary"
              :disabled="!sendEnabled"
              @click="send"
            >
              <i class="fas fa-paper-plane pr-2" />
              {{ $t('general.send') }}
            </button>
            <div v-else>
              <Loader size="25px" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="sendAsSms"
        class="small text-secondary pt-1 pl-3"
      >
        <span
          v-if="pendingSmsLimit"
        >
          <Loader
            size="15px"
            style="width: 15px; margin-top: 5px;"
          />
        </span>
        <span
          v-else
          :class="{
            'text-danger': false,
          }"
        >
          <div>
            <span style="font-weight: 300; padding-right: 2px">
              {{ $t('messages.numberOfSmsForThisMessage') }}:
            </span>
            {{ smsMessageCost }}
          </div>
          <span style="font-weight: 300; padding-right: 2px">
            {{ $t('messages.remainingSmsMessages') }}:
          </span>
          {{ smsLimit }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MessageReceivers from '@/components/messages/MessageReceivers';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    threadId: String,
    threadOwnerId: String,
    threadOwnerType: String,
    threadRecipients: Array,
    replyTo: Object,
  },
  data: () => ({
    title: '',
    text: '',
    payerIds: [],
    replyType: 'all',
    sendAsSms: false,
    pending: false,
    smsLimit: '?',
    smsMessageCost: 0,
    pendingSmsLimit: false,
  }),
  components: {
    MessageReceivers,
  },
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
    ...mapGetters(['selectedPayers', 'payeeId']),
    sendEnabled() {
      return (this.threadId || this.title.length >= 3)
        && (this.threadId || this.payerIds.length > 0)
        && this.text.length > 0;
    },
    draftKey() {
      return `thread_${this.oidcUser.sub}_${this.threadId}_${this.payeeId}`;
    },
  },
  watch: {
    text() {
      if (this.textChangeTimeout) {
        clearTimeout(this.textChangeTimeout);
      }
      this.textChangeTimeout = setTimeout(() => {
        this.refreshSmsMessageCost();
        this.saveInLocalStorage();
      }, 500);
    },
  },
  methods: {
    ...mapActions([
      'addPayeeThread',
      'sendPayeeMessage',
      'getSmsMessageCost',
      // 'getSmsLimit',
    ]),
    saveInLocalStorage() {
      if ((this.text || '').trim().length > 0) {
        localStorage.setItem(this.draftKey, this.text);
      } else {
        localStorage.removeItem(this.draftKey);
      }
    },
    setPayers(ids) {
      this.replyType = 'selected';
      this.payerIds = ids;
    },
    unsetPayers() {
      this.replyType = 'all';
      this.payerIds = [];
    },
    send() {
      if (this.threadId) {
        this.sendMessage();
      } else {
        this.sendThread();
      }
    },
    sendThread() {
      if (!this.sendEnabled) return;
      this.pending = true;

      this.addPayeeThread({
        data: {
          title: this.title,
          content: this.text,
          sendAsSms: this.sendAsSms,
          payerIds: this.payerIds,
          relatedBillIds: [],
        },
      })
        .then(({ data }) => {
          localStorage.removeItem(this.draftKey);
          this.$emit('done', data.id);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    sendMessage() {
      if (!this.sendEnabled) return;

      const userIds = [];
      if (this.threadOwnerType === 'UserId') {
        userIds.push(this.threadOwnerId);
      } else if (this.replyTo) {
        userIds.push(this.replyTo.sender.id);
      }

      this.pending = true;
      this.sendPayeeMessage({
        data: {
          threadId: this.threadId,
          threadOwnerId: this.threadOwnerId,
          content: this.text,
          sendAsSms: this.sendAsSms,
          payerIds: this.replyType === 'all' ? this.threadRecipients.map((x) => x.id) : this.payerIds,
          userIds,
        },
      })
        .then(({ data }) => {
          this.text = '';
          localStorage.removeItem(this.draftKey);
          this.$emit('done', data);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    cancel() {
      this.$emit('cancel');
    },
    refreshSmsMessageCost() {
      this.getSmsMessageCost({
        data: {
          body: this.text,
          senderName: this.companyName,
        },
      })
        .then(({ data }) => {
          this.smsMessageCost = data.cost;
        })
        .catch(() => {
          this.smsMessageCost = '?';
        });
    },
    refreshSmsLimit() {
      // this.pendingSmsLimit = true;
      // this.getSmsLimit({
      //   params: {
      //     companyId: this.companyId,
      //   },
      // })
      //   .then(({ data }) => {
      //     this.smsLimit = data.currentSmsLimit;
      //   })
      //   .catch(() => {
      //     this.smsLimit = '?';
      //   })
      //   .finally(() => {
      //     this.pendingSmsLimit = false;
      //   });
    },
  },
  created() {
    this.refreshSmsLimit();
  },
  mounted() {
    if (this.$route.query.useSelectedPayers) {
      this.$refs['receivers-dropdown'].setPayers(this.selectedPayers);
    }

    this.text = localStorage.getItem(this.draftKey) || '';
    if (this.text.length > 0 && this.$refs.input) {
      setTimeout(() => {
        this.$refs.input.focus();
        this.$refs.input.select();
      }, 200);
    }
  },
};
</script>

<style lang="scss" scoped>
  .title {
    border: none;
    height: 50px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .form-control:focus {
    border-color: none;
    box-shadow: none;
  }

  .char-no {
    position: absolute;
    bottom: 3px;
    right: 10px;
    font-size: 10px;
    color: #888;
  }

  .recipient {
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 10px;
    margin: 0px 5px;
    background-color: rgba(100,100,100, 0.2);
  }

  .reply-box {
    box-shadow: 0 0 5px rgba(100,100,100,0.3);
    border-radius: 10px;

    .custom-control-label {
      font-weight: 400;
      font-size: 14px;
    }

    .actions {
      padding: 5px;
    }

    textarea {
      border: none;
      border-radius: 0;
      border-top: 1px solid #eee;
    }
  }
</style>
