<template>
  <div class="position-relative">
    <div class="page-header">
      <h1 class="text-center text-sm mt-3">
        {{ $t('company.newCompanyRegister') }}
      </h1>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="close"
      >
        ×
      </button>
    </div>

    <BForm
      class="mx-auto p-4 my-4"
      style="max-width: 500px;"
      @submit.prevent="submit"
    >
      <h5>
        {{ $t('company.companyInfo') }}
      </h5>
      <hr>
      <div class="ml-3">
        <BFormGroup>
          <label>{{ $t('settings.fullCompanyName') }}</label>
          <BFormInput
            v-model="form.name"
            :state="nameState"
            type="text"
            :placeholder="`${$t('settings.fullCompanyName')}`"
            required
          />
          <div
            v-if="serverError.name"
            class="text-danger font-weight-bold pt-2"
            style="font-size: 12px; line-height: 1.2"
          >
            {{ $t('company.nameTaken', { name: oldForm.name }) }}
          </div>
          <BFormInvalidFeedback>
            {{ $t('error.enterAtLeastThree') }}
          </BFormInvalidFeedback>
        </BFormGroup>
        <BFormGroup>
          <label>{{ $t('settings.shortCompanyName') }}</label>
          <BFormInput
            v-model="form.shortName"
            :state="shortNameState"
            type="text"
            :placeholder="`${$t('settings.shortCompanyName')}`"
            required
          />
          <BFormInvalidFeedback>
            {{ $t('error.enterAtLeastThree') }}
          </BFormInvalidFeedback>
        </BFormGroup>
        <BFormGroup>
          <label>{{ $t('form.street') }}</label>
          <BFormInput
            v-model="form.address.street"
            :state="streetState"
            type="text"
            :placeholder="`${$t('form.street')}`"
            required
          />
          <BFormInvalidFeedback>
            {{ $t('error.enterAtLeastThree') }}
          </BFormInvalidFeedback>
        </BFormGroup>
        <div class="d-flex">
          <BFormGroup
            style="width: 100%;"
            class="mr-2"
          >
            <label>{{ $t('form.postCode') }}</label>
            <BFormInput
              v-model="form.address.postCode"
              :state="postCodeState"
              type="text"
              :placeholder="`${$t('form.postCode')}`"
              required
            />
            <BFormInvalidFeedback>
              {{ $t('error.enterAtLeastThree') }}
            </BFormInvalidFeedback>
          </BFormGroup>
          <BFormGroup style="width: 100%;">
            <label>{{ $t('form.city') }}</label>
            <BFormInput
              v-model="form.address.city"
              :state="cityState"
              type="text"
              :placeholder="`${$t('form.city')}`"
              required
            />
            <BFormInvalidFeedback>
              {{ $t('error.enterAtLeastThree') }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </div>
        <BFormGroup>
          <label>{{ $t('settings.NIP') }}</label>
          <BFormInput
            v-model="form.NIP"
            :state="NIPState"
            type="text"
            :placeholder="`${$t('settings.NIP')}`"
            required
          />
        </BFormGroup>
      </div>
      <BFormGroup class="my-3">
        <BFormCheckbox v-model="form.isOtherInvoiceInfo">
          {{ $t('company.giveOtherInvoiceInfo') }}
        </BFormCheckbox>
      </BFormGroup>
      <div
        v-if="form.isOtherInvoiceInfo"
      >
        <h5>
          {{ $t('company.otherInvoiceInfo') }}
        </h5>
        <hr>
        <div
          class="ml-3"
        >
          <BFormGroup>
            <label>{{ $t('settings.fullCompanyName') }}</label>
            <BFormInput
              v-model="form.otherInvoiceInfo.name"
              :state="otherInvoiceInfoNameState"
              type="text"
              :placeholder="`${$t('settings.fullCompanyName')}`"
              required
            />
            <BFormInvalidFeedback>
              {{ $t('error.enterAtLeastThree') }}
            </BFormInvalidFeedback>
          </BFormGroup><BFormGroup>
            <label>{{ $t('form.street') }}</label>
            <BFormInput
              v-model="form.otherInvoiceInfo.address.street"
              :state="otherInvoiceInfoStreetState"
              type="text"
              :placeholder="`${$t('form.street')}`"
              required
            />
            <BFormInvalidFeedback>
              {{ $t('error.enterAtLeastThree') }}
            </BFormInvalidFeedback>
          </BFormGroup>
          <div class="d-flex">
            <BFormGroup
              style="width: 100%;"
              class="mr-2"
            >
              <label>{{ $t('form.postCode') }}</label>
              <BFormInput
                v-model="form.otherInvoiceInfo.address.postCode"
                :state="otherInvoiceInfoPostCodeState"
                type="text"
                :placeholder="`${$t('form.postCode')}`"
                required
              />
              <BFormInvalidFeedback>
                {{ $t('error.enterAtLeastThree') }}
              </BFormInvalidFeedback>
            </BFormGroup>
            <BFormGroup style="width: 100%;">
              <label>{{ $t('form.city') }}</label>
              <BFormInput
                v-model="form.otherInvoiceInfo.address.city"
                :state="otherInvoiceInfoCityState"
                type="text"
                :placeholder="`${$t('form.city')}`"
                required
              />
              <BFormInvalidFeedback>
                {{ $t('error.enterAtLeastThree') }}
              </BFormInvalidFeedback>
            </BFormGroup>
          </div>
          <BFormGroup>
            <label>{{ $t('settings.NIP') }}</label>
            <BFormInput
              v-model="form.otherInvoiceInfo.NIP"
              :state="otherInvoiceInfoNIPState"
              type="text"
              :placeholder="`${$t('settings.NIP')}`"
              required
            />
          </BFormGroup>
        </div>
      </div>
      <h5 class="mt-5">
        {{ $t('company.contactInfo') }}
      </h5>
      <hr>
      <div
        class="ml-3 mb-5"
      >
        <div class="d-flex">
          <BFormGroup
            style="width: 100%"
            class="mr-2"
          >
            <label>{{ $t('client.firstName') }}</label>
            <BFormInput
              v-model="form.firstName"
              :state="firstNameState"
              type="text"
              :placeholder="`${$t('client.firstName')}`"
              required
            />
            <BFormInvalidFeedback>
              {{ $t('error.enterAtLeastThree') }}
            </BFormInvalidFeedback>
          </BFormGroup>
          <BFormGroup style="width: 100%">
            <label>{{ $t('client.lastName') }}</label>
            <BFormInput
              v-model="form.lastName"
              :state="lastNameState"
              type="text"
              :placeholder="`${$t('client.lastName')}`"
              required
            />
            <BFormInvalidFeedback>
              {{ $t('error.enterAtLeastThree') }}
            </BFormInvalidFeedback>
          </BFormGroup>
        </div>
        <BFormGroup>
          <label>{{ $t('client.position') }}</label>
          <BFormInput
            v-model="form.position"
            :state="positionState"
            type="text"
            :placeholder="`${$t('client.position')}`"
            required
          />
          <BFormInvalidFeedback>
            {{ $t('error.enterAtLeastThree') }}
          </BFormInvalidFeedback>
        </BFormGroup>
      </div>
      <h5>
        {{ $t('company.accountType.accountType') }}
      </h5>
      <hr>
      <div class="mb-5 ml-3">
        <BFormGroup
          id="accountType"
        >
          <BButton
            v-model="form.demo"
            :variant="!form.demo ? 'outline-primary' : 'primary'"
            class="mr-2"
            @click="form.demo = !form.demo"
          >
            {{ $t('company.accountType.demo') }}
          </BButton>
          <BButton
            v-model="form.demo"
            :variant="form.demo ? 'outline-primary' : 'primary'"
            @click="form.demo = !form.demo"
          >
            {{ $t('company.accountType.license') }}
          </BButton>
        </BFormGroup>
        <BTooltip
          target="accountType"
          placement="lefttop"
        >
          {{ $t('form.defaultAccountType') }}
        </BTooltip>
      </div>
      <h5>
        {{ $t('form.mandatoryAgreements') }}
      </h5>
      <hr>
      <div
        class="mb-5"
      >
        <BFormGroup class="mb-3">
          <BFormCheckbox
            v-model="form.terms.decisionMaker"
            :state="form.terms.decisionMaker ? null : false"
          >
            {{ $t('form.decisionMaker') }}
          </BFormCheckbox>
          <BFormInvalidFeedback
            :state="form.terms.decisionMaker"
          >
            {{ $t('error.mandatoryField') }}
          </BFormInvalidFeedback>
        </BFormGroup>
        <BFormGroup class="mb-3">
          <BFormCheckbox
            v-model="form.terms.personalDataProcessing"
            :state="form.terms.personalDataProcessing ? null : false"
          >
            {{ $t('form.acceptTerms') }}
          </BFormCheckbox>
          <BFormInvalidFeedback
            :state="form.terms.personalDataProcessing"
          >
            {{ $t('error.mandatoryField') }}
          </BFormInvalidFeedback>
        </BFormGroup>
        <BFormGroup class="mb-3">
          <BFormCheckbox
            v-model="form.terms.companyTerms"
            :state="form.terms.companyTerms ? null : false"
          >
            {{ $t('form.iAccept') }}
            <a
              href="https://pagaspot.com/docs/regulamin-komercyjni.pdf"
              target="_blank"
            >
              {{ $t('form.termsCommercialUsers') }}
            </a>
          </BFormCheckbox>
          <BFormInvalidFeedback
            :state="form.terms.companyTerms"
          >
            {{ $t('error.mandatoryField') }}
          </BFormInvalidFeedback>
        </BFormGroup>
      </div>
      <div
        v-if="error"
        class="alert alert-secondary"
      >
        {{ $t('error.fillMandatoryFields') }}
      </div>
      <div
        v-if="serverError.unknown"
        class="alert alert-danger"
      >
        {{ $t('error.internalError') }}
      </div>
      <BFormGroup>
        <BButton
          type="submit"
          variant="primary"
          class="w-100"
          :disabled="error || pending"
        >
          <span v-if="!pending">{{ $t('general.next') }}</span>
          <Loader
            v-else
            size="15px"
            color="white"
          />
        </BButton>
      </BFormGroup>
    </BForm>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import lang from '@/lang';

export default {
  data: () => ({
    form: {
      name: '',
      shortName: '',
      address: {
        street: '',
        postCode: '',
        city: '',
      },
      NIP: '',
      isOtherInvoiceInfo: null,
      otherInvoiceInfo: {
        name: '',
        address: {
          street: '',
          postCode: '',
          city: '',
        },
        NIP: '',
      },
      firstName: '',
      lastName: '',
      position: '',
      demo: true,
      terms: {
        decisionMaker: null,
        personalDataProcessing: null,
        companyTerms: null,
      },
    },
    oldForm: {
      name: '',
    },
    serverError: {
      name: false,
      unknown: false,
    },
    pending: false,
  }),
  computed: {
    nameState() {
      if (!this.form.name.length) return null;
      return this.form.name.length >= 3;
    },
    shortNameState() {
      if (!this.form.shortName.length) return null;
      return this.form.shortName.length >= 3;
    },
    streetState() {
      if (!this.form.address.street.length) return null;
      return this.form.address.street.length >= 3;
    },
    postCodeState() {
      if (!this.form.address.postCode.length) return null;
      return this.form.address.postCode.length >= 3;
    },
    cityState() {
      if (!this.form.address.city.length) return null;
      return this.form.address.city.length >= 3;
    },
    NIPState() {
      if (!this.form.NIP.length) return null;
      return this.form.NIP.length >= 1;
    },
    firstNameState() {
      if (!this.form.firstName.length) return null;
      return this.form.firstName.length >= 3;
    },
    lastNameState() {
      if (!this.form.lastName.length) return null;
      return this.form.lastName.length >= 3;
    },
    positionState() {
      if (!this.form.position.length) return null;
      return this.form.position.length >= 3;
    },
    otherInvoiceInfoNameState() {
      if (!this.form.otherInvoiceInfo.name.length) return null;
      return this.form.otherInvoiceInfo.name.length >= 3;
    },
    otherInvoiceInfoStreetState() {
      if (!this.form.otherInvoiceInfo.address.street.length) return null;
      return this.form.otherInvoiceInfo.address.street.length >= 3;
    },
    otherInvoiceInfoPostCodeState() {
      if (!this.form.otherInvoiceInfo.address.postCode.length) return null;
      return this.form.otherInvoiceInfo.address.postCode.length >= 3;
    },
    otherInvoiceInfoCityState() {
      if (!this.form.otherInvoiceInfo.address.city.length) return null;
      return this.form.otherInvoiceInfo.address.city.length >= 3;
    },
    otherInvoiceInfoNIPState() {
      if (!this.form.otherInvoiceInfo.NIP.length) return null;
      return this.form.otherInvoiceInfo.NIP.length >= 1;
    },
    error() {
      if (this.form.isOtherInvoiceInfo) {
        if (!this.nameState || !this.shortNameState || !this.streetState || !this.postCodeState || !this.cityState
        || !this.NIPState || !this.firstNameState || !this.lastNameState || !this.positionState
        || !this.form.terms.decisionMaker || !this.form.terms.personalDataProcessing || !this.form.terms.companyTerms
        || !this.otherInvoiceInfoNameState || !this.otherInvoiceInfoStreetState || !this.otherInvoiceInfoPostCodeState
        || !this.otherInvoiceInfoCityState || !this.otherInvoiceInfoNIPState) {
          return true;
        }
        return false;
      }
      if (!this.nameState || !this.shortNameState || !this.streetState || !this.postCodeState || !this.cityState
        || !this.NIPState || !this.firstNameState || !this.lastNameState || !this.positionState
        || !this.form.terms.decisionMaker || !this.form.terms.personalDataProcessing || !this.form.terms.companyTerms) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('oidc', ['signOutOidc']),
    ...mapActions(['addCompany']),
    close() {
      this.$router.push('/?stay=true');
    },
    submit() {
      if (this.error) return;
      this.pending = true;

      this.addCompany({
        data: {
          name: this.form.name,
          shortName: this.form.shortName,
          address: {
            street: this.form.address.street,
            postCode: this.form.address.postCode,
            city: this.form.address.city,
          },
          NIP: this.form.NIP,
          otherInvoiceInfo: {
            isOtherInvoiceInfo: this.form.isOtherInvoiceInfo,
            name: this.form.otherInvoiceInfo.name,
            address: {
              street: this.form.otherInvoiceInfo.address.street,
              postCode: this.form.otherInvoiceInfo.address.postCode,
              city: this.form.otherInvoiceInfo.address.city,
            },
            NIP: this.form.otherInvoiceInfo.NIP,
          },
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          position: this.form.position,
          demo: this.form.demo,
          terms: {
            decisionMaker: this.form.terms.decisionMaker,
            personalDataProcessing: this.form.terms.personalDataProcessing,
            companyTerms: this.form.terms.companyTerms,
          },
        },
      })
        .then(() => {
          this.pending = false;
          this.$bvModal.msgBoxConfirm(lang.t('company.logoutToAccess'), {
            title: `${lang.t('company.theCompany')} ${this.form.name} ${lang.t('company.companyCreated')}`,
            size: 'm',
            buttonSize: 'xl',
            okVariant: 'primary',
            cancelTitle: lang.t('company.stayLoggedIn'),
            okTitle: lang.t('company.logOutNow'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
            .then((value) => {
              if (value) {
                this.signOutOidc();
                return;
              }
              this.$router.push({ path: '/' });
            });
        })
        .catch((error) => {
          this.pending = false;
          const errorMessage = error.response.data.message;
          if (errorMessage.includes('companies_name_unique')) {
            this.serverError.name = true;
            this.oldForm.name = this.form.name;
            this.form.name = '';
            return;
          }
          this.serverError.uknown = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
h5 {
  font-size: 16px;
  font-weight: bold;
}
.close {
  font-size: 40px;
  position: absolute;
  top: 0;
  right: -5px;
}
hr {
  margin: 0.5rem 0
}
label {
  font-size: 12px;
}
fieldset {
  margin-bottom: 0.25rem;
}
</style>
