<template>
  <div :class="bold ? 'font-weight-bold' : ''">
    <span
      v-if="!noIcon"
      class="d-inline-block payer-icon"
    >
      <i
        v-if="type === 'Organization'"
        class="fas fa-building pr-2 text-center"
        style="width: 20px"
      />
      <i
        v-else-if="type === 'Person'"
        class="fas fa-person-simple pr-2 text-center"
        style="width: 20px"
      />
    </span>
    {{ name }} <span
      v-if="code"
      class="text-secondary font-weight-normal"
    >({{ code }})</span>
  </div>
</template>

<script>
export default {
  props: {
    code: String,
    name: String,
    type: String,
    noIcon: Boolean,
    bold: {
      type: Boolean,
      default: true,
    },
  },

};
</script>

<style>

</style>
