<template>
  <div>
    <h4 class="client-page-title mt-3">
      {{ $t('messages.newMessage') }}
    </h4>
    <div
      key="back"
      class="my-3"
      @click="back"
    >
      <button class="btn btn-outline-secondary icon-btn">
        <i class="fas fa-angle-left" />
      </button>
    </div>
    <Transition
      name="slide-left"
      mode="out-in"
      appear
    >
      <div
        v-if="!selectedCompany"
        key="select"
      >
        <div class="font-weight-bold text-center">
          {{ $t('messages.selectCompany') }}:
        </div>
        <div class="mt-3">
          <div
            v-for="(payeeName, id) in payees"
            :key="id"
            class="company-box mb-2 hoverable"
            @click="selectedPayeeId = id"
          >
            {{ payeeName }}
          </div>
        </div>
      </div>
      <div
        v-else
        key="content"
      >
        <div class="message-wrapper pl-3">
          <div class="d-flex justify-content-between">
            <div
              class="company-box d-flex flex-column justify-content-center mb-2 hoverable"
              style="position: relative"
              @click="selectedPayeeId = null; paymentId = null"
            >
              {{ selectedCompany }}
            </div>
          </div>
          <input
            v-model="title"
            class="form-control my-2"
            :placeholder="$t('messages.title')"
            data-test="message-title"
          >
          <div class="reply-box">
            <BFormTextarea
              v-model="content"
              class="form-control"
              rows="6"
              :placeholder="`${$t('messages.message')}...`"
              data-test="message-content"
            />
          </div>
        </div>

        <button
          class="btn btn-success d-block my-4 ml-auto"
          :disabled="title.length === 0 || content.length === 0 || pending"
          data-test="send-message"
          @click="sendData"
        >
          <span v-if="!pending">
            <i class="fas fa-paper-plane pr-2" />
            {{ $t('general.send') }}
          </span>
          <Loader
            v-else
            size="15px"
            color="#fff"
          />
        </button>
      </div>
    </Transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    title: '',
    content: '',
    pending: false,
    selectedPayeeId: null,
  }),
  computed: {
    ...mapGetters(['userPayers']),
    payees() {
      return this.userPayers.reduce((acc, curr) => {
        acc[curr.payeeId] = curr.payeeName;
        return acc;
      }, {});
    },
    selectedCompany() {
      if (!this.selectedPayeeId) return null;
      return this.payees[this.selectedPayeeId];
    },
  },
  methods: {
    ...mapActions(['createNewThread']),
    back() {
      this.$router.push('/payer/messages');
    },
    sendData() {
      this.createNewThread({
        data: {
          payeeId: this.selectedPayeeId,
          title: this.title,
          content: this.content,
        },
      })
        .then(() => {
          this.$router.push('/payer/messages');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style";

.company-box {
  box-shadow: 0 0 5px rgba(150, 150, 150, 0.5);
  border-radius: 10px;
  padding: 10px;
  color: $blue;
}

.title {
  font-weight: 500;
  padding-top: 10px;
}

.message-wrapper {
  border-left: 2px solid $blue;
}
</style>
