<template>
  <EditableList
    ref="list"
    :list="propertyOptions"
    :pending="pending"
    :valid="isValid"
    :remove-warning="$t('settings.clientPropertyRemove')"
    :form-add-title="$t('settings.addField')"
    :form-edit-title="$t('settings.editField')"
    sticky
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
  >
    <template #listElement="{ row }">
      <span>
        {{ row.name }}
      </span>
      <span
        v-tippy
        class="text-secondary"
        :content="getPropertyInfo(row.type)"
      >
        <i :class="getPropertyIcon(row)" />
      </span>
    </template>

    <template #form="{edited}">
      <PayersPropertyForm
        ref="form"
        :pending.sync="pending"
        :property="getProperty(edited)"
        @valid="isValid = $event"
        @close="$refs.list.close()"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import PayersPropertyForm from '@/components/settings/payers/PayersPropertyForm';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    isValid: false,
    pending: false,
  }),
  components: {
    EditableList,
    PayersPropertyForm,
  },
  computed: {
    ...mapGetters([
      'payersPropertyTypes',
      'properties',
    ]),
    propertyOptions() {
      return this.properties
        .map((x) => ({
          id: x.code,
          name: x.name,
          type: x.type,
          format: x.format,
        }));
    },
  },
  methods: {
    getProperty(id) {
      return this.properties.find((x) => x.code === id);
    },
    getPropertyIcon({ type, format }) {
      if (format.startsWith('checkbox')) {
        return 'far fa-square-check';
      }
      if (format.startsWith('dropdown')) {
        return 'far fa-square-caret-down';
      }
      if (format.startsWith('multiselect')) {
        return 'far fa-list-check';
      }

      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
    getPropertyInfo(type) {
      return this.payersPropertyTypes.find((x) => x.key === type)?.text;
    },
  },
};
</script>
<style lang="scss" scoped>

  .group-border {
    padding: 1rem;
  }

  .client-property {
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }
    &.selected {
      margin-right: -20px;
      background-color: rgba($blue, 0.1);
      border-color: $darkblue;
    }
  }

  .add-client-property {
    border: 2px solid $blue;
    background-color: $blue;
    color: white;

    &:hover {
      border-color: $darkblue;
    }

    &.selected {
      margin-right: -20px;
      background-color: $darkblue;
      border-color: $darkblue;
    }
  }

  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }
</style>
