<template>
  <div>
    <div
      v-if="availability.name"
      style="font-size: 18px"
      class="mb-2 font-weight-bold"
    >
      {{ availability.name }}
    </div>
    <div class="d-flex mb-3">
      <div class="pr-2">
        <div
          class="secondary-icon bill-button px-3"
          @click="edit"
        >
          <i class="fas fa-pen mr-2" />
          <span class="edit-details">
            {{ $t('shop.editAvailability') }}
          </span>
        </div>
      </div>
      <div class="px-2">
        <Tippy
          interactive
          arrow
          theme="light"
          trigger="click"
        >
          <template #trigger>
            <i
              v-tippy
              class="fas fa-trash secondary-icon"
              :content="$t('general.remove')"
            />
          </template>

          <div v-if="!pendingRemove">
            <button
              class="btn btn-block btn-danger"
              @click="remove"
            >
              <i class="fas fa-trash pr-2" />
              {{ $t('general.confirmRemove') }}
            </button>
          </div>
          <div v-else>
            <Loader size="22px" />
          </div>
        </Tippy>
      </div>
    </div>
    <div
      ref="scrollContent"
      :style="{
        height: noScroll ? undefined : `calc(100vh - ${scrollTopMargin}px)`,
        overflow: noScroll ? undefined : 'auto'
      }"
    >
      <div class="section-title">
        {{ $t('shop.price') }}
      </div>
      <hr class="mt-2">
      <div
        v-for="(p, i) in groupPrices"
        :key="p.groupCode"
        class="d-flex justify-content-between group-price px-2"
        :class="{ first: i === 0}"
      >
        <div class="text-secondary font-weight-bold">
          <i class="fas fa-users pr-2" />
          {{ groupName(p.groupCode) }}
        </div>
        <div v-if="p.price">
          {{ formatCurrency(p.price.value, p.price.currency) }}
        </div>
      </div>

      <template v-if="availability.payerAvailabilityScript">
        <div class="section-title mt-4">
          {{ $t('shop.availability') }}
        </div>
        <hr class="mt-2">
        <div
          class="d-flex justify-content-between group-price px-2 first"
        >
          <div class="text-secondary small money-font">
            {{ availability.payerAvailabilityScript }}
          </div>
        </div>
      </template>

      <div class="section-title mt-4">
        {{ $t('shop.timeBoundaries') }}
      </div>
      <hr class="mt-2">
      <div class="d-flex justify-content-between group-price px-2 first">
        <div class="text-secondary font-weight-bold">
          <i
            :class="timeIcon(availability.orderFrom)"
            class="pr-2"
          />
          {{ $t('shop.orderFrom') }}
        </div>
        <div>
          {{ formatTimeBoundary(availability.orderFrom) }}
        </div>
      </div>
      <div class="d-flex justify-content-between group-price px-2">
        <div class="text-secondary font-weight-bold">
          <i
            :class="timeIcon(availability.orderTo)"
            class="pr-2"
          />
          {{ $t('shop.orderTo') }}
        </div>
        <div>
          {{ formatTimeBoundary(availability.orderTo) }}
        </div>
      </div>
      <div class="d-flex justify-content-between group-price px-2">
        <div class="text-secondary font-weight-bold">
          <i
            :class="timeIcon(availability.payTo)"
            class="pr-2"
          />
          {{ $t('shop.payTo') }}
        </div>
        <div>
          {{ formatTimeBoundary(availability.payTo) }}
        </div>
      </div>
      <div class="d-flex justify-content-between group-price px-2">
        <div class="text-secondary font-weight-bold">
          <i
            :class="timeIcon(availability.cancelTo)"
            class="pr-2"
          />
          {{ $t('shop.cancelTo') }}
        </div>
        <div>
          {{ formatTimeBoundary(availability.cancelTo) }}
        </div>
      </div>

      <div class="section-title mt-4">
        {{ $t('shop.plan') }}
      </div>
      <hr class="mt-2">
      <div class="pb-5">
        <div
          v-for="day in calendar"
          :key="day.day"
          class="d-flex justify-content-between align-items-center day-limit"
        >
          <div>
            {{ formatDay(day.day) }}
          </div>
          <div>
            <div
              v-for="p in day.productIds"
              :key="p"
              class="product-name font-weight-bold mt-1"
            >
              {{ productName(p) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    availability: Object,
    noScroll: Boolean,
  },
  data: () => ({
    pending: false,
    pendingRemove: false,
    scrollTopMargin: 0,
  }),
  computed: {
    ...mapGetters(['payeeId', 'groups', 'formatCurrency']),
    ...mapGetters('shop', ['products', 'catalogDetails']),
    groupPrices() {
      return this.availability.limits
        .filter((x) => x.type === 'PayersGroupAvailabilityLimit')
        .map((x) => JSON.parse(x.definition));
    },
    calendar() {
      return this.availability.limits
        .filter((x) => x.type === 'DayAvailabilityLimit')
        .map((x) => JSON.parse(x.definition))
        .sort((a, b) => moment(a.day, 'YYYY-MM-DD').unix() - moment(b.day, 'YYYY-MM-DD').unix());
    },
    timeTypes() {
      return [
        {
          key: 'Calendar',
          icon: 'fas fa-calendar',
          text: 'Data',
        },
        {
          key: 'Clock',
          icon: 'fas fa-clock',
          text: 'Godzina',
        },
      ];
    },
  },
  methods: {
    ...mapActions('shop', ['removeProductSetAvailability']),
    timeIcon({ type }) {
      if (type === 'RelativeToProduct') {
        return 'fas fa-clock';
      }
      return 'fas fa-calendar';
    },
    formatTimeBoundary({ value, type }) {
      if (type === 'RelativeToProduct') {
        return value;
      }
      return moment(value, 'YYYY-MM-DD').format('DD MMM YYYY');
    },
    formatDay(day) {
      return moment(day, 'YYYY-MM-DD').format('DD MMM YYYY');
    },
    productName(productId) {
      const product = this.products.find((x) => x.id === productId);
      return product?.name || '?';
    },
    groupName(code) {
      if (code === '#all') return this.$t('company.all');
      return this.groups.find((x) => x.code === code)?.path || '?';
    },
    remove() {
      this.pendingRemove = true;
      this.removeProductSetAvailability({
        params: {
          availabilityId: this.availability.id,
          query: {
            productSetId: this.availability.productSetId,
          },
        },
      })
        .then(() => {
          this.$emit('removed');
        })
        .finally(() => {
          this.pendingRemove = false;
        });
    },
    edit() {
      this.$router.push({
        path: `/payee/${this.payeeId}/shop/${this.catalogDetails.id}/availability`,
        query: {
          productSetId: this.availability.productSetId,
          availabilityId: this.availability.id,
        },
      });
    },
  },
  mounted() {
    this.updateTopMargin = () => {
      if (this.$refs.scrollContent) {
        const scrollContent = this.$refs.scrollContent.getBoundingClientRect();
        this.scrollTopMargin = scrollContent.top;
      }
    };
    this.updateTopMargin();
    window.addEventListener('resize', this.updateTopMargin);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateTopMargin);
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.time-select {
  padding: 5px 5px;

  input {
    width: 207px;
  }
}

.product-name {
  font-size: 12px;
  width: 250px;
  padding: 2px 10px;
  text-align: left;
  background-color: #fafafa;
  border-radius: 10px;
}

.day-limit {
  border-bottom: 1px solid #eee;
  padding: 10px 10px;
}

.group-price {
  padding: 5px;
  border: 1px solid #eee;
  border-top: none;

  &.first {
    border-top: 1px solid #eee;
  }
}

.group-remove {
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;

  &:hover {
    background-color: #eee;
  }
}
</style>
