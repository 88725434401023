<template>
  <div>
    <div
      class="bill d-flex"
    >
      <div>
        <div
          class="product-set-icon"
          :class="result ? 'mt-4' : ''"
        >
          <i
            class="fa-solid"
            :class="catalogIcon(catalog.productCategory)"
          />
        </div>
      </div>
      <div class="flex-grow-1 align-self-center">
        <div
          v-if="result"
          class="mb-1"
        >
          <span
            v-if="result === 'Ordered'"
            class="badge badge-success"
          >
            Zamówiono
          </span>
          <span
            v-else
            class="badge badge-danger"
          >
            Zamówienie nieudane
          </span>
        </div>
        <div
          class="bill-due-date pr-2"
        >
          <span v-if="isReturned"> Odwołanie:</span>
          <div :class="{ return: isReturned }">
            {{ listing.name }}
          </div>
        </div>
        <div class="d-md-flex align-items-center">
          <div class="bill-payer-name">
            {{ payer?.payerName }}
          </div>
        </div>
      </div>
      <div class="align-self-center pr-2">
        <div class="money-font text-right text-nowrap">
          {{ formatCurrency(price, listing.currency) }}
        </div>
      </div>
      <div
        v-if="!result"
        class="basket-icon d-flex align-items-center justify-content-center"
        @click="remove"
      >
        <i class="far fa-trash" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    payerId: String,
    listing: Object,
    catalog: Object,
    price: Number,
    payer: Object,
    result: String,
    isReturned: Boolean,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
      'catalogIcon',
    ]),
  },
  methods: {
    remove() {
      this.$store.commit('removeFromBasket', {
        payerId: this.payerId,
        offerId: this.listing.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bill {
  box-shadow: 0 2px 4px #eee, -1px 2px 4px #eee;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  background-color: white;

  &.selected {
    border-left: 2px solid $blue;
  }

  &.deselected {
    border-left: 2px solid #aaa;
    opacity: 0.6;
  }
}

.bill-payee-name {
  font-size: 12px;
  color: $blue;
  line-height: 1;
}

.product-set-icon {
  background-color: #eee;
  border-radius: 10px;
  font-size: 20px;
  width: 50px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 15px;
  margin-left: 10px;
  text-align: center;
}

.bill-payer-name {
  color: #666;
  font-size: 12px;
}

.bill-due-date {
  font-size: 12px;
  font-weight: 500;

  .return {
    text-decoration: line-through;
  }

  &.overdue {
    color: $red;
  }
}

.info-divider {
  padding-left: 10px;
  padding-right: 10px;
  color: #ccc;
}

.basket-icon {
  background-color: #eee;
  padding: 5px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  text-align: center;
  font-size: 20px;
  width: 45px;
  text-align: center;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background-color: #bbb;
  }

  &.selected {
    background-color: $blue;
    color: white;

    &:hover {
      background-color: darken($blue, 10);
    }
  }
}
</style>
