<template>
  <div
    class="left-menu"
    :class="{ hidden }"
  >
    <div
      :class="mobile ? '' : 'menu-content position-fixed'"
    >
      <div
        v-if="!mobile"
        class="d-flex justify-content-center"
      >
        <div
          class="logo-container text-center"
          :style="{
            'margin-top': payeeDemo ? '30px' : null,
          }"
          @click="showAll"
        >
          <img
            src="@/assets/logo_p.png"
            class="logo"
          >
        </div>
      </div>
      <div
        v-if="payeeId"
        style="margin: 10px auto"
        :class="mobile ? 'd-flex align-items-center no-gutters mobile-menu' : ''"
      >
        <div
          v-for="el in list"
          :key="el.name"
          :class="mobile ? 'col py-1' : ''"
        >
          <div class="text-right">
            <span
              v-if="el.name === 'messages' && unreadMessages"
              class="badge badge-pill float-right badge-danger message-count"
            >{{ unreadMessages }}</span>
          </div>
          <RouterLink
            class="text-center menu-tab d-flex flex-column justify-content-center mx-auto"
            :class="{ 'active': page === el.name }"
            :data-test="el.dataTest"
            :to="`/payee/${payeeId}/${el.name}`"
          >
            <i :class="el.icon" />
            <div
              class="menu-text"
            >
              {{ el.text }}
            </div>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    page: String,
    mobile: Boolean,
  },
  computed: {
    ...mapGetters([
      'payeeId',
      'payee',
      'tenantId',
      'payeeDemo',
      'unreadMessages',
    ]),
    hidden() {
      return this.page === 'form';
    },
    list() {
      return [
        {
          name: 'payers',
          text: this.$t('menu.payers'),
          icon: 'far fa-users',
        },
        {
          name: 'bills',
          text: this.$t('menu.bills'),
          icon: 'far fa-money-bills',
        },
        {
          name: 'payments',
          text: this.$t('menu.payments'),
          icon: 'far fa-money-check-dollar-pen',
        },
        {
          name: 'messages',
          text: this.$t('menu.messages'),
          icon: 'far fa-comments',
        },
        ...(this.payee?.shopEnabled ? [{
          name: 'shop',
          text: this.$t('menu.shop'),
          icon: 'far fa-store',
        }] : []),
        {
          name: 'reports',
          text: this.$t('menu.reports'),
          icon: 'far fa-file-contract',
        },
        // TODO: Make it configurable
        // {
        //   name: 'enrollement',
        //   text: this.$t('menu.enrollement'),
        //   icon: 'far fa-user-check',
        // },
        {
          name: 'settings',
          text: this.$t('menu.settings'),
          icon: 'far fa-gear',
        },
      ];
    },
  },
  methods: {
    showAll() {
      this.$router.push('/?stay=true');
    },
  },
};
</script>

<style lang="scss" scoped>

  .btn {
    border-radius: 20px;
  }
  .message-count {
    cursor: pointer;
    margin-bottom: -20px;
    margin-right: 5px;
  }
  .logo-container {
    cursor: pointer;
    height: 72px;
    width: 72px;
    border-radius: 50%;
    padding: 10px 8px;

    &:hover {
      color: white;
    }

    &:hover img {
      opacity: 1;
    }
  }

  .logo {
    width: 100%;
    opacity: 0.8;
  }

  .menu-tab {
    line-height: 1;
    cursor: pointer;
    color: lighten($blue, 30);
    margin: 0 3px 2px;
    text-decoration: none;
    border-radius: 20px;
    height: 72px;
    width: 72px;
    transition: background-color 100ms;

    &:hover {
      color: white;
    }

    &.active {
      color: white;
      background-color: rgba(#fff, 0.2);
    }

    i {
      font-size: 1.6rem;
    }
  }

  .left-menu {
    width: 80px;
    max-width: 80px;
    position: relative;
    transition: left 500ms, width 500ms;

    .menu-content {
      left: 0;
      height: 100vh;
      width: 80px;
      max-width: 80px;
      z-index: 100;
      background-color: darken($blue, 10);
      transition: left 500ms;
    }

    &.hidden {
      left: -80px;
      width: 0px;

      > .menu-content {
        left: -80px;
      }
    }
  }

  .menu-text {
    font-size: 11px;
    line-height: 1.2;
    font-weight: 500;
    padding-top: 5px;
  }

  .mobile-menu {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background: $blue;
    padding: 0 10px env(safe-area-inset-bottom);
    margin-bottom: 0 !important;

    .menu-tab {
      font-size: 0.8rem;
      width: auto;
      height: auto;
      padding: 5px;
      margin: 0;
    }

    .menu-text {
      font-size: 9px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
