<template>
  <div>
    <div class="d-flex justify-content-between align-items-center my-2 no-gutters">
      <div class="flex-grow-1">
        <h5
          class="text-left mb-0"
          style="font-size: 16px; font-weight: 600 !important"
        >
          {{ $t('menu.payers') }}
        </h5>
      </div>
    </div>

    <hr>
    <div class="d-flex align-items-center justify-content-between mt-2">
      <div>
        <Tippy
          ref="popover"
          :arrow="true"
          trigger="click"
          interactive
          theme="light"
          placement="bottom"
        >
          <template #trigger>
            <div class="sort-option-selected">
              <div
                class="font-weight-bold text-secondary"
                style="font-size: 10px; min-width: 180px"
              >
                {{ $t('general.sortBy') }}
              </div>
              <div
                class="first"
                style="font-size: 14px"
              >
                <i :class="`${sortBy.icon} pr-1`" />
                {{ sortBy.text }}
              </div>
            </div>
          </template>

          <div style="min-width: 180px">
            <div
              v-for="(s, i) in sortOptions"
              :key="s.key"
              class="sort-option text-left"
              :class="{ first: i === 0, active: sortKey === s.key }"
              @click="sortKey = s.key; $refs.popover.tippy().hide()"
            >
              <i :class="`${s.icon} pr-1`" />
              {{ s.text }}
            </div>
          </div>
        </Tippy>
      </div>
      <SearchBox
        v-model="search"
        lazy
        class="mr-3"
      />
    </div>
    <hr>
    <div>
      <div
        v-if="filteredPayers.length > 0"
        class="d-flex justify-content-end text-secondary small text-right pb-1"
        style="font-size: 11px; padding: 0 10px"
      >
        <div style="width: 135px">
          {{ $t('report.balance.paid') }}
        </div>
        <div style="width: 135px">
          {{ $t('report.balance.due') }}
        </div>
      </div>
      <TransitionGroup
        name="list-complete"
        class="d-flex flex-column position-relative"
      >
        <div
          v-for="payer in highlightedPayers"
          :key="payer.id"
          class="list-complete-item w-100"
          data-test="client-row-payments"
        >
          <div class="position-relative flex-grow-1">
            <BillPayerRow
              :paid="payer.paid"
              :amount="payer.amount"
              :name="payer.name"
              :archived="payer.archived"
              :color="color"
              :show-border="true"
              :currency="currency"
              :is-plan="isPlan"
              class="position-relative"
              @select="$emit('select', payer)"
            />
          </div>
        </div>
        <div
          v-if="selectedPayerIds.length > 0"
          key="divider"
        >
          <hr class="mt-2 mb-3">
        </div>
        <div
          v-for="payer in filteredPayers"
          :key="payer.id"
          class="list-complete-item w-100"
          data-test="client-row-payments"
        >
          <div class="position-relative flex-grow-1">
            <BillPayerRow
              :paid="payer.paid"
              :amount="payer.amount"
              :name="payer.name"
              :archived="payer.archived"
              :color="color"
              :show-border="true"
              :currency="currency"
              :is-plan="isPlan"
              class="position-relative"
              @select="$emit('select', payer)"
            />
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import createSearch from '@/utils/search';
import { mapActions } from 'vuex';
import BillPayerRow from './BillPayerRow';

export default {
  props: {
    billId: String,
    currency: String,
    isPlan: Boolean,
    initSearch: String,
    selectedPayerIds: {
      type: Array,
      default: () => [],
    },
    color: String,
  },
  data: () => ({
    search: '',
    sortKey: 'name',
    payers: [],
  }),
  components: {
    BillPayerRow,
  },
  computed: {
    highlightedPayers() {
      return this.payers
        .filter((x) => this.selectedPayerIds.includes(x.id));
    },
    filteredPayers() {
      const search = createSearch(this.search);
      return this.payers
        .filter((x) => !this.selectedPayerIds.includes(x.id))
        .filter((x) => search(x.name) || search(x.code));
    },
    steps() {
      return [
        {
          key: 'clients',
          text: this.$t('form.addPayers'),
        },
        {
          key: 'price',
          text: this.$t('form.selectAmount'),
        },
      ];
    },
    sortBy() {
      return this.sortOptions.find((x) => x.key === this.sortKey);
    },
    sortOptions() {
      return [
        {
          key: 'name',
          icon: 'fal fa-arrow-down-a-z',
          text: this.$t('general.name'),
        },
        {
          key: 'amount',
          icon: 'fas fa-money-bill-wave',
          text: this.$t('report.balance.due'),
        },
        ...(this.isPlan ? [] : [
          {
            key: 'toPay',
            icon: 'fas fa-hand-holding-usd',
            text: this.$t('payment.toPay'),
          },
        ]),
      ];
    },
  },
  watch: {
    initSearch(v) {
      this.search = v;
    },
    billId() {
      this.payers = [];
      this.request();
    },
    sortKey() {
      this.payers = [];
      this.request();
    },
  },
  methods: {
    ...mapActions([
      'getBillPayers',
      'getBillingPlanPayers',
    ]),
    request() {
      if (!this.isPlan) {
        this.getBillPayers({
          params: {
            billId: this.billId,
            query: {
              sort: this.sortKey,
            },
          },
        })
          .then(({ data }) => {
            this.payers = data;
          });
      } else {
        this.getBillingPlanPayers({
          params: {
            billingPlanId: this.billId,
            query: {
              sort: 'Name',
            },
          },
        })
          .then(({ data }) => {
            this.payers = data;
          });
      }
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
 @import '~@/components/payers/style.scss';

  .fixed-height {
    max-height: 500px;
    height: 500px;
    overflow: auto;
    padding: 0 15px 0 15px;
    margin-left: -25px;
    margin-right: -25px;
  }

  .verify {
    height: 18px;
    width: 18px;
    font-size: 18px;
    text-align: center;
    background-color: $blue;
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 5px;
    right: -10px;
    line-height: 1;
    z-index: 100;
    i {
      padding-left: 1px;
    }
  }

</style>
