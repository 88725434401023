<template>
  <div class="progress-container">
    <div
      class="progress"
      :style="{
        'background-color': lightColor
      }"
    >
      <div
        class="progress-bar"
        :style="{
          'width': `${value}%`,
          'background-color': color
        }"
      />
      <div class="bar-percent d-flex align-items-center">
        {{ value }}%
      </div>
    </div>
  </div>
</template>

<script>
import tinycolor from 'tinycolor2';
import colors from '@/utils/colors';

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    color: String,
  },
  computed: {
    lightColor() {
      return tinycolor(this.color || colors('blue')).setAlpha(0.15).toString();
    },
  },
};
</script>

<style lang="scss" scoped>
  .progress-container {
    opacity: 0.7;
    height: 100%;
    top: 0px;

    .progress {
      border-radius: 10px;
      height: 100%;
    }
  }

  .progress-bar.green {
    background-color: $green;
  }

  .bar-percent {
    position: absolute;
    width: 100%;
    font-weight: 500;
    height: 100%;
    padding-left: 8px;
    color: white;
  }
</style>
