import { render, staticRenderFns } from "./ActivateManyButton.vue?vue&type=template&id=48963f1b&scoped=true&"
import script from "./ActivateManyButton.vue?vue&type=script&lang=js&"
export * from "./ActivateManyButton.vue?vue&type=script&lang=js&"
import style0 from "./ActivateManyButton.vue?vue&type=style&index=0&id=48963f1b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48963f1b",
  null
  
)

export default component.exports