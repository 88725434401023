<template>
  <div>
    <div
      v-if="!audit"
      class="d-flex align-items-center flex-wrap"
    >
      <GroupOrdersDropdown
        class="mr-3 mb-1"
        :group-key.sync="groupKey"
      />
      <CalendarDropdown
        v-if="!singleOrderSchema"
        :highlighted.sync="highlighted"
        :available-days="availableDays"
        :day-status="dayStatus"
        :dates.sync="dates"
        class="mr-3 mb-1"
        @title="selectTitle = $event"
      />
      <PayersFilterDropdown
        class="mr-3 mb-1"
        @selected="selectedPayers = $event"
      />
      <SwitchWithDescription
        :title="$t('shop.showUnpaid')"
        small
        class="mb-1"
        style="min-height: 44px; margin-top: 0"
        :value="showUnpaid"
        @switch="showUnpaid = !showUnpaid"
      />
      <div
        v-if="!pendingDownload"
        v-tippy
        class="secondary-icon ml-2"
        :content="$t('shop.downloadReport')"
        @click="download"
      >
        <i class="fas fa-download" />
      </div>
      <div v-else>
        <Loader />
      </div>
      <div
        v-tippy
        class="secondary-icon smaller-icon ml-2"
        :content="$t('bill.changeHistory')"
        @click="audit = true"
      >
        <i class="fas fa-clock-rotate-left" />
      </div>
      <div
        class="flex-grow-1 d-flex align-items-center justify-content-end"
      >
        <div>
          <button
            class="btn btn-sm btn-primary"
            data-test="toggle-add-client"
            @click="addingOrder = true"
          >
            <i class="fas fa-plus pr-1" />
            {{ $t('shop.addOrders') }}
          </button>
        </div>
      </div>
    </div>

    <BModal
      v-model="addingOrder"
      hide-footer
      size="xl"
      hide-header
    >
      <AddOrderForm
        v-if="addingOrder"
        :product-sets="productSets"
        @close="addingOrder = false"
        @refresh="request"
      />
    </BModal>

    <ColumnEdit
      :edit="false"
      :focused="false"
      class="h-100"
    >
      <template v-if="!audit">
        <OrdersList
          v-if="!pending"
          class="flex-grow-1"
          :dates="dates"
          :orders="ordersInSelectedDays"
          :group-by="groupKey"
          :payers="orders.payers"
          :product-sets="orders.productSets"
          @refresh="request"
        />
        <div
          v-else
          class="mt-4"
        >
          <Loader />
        </div>
      </template>

      <OrdersAudit
        v-else
        :catalog-id="catalogDetails.id"
        @close="audit = false"
      />
      <template #edit />
    </ColumnEdit>
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import OrdersAudit from '@/components/shop/audit/OrdersAudit';
import AddOrderForm from '@/components/shop/report/AddOrderForm';
import CalendarDropdown from '@/components/shop/report/CalendarDropdown';
import GroupOrdersDropdown from '@/components/shop/report/GroupOrdersDropdown';
import OrdersList from '@/components/shop/report/OrdersList';
import getFileName from '@/utils/file-name';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const showUnpaidStorageKey = 'shop_showUnpaid';
const groupByStorageKey = 'shop_groupBy';

export default {
  data: () => ({
    dates: null,
    audit: false,
    focus: false,
    orders: {
      orders: [],
      productSets: [],
    },
    addingOrder: false,
    selectTitle: '',
    showUnpaid: true,
    groupKey: 'payers',
    highlighted: [],
    productSets: [],
    selectedPayers: [],
    pendingDownload: false,
    pending: false,
    previousFrom: null,
  }),
  components: {
    OrdersList,
    AddOrderForm,
    ColumnEdit,
    CalendarDropdown,
    SwitchWithDescription,
    GroupOrdersDropdown,
    PayersFilterDropdown,
    OrdersAudit,
  },
  computed: {
    ...mapGetters(['payeeId']),
    ...mapGetters('shop', [
      'catalogDetails',
      'singleOrderSchema',
    ]),
    availableDays() {
      return Object.keys(this.dayStatus);
    },
    filteredOrders() {
      return this.orders.orders
        .filter((x) => this.showUnpaid || x.isPaid)
        .filter((x) => this.selectedPayers.length === 0 || this.selectedPayers.some((p) => p.id === x.payerId));
    },
    ordersInSelectedDays() {
      return this.filteredOrders
        .filter((x) => this.highlighted.length === 0 || this.highlighted.includes(x.day));
    },
    dayStatus() {
      return this.filteredOrders.reduce((acc, curr) => {
        if (!acc[curr.day]) {
          acc[curr.day] = {
            paid: 0,
            waiting: 0,
          };
        }

        if (curr.isPaid) {
          acc[curr.day].paid += 1;
        } else {
          acc[curr.day].waiting += 1;
        }
        return acc;
      }, {});
    },
    everyday() {
      if (!this.dates) return [];
      const curr = moment(this.dates.from);
      const end = moment(this.dates.to);
      const result = [];

      while (curr.isBefore(end)) {
        result.push(curr.format('YYYY-MM-DD'));
        curr.add(1, 'day');
      }

      return result;
    },
    reportDays() {
      return this.highlighted.length === 0
        ? this.everyday
        : this.highlighted;
    },
  },
  watch: {
    dates() {
      if (this.debounceRequest) {
        clearTimeout(this.debounceRequest);
      }

      this.debounceRequest = setTimeout(() => {
        this.request();
      }, 300);
    },
    showUnpaid(v) {
      if (v) {
        localStorage.setItem(showUnpaidStorageKey, v);
      } else {
        localStorage.removeItem(showUnpaidStorageKey);
      }
    },
    groupKey(v) {
      localStorage.setItem(groupByStorageKey, v);
    },
  },
  methods: {
    ...mapActions('shop', [
      'getOrders',
      'getOrdersReport',
    ]),
    download() {
      this.pendingDownload = true;

      this.getOrdersReport({
        data: {
          catalogId: this.catalogDetails.id,
          days: this.reportDays,
          showUnpaid: this.showUnpaid,
          payerIds: this.selectedPayers.map((x) => x.id),
          properties: ['name', 'code'],
        },
      })
        .then(({ data, headers }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', getFileName(headers));
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.pendingDownload = false;
        });
    },
    request() {
      if (this.singleOrderSchema) {
        this.pending = true;
        this.getOrders({
          params: {
            query: {
              catalogId: this.catalogDetails.id,
            },
          },
        })
          .then(({ data }) => {
            this.orders = data;
          })
          .finally(() => {
            this.pending = false;
          });

        return;
      }

      if (!this.dates) return;

      this.pending = true;
      this.getOrders({
        params: {
          query: {
            catalogId: this.catalogDetails.id,
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data, config }) => {
          if (this.dates.from === config.params.query.from) {
            this.orders = data;
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.showUnpaid = !!localStorage.getItem(showUnpaidStorageKey);
    this.groupKey = localStorage.getItem(groupByStorageKey) || 'payers';

    if (this.singleOrderSchema) {
      this.request();
    }

    this.$emit('page', 'orders');
  },
};
</script>

<style>
.smaller-icon {
    font-size: 0.85rem;
  }
</style>
