<template>
  <div
    class="col-sm-12 px-0"
  >
    <div
      v-if="error"
      class="settings-box"
    >
      <span
        class="text-danger small"
      >
        {{ $t('error.settingsUnavailable') }}
      </span>
    </div>

    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="settings-title pb-2">
        {{ $t('settings.notify.billUpdates') }}
      </div>

      <SwitchWithDescription
        class="mt-4"
        :title="$t('settings.notify.sendBillUpdates')"
        :description="$t('settings.info.sendBillUpdates')"
        :pending="preferencesPending || error"
        :value="preferences['BlockBillUpdates'] !== 'true'"
        @switch="update('BlockBillUpdates', (!$event).toString())"
      />

      <SwitchWithDescription
        class="mt-4"
        :title="$t('settings.notify.sendBillUpdatesToInactive')"
        :description="$t('settings.info.sendBillUpdatesToNotInvited')"
        :pending="preferencesPending || error"
        :value="preferences['SendInactiveBillUpdates'] === 'true'"
        @switch="update('SendInactiveBillUpdates', $event.toString())"
      />
    </div>

    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="settings-title pb-2">
        {{ $t('settings.notify.billingScopeSummary') }}
      </div>
      <SwitchWithDescription
        class="mt-2"
        :title="$t('settings.notify.sendBillingScopeSummary')"
        :description="$t('settings.info.sendBillingScopeSummary')"
        :pending="preferencesPending || error"
        :value="preferences['SendBillingScopeSummary'] === 'true'"
        @switch="update('SendBillingScopeSummary', $event.toString())"
      />

      <ScopeDaySelect
        style="max-width: 370px;"
        :init-value="preferences['BillingScopeSummaryDay']"
        :pending="preferencesPending || error"
        @update="update('BillingScopeSummaryDay', $event.toString())"
      />
    </div>

    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div
        v-if="preferencesPending"
        class="position-absolute text-center"
        style="top: 20px; width: 100%;"
      >
        <Loader />
      </div>
      <div class="settings-title pb-2">
        {{ $t('settings.notify.reminders') }}
      </div>

      <SwitchWithDescription
        class="mt-2"
        :title="$t('settings.notify.sendBillDueDateExpired')"
        :description="$t('settings.info.sendBillDueDateExpired')"
        :pending="preferencesPending || error"
        :value="preferences['SendBillDueDateExpired'] === 'true'"
        @switch="update('SendBillDueDateExpired', $event.toString())"
      />

      <div
        class="settings-title mt-4 mb-2 d-flex"
      >
        {{ $t('settings.remindLatePayments') }}
        <TooltipHelp class="pl-3">
          {{ $t('settings.info.remindLatePayments') }}
        </TooltipHelp>
      </div>
      <div class="row container-small mt-3">
        <div>
          <BFormRadioGroup
            :checked="preferences['RemindersPolicy']"
            :disabled="preferencesPending || error"
            class="ml-3"
            stacked
            @change="update('RemindersPolicy', $event)"
          >
            <BFormRadio
              name="notify-email"
              value="EveryThreeDays"
            >
              {{ $t('settings.notify.every3days') }}
            </BFormRadio>
            <BFormRadio
              name="notify-email"
              value="EveryWeek"
            >
              {{ $t('settings.notify.everyWeek') }}
            </BFormRadio>
            <BFormRadio
              name="notify-email"
              value="EveryTwoWeeks"
            >
              {{ $t('settings.notify.everyTwoWeeks') }}
            </BFormRadio>
            <BFormRadio
              name="notify-email"
              value="EveryMonth"
            >
              {{ $t('settings.notify.everyMonth') }}
            </BFormRadio>
            <BFormRadio
              name="notify-email"
              value="Never"
            >
              {{ $t('settings.notify.never') }}
            </BFormRadio>
          </BFormRadioGroup>
        </div>
      </div>

      <DaySelect
        style="max-width: 370px;"
        :init-value="preferences['RemindersFirstDay']"
        :pending="preferencesPending || error"
        @update="update('RemindersFirstDay', $event.toString())"
      />
    </div>

    <div
      class="settings-box"
      :class="{ disabled: error }"
    >
      <div class="settings-title pb-2">
        {{ $t('settings.notify.smsNotifications') }}
      </div>
      <SwitchWithDescription
        :title="$t('settings.notify.sendRemindersBySms')"
        :description="$t('settings.info.sendRemindersBySms')"
        :pending="preferencesPending || error"
        :value="preferences['SendSmsReminders'] === 'true'"
        @switch="update('SendSmsReminders', $event.toString())"
      />
      <SwitchWithDescription
        :title="$t('settings.notify.sendBillUpdates')"
        :description="$t('settings.info.sendBillUpdatesBySms')"
        :pending="preferencesPending || error"
        :value="preferences['SendSmsBillUpdates'] === 'true'"
        @switch="update('SendSmsBillUpdates', $event.toString())"
      />
      <!-- <div
        class="mb-1 mt-3 d-flex align-items-center"
      >
        <div class="pr-3">
          <small>
            {{ $t('settings.notify.smsCount') }}:
          </small>
        </div>
        <TooltipHelp class="pl-3">
          {{ $t('settings.info.smsCount') }}
        </TooltipHelp>
      </div>

      <div
        class="font-weight-bold pl-2"
        style="font-size: 30px; width: 120px"
      >
        {{ limits ? (limits.currentSmsLimit || 0) : 0 }}
      </div> -->
    </div>

    <div
      class="settings-box mb-4"
      :class="{ disabled: error }"
    >
      <div class="settings-title pb-2">
        {{ $t('settings.notify.uninvitedPayers') }}
      </div>
      <SwitchWithDescription
        :title="$t('settings.notify.sendReminders')"
        :description="$t('settings.info.sendRemindersToNotInvited')"
        :pending="preferencesPending || error"
        :value="preferences['SendInactiveReminders'] === 'true'"
        @switch="update('SendInactiveReminders', $event.toString())"
      />
      <SwitchWithDescription
        class="mt-4"
        :title="$t('settings.notify.sendNewMessages')"
        :description="$t('settings.info.sendNewMessagesToNotInvited')"
        :pending="preferencesPending || error"
        :value="preferences['SendInactiveMessages'] === 'true'"
        @switch="update('SendInactiveMessages', $event.toString())"
      />
    </div>
  </div>
</template>

<script>
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import DaySelect from '@/components/settings/notify/DaySelect';
import ScopeDaySelect from '@/components/settings/notify/ScopeDaySelect';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    emailNotify: '',
    preferences: {},
    limits: null,
    error: false,
    smsPending: false,
    preferencesPending: false,
    initBillingScopeSummaryDay: '1',
    billingScopeDaySuccess: false,
  }),
  components: {
    SwitchWithDescription,
    ScopeDaySelect,
    DaySelect,
  },
  computed: {
    ...mapGetters(['payeeId']),
  },
  methods: {
    ...mapActions([
      'getPayeeNotifyPreferences',
      'setPayeeNotifyPreferences',
      'getSmsLimit',
    ]),
    update(key, value) {
      this.preferencesPending = true;
      this.setPayeeNotifyPreferences({
        data: [{
          key,
          value,
        }],
      })
        .then(({ data }) => {
          this.init(data);
        })
        .finally(() => {
          this.preferencesPending = false;
        });
    },
    init(data) {
      this.preferences = data.reduce((acc, curr) => {
        this.$set(acc, curr.key, curr.value);
        return acc;
      }, {});

      this.$set(this.preferences, 'BillingScopeSummaryDay', this.preferences.BillingScopeSummaryDay || 1);
      this.initBillingScopeSummaryDay = this.preferences.BillingScopeSummaryDay;
    },
    request() {
      this.smsPending = true;
      this.getSmsLimit()
        .then(({ data }) => {
          this.limits = data;
        })
        .finally(() => {
          this.smsPending = false;
        });

      this.preferencesPending = true;
      this.error = false;
      this.getPayeeNotifyPreferences()
        .then(({ data }) => {
          this.init(data);
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.preferencesPending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'notifications');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .time-text {
    line-height: 1.1;
    font-size: 13px;
  }
</style>
