<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t('messages.threadStatus') }}
        </div>
        <div
          v-if="selectedOption"
          style="font-size: 14px"
        >
          <i
            :class="selectedOption.icon"
            class="pr-1"
          />
          {{ selectedOption.text }}
        </div>
      </div>
    </template>

    <div style="min-width: 180px">
      <div
        v-for="(k, i) in options"
        :key="k.key"
        class="sort-option text-left"
        :class="{ first: i === 0, active: selected === k.key }"
        @click="select(k.key)"
      >
        <i
          :class="k.icon"
          class="pr-1"
        />
        {{ k.text }}
      </div>
    </div>
  </Tippy>
</template>

<script>
export default {
  props: {
    selected: String,
  },
  computed: {
    selectedOption() {
      return this.options.find((x) => x.key === this.selected);
    },
    options() {
      return [
        {
          key: 'open',
          icon: 'far fa-inbox',
          text: this.$t('messages.status.open'),
        },
        {
          key: 'closed',
          icon: 'far fa-circle-check',
          text: this.$t('messages.status.closed'),
        },
      ];
    },
  },
  methods: {
    select(key) {
      this.$emit('update:selected', key);
      this.$refs.popover.tippy().hide();
    },
  },
};
</script>

<style lang="scss" scoped>
 @import "../payers/style.scss";
</style>
