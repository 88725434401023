import Dashboard from './components/layout/Dashboard';
import NewMessage from './components/messages/NewMessage';
import BasketSummary from './pages/BasketSummary';
import CatalogOrderScreen from './pages/CatalogOrderScreen';
import Home from './pages/Home';
import MealOrderScreen from './pages/MealOrderScreen';
import Messages from './pages/Messages';
import PayerSettings from './pages/PayerSettings';
import Shop from './pages/Shop';

export default {
  path: '',
  name: 'payerRoot',
  component: Dashboard,
  children: [
    {
      path: 'payer/home',
      component: Home,
    },
    {
      path: 'payer/settings',
      component: PayerSettings,
    },
    {
      path: 'payer/basket',
      name: 'basket',
      component: BasketSummary,
      meta: {
        isPublic: true,
      },
    },
    {
      path: 'payer',
      redirect: 'payer/home',
    },
    {
      path: 'payer/shop',
      component: Shop,
    },
    {
      path: 'payer/shop/:payerId',
      component: MealOrderScreen,
      props: true,
    },
    {
      path: 'payer/shop/catalog/:payeeId/:catalogId',
      component: CatalogOrderScreen,
      props: true,
    },
    {
      path: 'payer/messages',
      component: Messages,
    },
    {
      path: 'payer/messages/new',
      component: NewMessage,
    },
  ],
};
