<template>
  <div class="pt-2 full-screen">
    <div class="section-title">
      {{ $t('general.name') }}
    </div>
    <div style="max-width: 500px">
      <BFormInput
        :value="name"
        :placeholder="$t('general.name')"
        @update="$emit('update:name', $event)"
      />
    </div>
    <hr>
    <div class="section-title mt-3">
      {{ $t('shop.price') }}
    </div>
    <div
      v-for="(gp, index) in groupPrice"
      :key="index"
      class="d-flex align-items-center justify-content-between group-price"
    >
      <PayersGroupFilterDropdown
        :selected.sync="gp.groupFilter"
        class="mr-3 ml-1"
        hide-edit
      />
      <div class="d-flex align-items-center">
        <div class="pr-2">
          <MoneyInput
            v-model="gp.price"
            :select-currency="true"
            :currency.sync="gp.currency"
            style="width: 250px"
          />
        </div>
        <div
          class="px-1 group-remove"
          @click="removeGroup(index)"
        >
          <i class="far fa-trash" />
        </div>
      </div>
    </div>
    <div
      v-if="showRule"
      class="d-flex align-items-center justify-content-between group-price"
    >
      <div class="mr-3 pl-3 small">
        <i class="fa-solid fa-code mr-1" />
        {{ $t('shop.availabilityRule') }}:
      </div>
      <div class="pr-2 flex-grow-1">
        <BFormTextarea
          :value="payerAvailabilityScript"
          size="sm"
          :rows="1"
          class="money-font"
          @update="$emit('update:payerAvailabilityScript', $event)"
        />
      </div>
      <div
        class="px-1 group-remove"
        @click="showRule = false"
      >
        <i class="far fa-trash" />
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div
        class="secondary-icon bill-button px-3 mt-2 mx-2"
        style="width: 300px"
        @click="addGroupLimit"
      >
        <i class="fas fa-plus mr-2" />
        <span class="edit-details">
          {{ $t('shop.addPayersGroup') }}
        </span>
      </div>
      <div
        v-if="!showRule"
        style="width: 300px"
        class="secondary-icon bill-button px-3 mt-2 mx-2"
        @click="showRule = true"
      >
        <i class="fa-solid fa-code mr-2" />
        <span class="edit-details">
          {{ $t('shop.availabilityRule') }}
        </span>
      </div>
    </div>

    <hr>
    <div class="row align-items-center">
      <div
        class="section-title mb-0 col-4"
      >
        {{ $t('shop.orderFrom') }}
        <div class="text-secondary small">
          {{ $t('shop.orderFromInfo') }}
        </div>
      </div>
      <div class="d-flex time-select align-items-center col-8">
        <CtkDateTimePicker
          v-model="orderFrom"
          style="width: 300px; margin-left: 6px"
          format="YYYY-MM-DD"
          formatted="DD MMMM YYYY"
          :locale="locale"
          no-label
          :only-date="true"
          input-size="sm"
          data-test="bills-payment-from"
        />
      </div>
    </div>
    <hr>
    <div class="row align-items-center">
      <div
        class="section-title mb-0 col-4"
      >
        {{ $t('shop.payTo') }}
        <div class="text-secondary small">
          {{ $t('shop.orderFromInfo') }}
        </div>
      </div>
      <div class="time-select col-8">
        <CtkDateTimePicker
          v-model="paymentDeadline"
          style="width: 300px; margin-left: 6px"
          format="YYYY-MM-DD"
          formatted="DD MMMM YYYY"
          :locale="locale"
          no-label
          input-size="sm"
          :only-date="true"
          data-test="bills-payment-from"
        />
      </div>
    </div>
    <hr>
    <div class="row align-items-center">
      <div class="section-title col-4">
        {{ $t('shop.orderTo') }}
        <div class="text-secondary small">
          {{ $t('shop.orderToInfo') }}
        </div>
      </div>
      <div class="d-flex time-select align-items-center col-8">
        <BoxSelect
          :selected.sync="orderTo.type"
          :fields="timeTypes"
          style="width: 200px"
        />
        <div class="ml-3">
          <BFormInput
            v-if="orderTo.type === 'RelativeToProduct'"
            v-model="orderTo.time"
            type="text"
            placeholder="HH:mm"
          />
          <CtkDateTimePicker
            v-else
            v-model="orderTo.date"
            format="YYYY-MM-DD"
            formatted="DD MMMM YYYY"
            :locale="locale"
            no-label
            :only-date="true"
            data-test="bills-payment-from"
          />
        </div>
      </div>
    </div>
    <hr>
    <div class="row align-items-center">
      <div class="section-title col-4">
        {{ $t('shop.cancelTo') }}
        <div class="text-secondary small">
          {{ $t('shop.cancelToInfo') }}
        </div>
      </div>
      <div class="d-flex time-select align-items-center col-8">
        <BoxSelect
          :selected.sync="cancelTo.type"
          :fields="timeTypes"
          style="width: 200px"
        />
        <div class="ml-3">
          <BFormInput
            v-if="cancelTo.type === 'RelativeToProduct'"
            v-model="cancelTo.time"
            type="text"
            placeholder="HH:mm"
          />
          <CtkDateTimePicker
            v-else
            v-model="cancelTo.date"
            format="YYYY-MM-DD"
            formatted="DD MMMM YYYY"
            :locale="locale"
            no-label
            :only-date="true"
            data-test="bills-payment-from"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoxSelect from '@/components/BoxSelect';
import PayersGroupFilterDropdown from '@/components/payers/PayersGroupFilterDropdown';
import MoneyInput from '@/components/utils/MoneyInput';
import moment from 'moment';
import CtkDateTimePicker from 'vue-ctk-date-time-picker';
import { mapGetters } from 'vuex';

export default {
  props: {
    price: Array,
    name: String,
    firstDay: String,
    timeSpec: Object,
    payerAvailabilityScript: String,
  },
  data: () => ({
    showRule: false,
    groupPrice: [{
      groupFilter: '#all',
      currency: 'pln',
      price: null,
    }],
    orderTo: {
      type: 'RelativeToProduct',
      time: '10:00',
      date: moment().format('YYYY-MM-DD'),
    },
    paymentDeadline: moment().format('YYYY-MM-DD'),
    orderFrom: moment().format('YYYY-MM-DD'),
    cancelTo: {
      type: 'RelativeToProduct',
      time: '10:00',
      date: moment().format('YYYY-MM-DD'),
    },
  }),
  components: {
    MoneyInput,
    BoxSelect,
    CtkDateTimePicker,
    PayersGroupFilterDropdown,
  },
  computed: {
    ...mapGetters(['locale', 'payee']),
    timeTypes() {
      return [
        {
          key: 'Absolute',
          icon: 'fas fa-calendar',
          text: this.$t('time.date'),
        },
        {
          key: 'RelativeToProduct',
          icon: 'fas fa-clock',
          text: this.$t('shop.orderFulfillmentBased'),
        },
      ];
    },
    timeSpecification() {
      return {
        paymentDeadline: this.paymentDeadline,
        orderFrom: this.orderFrom,
        orderTo: this.orderTo,
        cancelTo: this.cancelTo,
      };
    },
  },
  watch: {
    timeSpecification: {
      deep: true,
      handler() {
        this.$emit('update:timeSpec', this.timeSpecification);
      },
    },
  },
  methods: {
    addGroupLimit() {
      this.groupPrice.push({
        groupFilter: '#all',
        currency: 'pln',
        price: null,
      });
      this.$emit('update:price', this.groupPrice);
    },
    removeGroup(index) {
      this.groupPrice = this.groupPrice.filter((x, i) => i !== index);
      this.$emit('update:price', this.groupPrice);
    },
  },
  created() {
    if (this.timeSpec?.paymentDeadline) {
      this.paymentDeadline = this.timeSpec.paymentDeadline;
    } else if (this.firstDay) {
      this.paymentDeadline = this.firstDay;
    }

    if (this.timeSpec?.orderFrom) {
      this.orderFrom = this.timeSpec.orderFrom;
    }

    if (this.timeSpec?.orderTo) {
      this.orderTo = { ...this.timeSpec.orderTo };

      if (this.orderTo.type === 'Absolute') {
        this.orderTo.time = '10:00';
      } else {
        this.orderTo.date = moment().format('YYYY-MM-DD');
      }
    }
    if (this.timeSpec?.cancelTo) {
      this.cancelTo = { ...this.timeSpec.cancelTo };

      if (this.cancelTo.type === 'Absolute') {
        this.cancelTo.time = '10:00';
      } else {
        this.cancelTo.date = moment().format('YYYY-MM-DD');
      }
    }

    if (this.payerAvailabilityScript.length > 0) {
      this.showRule = true;
    }

    this.$emit('update:timeSpec', this.timeSpecification);

    if (this.price && this.price.length > 0) {
      this.groupPrice = this.price;
    } else {
      this.$emit('update:price', this.groupPrice);
    }
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.time-select {
  padding: 5px 5px;

  input {
    width: 207px;
  }
}

.full-screen {
  height: calc(100vh - 210px);
  overflow: auto;
  padding-bottom: 100px;
}

.group-price {
  padding: 5px;
  border: 1px solid #eee;
}

.group-remove {
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;

  &:hover {
    background-color: #eee;
  }
}
</style>
