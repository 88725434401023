<template>
  <div
    class="message"
    :class="{
      'received-message': !sent,
      replying: replyTo,
    }"
  >
    <h5>
      <div
        class="text-secondary pull-right mb-1"
        style="font-size: 12px; font-weight: 300"
      >
        {{ formatDate(timestamp) }}
      </div>
      <div class="d-flex justify-content-between">
        <div class="sender-name d-flex">
          <div
            v-for="(s, i) in senderNames"
            :key="s.id"
          >
            <PayerDetailsPopover
              :id="s.id"
              :enabled="sender.type !== 'PayeeId'"
              class="d-flex mr-1"
              :class="sender.type !== 'PayeeId' ? 'hoverable' : ''"
            >
              {{ s.name }}<span v-if="i !== senderNames.length - 1">,</span>
            </PayerDetailsPopover>
          </div>
        </div>
        <template v-if="!sent && !hideReply">
          <button
            v-if="!replyTo"
            v-tippy="{ arrow: true}"
            :content="$t('messages.reply')"
            class="btn btn-primary icon-btn"
            @click="$emit('reply')"
          >
            <i class="far fa-message-arrow-up-right" />
          </button>
          <button
            v-else
            class="btn btn-warning text-white icon-btn"
            @click="$emit('cancel-reply')"
          >
            <i class="far fa-xmark" />
          </button>
        </template>
        <template v-else-if="canDelete">
          <RemoveButton
            class="message-remove"
            :pending="pendingRemove"
            @confirm="remove"
          />
        </template>
      </div>
      <div class="message-details">
        <div
          v-if="senderEmail"
          class="sender-email"
        >
          <i class="fas fa-circle-user" />
          {{ senderEmail }}
        </div>
      </div>
    </h5>
    <div
      v-if="sender.type === 'PayeeId' && receivers && receivers.length > 0"
      class="d-flex align-items-center flex-wrap"
      style="margin-top: -4px; margin-bottom: 4px"
    >
      <i class="fas fa-message-arrow-up-right pr-1 text-primary replied-icon" />
      <div
        v-for="c in receivers"
        :key="c.id"
        class="pr-1"
      >
        <span class="badge badge-pill badge-primary">
          {{ c.name }}
        </span>
      </div>
    </div>

    <div class="content">
      <RichText
        :value="content"
        style="white-space: pre-line; width: 100%"
      />
    </div>

    <div
      v-if="sender.type !== 'PayeeId' && !seen"
      class="unseen-bouble"
    />
  </div>
</template>

<script>
import RemoveButton from '@/components/utils/RemoveButton';
import RichText from '@/modules/forms/RichText';
import moment from 'moment';
import { mapActions } from 'vuex';
import PayerDetailsPopover from './PayerDetailsPopover';

export default {
  props: {
    id: String,
    content: String,
    sender: Object,
    senderEmail: String,
    senderNames: Array,
    hideReply: Boolean,
    timestamp: String,
    receivers: Array,
    payerIds: Array,
    replyTo: Boolean,
    seen: Boolean,
    threadId: String,
    threadOwnerId: String,
  },
  data: () => ({
    pendingRemove: false,
  }),
  components: {
    RemoveButton,
    PayerDetailsPopover,
    RichText,
  },
  computed: {
    sent() {
      return this.sender.type === 'PayeeId';
    },
    canDelete() {
      return this.sender.type === 'PayeeId'
        && moment(this.timestamp).isAfter(moment().subtract(1, 'hour'));
    },
  },
  methods: {
    ...mapActions(['removeMessage']),
    remove() {
      this.pendingRemove = true;

      this.removeMessage({
        params: {
          query: {
            threadId: this.threadId,
            threadOwnerId: this.threadOwnerId,
            messageId: this.id,
          },
        },
      })
        .then(() => {
          this.$emit('removed');
        })
        .finally(() => {
          this.pendingRemove = false;
        });
    },
    formatDate(d) {
      const m = moment(d);
      if (m.year() === moment().year()) {
        return m.format('HH:mm D MMMM');
      }
      return m.format('HH:mm D MMMM YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>

  .message {
    box-shadow: 0 0 5px rgba(100,100,100,0.1);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    position: relative;
    font-size: 15px;
    border: 2px solid transparent;

    .unseen-bouble {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $red;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid transparent;
    }

    .content {
      padding-left: 10px;
    }

    &.replying {
      border: 2px solid $yellow;
    }

    &.unseen {
      border-right: 3px solid $blue;
    }

    .replied-icon {
      font-size: 12px;
      line-height: 1;
      padding-top: 2px;
    }

    h5 {
      margin: 0 0 5px;
      font-size: 17px;
      font-weight: 500;
    }

    .sender-name {
      font-size: 14px;
      margin-bottom: 2px;
      min-height: 32px;
    }

    .message-details {
      margin-top: -15px;
    }

    .sender-email {
      font-weight: 300;
      color: #939393;
      font-size: 12px;
      margin-bottom: 5px;

      i {
        position: relative;
        top: 1px;
        margin-right: 2px;
      }
    }

    &.received-message {
      margin-left: 40px;

      .sender-name {
        color: $blue;
      }
    }
  }

  .sender-name {
    .hoverable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .message-remove ::v-deep .remove-icon {
    color: #aaa;
    font-size: 14px;
  }
</style>
