<template>
  <Tippy
    :trigger="enabled ? 'click' : 'manual'"
    theme="light"
    interactive
    max-width="350"
    flip-on-update="true"
    @show="request"
  >
    <template #trigger>
      <slot />
    </template>

    <Loader v-if="pending" />
    <div
      v-else-if="details"
      class="text-left p-3"
      style="width: 400px"
    >
      <PayerHeader
        v-bind="details"
        class="px-0"
        style="font-size: 12px; margin-bottom: 12px; margin-left: -5px"
      />
      <PayerDataSummary
        v-bind="details"
      />
    </div>
  </Tippy>
</template>

<script>
import { mapActions } from 'vuex';
import PayerHeader from '../payers/PayerHeader';
import PayerDataSummary from '../payers/sections/PayerDataSummary';

export default {
  props: {
    enabled: Boolean,
    id: String,
  },
  data: () => ({
    details: null,
    pending: false,
  }),
  components: {
    PayerHeader,
    PayerDataSummary,
  },
  methods: {
    ...mapActions(['getPayerDetails']),
    request() {
      this.pending = true;
      this.getPayerDetails({
        params: {
          payerId: this.id,
          query: {
            withBills: true,
            withActivation: false,
          },
        },
      })
        .then(({ data }) => {
          this.details = data;
          this.$emit('updated', this.details);
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
};
</script>
