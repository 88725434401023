<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center"
    >
      <h5 class="payer-section mb-3">
        Historia zamówień
      </h5>
      <div @click="$emit('close')">
        <i class="fas fa-times secondary-icon" />
      </div>
    </div>

    <div class="d-flex align-items-center flex-wrap">
      <PayersFilterDropdown
        class="mr-3 mb-1"
        @selected="payers = $event"
      />
      <CalendarDropdown
        :title="$t('shop.orderForDay')"
        all-available
        :highlighted.sync="highlighted"
        :day-status="{}"
        :dates.sync="dates"
        allow-none
      />
      <TypeMultiselectDropdown
        :options="typeOptions"
        class="ml-2"
        :title="$t('general.operationTypes')"
        :selected.sync="selectedTypes"
      />
    </div>
    <hr class="mt-2">
    <div class="log-overflow">
      <div
        class="change-log"
        style="min-width: 600px"
      >
        <div
          v-for="(log, month) in monthsWithLog"
          :key="month"
        >
          <div class="text-secondary mt-3 mb-1 ml-2 small text-capitalize">
            {{ month }}
          </div>
          <div
            v-for="l in log"
            :key="`${l.timestamp}_${l.operationId}`"
            class="d-flex align-items-center log-row"
          >
            <div
              style="width: 130px"
              class="text-secondary"
            >
              {{ formatDate(l.timestamp) }}
            </div>
            <div
              style="width: 150px; max-width: 150px;"
              class="d-flex align-items-center"
            >
              <div class="text-truncate">
                {{ l.payer.name }}
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              style="width: 150px;"
            >
              <i
                :class="l.typeInfo.icon"
                :style="{ color: colors(l.typeInfo.color) }"
                class="pr-2 operation-type"
              />
              {{ l.typeInfo.text }}
            </div>
            <div
              v-if="catalogDetails.orderSchema === 'Daily'"
              class="font-weight-bold item-date"
            >
              {{ l.itemDate }}
            </div>
            <div
              style="min-width: 200px; max-width: 200px"
            >
              <Tippy
                theme="light"
                @show="$set(popup, l.operationId, true)"
                @hidden="$set(popup, l.operationId, false)"
              >
                <template #trigger>
                  <div class="text-truncate">
                    {{ l.productSetName }}
                  </div>
                </template>

                <div>
                  <AuditPopup
                    v-if="popup[l.operationId]"
                    :entry="l"
                  />
                </div>
              </Tippy>
            </div>
            <div class="money-font flex-grow-1 ml-3">
              {{ formatCurrency(l.price, 'pln') }}
            </div>

            <div
              v-if="l.user"
              v-tippy
              :content="l.user.email"
              class="text-secondary ml-2"
            >
              <i
                v-if="l.requesterId === 'abf5b083-8125-421c-94ee-bf77ec89e8dd'"
                class="fa-solid fa-gear"
              />
              <i
                v-else-if="l.user.email.includes('@pagaspot.com')"
                class="fa-solid fa-user-doctor-hair"
              />
              <i
                v-else-if="adminIds.includes(l.user.id)"
                class="fa-solid fa-building-user"
              />
              <i
                v-else
                class="fa-solid fa-user"
              />
            </div>
          </div>
        </div>

        <div class="log-row no-hover">
          <div class="text-secondary">
            <div>
              ...
            </div>
            {{ formatSimpleDate(from) }}
          </div>
        </div>
        <hr>
        <div class="text-center mb-3">
          <Loader
            v-if="pending"
          />
        </div>
      </div>
      <InfinityScrollCursor
        ref="cursor"
        @request="getMore"
      />
    </div>
  </div>
</template>

<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import colors from '@/utils/colors';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import CalendarDropdown from '../report/CalendarDropdown';
import AuditPopup from './AuditPopup';
import TypeMultiselectDropdown from './TypeMultiselectDropdown';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    from: moment().subtract(2, 'hours').format(),
    to: moment().add(1, 'day').startOf('day').format(),
    pending: false,
    payers: [],
    highlighted: [],
    selectedTypes: [
      'OrderCreatedEvent',
      'OrderItemsCancelledEvent',
    ],
    productSets: {},
    dates: null,
    popup: {},
    adminIds: [],
    busy: false,
    take: 100,
    response: {
      log: [],
      users: {},
      payers: {},
    },
    requestId: 0,
    stopped: false,
    jumpHours: 2,
  }),
  components: {
    TypeMultiselectDropdown,
    PayersFilterDropdown,
    CalendarDropdown,
    AuditPopup,
    InfinityScrollCursor,
  },
  computed: {
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
    ...mapGetters([
      'properties',
      'payee',
      'payersPropertyTypes',
      'formatCurrency',
    ]),
    monthsWithLog() {
      return this.filteredLog.reduce((acc, curr) => {
        const key = moment(curr.timestamp).format('MMMM YYYY');

        if (!acc[key]) {
          acc[key] = [];
        }

        if (curr.operationId) {
          acc[key].push(curr);
        }

        return acc;
      }, {});
    },
    typeOptions() {
      return Object.keys(this.typeInfo)
        .map((k) => ({
          key: k,
          text: this.typeInfo[k].text,
        }));
    },
    typeInfo() {
      return {
        OrderCreatedEvent: {
          icon: 'fas fa-circle-plus',
          text: 'Zamówiono',
          color: 'green',
        },
        OrderItemsCancelledEvent: {
          icon: 'fas fa-circle-xmark',
          text: 'Odwołano',
          color: 'red',
        },
        OrderPaidEvent: {
          icon: 'fas fa-circle-dollar',
          text: 'Opłacono',
          color: 'green',
        },
        OrderUnpaidEvent: {
          icon: 'fas fa-circle-dollar',
          text: 'Wycofano opłatę',
          color: 'orange',
        },
        OrderProductsUpdatedEvent: {
          icon: 'fas fa-pen-circle',
          text: 'Zmiana produktów',
          color: 'orange',
        },
        OrderItemUpdatedEvent: {
          icon: 'fas fa-pen-circle',
          text: 'Zaktualizowano',
          color: 'orange',
        },
      };
    },
    payerIds() {
      return this.payers.map((x) => x.id);
    },
    filteredLog() {
      return this.logWithProperty
        .slice(0, this.take);
    },
    logWithProperty() {
      return this.response.log
        .map((x) => ({
          ...x,
          payer: this.response.payers[x.payerId],
          user: this.response.users[x.requesterId],
          productSetName: this.productSets[x.offerId],
          typeInfo: this.typeInfo[x.operationType],
        }));
    },
    defaultJump() {
      if (this.highlighted && this.highlighted.length) {
        return 7 * 24;
      }

      if (this.payerIds.length > 0) {
        return 7 * 24;
      }

      return 2;
    },
  },
  watch: {
    highlighted() {
      this.resetAndRequest();
    },
    payerIds() {
      this.resetAndRequest();
    },
    selectedTypes() {
      this.resetAndRequest();
    },
  },
  methods: {
    ...mapActions('shop', [
      'getOrdersAudit',
      'getCatalogListings',
      'getProductSets',
    ]),
    ...mapActions(['getPayeeAdmins']),
    colors,
    formatDate(date) {
      return moment(date).format('HH:mm DD MMM YYYY');
    },
    formatSimpleDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
    resetAndRequest() {
      this.response.log = [];

      this.response = {
        log: [],
        users: {},
        payers: {},
      };
      this.stopped = false;

      this.jumpHours = this.defaultJump;

      this.from = moment().subtract(this.jumpHours, 'hours').format();
      this.to = moment().add(1, 'day').startOf('day').format();

      this.take = 100;
      this.requestId += 1;
      this.request(true);
    },
    getMore(force) {
      if (force || this.take >= this.response.log.length) {
        this.to = this.from;

        if (this.highlighted && this.highlighted.length > 0) {
          const firstDay = this.highlighted[0];
          if (moment(firstDay, 'YYYY-MM-DD').subtract(3, 'months').isAfter(moment(this.from))) {
            this.stopped = true;
          }
        }

        this.from = moment(this.from).subtract(this.jumpHours, 'hours').format();
        this.request();

        if (this.take < this.response.log.length) {
          this.take += 100;
        }
      } else {
        this.take += 100;
      }
    },
    propertyIcon(type) {
      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
    request(force) {
      if (this.stopped) return;
      if (!force && this.pending) return;
      this.pending = true;
      this.requestId += 1;

      this.getOrdersAudit({
        params: {
          query: {
            catalogId: this.catalogId,
            from: this.from,
            to: this.to,
            payerIds: this.payerIds,
            itemDates: this.highlighted,
            operationType: this.selectedTypes,
          },
          requestId: this.requestId,
        },
      })
        .then(({ data, config }) => {
          if (config.params.requestId !== this.requestId) {
            return;
          }

          if (data.log.length === 0) {
            this.jumpHours = Math.min(30 * 24, this.jumpHours * 1.5);
          } else {
            this.jumpHours = this.defaultJump;
          }

          this.response.log = [
            ...this.response.log,
            ...data.log,
            { timestamp: this.from },
          ];

          this.$set(this.response.users, 'abf5b083-8125-421c-94ee-bf77ec89e8dd', { email: 'System' });
          data.users.forEach((u) => {
            this.$set(this.response.users, u.id, u);
          });
          data.payers.forEach((p) => {
            this.$set(this.response.payers, p.id, p);
          });

          setTimeout(() => {
            if (this.$refs.cursor && !this.$refs.cursor.isBottom()) {
              this.getMore(true);
            }
          }, 100);
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.jumpHours = this.defaultJump;
    this.to = moment().add(1, 'day').startOf('day').format();
    this.from = moment().subtract(this.defaultJump, 'hours').format();

    this.getPayeeAdmins()
      .then(({ data }) => {
        this.adminIds = data.userIds;
      });

    if (this.catalogId !== '00000000-0000-0000-0000-000000000000') {
      this.getCatalogListings({
        params: {
          catalogId: this.catalogId,
        },
      })
        .then(({ data }) => {
          data.forEach((ps) => {
            this.$set(this.productSets, ps.id, ps.name);
          });
        });
    } else {
      this.getProductSets()
        .then(({ data }) => {
          data.forEach((ps) => {
            this.$set(this.productSets, ps.id, ps.name);
          });
        });
    }

    this.request();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/components/audit.scss";

  .payer-section {
    font-size: 20px;
    font-weight: 600 !important;
  }

  .log-overflow {
    overflow-y: auto;
    overflow-x: auto;
    max-height: calc(100vh - 310px);
  }

  .log-row.no-hover {
    &:hover {
      background-color: white;
    }
  }

  .operation-type {
    font-size: 13px;
  }

  .item-date {
    width: 80px;
    min-width: 80px;
  }

  .value-box {
    background-color: rgba($blue, 0.05);
    border-radius: 4px;
    padding: 1px 8px;
  }
</style>
