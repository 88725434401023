<template>
  <div>
    <div
      v-for="(rate, curr) in currRates"
      :key="curr"
      style="margin: 5px"
    >
      <div
        v-for="managCurr in supportedCurrencies"
        :key="managCurr"
      >
        <div v-if="(curr === (currencyKey + '/' + managCurr)) || (curr === (managCurr + '/' + currencyKey))">
          <div class="input-form">
            <div>
              <BInputGroup
                :prepend="'1 ' + firstChars(curr) + ' ='"
                size="sm"
                :append="lastChars(curr)"
              >
                <BFormInput
                  v-model="innerAmount[curr]"
                  :placeholder="currRates[curr]"
                  size="sm"
                  @keyup="check(curr)"
                />
              </BInputGroup>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="companyRates && companyRates.length === 0"
      class="small"
      style="color: #888"
    >
      {{ $t('settings.currencies.infoNbp') }}
    </div>
    <div v-if="sendErr">
      <span class="text-danger small justify-content-end d-flex"> {{ $t('settings.currencies.sendError') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    currencyKey: String,
  },
  data: () => ({
    selected: null,
    pending: false,
    innerAmount: {},
    currRates: {},
    sendErr: false,
  }),
  computed: {
    ...mapGetters(['companyId']),
    ...mapState({
      supportedCurrencies: (state) => state.currency.companyCurrencies,
      allCurrencies: (state) => state.currency.currencies,
      companyRates: (state) => state.currency.companyRates,
      basicRates: (state) => state.currency.basicRates,
    }),
  },
  methods: {
    ...mapActions([
      'updateCompanyRates',
      'getCompanyRates',
      'getCurrencies',
      'setCurrency',
    ]),
    remove() {
      this.$emit('update:pending', true);
      this.setCurrency({
        params: {
          companyId: this.companyId,
        },
        data: {
          supportedCurrencies: this.supportedCurrencies.filter((x) => x !== this.currencyKey),
        },
      }).catch(() => {
        if (this.supportedCurrencies.length === 1) {
          this.$emit('update:err', true);
        }
      }).then(() => {
        this.$emit('update:pending', false);
        this.toggle(-1);
      }).finally(() => {
        this.$emit('update:pending', false);
      });
    },
    firstChars(curr) {
      return curr.split('/')[0];
    },
    lastChars(curr) {
      return curr.split('/')[1];
    },
    check(v) {
      if (v) {
        this.innerAmount[v] = this.innerAmount[v]
          .replace(/[^\d.]|\.(?=.*\.)/g, '');
      }
    },
    toggle(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
        this.$emit('update:selected', id);
      }
    },
    submit() {
      this.$emit('update:pending', true);
      this.updateCompanyRates({
        params: {
          companyId: this.companyId,
        },
        data: this.innerAmount,
      })
        .catch(() => {
          this.sendErr = true;
        })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .finally(() => {
          this.sendErr = false;
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    this.$emit('page', 'CurrencyPropertyForm');
    this.innerAmount = { ...this.companyRates };
    this.currRates = { ...this.basicRates };
  },
};
</script>

<style lang="scss" scoped>

  .input-form {
      margin-bottom: 1rem;
      margin-top: 1rem;
  }

</style>
