<template>
  <div>
    <img
      v-show="!pending && !error"
      ref="preview"
      class="preview"
    >
    <div v-if="pending">
      <Loader />
    </div>
    <div
      v-else-if="error"
      class="text-warning text-center"
    >
      <div style="font-size: 25px">
        <i class="fa-solid fa-triangle-exclamation" />
      </div>
      {{ $t('accounting.previewNotAvailable') }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    documentId: String,
  },
  data: () => ({
    pending: false,
    error: false,
  }),
  watch: {
    documentId(id) {
      if (id) {
        this.request();
      }
    },
  },
  methods: {
    ...mapActions(['getDocumentPreview']),
    request() {
      this.pending = true;
      this.error = false;

      this.getDocumentPreview({
        params: {
          query: {
            documentId: this.documentId,
          },
        },
      })
        .then(({ data }) => {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(data);
          this.$refs.preview.src = imageUrl;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.preview {
  width: 100%;
  box-shadow: 0 0px 5px #f1f1f1;
}
</style>
