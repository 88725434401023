const everyThree = /(.{3})/g;
const everyFour = /(.{4})/g;
const stringRev = (str) => str.split('').reverse().join('');

export function addSpaces(n) {
  if (!n || n < 1000) return n;
  const str = n.toString();
  return stringRev(stringRev(str).replace(everyThree, '$1 ')).trim();
}

export const money = (v, decimals) => {
  if (!v && v !== 0) return '';
  const neg = parseInt(v, 10) < 0;
  const positive = Math.abs(v).toString().padStart(3, '0');

  const decimal = positive.substring(positive.length - (decimals || 0));
  const integer = positive.substring(0, positive.length - (decimals || 0));

  const withSpaces = addSpaces(parseInt(integer, 10));
  if (decimals < 1) {
    return `${neg ? '-' : ''}${withSpaces}`;
  }
  return `${neg ? '-' : ''}${withSpaces},${decimal}`;
};

export const accountNumber = (v) => (!v ? ''
  : stringRev(stringRev(v.replace(/\s/g, '')).replace(everyFour, '$1 ')).trim());
