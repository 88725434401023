<template>
  <div class="d-flex">
    <div
      v-for="field in fields"
      :key="field.key"
      class="text-center field-type col d-flex flex-column justify-content-center"
      :class="{ selected: field.key === selected }"
      @click="$emit('update:selected', field.key)"
    >
      <i :class="field.icon" />
      <div>
        {{ field.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: String,
    fields: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .field-type {
    border: 1px solid #eee;
    color: #666;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.1;
    margin: 5px;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 300ms, border-color 300ms;

    i {
      font-size: 20px;
      padding-bottom: 5px;
    }

    &:hover {
      border-color: #333;
      background-color: #eee;
    }

    &.selected {
      border-color: $darkblue;
      background-color: rgba($blue, 0.1);
      color: $darkblue;
    }
  }
</style>
