<template>
  <div class="d-flex">
    <div
      class="text-secondary text-nowrap"
      style="width: 150px"
    >
      {{ formatDateTime(timestamp) }}
    </div>
    <div class="pl-2">
      {{ operationName }}
    </div>
    <div class="flex-grow-1 d-flex justify-content-end">
      <div
        class="money-font text-right"
        style="width: 110px"
        :style="{ marginRight: `${operationDescription.column * 120}px` }"
      >
        {{ operationDescription.prefix }}{{ formatMoney(operationDescription.value, operationDescription.currency) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    timestamp: String,
    operationType: String,
    operationDetails: String,
    requesterId: String,
    billCurrency: String,
  },
  computed: {
    ...mapGetters(['formatMoney']),
    operationName() {
      const key = `bill.audit.${this.operationType}`;
      return this.$te(key) ? this.$t(key) : this.operationType;
    },
    operationDescription() {
      if (this.operationType === 'BillPayerAddedEvent') {
        return {
          value: this.operationData.Amount,
          currency: this.billCurrency,
          column: 0,
        };
      }
      if (this.operationType === 'BillPayerUpdatedEvent') {
        return {
          value: this.operationData.Amount,
          currency: this.billCurrency,
          column: 0,
        };
      }
      if (this.operationType === 'BillPayerRemovedEvent') {
        return {
          value: '0',
          currency: this.billCurrency,
          column: 0,
        };
      }
      if (this.operationType === 'MoneyRemovedFromBillEvent') {
        return {
          prefix: '-',
          value: this.operationData.Value,
          currency: this.operationData.Currency,
          column: 1,
        };
      }
      if (this.operationType === 'MoneyAddedToBillEvent') {
        return {
          prefix: '+',
          value: this.operationData.Value,
          currency: this.operationData.Currency,
          column: 1,
        };
      }

      return '';
    },
    operationData() {
      if (!this.operationDetails) {
        return null;
      }

      try {
        return JSON.parse(this.operationDetails);
      } catch {
        return null;
      }
    },
  },
  methods: {
    formatDateTime(date) {
      return moment(date).format('HH:mm DD MMM YYYY');
    },
  },
};
</script>
