<template>
  <div>
    <div
      v-for="s in children"
      :key="s.key"
    >
      <div
        class="text-left d-flex"
      >
        <div
          v-if="hasChildren(s.key)"
          class="caret hover"
          @click="$set(expanded, s.key, !expanded[s.key])"
        >
          <i
            class="far"
            :class="expanded[s.key] ? 'fa-caret-down' : 'fa-caret-right'"
          />
        </div>
        <div
          v-else
          class="caret"
        />
        <div
          class="sort-option d-flex flex-grow-1"
          :class="{
            active: selected === s.key,
          }"
          @click="select(s.key)"
        >
          <div class="flex-grow-1">
            {{ s.text }}
          </div>
          <div
            v-if="!hideSize"
          >
            {{ s.size }}
          </div>
        </div>
      </div>

      <PayersGroupFilterList
        v-if="s.key != '#all' && (expand || expanded[s.key])"
        class="ml-3"
        :groups="groups"
        :select="select"
        :selected="selected"
        :root-id="s.key"
        :hide-size="hideSize"
        :expand="expand"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayersGroupFilterList',
  props: {
    groups: Array,
    selected: String,
    select: Function,
    rootId: String,
    hideSize: Boolean,
    expand: Boolean,
  },
  data: () => ({
    expanded: {},
  }),
  computed: {
    children() {
      return this.groups.filter((x) => x.parentCode === this.rootId);
    },
  },
  methods: {
    hasChildren(parentCode) {
      return parentCode !== '#all'
        && this.groups.some((x) => x.parentCode === parentCode);
    },
  },
};
</script>

<style lang="scss" scoped>
 @import './style.scss';

 .caret {
  width: 25px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #efefef;

  &.hover {
    cursor: pointer;

    &:hover {
      background-color: #ddd;
    }
  }
 }
</style>
