<template>
  <PayersFilterDropdown
    v-if="!threadReceivers"
    ref="dropdown"
    class="receivers"
    :payer-ids="payerIds"
    @selected="updatePayers"
  />
  <PayersPredefinedDropdown
    v-else
    init-open
    class="response-receivers"
    :payers="threadReceivers"
    :payer-ids="payerIds"
    @selected="updatePayers"
  />
</template>

<script>
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import PayersPredefinedDropdown from '@/components/payers/PayersPredefinedDropdown';

export default {
  props: {
    payerIds: Array,
    threadReceivers: Array,
  },
  components: {
    PayersFilterDropdown,
    PayersPredefinedDropdown,
  },
  methods: {
    setPayers(payers) {
      this.$refs.dropdown.setPayers(payers);
    },
    updatePayers(payers) {
      this.$emit('update:payerIds', payers.map((x) => x.id));
    },
  },
};
</script>

<style lang="scss" scoped>
.receivers::v-deep .sort-option-selected {
  background-color: white;
  box-shadow: 0 0 5px rgb(100 100 100 / 30%);
}

.receivers::v-deep .sort-option-selected:hover {
  background-color: #efefef;
}

.response-receivers::v-deep .sort-option-selected {
  background-color: white;
}
</style>
