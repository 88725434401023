<template>
  <div>
    <div
      class="advanced-header"
      @click="expanded = !expanded"
    >
      <i
        class="fa-solid pr-1"
        :class="expanded ? 'fa-chevron-up' : 'fa-chevron-down'"
      />
      {{ $t('general.advanced') }}
    </div>
    <div
      v-show="expanded"
      class="advanced-section"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    expanded: false,
  }),
};
</script>

<style lang="scss" scoped>
.advanced-header {
  cursor: pointer;
  font-size: 0.7rem;
  color: #666;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
}

.advanced-section {
  border-left: 2px solid #aaa;
  padding-left: 10px;
  margin-left: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
