<template>
  <div>
    <div class="page-header">
      <h1>{{ $t('payers.archivedPayers') }}</h1>
      <div
        v-tippy
        :content="$t('menu.payers')"
        class="light-button"
        @click="showPayers"
      >
        <i class="far fa-users" />
      </div>
    </div>

    <div class="d-flex align-items-center">
      <SearchBox
        v-model="search"
        lazy
        class="mr-3"
      />

      <div
        v-if="Object.keys(selectedPayerIds).length > 0"
        class="d-flex align-items-center"
      >
        <Tippy
          ref="archive-popup"
          trigger="click"
          theme="light"
          arrow
          interactive
        >
          <template #trigger>
            <button
              v-tippy
              :content="$t('general.restore')"
              class="btn btn-outline-primary icon-btn mx-1"
            >
              <i class="fa-solid fa-rotate-left" />
            </button>
          </template>

          <button
            class="btn btn-secondary btn-sm btn-block"
            :disabled="pending"
            @click="restore(selectedPayerIds)"
          >
            <i class="fa-solid fa-arrow-turn-left pr-2" />
            {{ $t('general.restore') }}
          </button>
        </Tippy>

        <div class="pl-2 small text-primary text-nowrap">
          {{ $t('form.selected') }}: {{ Object.keys(selectedPayerIds).length }}
        </div>
      </div>
    </div>
    <hr class="mb-0">
    <ColumnEdit
      :edit="!!edited"
    >
      <div class="full-height">
        <div
          v-for="(groupPayers, group) in filteredList"
          :key="group"
        >
          <div
            class="font-weight-bold d-flex align-items-center client-row py-1 mt-3"
            @click="selectGroup(groupPayers, !groupSelected[group])"
          >
            <Checkbox
              :value="groupSelected[group]"
              :emit="false"
              style="position: relative;"
            />
            <div class="pl-1">
              {{ group }}
            </div>
          </div>
          <div
            v-for="payer in groupPayers"
            :key="payer.payer.id"
            data-test="client-list-element"
            class="w-100"
          >
            <SimplePayerRow
              class="list-complete-item"
              v-bind="payer.payer"
              :checked="selected[payer.payer.id]"
              @select="open(payer.payer)"
              @toggle="toggle(payer.payer)"
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            class="btn btn-sm btn-light"
            :disabled="pending"
            style="min-width: 200px"
            @click="getMore"
          >
            <Loader
              v-if="pending"
              size="20px"
              color="#333"
            />
            <span v-else>
              <i class="fas fa-down-to-bracket pr-2" />
              {{ $t('payers.seeBefore', [from.format('DD/MM/YYYY')]) }}
            </span>
          </button>
        </div>
      </div>
      <div
        v-if="list.length === 0"
        class="text-center pt-3"
      >
        {{ $t('payers.noArchived') }}
      </div>

      <template #edit>
        <div
          v-if="edited"
          class="p-2 page-content position-relative"
        >
          <CloseButton
            style="position: absolute; right: 8px; top: 8px; z-index: 100"
            @click.native.stop="edited = null"
          />
          <Transition
            name="client-details"
            mode="out-in"
          >
            <div class="archived-client">
              <PayerHeader
                :type="edited.type"
                :name="edited.name"
                :code="edited.code"
                class="px-2"
              />
              <hr>
              <div class="d-flex justify-content-between">
                <!--
                  TODO: How this should work?
                  <RemoveButton
                  @confirm="remove"
                /> -->
                <div />
                <button
                  class="btn btn-sm btn-primary"
                  :disabled="restorePending"
                  @click="restore([edited.id])"
                >
                  {{ $t('general.restore') }}
                </button>
              </div>
            </div>
          </Transition>
        </div>
      </template>
    </ColumnEdit>
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayerHeader from '@/components/payers/PayerHeader';
import SimplePayerRow from '@/components/payers/SimplePayerRow';
import createSearch from '@/utils/search';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    search: '',
    list: [],
    selected: {},
    from: moment().subtract(1, 'month').startOf('month'),
    to: moment(),
    pending: false,
    restorePending: false,
    edited: null,
  }),
  components: {
    SimplePayerRow,
    ColumnEdit,
    PayerHeader,
  },
  computed: {
    ...mapGetters(['payeeId', 'tenantId']),
    selectedPayerIds() {
      return Object.entries(this.selected).filter((e) => e[1]).map((e) => e[0]);
    },
    groupSelected() {
      return Object.entries(this.filteredList).reduce((acc, [key, payers]) => {
        const all = payers.every((x) => this.selected[x.payer.id]);
        acc[key] = all;
        return acc;
      }, {});
    },
    filteredList() {
      const group = (list) => list.reduce((acc, curr) => {
        const month = moment(curr.archived).format('DD MMMM YYYY');
        if (!acc[month]) {
          this.$set(acc, month, []);
        }

        acc[month].push(curr);
        return acc;
      }, {});

      if (this.search.length === 0) return group(this.list);
      const search = createSearch(this.search);

      return group(this.list
        .filter((x) => search(x.payer.name) || search(x.payer.code)));
    },
  },
  methods: {
    ...mapActions([
      'getArchivedPayers',
      'restorePayers',
    ]),
    selectGroup(payers, value) {
      payers.forEach((p) => {
        this.$set(this.selected, p.payer.id, value);
      });
    },
    showPayers() {
      this.$router.push(`/payee/${this.payeeId}/payers`);
    },
    getMore() {
      this.to = moment(this.from);
      this.from = this.from.subtract(1, 'month');
      this.request();
    },
    toggle(payer) {
      if (this.selected[payer.id]) {
        this.$set(this.selected, payer.id, false);
      } else {
        this.$set(this.selected, payer.id, true);
      }
    },
    open(payer) {
      if (this.edited == null || this.edited.id !== payer.id) {
        this.edited = payer;
      } else {
        this.edited = null;
      }
    },
    remove() {
      // TODO
    },
    restore(payerIds) {
      this.restorePending = true;

      this.restorePayers({
        data: {
          payerIds,
        },
      })
        .then(() => {
          this.edited = null;
          this.list = this.list.filter((x) => !payerIds.includes(x.payer.id));
          this.selected = {};
        })
        .finally(() => {
          this.restorePending = false;
        });
    },
    request() {
      this.pending = true;
      this.getArchivedPayers({
        params: {
          query: {
            from: this.from.toISOString(),
            to: this.to.toISOString(),
          },
        },
      })
        .then(({ data }) => {
          this.list = [...this.list, ...data];
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .light-button {
    position: absolute;
    top: 20px;
    right: -10px;
  }

  .client-row {
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px solid #dddddd;

    &:hover {
      background-color: #eee;
    }
  }

  .full-height {
    height: calc(100vh - 180px);
    overflow: auto;
  }

  .archived-client {
    padding: 20px;
    padding-top: 30px;
    box-shadow: 0px 2px 4px #eee;
    border-radius: 20px;
  }

  .page-header {
    position: relative;
  }
</style>
