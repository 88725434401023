<template>
  <div>
    <div
      class="payment-row align-items-center row no-gutters"
      :style="{
        'border-left-color': color,
        'background-color': isSelected ? lightColor : null,
      }"
    >
      <div
        class="font-weight-bold"
        :class="basic ? 'col-sm-6' : 'col-sm-4'"
        style="line-height: 1.2; overflow: hidden; max-height: 38px;"
      >
        <div>
          <i
            v-if="bill.recurring"
            class="far fa-calendar pr-1"
            style="font-size: 12px; position: relative; top: -2px"
          />
          <span data-test="payment-title">
            {{ bill.title }}
          </span>
        </div>
        <div
          v-if="category && !category.isDefault"
          class="bill-details"
        >
          {{ category.name }}
        </div>
      </div>
      <div class="col">
        <div
          class="progress-container"
        >
          <div
            class="progress"
            :style="{
              'background-color': lightColor
            }"
          >
            <div
              class="progress-bar"
              :style="{
                'width': `${completeRatio}%`,
                'background-color': color
              }"
            />
            <div class="bar-percent d-flex align-items-center">
              {{ !bill.amount ? 100 : round(100*(bill.paid / bill.amount)) }}%
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-md-block col-1 text-right money-font px-2">
        {{ bill.payersCount }}
      </div>
      <div class="col text-right money-font bill-money">
        {{ formatMoney(bill.amount, bill.currency) }}
      </div>
      <Transition name="column">
        <div
          v-if="!basic"
          key="sumPaid"
          class="col text-right money-font bill-money"
        >
          <span>{{ formatMoney(bill.paid, bill.currency) }}</span>
        </div>
      </Transition>
      <Transition name="column">
        <div
          v-if="!basic"
          key="sumAmount"
          class="col text-right money-font bill-money d-none d-md-block"
        >
          <span>{{ formatMoney(bill.amount - bill.paid, bill.currency) }}</span>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import colors from '@/utils/colors';

export default {
  props: {
    bill: Object,
    isSelected: Boolean,
    basic: Boolean,
  },
  computed: {
    ...mapGetters(['payee', 'formatMoney']),
    completeRatio() {
      if (!this.bill.amount) return 100;
      return Math.min(100, 100 * (this.bill.paid / this.bill.amount));
    },
    category() {
      return this.payee?.categories.find((x) => x.id === this.bill.categoryId);
    },
    color() {
      return colors(this.category?.colorCode || 'default');
    },
    lightColor() {
      return tinycolor(this.color).setAlpha(0.15).toString();
    },
  },
  methods: {
    round(v) {
      return Math.floor(v);
    },
    formatDate(epoch) {
      if (!epoch) return '';
      return moment.unix(epoch).format('LL');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "bill-list-animation";

  .payment-row {
    border: 2px solid rgba(100,100,100, 0.1);
    padding: 8px 10px 5px;
    border-radius: 10px;
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
    z-index: 2;
    cursor: pointer;
    height: 54px;
    transition: transform 500ms;

    > div {
      transition: flex 300ms;
    }
    &:hover {
      background-color: rgba(100, 100, 100, 0.1);
    }
  }

  .bill-money {
    font-size: 0.9rem;
  }

  .bill-details {
    font-size: 11px;
    color: #666;
    font-weight: 400;
  }

  .bar-percent {
    position: absolute;
    width: 100%;
    font-weight: 500;
    height: 100%;
    padding-left: 8px;
    color: white;
  }

  @media screen and (max-width: 1000px) {
    .payment-row {
      height: 74px;
    }

    .payment-row ::v-deep .bill-money {
      font-size: 0.8rem;
    }
  }
</style>
