<template>
  <div>
    <div class="d-flex justify-content-between">
      <PayerHeader
        v-bind="payer"
        class="px-2"
      />
      <!-- <BillingScopeDropdown
        :selected.sync="billingScope"
        single
      /> -->
    </div>
    <hr>
    <div
      class="d-flex justify-content-between align-items-center"
    >
      <h5 class="payer-section mb-0">
        {{ $t('bill.changeHistory') }}
      </h5>
      <div @click="$emit('close')">
        <i class="fas fa-times secondary-icon" />
      </div>
    </div>
    <hr class="mt-2">
    <div class="change-log">
      <div
        v-for="l in logWithProperty"
        :key="`${l.timestamp}_${l.propertyCode}`"
        class="d-flex align-items-center log-row"
      >
        <div
          style="width: 120px; min-width: 120px;"
          class="text-secondary"
        >
          {{ formatDate(l.timestamp) }}
        </div>
        <div
          style="width: 120px"
          class="d-flex align-items-center"
        >
          <i
            :class="propertyIcon(l.property.type)"
            class="pr-1 property-icon"
          />
          {{ l.property.name }}
        </div>
        <div class="d-flex align-items-center flex-grow-1">
          <div class="value-box">
            {{ l.valueBefore }}
          </div>
          <div class="px-2">
            <i class="far fa-arrow-right" />
          </div>
          <div class="value-box">
            {{ l.valueAfter }}
          </div>
        </div>
        <div
          v-tippy
          :content="l.user.email"
          class="text-secondary ml-2"
        >
          <i class="fa-solid fa-user" />
        </div>
      </div>

      <div class="log-row">
        <div class="text-secondary">
          <div>
            ...
          </div>
          {{ formatDateSimple(from) }}
        </div>
      </div>
      <hr>
      <div class="text-center mb-3">
        <button
          class="btn btn-sm btn-light"
          :disabled="pending"
          style="min-width: 200px"
          @click="getMore"
        >
          <Loader
            v-if="pending"
            size="20px"
            color="#333"
          />
          <span v-else>
            <i class="fas fa-arrow-down-to-dotted-line pr-2" />
            {{ $t('general.showMore') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import PayerHeader from './PayerHeader';

export default {
  props: {
    payer: Object,
  },
  data: () => ({
    from: moment().subtract(90, 'days').startOf('day').format(),
    to: moment().add(1, 'day').startOf('day').format(),
    pending: false,
    response: {
      log: [],
      users: [],
    },
  }),
  components: {
    PayerHeader,
  },
  computed: {
    ...mapGetters([
      'properties',
      'payersPropertyTypes',
    ]),
    logWithProperty() {
      return this.response.log.map((x) => ({
        ...x,
        user: this.response.users.find((u) => u.id === x.requesterId),
        property: this.properties.find((p) => p.code === x.propertyCode),
      }));
    },
  },
  watch: {
    from() {
      this.request();
    },
  },
  methods: {
    ...mapActions(['getPayerPropertiesChangeLog']),
    formatDate(date) {
      return moment(date).format('HH:mm DD MMM YYYY');
    },
    formatDateSimple(date) {
      return moment(date).format('DD MMM YYYY');
    },
    getMore() {
      this.to = this.from;
      this.from = moment(this.from).subtract(90, 'days').startOf('day').format();
    },
    propertyIcon(type) {
      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
    request() {
      this.pending = true;
      this.getPayerPropertiesChangeLog({
        params: {
          payerId: this.payer.id,
          query: {
            from: this.from,
            to: this.to,
          },
        },
      })
        .then(({ data }) => {
          this.response.log = [...this.response.log, ...data.log];
          this.response.users = [...this.response.users, ...data.users];
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/components/audit.scss";

  .payer-section {
    font-size: 16px;
    font-weight: 600 !important;
  }

  .property-icon {
    font-size: 11px;
  }

  .value-box {
    background-color: rgba($blue, 0.05);
    border-radius: 4px;
    padding: 1px 8px;
  }
</style>
