<template>
  <div class="h-100">
    <div class="position-relative">
      <div class="page-title-wrapper">
        <div
          class="page-title active"
        >
          {{ $t('menu.payers') }}
        </div>
      </div>

      <div
        v-tippy
        class="light-button"
        :content="$t('general.export')"
        style="right: 30px"
        @click="downloadPayers"
      >
        <Loader
          v-if="exportPending"
          color="#333"
          size="14px"
        />
        <i
          v-else
          class="fas fa-download secondary-icon"
        />
      </div>
      <div
        v-tippy
        :content="$t('payers.archivedPayers')"
        class="light-button"
        @click="showArchived"
      >
        <i class="far fa-rectangle-history-circle-user" />
      </div>
    </div>

    <div>
      <div class="d-flex align-items-center">
        <PayersSortDropdown
          :sort-key.sync="sortKey"
          class="mr-3"
        />
        <PayersGroupFilterDropdown
          :selected.sync="groupFilter"
          class="mr-3"
          @edit="groupEdit = true"
        />
        <SearchBox
          v-model="search"
          lazy
          class="mr-3"
        />
        <PayersPageSettingModal
          v-if="payee"
          @refresh="refreshLayout"
        />
      </div>
      <hr class="mt-2 mb-0">
      <div class="sticky-top bg-white">
        <div class="row py-2 align-items-center justify-content-between">
          <div class="col-12 col-md-4 order-3 order-md-1">
            <div>
              <div
                class="d-flex align-items-center"
                style="height: 40px"
              >
                <Checkbox
                  :value="groupCheck['#all']"
                  :pending="groupPending['#all']"
                  @update:value="toggleGroup('#all', $event)"
                />
                <div
                  v-if="Object.keys(selected).length > 0"
                  class="d-flex align-items-center"
                >
                  <button
                    v-tippy
                    class="btn btn-outline-primary icon-btn mx-1"
                    :content="$t('messages.newMessage')"
                    @click="showAddMessage"
                  >
                    <i class="fas fa-comments" />
                    <i class="fas fa-plus xs-add" />
                  </button>
                  <button
                    v-tippy
                    class="btn btn-outline-primary icon-btn mx-1"
                    :content="$t('bill.add')"
                    @click="showAddBill"
                  >
                    <i class="fas fa-money-bill-wave" />
                    <i class="fas fa-plus xs-add" />
                  </button>
                  <button
                    v-tippy
                    class="btn btn-outline-primary icon-btn mx-1"
                    :content="$t('groups.edit')"
                    @click="groupAssignement = true"
                  >
                    <i class="fas fa-users" />
                  </button>
                  <Tippy
                    ref="archive-popup"
                    trigger="click"
                    theme="light"
                    arrow
                    interactive
                  >
                    <template #trigger>
                      <button
                        v-tippy
                        :content="$t('general.archive')"
                        class="btn btn-outline-primary icon-btn mx-1"
                      >
                        <i class="fas fa-box-archive" />
                      </button>
                    </template>

                    <button
                      class="btn btn-secondary btn-sm btn-block"
                      :disabled="pendingRemove"
                      @click="requestArchivePayers"
                    >
                      <i class="fas fa-box-archive pr-2" />
                      {{ $t('general.archive') }}
                    </button>
                  </Tippy>

                  <Tippy
                    ref="activate-popup"
                    trigger="click"
                    placement="top"
                    theme="light"
                    arrow
                    interactive
                  >
                    <template #trigger>
                      <button
                        v-tippy
                        :content="$t('payers.invite')"
                        name="activate-button"
                        class="btn btn-outline-primary icon-btn mx-1"
                      >
                        <i class="fa-solid fa-paper-plane" />
                      </button>
                    </template>

                    <ActivateManyButton
                      :payer-ids="Object.keys(selected)"
                    />
                  </Tippy>

                  <div class="pl-2 small text-primary text-nowrap">
                    {{ $t('form.selected') }}: {{ Object.keys(selected).length }}
                  </div>
                </div>
                <div
                  v-else
                  class="pl-3 d-flex"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 my-1 my-md-0 order-2 order-md-3 text-right d-flex justify-content-end">
            <button
              class="btn btn-sm btn-primary"
              data-test="toggle-add-client"
              @click="addingClient = true"
            >
              <i class="fas fa-plus pr-1" />
              {{ $t('payers.add') }}
            </button>
          </div>
        </div>
        <hr class="mb-1 mt-0">
      </div>
      <div class="d-flex">
        <div
          style="overflow-x: auto"
          :style="{
            'flex-shrink': 0,
            'flex-basis': listWidth,
          }"
        >
          <div
            v-if="!!payee && payersLayout"
            class="pb-5"
            style="min-width: min-content; width: 100%; height: calc(100vh - 250px)"
          >
            <PayerHeaderRow
              :hide-details="edited && !detailsInModal"
              :layout="payersLayout"
              :balance-columns="balanceColumns"
            />

            <div
              class="position-relative page-content w-100"
            >
              <div
                v-for="({list, key, text}) in grouppedClients"
                :key="text"
              >
                <div
                  v-if="sortKey !== 'name'"
                  class="group-title d-flex align-items-center pb-2"
                >
                  <Checkbox
                    :value="groupCheck[key]"
                    :pending="groupPending[key]"
                    class="pr-1 position-relative"
                    style="top: -1px"
                    @update:value="toggleGroup(key, $event)"
                  />
                  <div>
                    {{ text }}
                  </div>
                </div>
                <div
                  :class="sortKey !== 'name' ? 'pl-2' : ''"
                >
                  <div
                    v-for="payer in list"
                    :key="payer.id"
                    data-test="client-list-element"
                    class="client-row w-100"
                  >
                    <PayerRow
                      class="list-complete-item"
                      v-bind="payer"
                      :hide-details="edited && !detailsInModal"
                      :edited="edited && payer.id == edited.id"
                      :checked="!!selected[payer.id]"
                      :payments="payer.payments"
                      :layout="payersLayout"
                      @select="toggleEdit(payer)"
                      @toggle="toggleClient(payer, $event)"
                    />
                  </div>
                  <div
                    v-if="!busy && list.length === 0"
                    class="small py-2 pl-2 text-secondary"
                  >
                    {{ $t('company.noPayers') }}
                  </div>
                </div>
              </div>
              <InfinityScrollCursor
                ref="cursor"
                @request="request"
              />
              <div
                v-if="error"
                class="text-danger text-center pt-4 small"
              >
                {{ $t('error.fail') }}
              </div>
              <div v-else-if="busy">
                <Loader />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="edited && !detailsInModal"
          class="pt-2 width-transition"
          :style="{
            'flex-basis': edited ? '50%' : 0,
          }"
        >
          <div
            class="sticky-top pt-4 px-4 page-content w-100"
            style="max-height: 100vh; overflow: auto; top: 50px; min-height: 100px"
          >
            <CloseButton
              style="position: absolute; right: 8px; top: 12px; z-index: 100"
              @click.native.stop="toggleEdit(edited)"
            />
            <Transition
              name="client-details"
              mode="out-in"
            >
              <PayerDetails
                v-if="detailsReady"
                :key="edited.id"
                class="page-cart mb-2"
                style="margin-top: 0; padding-top: 20px"
                :client-page.sync="clientPage"
                v-bind="edited"
                @updated="update"
                @archive="archive"
                @close="toggleEdit(edited)"
              />
            </Transition>
          </div>
        </div>
      </div>
    </div>

    <BModal
      v-model="detailsModal"
      hide-footer
      hide-header
      size="lg"
      @hidden="edited = null"
    >
      <CloseButton
        style="position: absolute; right: -8px; top: -10px; z-index: 100"
        @click.native.stop="toggleEdit(edited)"
      />
      <div class="p-3">
        <PayerDetails
          v-if="edited"
          :client-page.sync="clientPage"
          v-bind="edited"
          @updated="update"
          @archive="archive"
          @close="toggleEdit(edited)"
        />
      </div>
    </BModal>

    <BModal
      v-model="addingClient"
      hide-footer
      hide-header
      size="lg"
      @hidden="edited = null"
    >
      <CloseButton
        style="position: absolute; right: -8px; top: -10px; z-index: 100"
        @click.native.stop="addingClient = false"
      />
      <div
        class="font-weight-bold"
        style="font-size: 20px"
      >
        {{ $t('company.newClient') }}
      </div>
      <div class="p-3">
        <PayerForm
          @close="addingClient = false"
          @added="refresh"
        />
      </div>
    </BModal>

    <BModal
      v-model="groupAssignement"
      :lazy="true"
      size="lg"
      hide-footer
      hide-header
    >
      <GroupAssignement
        :selected="selected"
        @close="groupAssignement = false"
      />
    </BModal>

    <BModal
      v-model="groupEdit"
      :lazy="true"
      size="lg"
      hide-footer
      hide-header
    >
      <PayersGroups />
    </BModal>

    <BModal
      v-model="activateModal"
      :lazy="true"
      size="lg"
      hide-footer
      hide-header
    >
      <ActivateMany
        v-if="activateModal"
        :clients="selected"
        @close="activateModal = false"
      />
    </BModal>
  </div>
</template>
<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import ActivateMany from '@/components/activation/ActivateMany';
import GroupAssignement from '@/components/clients/GroupAssignement';
import ActivateManyButton from '@/components/payers/ActivateManyButton';
import PayerDetails from '@/components/payers/PayerDetails';
import PayerForm from '@/components/payers/PayerForm';
import PayerHeaderRow from '@/components/payers/PayerHeaderRow';
import PayerRow from '@/components/payers/PayerRow';
import PayersGroupFilterDropdown from '@/components/payers/PayersGroupFilterDropdown';
import PayersPageSettingModal from '@/components/payers/PayersPageSettingModal';
import PayersSortDropdown from '@/components/payers/PayersSortDropdown';
import Checkbox from '@/components/utils/Checkbox';
import PayersGroups from '@/pages/settings/payers/PayersGroups';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const layoutPropertyName = 'PAYERS_LAYOUT';

export default {
  data: () => ({
    newClient: null,
    groupAssignement: false,
    pendingRemove: false,
    groupEdit: false,
    edited: null,
    search: '',
    addingClient: false,
    activateModal: false,
    detailsReady: false,
    detailsInModal: false,
    detailsModal: false,
    clientPage: 'details',
    sortKey: 'name',
    exportPending: false,
    busy: false,
    skip: 0,
    take: 40,
    groupFilter: '#all',
    payments: [],
    payers: [],
    selected: {},
    haveLayout: false,
    groupPending: {},
    groupCheck: {},
    bottom: false,
    pend: true,
    error: null,
    requestId: 0,
    groupRefresh: 0,
    payersLayout: null,
  }),
  components: {
    Checkbox,
    PayerRow,
    PayerHeaderRow,
    PayersSortDropdown,
    PayersGroupFilterDropdown,
    PayersPageSettingModal,
    InfinityScrollCursor,
    PayersGroups,
    GroupAssignement,
    PayerForm,
    PayerDetails,
    ActivateMany,
    ActivateManyButton,
  },
  computed: {
    ...mapGetters([
      'payeeId',
      'tenantId',
      'selectedPayers',
      'payeeProperty',
      'payee',
    ]),
    listWidth() {
      if (this.edited && !this.detailsInModal) {
        return '50%';
      }
      return '100%';
    },
    rawLayout() {
      return this.payeeProperty(layoutPropertyName);
    },
    all: {
      get() {
        if (!this.selected || !this.payers) return null;
        if (this.payers.length === 0) return false;
        return this.selected.length === this.filteredPayers.length;
      },
      set(v) {
        if (!v) {
          this.selected = {};
        } else {
          this.selected = this.filteredPayers.slice();
        }
      },
    },
    grouppedClients() {
      // eslint-disable-next-line no-unused-expressions
      this.groupRefresh;
      if (this.pending) return [];

      return this.groupPayers(this.filteredPayers);
    },
    balanceColumns() {
      const categories = (this.payee?.categories || []).filter((x) => x.isActive);

      return Object.values(this.payers
        .filter((x) => x.balance)
        .flatMap((x) => x.balance)
        .reduce((acc, curr) => {
          const key = `${curr.categoryId}_${curr.currency}`;
          acc[key] = {
            categoryId: curr.categoryId,
            currency: curr.currency,
            category: (categories || []).find((x) => x.id === curr.categoryId),
          };
          return acc;
        }, {}))
        .filter((x) => x.category)
        .sort((a, b) => a.category.name.localeCompare(b.category.name));
    },
    filteredPayers() {
      return this.payers.map(this.fillBalanceColumns);
    },
  },
  watch: {
    sortKey() {
      this.refresh();
    },
    search() {
      this.refresh();
    },
    groupFilter() {
      this.refresh();
    },
  },
  methods: {
    ...mapActions([
      'getPayers',
      'getPayeeProperties',
      'archivePayers',
      'exportPayers',
    ]),
    initLayout() {
      this.payersLayout = [
        { key: 'name', type: 'default' },
        { key: 'code', type: 'default' },
        { key: 'space', type: 'default' },
        { key: 'categories', type: 'default' },
      ];

      try {
        if (this.rawLayout) {
          this.payersLayout = JSON.parse(this.rawLayout);
        }
      } catch {
        //
      }

      this.haveLayout = true;
      this.request();
    },
    refreshLayout() {
      this.initLayout();
      this.refresh();
    },
    showAddMessage() {
      this.$router.push(`/payee/${this.payeeId}/messages/messages?useSelectedPayers=1`);
    },
    showAddBill() {
      this.$router.push(`/payee/${this.payeeId}/bills/form?useSelectedPayers=1`);
    },
    fillBalanceColumns(payer) {
      return {
        ...payer,
        balance: this.balanceColumns
          .map((y) => {
            const categoryBalance = payer.balance.find((b) => b.categoryId === y.categoryId);

            return categoryBalance || {
              balance: 0,
              categoryId: y.categoryId,
              currency: y.currency,
            };
          }),
      };
    },
    downloadPayers() {
      if (this.exportPending) {
        return;
      }

      this.exportPending = true;
      this.exportPayers({
        params: {
          query: {
            group: this.groupFilter === '#all'
              ? undefined
              : this.groupFilter,
            search: this.search.length === 0
              ? undefined
              : this.search,
          },
        },
        data: {
          columns: this.payersLayout.map((x) => x.key),
        },
      })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = `Platnicy_${moment().format('DD_MM_YYYY')}`;
          link.setAttribute('download', `${fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.exportPending = false;
        });
    },
    groupPayers(payers) {
      if (this.sortKey === 'bills') {
        return payers.reduce((acc, curr) => {
          if (curr.balance.reduce((a, c) => a + c.balance, 0) < 0) {
            acc[0].list.push(curr);
          } else {
            acc[1].list.push(curr);
          }
          return acc;
        }, [
          { list: [], key: 'late', text: this.$t('client.late') },
          { list: [], key: 'not-late', text: this.$t('client.notLate') },
        ]);
      }
      if (this.sortKey === 'activation') {
        return payers.reduce((acc, curr) => {
          if (curr.activationStatus === 'Inactive') {
            acc[0].list.push(curr);
          } else if (curr.activationStatus === 'Invited') {
            acc[1].list.push(curr);
          } else {
            acc[2].list.push(curr);
          }
          return acc;
        }, [
          { list: [], key: 'not-invited', text: this.$t('client.notInvited') },
          { list: [], key: 'invite-sent', text: this.$t('client.inviteSent') },
          { list: [], key: 'activated', text: this.$t('client.activated') },
        ]);
      }

      return [{ list: payers, text: '-' }];
    },
    refresh() {
      this.skip = 0;
      this.bottom = false;
      this.busy = false;
      this.payers = [];
      this.requestId += 1;
      this.request();
    },
    archive() {
      const { id } = this.edited;
      this.toggleEdit(this.edited);
      this.payers = this.payers.filter((x) => x.id !== id);
    },
    update(details) {
      const payer = this.payers.find((x) => x.id === details.id);

      payer.name = details.name;
      payer.code = details.code;
      payer.type = details.type;
      payer.groups = details.groupCodes;

      if (!payer.properties) {
        this.$set(payer, 'properties', {});
      }

      details.properties.forEach((p) => {
        this.$set(payer.properties, p.key, p.value);
      });

      let activationStatus = 'Inactive';
      if ((details.invites || []).length > 0) {
        activationStatus = 'Invited';

        if (details.invites.some((x) => x.accepted)) {
          activationStatus = 'Active';
        }
      }

      if (activationStatus !== this.edited.activationStatus) {
        this.$set(payer, 'activationStatus', activationStatus);
        this.groupRefresh += 1;
      }

      this.fillBalanceColumns(payer);
    },
    request() {
      if (this.bottom || this.busy) return;
      if (!this.haveLayout) return;

      this.busy = true;
      this.error = null;
      const id = this.requestId;

      this.getPayers({
        params: {
          query: {
            withBills: this.payersLayout.some((x) => x.type === 'category' || x.key === 'categories'),
            withActivation: this.sortKey === 'activation',
            skip: this.skip,
            take: this.take,
            sort: this.sortKey,
            withGroup: this.payersLayout.filter((x) => x.type === 'group').map((x) => x.key),
            withProperty: this.payersLayout.filter((x) => x.type === 'property').map((x) => x.key),
            group: this.groupFilter === '#all'
              ? undefined
              : this.groupFilter,
            search: this.search.length === 0
              ? undefined
              : this.search,
          },
        },
      })
        .then(({ data }) => {
          if (id !== this.requestId) {
            return;
          }
          this.skip += this.take;

          const uniquePayers = data.filter((x) => !this.payers.some((y) => y.id === x.id));
          this.payers = [...this.payers, ...uniquePayers];

          if (data.length === 0) {
            this.bottom = true;
            this.busy = false;
            return;
          }

          setTimeout(() => {
            this.busy = false;

            if (!this.$refs.cursor || !this.$refs.cursor.isBottom()) {
              this.busy = false;
              this.request();
            }
          }, 200);
        })
        .catch(() => {
          this.error = true;
        });
    },
    toggleGroup(key, v) {
      if (key === '#all' && this.groupFilter === '#all' && this.search.length === 0 && !v) {
        this.selected = {};
        this.$set(this.groupCheck, key, v);
        this.$store.commit('setSelectedPayers', []);
        return;
      }

      this.$set(this.groupPending, key, true);
      this.getPayers({
        params: {
          query: {
            withBills: this.sortKey === 'bills' && key !== '#all',
            withActivation: this.sortKey === 'activation' && key !== '#all',
            withGroup: this.payersLayout.filter((x) => x.type === 'group').map((x) => x.key),
            withCategory: this.payersLayout.filter((x) => x.type === 'category').map((x) => x.key),
            withProperty: this.payersLayout.filter((x) => x.type === 'property').map((x) => x.key),
            skip: 0,
            take: -1,
            sort: key === '#all' ? 'name' : this.sortKey,
            group: this.groupFilter === '#all'
              ? undefined
              : this.groupFilter,
            search: this.search.length === 0
              ? undefined
              : this.search,
          },
        },
      })
        .then(({ data }) => {
          const list = key === '#all'
            ? data
            : this.groupPayers(data).find((x) => x.key === key).list;

          this.$set(this.groupCheck, key, v);
          if (v) {
            list.forEach((x) => {
              this.$set(this.selected, x.id, x);
            });
          } else {
            list.forEach((x) => {
              this.$delete(this.selected, x.id);
            });
          }
          this.$store.commit('setSelectedPayers', Object.values(this.selected));
        })
        .finally(() => {
          this.$set(this.groupPending, key, false);
        });
    },
    toggleEdit(payer) {
      clearTimeout(this.timeout);
      if (this.edited == null || this.edited.id !== payer.id) {
        if (window.innerWidth < 1300) {
          this.detailsInModal = true;
          this.detailsModal = true;
        } else {
          this.detailsInModal = false;
        }
        this.edited = payer;
        this.timeout = setTimeout(() => {
          this.detailsReady = true;
        }, 200);
      } else {
        this.detailsReady = false;
        this.detailsModal = false;
        if (!this.detailsInModal) {
          this.edited = null;
        }
      }
    },
    requestArchivePayers() {
      this.pendingRemove = true;
      const payerIds = Object.keys(this.selected);

      this.archivePayers({
        data: { payerIds },
      })
        .then(() => {
          this.payers = this.payers.filter((x) => !payerIds.includes(x.id));
          payerIds.forEach((id) => {
            this.$delete(this.selected, id);
          });

          this.$store.commit('setSelectedPayers', Object.values(this.selected));
          this.$refs['archive-popup'].tippy().hide();
        })
        .finally(() => {
          this.pendingRemove = false;
        });
    },
    toggleClient(c, isSelected) {
      const ids = Object.keys(this.selected);
      const saved = ids.includes(c.id);
      if (isSelected && !saved) {
        this.$set(this.selected, c.id, c);
      } else if (!isSelected && saved) {
        this.$delete(this.selected, c.id);
      }
      this.$store.commit('setSelectedPayers', Object.values(this.selected));
    },
    showArchived() {
      this.$router.push(`/payee/${this.payeeId}/payers/archived`);
    },
  },
  created() {
    this.$emit('page', 'payers');

    this.selectedPayers.forEach((p) => {
      this.$set(this.selected, p.id, p);
    });

    this.getPayeeProperties({
      params: {
        query: {
          names: 'PAYERS_LAYOUT',
        },
      },
    })
      .finally(() => {
        this.initLayout();
      });

    this.request();
  },
  mounted() {
    this.onKeyDown = (ev) => {
      if (!this.edited) { return; }

      const { key } = ev;

      // jump to next client
      if (key === 'ArrowDown') {
        this.grouppedClients.forEach((g) => {
          const ci = g.list.findIndex((y) => y.id === this.edited.id);
          if (ci < g.list.length) {
            this.edited = g.list[ci + 1];
          }
        });
      } else if (key === 'ArrowUp') {
        this.grouppedClients.forEach((g) => {
          const ci = g.list.findIndex((y) => y.id === this.edited.id);
          if (ci > 1) {
            this.edited = g.list[ci - 1];
          }
        });
      }
    };

    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
};
</script>

<style lang="scss" scoped>

  .info-row {
    border-bottom: 1px solid #dddddd;
    font-size: 12px;
    font-weight: 400;
    padding-top: 3px;
    padding-bottom: 7px;
    width: min-content;
  }

  .page-content {
    height: calc(100vh - 285px);
    overflow-y: scroll;
    padding-bottom: 50px;
    scrollbar-width: 20px;
    width: min-content;
  }

  .group-title {
    font-weight: 500;
    padding-top: 10px;
  }

  .light-button {
    position: absolute;
    top: 20px;
    right: -10px;
  }

  .grouping-prop {
    font-weight: bold;
    cursor: pointer;
    padding: 5px 10px;
    &:hover {
      background-color: rgba(100, 100, 100, 0.2);
    }
  }

  .payment-info-title {
    max-width: 250px;
    padding-left: 10px;
  }

  .xs-add {
    top: 2px;
    right: 4px;
  }

  .slide-enter,
  .slide-leave-to { opacity: 0; max-height: 0px; }

  .slide-leave,
  .slide-enter-to { opacity: 1; max-height: 500px; }

  .slide-enter-active { transition: opacity 400ms, max-height 400ms }
  .slide-leave-active { transition: opacity 200ms, max-height 200ms }

  @media screen and (max-width: 765px) {
    .payment-info-title {
      max-width: 120px;
    }
  }

  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }

</style>
