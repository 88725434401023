<template>
  <div>
    <SaveButton
      :pending="pending"
      @cancel="cancel"
      @save="save"
    />
    <hr>
    <BillTitleAndDescription
      :hide-type="true"
      :bill-title.sync="billTitle"
      :bill-code.sync="billCode"
      :bill-description.sync="billDescription"
      :bill-payment-time.sync="billPaymentTime"
      :bill-from.sync="billFrom"
      :bill-category.sync="billCategory"
    />
  </div>
</template>

<script>
import SaveButton from '@/components/SaveButton';
import moment from 'moment';
import { mapActions } from 'vuex';
import BillTitleAndDescription from '../form/BillTitleAndDescription';

export default {
  props: {
    bill: Object,
    color: String,
  },
  data: () => ({
    pending: false,
    billTitle: '',
    billCode: '',
    billDescription: '',
    billPaymentTime: '',
    billFrom: '',
    billCategory: '',
    error: null,
  }),
  components: {
    BillTitleAndDescription,
    SaveButton,
  },
  methods: {
    ...mapActions(['updateBillDetails']),
    cancel() {
      this.$emit('close');
    },
    checkAmount() {
      if (!this.amount || this.amount.toString().trim() === '0') {
        this.error = this.$t('error.enterPaymentAmount');
        return false;
      }
      return true;
    },
    save() {
      if (!this.billTitle) {
        this.error = this.$t('error.enterPaymentName');
        return;
      }

      this.pending = true;
      this.updateBillDetails({
        params: {
          billId: this.bill.id,
        },
        data: {
          title: this.billTitle,
          code: this.billCode,
          description: this.billDescription,
          categoryId: this.billCategory,
          billingDay: moment.utc(this.billFrom, 'YYYY-MM-DD').startOf('day').format(),
          beginDate: moment.utc(this.billFrom, 'YYYY-MM-DD').startOf('day').format(),
          dueDate: moment.utc(this.billFrom, 'YYYY-MM-DD').add(this.billPaymentTime, 'days')
            .startOf('day')
            .format(),
          descriptions: [],
        },
      })
        .then(({ data }) => {
          this.pending = false;
          this.$emit('update', data);
        })
        .catch(({ response }) => {
          this.pending = false;
          if (response.data) {
            this.error = response.data;
          }
        });
    },
  },

  created() {
    if (this.bill) {
      this.billTitle = this.bill.title;
      this.billCode = this.bill.code;
      this.billDescription = this.bill.description;
      const diff = moment(this.bill.dueDate).diff(moment(this.bill.beginDate));
      this.billPaymentTime = moment.duration(diff).asDays().toString();
      this.billFrom = moment(this.bill.beginDate).format('YYYY-MM-DD');
      this.billCategory = this.bill.categoryId;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
