<template>
  <div
    class="d-flex align-items-center"
  >
    <BFormInput
      v-model="days"
      :disabled="disabled"
      type="text"
      placeholder="dd"
      class="text-right"
    />
    <div class="ml-1 mr-3 font-weight-bold text-lowercase time-text">
      {{ $t('time.days') }}
    </div>
    <BFormInput
      v-model="hours"
      :disabled="disabled"
      type="text"
      placeholder="HH"
      class="text-right"
    />
    <div class="ml-1 mr-3 font-weight-bold time-text">
      {{ $t('time.hrs') }}
    </div>
    <BFormInput
      v-model="minutes"
      :disabled="disabled"
      type="text"
      placeholder="mm"
      class="text-right"
    />
    <div class="ml-1 mr-3 font-weight-bold time-text">
      min
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    disabled: Boolean,
  },
  data: () => ({
    days: '0',
    hours: '0',
    minutes: '0',
  }),
  computed: {
    full() {
      if (parseInt(this.days, 10) === 0) {
        return `${this.hours.padStart(2, '0')}:${this.minutes.padStart(2, '0')}:00`;
      }
      return `${this.days}.${this.hours.padStart(2, '0')}:${this.minutes.padStart(2, '0')}:00`;
    },
  },
  watch: {
    full(v) {
      this.$emit('input', v);
    },
    value() {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.value) {
        const dayPart = this.value.split('.');
        if (dayPart.length > 1) {
          this.days = parseInt(dayPart[0], 10).toString();
        } else {
          this.days = '0';
        }

        const parts = (dayPart.length > 1 ? dayPart[1] : this.value).split(':');

        if (parts.length > 1) {
          this.hours = parseInt(parts[0], 10).toString();
          this.minutes = parseInt(parts[1], 10).toString();
        }
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
  .time-text {
    min-width: 35px;
  }
</style>
