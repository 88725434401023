<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="page-header">
        <h1>
          {{ $t('bill.import') }}
        </h1>
      </div>
      <div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="cancel"
        >
          ×
        </button>
      </div>
    </div>
    <hr class="mt-0">

    <div class="d-flex mb-4">
      <div class="w-50 pr-3 d-flex flex-column">
        <label>
          <i class="far fa-file pr-1" />
          {{ $t('import.file') }}
        </label>
        <div
          v-if="!pending"
          class="flex-grow-1"
        >
          <FileUpload
            class="upload h-100 d-flex align-items-center justify-content-center"
            @upload="validate"
          />
        </div>
        <div
          v-else
          class="w-50"
        >
          <Loader />
        </div>
      </div>

      <div
        v-if="payee"
        class="w-50 pl-3"
        style="max-width: 300px"
      >
        <label class="mb-0">
          {{ $t('payment.accountNumber') }}
        </label>
        <div>
          <BankAccountSelect
            :bank-account-id.sync="accountNumber"
            find-default-bank-account
            :validation="null"
          />
        </div>
        <label class="mt-3">{{ $t('bill.category') }}</label>
        <div>
          <CategoryDropdown :selected.sync="categoryId" />
        </div>
        <label class="mt-3">{{ $t('payment.daysToPay') }}</label>
        <div>
          <BFormInput
            v-model="daysToPay"
            type="number"
          />
        </div>
      </div>
    </div>

    <hr>
    <div
      class="mt-3"
    >
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <div
            v-if="validationResult && validationResult.length === 0"
            class="my-4 d-flex align-items-center"
          >
            <i class="fa-regular file-icon fa-file-circle-check text-primary pr-3" />
            <div class="font-weight-bold text-primary">
              {{ $t('import.noIssues') }}
            </div>
          </div>
          <div
            v-else-if="validationResult"
            class="my-4 d-flex align-items-center"
          >
            <i class="fa-regular file-icon fa-file-circle-info text-warning pr-3" />
            <div class="font-weight-bold text-warning">
              {{ $t('import.thereAreSomeIssues') }}
            </div>
          </div>
          <div v-else />

          <div>
            <button
              class="btn btn-sm btn-primary mt-2"
              :disabled="!validationResult || pending"
              @click="upload"
            >
              {{ $t('shop.confirmImport') }}
              <i class="fa-regular fa-arrow-right pl-2" />
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="error"
        class="text-danger text-center font-weight-bold pb-2"
      >
        {{ $t('error.importImpossible') }}
      </div>
      <div
        v-if="importResult"
        class="text-danger font-weight-bold pb-2"
      >
        <div
          v-for="(e, i) in importResult"
          :key="i"
          class="danger-box text-left"
        >
          <i class="fa-regular fa-triangle-exclamation" />
          {{ e }}
        </div>
      </div>
      <div
        v-else-if="validationResult"
        class="pb-4 validation-result"
      >
        <div
          v-for="(err, i) in validationResult"
          :key="`product_${i}`"
        >
          <ShopImportError
            v-bind="err"
            :translation-key="'bill.error'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload';
import BankAccountSelect from '@/components/bills/form/BankAccountSelect';
import CategoryDropdown from '@/components/bills/form/CategoryDropdown';
import ShopImportError from '@/components/shop/ShopImportError';
import { money } from '@/utils/filters';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    file: null,
    pending: false,
    result: null,
    error: null,
    selected: null,
    validationResult: null,
    importResult: null,

    categoryId: null,
    accountNumber: null,
    daysToPay: 14,
  }),
  components: {
    FileUpload,
    ShopImportError,
    CategoryDropdown,
    BankAccountSelect,
  },
  computed: {
    ...mapGetters(['payee', 'payeeId']),
    dropzoneOptions() {
      return {
        url: 'https://api.pagaspot.com',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: this.$t('import.upload'),
        headers: { },
      };
    },
  },
  watch: {
    payee(p) {
      if (p) {
        this.categoryId = this.payee.categories.find((x) => x.isDefault)?.id;
      }
    },
  },
  methods: {
    money,
    ...mapActions([
      'importBillsValidate',
      'importBills',
    ]),
    cancel() {
      this.$router.push(`/payee/${this.payeeId}/bills`);
    },
    validate(file) {
      this.pending = true;
      this.file = file;

      const formData = new FormData();
      formData.append('file', file);

      this.importBillsValidate({
        data: formData,
      })
        .then(({ data }) => {
          this.validationResult = data;
          this.pending = false;
        })
        .catch(() => {
          this.error = true;
          this.pending = false;
        });
    },
    upload() {
      this.pending = true;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('accountNumber', this.accountNumber);
      formData.append('categoryId', this.categoryId);
      formData.append('daysToPay', this.daysToPay);

      this.importBills({
        data: formData,
      })
        .then(({ data }) => {
          this.importResult = data.errors;
          if (data.errors.length === 0) {
            this.cancel();
          }
          this.pending = false;
        })
        .catch(() => {
          this.error = true;
          this.pending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'form');

    if (this.payee) {
      this.categoryId = this.payee.categories.find((x) => x.isDefault)?.id;
    }
  },
};
</script>

<style lang="scss" scoped>

  .validation-result {
    height: calc(100vh - 213px);
    overflow: auto;
  }

  .danger-box {
    background-color: rgba($red, 0.1);
    margin-top: 5px;
    border-radius: 5px;
    padding: 10px 15px;

    i {
      color: $red;
      font-size: 25px;
      padding-right: 10px;
    }
  }

  .upload {
    min-height: 20px;
    padding: 5px 15px;
    max-width: 700px;
    margin: 0;
  }

  .upload ::v-deep .dz-default {
    margin: 1em 0;
  }

  .close {
    font-size: 40px;
  }
</style>
