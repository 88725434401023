<template>
  <div class="search-input-wrapper d-flex align-items-center">
    <i
      v-if="search.length > 0"
      class="far fa-close close-btn"
      @click="$emit('input', ''); search = '';"
    />
    <i
      v-else
      class="fal fa-search"
    />
    <input
      ref="search"
      v-model="search"
      :placeholder="$t('general.search')"
      class="form-control form-control-sm search-input"
      :class="{ 'constant-width': constantWidth }"
      :style="{
        height: height ? `${height}px` : undefined,
      }"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    lazy: Boolean,
    height: Number,
    delay: {
      type: Number,
      default: 150,
    },
    constantWidth: Boolean,
    autoFocus: Boolean,
  },
  data: () => ({
    search: '',
  }),
  watch: {
    value(v) {
      this.search = v;
    },
    search(s) {
      if (!this.lazy) {
        this.$emit('input', s);
      } else {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
          this.$emit('input', s);
        }, this.delay);
      }
    },
  },
  methods: {
    set(v) {
      this.search = v;
    },
    blur() {
      if (this.$refs.search) {
        this.$refs.search.blur();
      }
    },
    focus() {
      if (this.$refs.search) {
        this.$refs.search.focus();
      }
    },
  },
  created() {
    this.search = this.value;
  },
  mounted() {
    this.$refs.search.addEventListener('keyup', (event) => {
      if (event.key === 'Enter') {
        this.$emit('enter');
      } else if (event.key === 'Escape') {
        this.$emit('cancel');
      }
    });
  },
  destroyed() {

  },
};
</script>

<style lang="scss" scoped>
.search-input-wrapper {
  position: relative;

  .search-input {
    height: 44px;
    background-color: #f4f4f4;
    padding-left: 40px;
    font-size: 14px;
    width: 208px;
    transition: width 300ms;

    &.constant-width {
      width: 100%;
    }

    &::placeholder {
      font-size: 14px;
      color: #939393;
    }
  }

  .close-btn {
    cursor: pointer;
    font-size: 20px;
    width: 24px;
    left: 10px;
    padding: 2px;
    text-align: center;
    border-radius: 50%;

    &:hover {
      color: #333;
      background-color: #ddd;
    }
  }

  .form-control:focus {
    border: none;
    box-shadow: 0px 0px 0px 2px #eee;
    width: 300px;
    background-color: #f9f9f9;

    &.constant-width {
      width: 100%;
    }
  }

  i {
    position: absolute;
    left: 14px;
    color: #888;
  }
}
</style>
