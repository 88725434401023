import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';

import PayApp from '@/modules/pay/PayApp';
import PayStatus from '@/modules/pay/PayStatus';
import payer from '@/modules/payer/router';
import UserSettings from '@/pages/UserSettings';
import OidcCallback from '@/pages/auth/OidcCallback';
import TodayOrder from '@/pages/shop/TodayOrder';
import store from '@/store';
import payee from './payee';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: OidcCallback,
    },
    {
      path: '/usersettings',
      component: UserSettings,
    },
    {
      path: '/pay/:id/:date/:hash',
      component: PayApp,
      props: true,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/client/pay',
      component: PayStatus,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/client/paid',
      component: PayStatus,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/payee/:payeeId/order/today',
      component: TodayOrder,
      props: true,
    },
    payee,
    payer,
  ],
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidc'));

export default router;
