<template>
  <div class="pt-2 w-100">
    <div
      v-if="!result"
      class="pt-2"
    >
      <div
        v-if="error"
        class="text-danger text-center font-weight-bold pb-2"
      >
        {{ $t('error.importImpossible') }}
      </div>
      <VueDropzone
        v-if="!file"
        id="drop"
        :options="dropzoneOptions"
        @vdropzone-file-added="uploaded"
      />
      <Loader v-else />
      <hr>

      <div class="d-flex my-2 align-items-center justify-content-between">
        <div class="d-flex">
          <div class="font-weight-bold">
            {{ $t('import.expectedFormat') }}
          </div>
          <TooltipHelp
            class="pl-3"
            :width="500"
          >
            {{ $t('import.columnsInfo') }}<br>
            {{ type === 'Person' ? $t('import.duplicateDectionPerson') : $t('import.duplicateDectionOrganization') }}
          </TooltipHelp>
        </div>

        <div>
          <button
            class="btn btn-sm btn-outline-secondary"
            @click="reset"
          >
            <i class="fa-regular fa-rotate-right pr-2" />
            {{ $t('import.resetFormat') }}
          </button>
        </div>
      </div>

      <Draggable
        v-model="columnOrder"
        class="draggable row no-gutters align-items-center"
        group="props"
        :move="checkMove"
        ghost-class="ghost"
        @change="saveFormat"
      >
        <div
          v-for="(column, index) in columnOrder"
          :key="index"
          class="col d-flex align-items-center column"
        >
          <div>
            <i
              v-if="column.icon"
              :class="column.icon"
              class="pr-1"
            />
            {{ column.name }}
          </div>
        </div>
      </Draggable>

      <div class="font-weight-bold mt-3 mb-2">
        {{ $t('import.noImport') }}:
      </div>
      <Draggable
        v-model="columnsIgnored"
        class="draggable row no-gutters align-items-center"
        group="props"
        ghost-class="ghost"
      >
        <div
          v-for="(column, index) in columnsIgnored"
          :key="index"
          class="col d-flex align-items-center column"
        >
          <div>
            <i
              v-if="column.icon"
              :class="column.icon"
              class="pr-1"
            />
            {{ column.name }}
          </div>
        </div>
        <span
          v-if="columnsIgnored.length === 0"
          class="px-2 small text-secondary"
        >
          {{ $t('import.moveHereToIgnore') }}
        </span>
      </Draggable>
    </div>
    <div v-else>
      <PayersImportList
        :import="result"
        :columns="columnOrder"
        @cancel="result = null; file = null"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueDropzone from 'vue2-dropzone';
import PayersImportList from '@/components/payers/PayersImportList';
import Draggable from 'vuedraggable';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const storageKey = 'payerimport:format';

export default {
  data: () => ({
    file: null,
    pending: false,
    result: null,
    error: null,
    type: 'Person',
    columnOrder: [],
    columnsIgnored: [],
  }),
  components: {
    VueDropzone,
    PayersImportList,
    Draggable,
  },
  computed: {
    ...mapGetters([
      'payeeId',
      'properties',
      'payersPropertyTypes',
    ]),
    columns() {
      let header = [
        {
          name: this.$t('general.name'),
          code: 'name',
          locked: true,
        },
        {
          name: this.$t('settings.vatNumber'),
          code: 'vatNumber',
        },
        {
          name: this.$t('settings.address'),
          code: 'address',
        },
      ];

      if (this.type === 'Person') {
        header = [
          {
            name: this.$t('client.lastName'),
            code: 'lastName',
            locked: true,
          },
          {
            name: this.$t('client.firstName'),
            code: 'firstName',
            locked: true,
          },
          {
            name: this.$t('client.clientCode'),
            code: 'code',
          },
        ];
      }
      return [
        ...header,
        ...this.properties.map((x) => ({
          name: x.name,
          icon: this.getIcon(x.type),
          code: x.code,
        })),
      ];
    },
    dropzoneOptions() {
      return {
        url: 'https://api.pagaspot.com',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: this.$t('import.upload'),
        headers: { },
      };
    },
  },
  watch: {
    properties() {
      this.restoreFormat();
    },
  },
  methods: {
    ...mapActions([
      'parsePayers',
    ]),
    checkMove(v) {
      if (v.draggedContext.element.locked && v.to !== v.from) {
        return false;
      }
      return true;
    },
    reset() {
      this.columnOrder = this.columns;
      this.columnsIgnored = [];
    },
    getIcon(type) {
      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
    uploaded(file) {
      this.pending = true;
      this.file = true;
      this.error = false;

      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', this.type);

      for (let i = 0; i < this.columnOrder.length; i += 1) {
        formData.append(`columns[${i}]`, this.columnOrder[i].code);
      }

      this.parsePayers({
        data: formData,
      })
        .then(({ data }) => {
          this.result = data;
        })
        .catch(() => {
          this.error = true;
          this.file = null;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    saveFormat() {
      localStorage.setItem(storageKey, JSON.stringify(this.columnOrder.map((x) => x.code)));
    },
    restoreFormat() {
      const storedColumns = localStorage.getItem(storageKey);
      if (!storedColumns) return;

      let codes = [];
      try {
        codes = JSON.parse(storedColumns);
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
      }

      if (codes.length > 0) {
        this.columnOrder = codes.map((x) => this.columns.find((c) => c.code === x)).filter((x) => x);
        this.columnsIgnored = this.columns.filter((x) => !codes.includes(x.code));
      }
    },
  },
  created() {
    this.$emit('backUrl', `/payee/${this.payeeId}/settings/payers`);
    this.reset();
    this.restoreFormat();
  },
};
</script>

<style lang="scss" scoped>

  .activate-info {
    font-size: 22px;
    line-height: 1;
  }

  #drop {
    border-radius: 20px;
    margin-top: 20px;
    font-family: 'Poppins';
  }

  .draggable {
    background-color: #eee;
    margin-left: 0;
    margin-right: 0;
    padding: 5px;
    min-height: 40px;
  }

  .table th {
    font-size: 10px;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .column {
    max-width: 150px;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid #eee;
    border-radius: 7px;
    background-color: #fefefe;
    margin-right: 2px;
    cursor: pointer;
  }
</style>
