<template>
  <div
    class="row no-gutters position-relative"
    style="padding-bottom: 100px; padding-top: 20px"
  >
    <BModal
      v-model="basketModal"
      hide-footer
      hide-header
      size="lg"
    >
      <div class="position-relative pb-3">
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="basketModal = false"
        >
          ×
        </button>
      </div>
      <div
        v-for="g in basketGroups"
        :key="g.key"
      >
        <BasketGroup
          v-bind="g"
          :payee-setup="getPayee(g.payeeId)"
        />
      </div>
    </BModal>

    <div
      v-if="pending"
      class="overlay"
    >
      <Loader />
    </div>

    <div class="col-12 col-md-6 pr-3">
      <Calendar
        :available-days="availableDays"
        :selected-days="daysToAdd"
        :day-status="dayStatus"
        :init-at="initAt"
        @dates="dates = $event"
        @highlighted="highlighted = $event"
      />
    </div>

    <div
      v-if="highlighted.length === 0"
      class="p-4 text-secondary col-12 col-md-6 text-center"
    >
      <i
        class="fa-solid fa-circle-question mb-3"
        style="font-size: 20px"
      />
      <div v-html="$t('shop.shopPayerHelp')" />
    </div>
    <div
      v-else
      class="col-12 col-md-6 pl-2 pt-2"
    >
      <template v-if="highlightedOrderedSets.length > 0">
        <div class="font-weight-bold mb-2">
          {{ $t('shop.orderedSets') }}:
        </div>

        <ProductSetRow
          v-for="set in highlightedOrderedSets"
          :key="`ordered_${set.availabilityId}_${set.isPaid}_${set.isReturned}`"
          v-bind="set"
          :min-price="set.unitPrice"
          :max-price="set.unitPrice"
          :paid="set.isPaid"
          :selected="set.count"
          :returned="set.isReturned"
          :ordered="true"
          @cancel="cancelSet(set)"
        />
        <hr>
      </template>

      <div
        v-if="availableSets.length > 0"
        class="font-weight-bold my-2"
      >
        {{ $t('shop.orderSet') }}:
      </div>

      <ProductSetRow
        v-for="set in availableSets"
        :key="set.availabilityId"
        v-bind="set"
        :available-count="set.typeDisabled ? 0 : 1"
        :selected="highlightedAvailabilities[set.availabilityId]"
        @order="toggle(set)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BasketGroup from '../BasketGroup';
import Calendar from './Calendar';
import ProductSetRow from './ProductSetRow';

export default {
  props: {
    payerId: String,
    payeeId: String,
    initAt: String,
  },
  data: () => ({
    highlighted: [],
    cancelModal: false,
    pending: false,
    offer: {
      products: [],
      productSets: [],
      calendar: [],
    },
    ordered: {
      orders: [],
      products: [],
      productSets: [],
    },
    selectedAvailabilityId: null,
    dates: null,
    basketModal: false,
  }),
  components: {
    BasketGroup,
    ProductSetRow,
    Calendar,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
      'itemInBasket',
      'billingScopeName',
      'basketGroups',
      'userPayers',
    ]),
    ...mapState({
      basket: (state) => state.userBills.basket,
    }),
    blockMultipleOrders() {
      return this.payeeId === 'd5956d90-2876-11ed-9d1d-2fd0bf77d87f';
    },
    daysToAdd() {
      if (!this.selectedAvailabilityId) return [];
      return this.offerDays
        .filter((x) => x.productSets.some((s) => s.availabilityId === this.selectedAvailabilityId))
        .map((x) => x.day);
    },
    daysToRemove() {
      const days = this.highlighted
        .map((day) => {
          const value = this.dayStatus[day]?.new || 0;
          return { day, value };
        })
        .filter((x) => x.value > 0);

      return {
        days: days.map((x) => x.day),
        sum: days.reduce((acc, curr) => acc + curr.value, 0),
      };
    },
    availableSets() {
      const sets = this.offerDays
        .flatMap((x) => x.productSets)
        .reduce((acc, curr) => {
          const key = curr.availabilityId;
          if (acc[key]) {
            acc[key].minPrice = Math.min(curr.price, acc[key].minPrice);
            acc[key].maxPrice = Math.max(curr.price, acc[key].maxPrice);

            const products = curr.productIds.map((id) => this.offer.products.find((y) => y.id === id));
            acc[key].products.push(...products);
          } else {
            acc[key] = {
              minPrice: curr.price,
              maxPrice: curr.price,
              currency: curr.currency,
              id: curr.productSetId,
              availabilityId: curr.availabilityId,
              typeDisabled: curr.typeDisabled,
              showProducts: this.offerDays.length === 1,
              products: curr.productIds.map((id) => this.offer.products.find((y) => y.id === id)),
              productSet: this.offer.productSets.find((x) => x.id === curr.productSetId),
            };
          }

          return acc;
        }, {});

      return Object.values(sets)
        .sort((a, b) => (a.productSet?.name || '').localeCompare(b.productSet?.name || ''));
    },
    offerDays() {
      return this.highlighted
        .map((day) => {
          const dayOffer = this.offer.calendar.find((y) => y.day === day);
          const basket = (this.dayStatus[day]?.basket || []);
          const ordered = (this.dayStatus[day]?.ordered || []);

          // Remove sets which are already ordered
          const orderedTypes = ordered
            .filter((x) => !basket.some((y) => y.isReturned && y.availabilityId === x.availabilityId))
            .map((y) => y.productSetTypeId);

          const addedTypes = basket
            .filter((x) => !x.isReturned)
            .map((x) => x.productSetTypeId);

          return {
            ...dayOffer,
            productSets: (dayOffer?.productSets || [])
              .filter((y) =>
                !orderedTypes.includes(y.productSetTypeId)
                || basket.some((z) => z.availabilityId === y.availabilityId))
              .map((x) => ({
                ...x,
                typeDisabled: addedTypes.includes(x.productSetTypeId),
              })),
          };
        });
    },
    highlightedAvailabilities() {
      return this.highlighted.reduce((acc, day) => {
        const dayProducts = this.dayStatus[day]?.basket;
        if (!dayProducts) return acc;

        dayProducts
          .filter((x) => x.price >= 0)
          .forEach((p) => {
            if (acc[p.availabilityId]) {
              acc[p.availabilityId] += 1;
            } else {
              acc[p.availabilityId] = 1;
            }
          });

        return acc;
      }, {});
    },
    availableDays() {
      return this.offer.calendar.filter((x) => x.productSets.length > 0).map((x) => x.day)
        .concat(this.ordered.orders.map((x) => x.day));
    },
    highlightedDaysWithNoOrders() {
      return this.highlighted
        .filter((x) => {
          const orderedNotRemoved = this.dayStatus[x]?.ordered;
          if (!orderedNotRemoved) return true;

          return orderedNotRemoved.filter((y) => !this.itemInBasket({ ...y, isReturned: true })).length === 0;
        });
    },
    highlightedOrderedSets() {
      return Object.values(this.highlighted.reduce((acc, day) => {
        const dayProducts = this.dayStatus[day]?.ordered;
        if (!dayProducts) return acc;

        dayProducts.forEach((p) => {
          const isReturned = this.dayStatus[day].basket
            .some((y) => y.value < 0 && y.availabilityId === p.availabilityId && y.isPaid === p.isPaid);

          const key = `${p.availabilityId}_${p.isPaid}_${isReturned}`;

          const products = p.productIds.map((id) => this.ordered.products.find((z) => z.id === id));

          if (acc[key]) {
            const { days } = acc[key];
            acc[key].count += p.count;
            acc[key].showProducts = acc[key].showProducts && (days[days.length - 1] === day);
            acc[key].days.push({ day, count: p.count });
          } else {
            acc[key] = {
              ...p,
              isReturned,
              products,
              orders: [p],
              productSet: this.ordered.productSets.find((x) => x.id === p.productSetId),
              showProducts: true,
              days: [{ day, count: p.count }],
              count: p.count,
            };
          }
        });

        return acc;
      }, {}))
        .sort((a, b) => {
          if (a.isPaid === b.isPaid) {
            return (a.productSet?.name || '').localeCompare(b.productSet?.name || '');
          }
          return a.isPaid - b.isPaid;
        });
    },
    dayStatus() {
      const days = Object.keys(this.basket)
        .filter((k) => k.startsWith('productset'))
        .map((k) => this.basket[k])
        .filter((x) => x.payerId === this.payerId)
        .flatMap((x) => x.days.map((d) => ({ ...x, ...d })))
        .reduce((acc, curr) => {
          if (!acc[curr.day]) {
            acc[curr.day] = {
              new: 0,
              cancel: 0,
              paid: 0,
              waiting: 0,
              basket: [],
              ordered: [],
            };
          }

          if (curr.price >= 0) {
            acc[curr.day].new += curr.count;
          } else {
            acc[curr.day].cancel += curr.count;
          }

          acc[curr.day].basket.push({
            ...curr,
            productSetTypeId: this.offer.productSets.find((x) => x.id === curr.productSetId)?.typeId,
          });

          return acc;
        }, {});

      this.ordered.orders.forEach((o) => {
        if (!days[o.day]) {
          days[o.day] = {
            new: 0,
            paid: 0,
            waiting: 0,
            basket: [],
            ordered: [],
          };
        }

        days[o.day].ordered.push(o);

        if (o.isPaid) {
          days[o.day].paid += o.count;
        } else {
          days[o.day].waiting += o.count;
        }
      });

      return days;
    },
  },
  watch: {
    dates() {
      this.request();
    },
    basketGroups(groups) {
      if (groups.length === 0) {
        this.basketModal = false;
      }
    },
  },
  methods: {
    ...mapActions([
      'getUserShop',
      'getOrdered',
    ]),
    getPayee(payeeId) {
      return this.userPayers.find((x) => x.payeeId === payeeId);
    },
    request() {
      if (!this.dates) return;
      this.pending = true;

      const p1 = this.getUserShop({
        params: {
          query: {
            payerId: this.payerId,
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data }) => {
          this.offer = data[0].offer;
        });

      const p2 = this.getOrdered({
        params: {
          query: {
            payerId: this.payerId,
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data }) => {
          this.ordered = data;
        });

      Promise.all([p1, p2])
        .finally(() => {
          this.pending = false;
        });
    },
    cancelSet({
      availabilityId, isPaid, isReturned,
    }) {
      if (isReturned) {
        this.highlightedOrderedSets
          .filter((x) => x.availabilityId === availabilityId && x.isPaid === isPaid)
          .forEach((x) => {
            x.days.forEach((y) => {
              this.$store.commit('removeItemBasket', {
                payerId: this.payerId,
                availabilityId: x.availabilityId,
                timeFrame: x.scopeFrom,
                isPaid: x.isPaid,
                isReturned: true,
                day: y.day,
                count: y.count,
              });
            });
          });
      } else {
        this.highlightedOrderedSets
          .filter((x) => x.availabilityId === availabilityId && x.isPaid === isPaid)
          .forEach((x) => {
            x.days.forEach((y) => {
              this.$store.commit('addItemBasket', {
                payerId: this.payerId,
                productSetName: x.productSet.name,
                categoryId: x.productSet.categoryId,
                productSetId: x.productSetId,
                currency: x.currency,
                payeeId: this.payeeId,
                availabilityId: x.availabilityId,
                products: x.products,
                isPaid: x.isPaid,
                isReturned: true,
                price: -(x.unitPrice * y.count),
                timeFrame: x.scopeFrom,
                timeFrameName: this.billingScopeName({
                  type: x.scopeType,
                  from: x.scopeFrom,
                  to: x.scopeTo,
                }),
                count: y.count,
                day: y.day,
              });
            });
          });

        this.basketModal = true;
      }
    },
    toggle({ availabilityId }) {
      if (this.highlightedAvailabilities[availabilityId]) {
        Object.keys(this.basket)
          .filter((k) => k.startsWith('productset'))
          .map((k) => this.basket[k])
          .filter((x) => x.payerId === this.payerId && x.availabilityId === availabilityId)
          .flatMap((x) => x.days.map((d) => ({ ...x, ...d })))
          .filter((x) => this.highlighted.includes(x.day))
          .forEach((item) => {
            this.$store.commit('removeItemBasket', {
              payerId: this.payerId,
              availabilityId: item.availabilityId,
              timeFrame: item.timeFrame,
              isPaid: false,
              isReturned: false,
              day: item.day,
            });
          });
      } else {
        this.offerDays
          .map((x) => ({
            ...x,
            productSets: undefined,
            productSet: x.productSets.find((s) => s.availabilityId === availabilityId),
          }))
          .filter((x) => x.productSet)
          .forEach((item) => {
            if (this.blockMultipleOrders) {
              Object.keys(this.basket)
                .filter((k) => k.startsWith('productset'))
                .map((k) => this.basket[k])
                .filter((x) => x.payerId === this.payerId && x.day === item.day)
                .forEach((sameDayItem) => {
                  this.$store.commit('removeItemBasket', {
                    payerId: this.payerId,
                    availabilityId: sameDayItem.availabilityId,
                    isPaid: false,
                    timeFrame: sameDayItem.scopeFrom,
                    isReturned: false,
                    day: sameDayItem.day,
                  });
                });
            }

            // This day already has an order
            if (this.blockMultipleOrders && !this.highlightedDaysWithNoOrders.includes(item.day)) {
              return;
            }

            const set = this.offer.productSets.find((x) => x.id === item.productSet.productSetId);

            this.$store.commit('addItemBasket', {
              payerId: this.payerId,
              productSetName: set.name,
              categoryId: set.categoryId,
              isReturned: false,
              timeFrame: item.scopeFrom,
              timeFrameName: this.billingScopeName({
                type: item.scopeType,
                from: item.scopeFrom,
                to: item.scopeTo,
              }),
              productSetId: item.productSet.productSetId,
              currency: item.productSet.currency,
              products: item.productSet.productIds.map((id) => this.offer.products.find((y) => y.id === id)),
              payeeId: this.payeeId,
              isPaid: false,
              availabilityId: item.productSet.availabilityId,
              price: item.productSet.price,
              day: item.day,
              count: 1,
            });
          });
      }
    },
  },
  created() {
    this.refreshOrders = () => this.request();
    this.$root.$on('order-created', this.refreshOrders);
  },
  destroyed() {
    this.$root.$off('order-created', this.refreshOrders);
  },
};
</script>

<style lang="scss" scoped>
  .overlay {
    background-color: rgba(#eee, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;

    > div {
      margin-top: 50px;
    }
  }

  .close {
    font-size: 30px;
    padding-right: 5px;
  }
</style>
