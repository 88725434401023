<template>
  <div>
    <div class="d-flex align-items-center py-3 px-2">
      <div class="bill-icon flex-shrink-0">
        <i class="far fa-memo-circle-info" />
      </div>
      <div class="title">
        {{ plan.title }}

        <span
          v-if="plan.code"
          v-tippy
          :content="$t('bill.code')"
          class="code pl-1"
        >
          <i class="fas fa-hashtag" />{{ plan.code }}
        </span>

        <div
          v-if="showCategories"
          class="category"
        >
          <CategoryInfo :category-id="plan.categoryId" />
        </div>
      </div>
    </div>
    <hr class="mt-0">
    <BillControls
      :id="plan.id"
      :action.sync="action"
      :is-plan="true"
      @close="selected = null"
      @removed="planRemoved"
      @refresh="$emit('refresh')"
    />
    <hr class="mt-2">
    <div v-if="!action">
      <div style="width: 30vw; height: 200px">
        <BillingPlanDetails
          :plan="plan"
          :color="color"
        />
      </div>
      <BillPayersList
        class="pb-3"
        :is-plan="true"
        :bill-id="plan.id"
        :color="color"
        :currency="plan.currency"
        @focus="$emit('focus', $event)"
      />
    </div>
    <div v-else-if="action ==='details'">
      <BillingPlanDetailsForm
        :color="color"
        :plan="plan"
        @close="action = ''"
        @update="action = ''; $emit('refresh')"
      />
    </div>
    <div v-else>
      <BillingPlanPayersEditList
        :key="action"
        :is-plan="true"
        :plan="plan"
        :edit="action !== 'add'"
        @cancel="action = ''"
        @update="action = ''; $emit('refresh')"
      />
    </div>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import colors from '@/utils/colors';
import { mapGetters } from 'vuex';
import BillControls from './BillControls';
import BillingPlanDetails from './BillingPlanDetails';
import BillingPlanDetailsForm from './BillingPlanDetailsForm';
import BillingPlanPayersEditList from './BillingPlanPayersEditList';
import BillPayersList from './BillPayersList';

export default {
  props: {
    plan: Object,
    focus: Boolean,
  },
  data: () => ({
    pending: false,
    payers: [],
    action: '',
  }),
  components: {
    BillControls,
    BillingPlanDetails,
    BillingPlanDetailsForm,
    BillPayersList,
    BillingPlanPayersEditList,
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['payee', 'showCategories']),
    color() {
      if (!this.plan) return '';
      const category = this.payee?.categories.find((x) => x.id === this.plan.categoryId);
      return colors(category.colorCode);
    },
  },
  watch: {
    action(v) {
      if (v) {
        this.$emit('focus', true);
      } else {
        this.$emit('focus', false);
      }
    },
  },
  methods: {
    planRemoved() {
      this.$emit('focus', false);
      this.$emit('close');
      this.$emit('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;

    .code {
      font-size: 12px;
      color: #aaa;
    }
  }

  .category {
    font-size: 12px;
    margin-top: 5px;
    color: #888;
  }

  .empty-details {
    height: 150px;
    background-color: #f8f9fa;
  }
</style>
