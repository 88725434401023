<template>
  <div>
    <div class="d-flex align-items-centerpy-2">
      <div class="flex-grow-1">
        <SearchBox
          v-model="payerSearch"
          style="margin-top: 21px"
        />
      </div>
      <div class="input-accounts mr-4 mb-2">
        <div>
          <div class="d-flex align-items-center mb-1">
            <label class="mb-0">
              {{ $t('payment.accountNumber') }}
            </label>
            <TooltipHelp
              v-if="selectedAccount && !selectedAccount.isVerified"
              class="pl-2"
              error
            >
              <template #trigger>
                <div class="circle-icon">
                  <i class="fas fa-seal-exclamation text-danger" />
                </div>
              </template>

              {{ $t('settings.bankAccounts.accountNotValidatedInfo') }}
            </TooltipHelp>
            <TooltipHelp
              v-if="payersWithInvalidBankAccounts.length > 0"
              class="pl-2"
              error
            >
              <template #trigger>
                <div class="circle-icon">
                  <i class="fas fa-person-circle-exclamation text-danger" />
                </div>
              </template>

              {{ $t('settings.bankAccounts.somePayersNotConfigured') }}
            </TooltipHelp>
          </div>

          <BankAccountSelect
            :bank-account-id.sync="bankAccountId"
            :validation="payersWithInvalidBankAccounts.length === 0 ? null : false"
            :find-default-bank-account="findDefaultBankAccount"
            @select="selectedAccount = $event"
          />
        </div>
      </div>
      <div class="position-relative mb-2">
        <label class="mb-1">
          {{ $t('payment.toPay') }}
        </label>
        <MoneyInput
          v-model="masterAmount"
          :select-currency="!allowRemove"
          :currency.sync="billCurrency"
          style="width: 300px"
          class="money-input"
        />

        <div
          v-if="anyDifferent"
          class="small text-primary text-right force-amount"
          @click="setAsMaster(true)"
        >
          {{ $t('form.overwriteAmount', [formatCurrency(masterAmount, billCurrency)]) }}
        </div>
      </div>
    </div>

    <hr class="mb-0">
    <div
      v-for="({ payer, highlighted }, index) in payersWithHighlights"
      :key="payer.id"
      class="client-row w-100 d-flex align-items-center justify-content-between"
      :class="{
        removed: removed.includes(payer.id),
        highlighted: highlighted
      }"
    >
      <div
        class="pl-2 flex-grow-1"
        style="max-width: 300px"
      >
        <span data-test="client-last-name">{{ payer.name }} </span>
      </div>
      <div class="d-flex align-items-center">
        <div
          v-if="payersWithInvalidBankAccounts.includes(payer.id)"
          class="text-danger warning py-0 mr-2"
        >
          <i
            v-tippy
            :content="$t('payment.warningNoBankAccount', [selectedAccount ? selectedAccount.text : '?'])"
            class="fas fa-exclamation-triangle"
          />
        </div>
        <div
          v-if="payer.paid && !addMode"
          class="flex-shrink-1 text-nowrap pr-2"
        >
          {{ $t('payment.paid') }}:
          <span
            class="money-font pl-1"
            style="font-size: 0.95em"
          >
            {{ formatCurrency(payer.paid, billCurrency) }}
          </span>
          |
        </div>

        <div
          style="padding-right: 11px"
          class="d-flex align-items-center"
        >
          <BFormInput
            :ref="`input_0_${index}`"
            v-model="payer.count"
            size="sm"
            style="width: 100px"
            :highlighted="payer.count > 1"
            :label="' '"
            @changeFocus="changeFocus(0, index, $event)"
            @input="emitPayers"
          />
          <div class="px-1">
            <i class="fas fa-times" />
          </div>
          <MoneyInput
            :ref="`input_1_${index}`"
            v-model="payer.amount"
            :currency="billCurrency"
            style="width: 200px"
            :highlighted="payer.amount !== masterAmount"
            size="sm"
            @changeFocus="changeFocus(1, index, $event)"
            @input="emitPayers"
          />
        </div>

        <div v-if="addMode">
          <button
            class="mx-2 btn btn-sm btn-success icon-btn no-scale"
            style="line-height: 26px"
            @mouseover="highlight = index"
            @mouseleave="highlight = null"
            @click="$emit('add', payer.id)"
          >
            <i class="fas fa-plus" />
          </button>
        </div>
        <div v-else-if="allowRemove && !addMode">
          <div
            class="px-2 remove-icon"
            @click="toggleRemove(payer)"
          >
            <i
              v-if="!removed.includes(payer.id)"
              class="fas fa-trash"
            />
            <i
              v-else
              class="fas fa-arrow-rotate-left"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyInput from '@/components/utils/MoneyInput';
import { money } from '@/utils/filters';
import createSearch from '@/utils/search';
import { mapActions, mapGetters } from 'vuex';
import BankAccountSelect from './BankAccountSelect';

export default {
  props: {
    payers: {
      type: Array,
      default: () => [],
    },
    addedClientIds: {
      type: Array,
      default: () => [],
    },
    removed: {
      type: Array,
      default: () => [],
    },
    selectedPayerIds: {
      type: Array,
      default: () => [],
    },
    billId: String,
    allowRemove: Boolean,
    allowAdd: Boolean,
    initMasterAmount: Number,
    initCurrency: String,
    initBankAccountId: String,
    findDefaultBankAccount: Boolean,
    initCounts: Object,
    sortKey: {
      type: String,
      default: 'name',
    },
  },
  data: () => ({
    addMode: false,
    highlight: null,
    prevMasterAmount: undefined,
    masterAmount: null,
    payersAmounts: [],
    payersWithInvalidBankAccounts: [],
    billCurrency: '',
    bankAccountId: '',
    selectedAccount: null,
    payerSearch: '',
  }),
  components: {
    BankAccountSelect,
    MoneyInput,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
    ]),
    payersWithHighlights() {
      const search = createSearch(this.payerSearch);

      return this.payersAmounts
        .filter((x) => search(x.name) || search(x.code))
        .map((x) => ({
          payer: x,
          highlighted: this.selectedPayerIds.includes(x.id),
        }))
        .sort((a, b) => b.highlighted - a.highlighted);
    },
    anyDifferent() {
      return this.payersAmounts.some((y) => y.amount !== this.masterAmount);
    },
  },
  watch: {
    masterAmount(am) {
      this.setAsMaster(false);
      this.$emit('masterAmount', am);
    },
    billCurrency(am) {
      this.$emit('currency', am);
    },
    bankAccountId(am) {
      if (am === '') {
        this.$emit('bankAccountId', null);
      } else {
        this.$emit('bankAccountId', am);
      }
      this.checkBankAccounts();
    },
  },
  methods: {
    ...mapActions(['validateBillPayersBankAccounts']),
    money,
    checkBankAccounts() {
      if (!this.bankAccountId || this.bankAccountId === '' || !this.bankAccountId.includes('_')) {
        this.payersWithInvalidBankAccounts = [];
      }

      this.validateBillPayersBankAccounts({
        params: {
          billId: this.billId,
        },
        data: {
          accountNumber: this.bankAccountId,
          removePayers: this.removed,
          updatePayers: this.payersAmounts.map((x) => ({
            amount: Math.round(x.amount * x.count),
            count: x.count,
            payerId: x.id,
          })),
        },
      })
        .then(({ data }) => {
          this.payersWithInvalidBankAccounts = data.invalidPayerIds;
        });
    },
    toggleRemove({ id }) {
      if (this.removed.includes(id)) {
        this.$emit('update:removed', this.removed.filter((x) => x !== id));
      } else {
        this.$emit('update:removed', this.removed.concat(id));
      }
      this.checkBankAccounts();
    },
    setAsMaster(force) {
      this.payersAmounts.forEach((x) => {
        if (force || x.amount === this.prevMasterAmount) {
          this.$set(x, 'amount', this.masterAmount);
        }
      });
      this.prevMasterAmount = this.masterAmount;
      this.emitPayers();
    },
    emitPayers() {
      this.$emit('payers', this.payersAmounts);
    },
    changeFocus(column, index, value) {
      let newColumn = column;
      let newIndex = index;
      const indexNum = this.payersAmounts.length;
      const columnNum = 2;

      switch (value) {
        case 39: // right
          newColumn = (newColumn + 1) % 2;
          break;
        case 40: // up
          newIndex = (indexNum + newIndex - 1) % indexNum;
          break;
        case 38: // down
          newIndex = (newIndex + 1) % indexNum;
          break;
        case 37: // left
          newColumn = (columnNum + newColumn - 1) % 2;
          break;
        default:
      }

      this.$refs[`input_${newColumn}_${newIndex}`][0].$refs.money.focus();
      this.$refs[`input_${newColumn}_${newIndex}`][0].$refs.money.select();
    },
  },
  created() {
    this.billCurrency = this.initCurrency;

    this.payersAmounts = this.payers.map((x) => ({
      ...x,
      amount: x.amount,
      count: x.count || 1,
    }));

    this.masterAmount = this.initMasterAmount;
    this.bankAccountId = this.initBankAccountId || '';
  },
};
</script>

<style lang="scss" scoped>

  .input-accounts {
    min-width: 300px;
  }

  .circle-icon {
    border-radius: 50%;
    border: 1px solid $red;
    width: 22px;
    height: 22px;
    text-align: center;
    font-size: 14px;
  }

  .client-row {
    font-size: 16px;
    border-bottom: 1px solid #dddddd;
    padding: 2px 0;

    &.removed {
      text-decoration: line-through;
      opacity: 0.5;
      .warning {
        display: none;
      }
    }

    &.highlighted {
      background-color: rgba($blue, 0.1);
    }

    &.green-client {
      background-color: rgba($green, 0.1);
    }
  }

  .remove-icon, .add-icon {
    color: #888;
    cursor: pointer;
  }

  .remove-icon:hover {
    color: $red;
  }

  .warning {
    background-color: rgba($red, 0.2);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .add-icon {
    border: 1px solid #888;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
  }

  .add-icon:hover {
    color: $green;
    border-radius: 50%;
    border-color: $green;
  }

  .force-amount {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    right: 0;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
