<template>
  <Portal to="in-app">
    <div class="p-5">
      <div class="d-flex justify-content-between">
        <div
          style="font-size: 30pt"
          class="font-weight-bold"
        >
          <div style="line-height: 35pt">
            {{ $t('payment.confirmation') }}
          </div>
          <div
            style="font-size: 20px"
            class="text-primary"
          >
            Pagaspot
          </div>
        </div>
      <!-- TODO
        <div>
          <div class="text-secondary">
            {{ $t('payment.number') }}:
          </div>
          <div
            style="font-size: 20pt"
            class="font-weight-bold"
          >
            {{ payment.number }} / {{ year }}
          </div>
        </div> -->
      </div>

      <table class="table mt-5">
        <thead />
        <tbody>
          <tr style="font-size: 16pt">
            <td style="width: 30%">
              {{ $t('payment.amount') }}:
            </td>
            <td>
              <span class="font-weight-bold">{{ formatCurrency(payment.value, payment.currency) }}</span>
            </td>
          </tr>
          <tr style="font-size: 16pt">
            <td style="width: 30%">
              {{ $t('payment.paymentMethod') }}:
            </td>
            <td>
              <span class="font-weight-bold">{{ paymentMethod }}</span>
            </td>
          </tr>
          <tr style="font-size: 16pt">
            <td style="width: 30%">
              {{ $t('payment.date') }}:
            </td>
            <td>
              <span class="font-weight-bold">{{ formatDate(payment.timestamp) }}</span>
            </td>
          </tr>
          <tr style="font-size: 16pt">
            <td style="width: 30%">
              {{ $t('payment.subject') }}:
            </td>
            <td>
              <span class="font-weight-bold">{{ payee.name }}</span>
            </td>
          </tr>
          <tr style="font-size: 16pt">
            <td>
              {{ $t('payment.settledFor') }}:
            </td>
            <td>
              <div
                v-for="(p, i) in payersWithSettlements"
                :key="p.id"
              >
                <hr
                  v-if="i !== 0"
                  class="mb-2"
                >
                <div class="font-weight-bold pt-3 pb-1">
                  {{ p.name }}
                </div>
                <table class="table-borderless w-100">
                  <tr
                    v-for="t in p.bills"
                    :key="t.billId"
                    class="entry-row"
                  >
                    <td class="w-50">
                      {{ billTitle(t.billId) }}:
                    </td>
                    <td class="font-weight-bold text-right text-nowrap w-50">
                      {{ formatCurrency(t.value, payment.currency) }}
                    </td>
                  </tr>
                  <tr
                    v-for="acc in p.overpayments"
                    :key="`${acc.categoryId}_${acc.payerId}`"
                    class="entry-row"
                  >
                    <td class="w-50">
                      {{ $t('client.excessFor') }}
                      "{{ getChannelName(acc.categoryId) }}" :
                    </td>
                    <td class="font-weight-bold text-nowrap text-right w-50">
                      {{ formatCurrency(Math.round(acc.value * 100) / 100, payment.currency) }}
                    </td>
                  </tr>
                  <tr
                    v-for="r in p.refunds"
                    :key="r.payerId"
                    class="entry-row"
                  >
                    <td class="w-50">
                      {{ $t('payment.creditReturn') }}
                    </td>
                    <td class="font-weight-bold text-nowrap text-right w-50">
                      {{ formatCurrency(Math.round(r.value * 100) / 100, payment.currency) }}
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
        </tbody>
      </table>

      <div class="mt-5">
        <div class="d-flex justify-content-end">
          <div
            style="width: 300pt; border-top: 1px solid #999"
            class="pt-3 text-center"
          >
            ({{ $t('general.signature') }})
          </div>
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import { money } from '@/utils/filters';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    payment: Object,
    bills: Array,
    payers: Array,
  },
  computed: {
    ...mapGetters([
      'payee',
      'paymentTypes',
      'formatCurrency',
    ]),
    year() {
      return moment.unix(this.payment.timestamp).format('YYYY');
    },
    payersWithSettlements() {
      if (!this.payment) return [];

      return this.payers.map((p) => ({
        ...p,
        bills: (this.payment.bills || []).filter((x) => x.payerId === p.id),
        overpayments: (this.payment.overpayments || []).filter((x) => x.payerId === p.id),
        refunds: (this.payment.refunds || []).filter((x) => x.payerId === p.id),
      }));
    },
    paymentMethod() {
      const type = this.paymentTypes.find((x) => x.key === this.payment.type);
      return type.name;
    },
    amount() {
      return money(this.payment.value);
    },
    clientName() {
      const payer = this.payers.find((x) => x.id === this.payment.clientId);
      return payer.name;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    billTitle(bid) {
      return this.bills.find((x) => x.billId === bid)?.title;
    },
    getChannelName(channelId) {
      const channel = this.payee.categories.find((x) => x.id === channelId);
      return channel ? channel.name : '';
    },
  },
};
</script>
