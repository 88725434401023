<template>
  <div>
    <div class="settings-box">
      <div class="section-title d-flex align-items-center">
        <i class="far fa-user-lock pr-2" />
        {{ $t('settings.access') }}
      </div>
      <RoleList />
    </div>
  </div>
</template>

<script>
import RoleList from './RoleList';

export default {
  components: {
    RoleList,
  },
  created() {
    this.$emit('page', 'access');
  },
};
</script>
