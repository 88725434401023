<template>
  <div class="badge-wrapper">
    <div
      v-if="orders.length === 0"
      class="text-secondary"
    >
      <i class="fa-solid fa-ban" />
      <div class="info">
        Brak
      </div>
    </div>
    <div
      v-else-if="delivered"
      class="text-danger"
    >
      <i class="fa-solid fa-hand-holding-box" />
      <div class="info">
        Wydano
      </div>
    </div>
    <div
      v-else
      class="text-success"
    >
      <i class="fa-solid fa-box" />
      <div class="info">
        Zamówiono
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orders: Array,
  },
  computed: {
    delivered() {
      return this.orders.some((o) => o.delivered > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  font-size: 1.2rem;;
}

.badge-wrapper {
  width: 70px;
  text-align: center;
  position: relative;
  top: 5px;
}

.info {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
}
</style>
