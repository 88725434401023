<template>
  <div
    style="max-width: 500px"
    class="px-2"
  >
    <div class="mt-3">
      <label>{{ $t('payment.payment') }}</label>
      <MoneyInput
        :value="paid"
        :select-currency="true"
        :currency="currency"
        class="money-input"
        @input="$emit('update:paid', $event)"
        @update:currency="$emit('update:currency', $event)"
      />
    </div>
    <div class="mt-3">
      <label>{{ $t('payment.date') }}</label>
      <DateTimePicker
        :value="date"
        formatted="DD MMMM YYYY"
        only-date
        :format="dateFormat"
        :locale="locale"
        input-size="sm"
        no-clear-button
        auto-close
        class="date-time-picker"
        no-label
        @input="$emit('update:date', $event); checkBillingScope($event)"
      />
      <div class="mt-2 small">
        {{ $t('settings.billingScope') }}:
        <span
          v-if="billingScope"
          class="pl-1"
          :class="billingScope.isOpen ? 'text-success' : 'text-danger'"
        >
          <i
            v-if="billingScope.isOpen"
            class="fas fa-lock-open"
          />
          <i
            v-else
            class="fas fa-lock"
          />
          {{ billingScopeName(billingScope) }}
        </span>
      </div>
    </div>
    <div class="mt-3">
      <label>{{ $t('payment.paymentMethod') }}</label>
      <BoxSelect
        :selected="method"
        :fields="types"
        @update:selected="$emit('update:method', $event)"
      />
    </div>

    <div class="mt-3">
      <label>{{ $t('payment.description') }}</label>
      <BFormTextarea
        :value="description"
        :placeholder="`${$t('payment.for')}...`"
        @input="$emit('update:description', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BoxSelect from '@/components/BoxSelect';
import MoneyInput from '@/components/utils/MoneyInput';
import DateTimePicker from 'vue-ctk-date-time-picker';

export default {
  props: {
    paid: Number,
    method: String,
    description: String,
    currency: String,
    date: String,
  },
  data: () => ({
    billingScope: null,
    dateFormat: 'YYYY-MM-DD',
  }),
  components: {
    BoxSelect,
    DateTimePicker,
    MoneyInput,
  },
  computed: {
    ...mapGetters([
      'paymentTypes',
      'billingScopeName',
      'locale',
    ]),
    types() {
      return this.paymentTypes
        .filter((x) => !x.system)
        .map((x) => ({
          ...x,
          text: x.name,
        }));
    },
  },
  methods: {
    ...mapActions(['getBillingScope']),
    checkBillingScope(date) {
      if (!date) return;
      this.$emit('billingScopeOpen', false);
      this.getBillingScope({
        params: {
          date,
        },
      })
        .then(({ data }) => {
          this.billingScope = data;
          this.$emit('billingScopeOpen', data.isOpen);
        });
    },
  },
  created() {
    if (this.date) {
      this.checkBillingScope(this.date);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
