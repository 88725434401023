<template>
  <div>
    <div class="mt-1">
      <EditableList
        ref="list"
        :list="currencies"
        :pending="pending"
        :remove-warning="$t('settings.currencies.alertDeleteCurr')"
        :form-add-title="$t('settings.addField')"
        :form-edit-title="$t('settings.editField')"
        @submit="$refs.form.submit()"
        @remove="$refs.form.remove()"
      >
        <template #form="{ edited }">
          <div v-if="edited === -1">
            <AddSupportedCurrencyForm
              ref="form"
              :pending.sync="pending"
              :selected.sync="$refs.list.selected"
            />
          </div>
          <div v-else>
            <CurrencyPropertyForm
              ref="form"
              :err.sync="err"
              :pending.sync="pending"
              :currency-key="currencies.find(c => c.id === edited).name"
              :selected.sync="$refs.list.selected"
              @close="$refs.list.close()"
            />
          </div>
        </template>
      </EditableList>
      <div
        class="small"
        style="color: #888"
      >
        <i class="fas fa-info-circle info-icon" />
        {{ $t('settings.currencies.currencyInfo') }}
      </div>
      <div
        v-if="err"
        class="err-box d-flex"
      >
        <div>
          <div
            class="small"
            style="color: #888"
          >
            <i class="fas fa-info-circle info-icon" />
            {{ $t('settings.currencies.currencyErrNoCurrency') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CurrencyPropertyForm from '@/components/settings/CurrencyPropertyForm';
import EditableList from '../../components/settings/EditableList';
import AddSupportedCurrencyForm from '../../components/settings/AddSupportedCurrencyForm';

export default {
  data: () => ({
    pending: false,
    err: false,
  }),
  components: {
    EditableList,
    CurrencyPropertyForm,
    AddSupportedCurrencyForm,
  },
  computed: {
    ...mapState({
      supportedCurrencies: (state) => state.currency.companyCurrencies,
    }),
    currencies() {
      return this.supportedCurrencies.map((x) => ({ id: x, name: x }));
    },
  },
  methods: {
  },
  created() {
    this.$emit('page', 'currencies');
  },
};

</script>
<style>

</style>
