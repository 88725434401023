import { render, staticRenderFns } from "./BillingPlanPayersEditList.vue?vue&type=template&id=742a545a&scoped=true&"
import script from "./BillingPlanPayersEditList.vue?vue&type=script&lang=js&"
export * from "./BillingPlanPayersEditList.vue?vue&type=script&lang=js&"
import style0 from "./BillingPlanPayersEditList.vue?vue&type=style&index=0&id=742a545a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742a545a",
  null
  
)

export default component.exports