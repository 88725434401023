<template>
  <div>
    <h4 class="client-page-title mt-3">
      {{ $t('menu.userSettings') }}
    </h4>

    <template v-if="refreshUserPayers">
      <Loader />
    </template>
    <template v-if="!refreshUserPayers && payersWithFields.length === 0">
      <i
        class="fa-solid fa-id-card text-secondary mb-4 ml-1 pt-3"
        style="font-size: 35px"
      />
      <div>
        <div
          class="font-weight-bold h5"
          v-html="$t('client.noConnectedClients')"
        />
        <div v-html="$t('client.noConnectedClientsInfo')" />
      </div>
    </template>
    <template v-if="!selected">
      <PayerSettingsRow
        v-for="payer in payersWithFields"
        :key="payer.payerId"
        :payer="payer"
        @refresh="load"
        @updated="update(payer.payerId, $event)"
      />
    </template>
    <template v-else>
      <div class="payer-section d-flex align-items-center">
        <div class="flex-grow-1">
          <div class="font-weight-bold">
            {{ selected.payerName }}
          </div>
          {{ selected.payeeName }}
          <div class="d-flex mt-1">
            <div
              v-if="selected.isInvite"
              class="check-icon check-icon-warning"
            >
              <i class="fa-solid fa-user-plus pr-1" />
              {{ $t('client.pendingInvitation') }}
            </div>
            <div
              v-else
              class="check-icon check-icon-success"
            >
              <i class="fa-solid fa-check pr-1" />
              {{ $t('client.connected') }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PayerSettingsRow from '../components/PayerSettingsRow';

export default {
  data: () => ({
    selected: null,
    pending: false,
    propertyPayers: [],
    values: {},
  }),
  components: {
    PayerSettingsRow,
  },
  computed: {
    ...mapGetters([
      'userPayers',
      'refreshUserPayers',
    ]),
    payersWithFields() {
      return this.userPayers
        .map((x) => {
          const payer = this.propertyPayers.find((p) => p.payerId === x.payerId);

          return {
            ...x,
            values: (payer?.values || []),
            properties: x.isInvite
              ? []
              : (payer?.properties || []),
          };
        });
    },
  },
  methods: {
    ...mapActions([
      'getUserPayers',
      'getUserProperties',
      'removeUserRole',
    ]),
    update(payerId, values) {
      const payer = this.propertyPayers.find((p) => p.payerId === payerId);
      if (payer) {
        payer.values = values;
      }
    },
    refreshUsers() {
      this.getUserPayers()
        .finally(() => {
          this.pending = false;
        });
    },
    load() {
      this.getUserProperties()
        .then(({ data }) => {
          this.propertyPayers = data;
        });
    },
  },
  created() {
    if (this.refreshUserPayers) {
      this.getUserPayers();
    }
    this.load();
  },
};
</script>

<style lang="scss" scoped>

.btn-outline-secondary {
  box-shadow: none;
}
</style>
