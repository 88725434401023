var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex",class:{ focused: _vm.focused }},[_c('div',{staticClass:"width-transition w-100 h-100",style:({
      'flex-shrink': 0,
      'flex-basis': _vm.listWidth,
      'max-width': _vm.edit ? '50%' : '100%',
      'opacity': _vm.focused ? 0 : 1,
      'margin-left': _vm.focused ? '-70%' : null,
    })},[_vm._t("default")],2),_c('div',{staticClass:"focus-margin"}),_c('div',{staticClass:"width-transition h-100",style:({
      'flex-basis': _vm.edit ? (_vm.focused ? '80%' : '50%') : 0,
      'width': _vm.edit ? (_vm.focused ? '80%' : '50%') : 0,
      'min-width': _vm.edit ? (_vm.focused ? '80%' : '50%') : 0,
    })},[_vm._t("edit")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }