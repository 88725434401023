<template>
  <div>
    <div
      v-if="pending"
      class="text-center"
    >
      <Loader />
    </div>
    <div v-else>
      <div v-if="error">
        <div class="text-center text-danger pt-5 pb-2">
          <i
            class="fas fa-times-circle"
            style="font-size: 50px"
          />
          <div
            class="mt-3"
            style="font-size: 20px"
          >
            Error
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="info.status.toUpperCase() === 'SUCCESS'">
          <div class="text-center text-success pt-5 pb-2">
            <i
              class="fas fa-check-circle"
              style="font-size: 50px"
            />
            <div
              class="mt-3"
              style="font-size: 20px"
            >
              {{ $t('client.paymentRegistered') }}
            </div>
          </div>
        </div>
        <div v-else>
          <Loader
            class="pt-5"
            size="50px"
          />
          <div class="text-secondary pt-3 text-center">
            {{ $t('client.paymentSettledInBackground') }}
          </div>
        </div>
      </div>
      <div class="text-center pt-2">
        <button
          class="btn btn-primary px-4"
          @click="$router.replace('/payer')"
        >
          <i class="fas fa-xmark pr-1" />
          {{ $t('paymentStatus.goBack') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    pending: false,
    info: null,
    error: null,
  }),
  methods: {
    ...mapActions(['getInternalPaymentInfo']),
    refresh(orderId) {
      if (!orderId) return;
      this.pending = true;

      this.getInternalPaymentInfo({
        params: { orderId },
      })
        .then(({ data }) => {
          this.pending = false;
          this.info = data;

          if (data.status === 'INIT' || data.status === 'PENDING') {
            this.timeout = setTimeout(() => this.refresh(orderId), 5000);
          }
        })
        .catch(({ response: { data } }) => {
          this.error = data;
          this.pending = false;
        });
    },
  },
  destroyed() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  created() {
    const orderId = this.$route.query.OrderID;
    const hash = this.$route.query.Hash;

    if (!orderId || !hash) {
      this.$router.replace('/payer');
    } else {
      this.refresh(orderId);
    }
  },
};
</script>

<style>

</style>
