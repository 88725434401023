<template>
  <div>
    <div style="margin: 5px">
      <label class="d-flex">
        {{ $t('settings.clientProp.propertyName') }}

      </label>
      <BFormInput
        v-model="name"
        :placeholder="$t('settings.clientProp.propertyName')"
        size="sm"
      />
    </div>

    <BoxSelect
      :selected.sync="type"
      :fields="payersPropertyTypes"
      class="mt-2"
    />

    <div
      v-if="type === bankAccountType"
      class="py-1"
    >
      <label style="font-size: 12px">
        {{ $t('settings.bankAccountNumber') }}
      </label>
      <template v-if="!property">
        <BFormSelect
          v-if="bankAccountNumbers.length > 0"
          v-model="bankAccountId"
          :options="bankAccountNumbers"
          :state="!!bankAccountId"
          size="sm"
        />
        <div
          v-else
          class="text-danger small mt-2 warning"
        >
          <i class="fas fa-exclamation-triangle pr-1" />
          {{ $t('settings.bankAccounts.noAccountWithPlaceholder') }}
        </div>
      </template>
      <div
        v-else
        class="small selected-bank-account"
      >
        {{ selectedBankAccount }}
      </div>
    </div>

    <AdvancedSection>
      <div>
        <label class="small">
          {{ $t('settings.clientProp.code') }}
        </label>
        <div class="money-font small">
          <BFormInput
            :value="property && property.name === name ? property.scriptKey : '...'"
            :placeholder="$t('settings.clientProp.propertyName')"
            size="sm"
            readonly
          />
        </div>
      </div>

      <div v-if="type === otherType">
        <label class="small mt-2">
          {{ $t('forms.forms.fieldType') }}
        </label>
        <BFormSelect
          v-model="formatType"
          :options="formatTypes"
          size="sm"
        />

        <div v-if="formatType === 'dropdown' || formatType === 'multiselect'">
          <label class="small mt-2">
            {{ $t('forms.forms.options') }}
          </label>
          <div>
            <textarea
              v-model="formatOptions"
              class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center mt-3">
        <BFormCheckbox
          switch
          class="white-switch white-switch-sm"
          :disabled="pending"
          :checked="allowEdit"
          @change="allowEdit = !allowEdit; updateRule()"
        />
        <div class="ml-n3 mr-2">
          <i class="fas fa-rectangle-code text-secondary" />
        </div>
        <span
          class="small"
          style="line-height: 1.1;"
        >
          {{ $t('settings.clientProp.allowEdit') }}
        </span>
      </div>
      <div v-if="allowEdit">
        <BFormTextarea
          v-model="payerEditAccessRule"
          size="sm"
          :state="!payerEditAccessRule.trim() ? false : null"
          class="money-font mt-2 rule-edit"
        />
        <TestRuleExecutor
          :rule="payerEditAccessRule"
          :previous-rule="property ? property.payerEditAccessRule : ''"
        />
      </div>
    </AdvancedSection>
  </div>
</template>

<script>
import BoxSelect from '@/components/BoxSelect';
import AdvancedSection from '@/components/settings/AdvancedSection';
import TestRuleExecutor from '@/components/settings/TestRuleExecutor';
import { mapActions, mapGetters } from 'vuex';

const bankAccountType = 'BankAccount';
const otherType = 'Other';

export default {
  props: {
    property: Object,
    pending: Boolean,
  },
  data: () => ({
    name: '',
    code: '',
    type: 'other',
    bankAccountId: null,
    bankAccountType,
    otherType,
    formatType: 'text',
    formatOptions: '',
    allowEdit: false,
    payerEditAccessRule: '',
  }),
  components: {
    AdvancedSection,
    TestRuleExecutor,
    BoxSelect,
  },
  computed: {
    ...mapGetters(['payersPropertyTypes', 'payee']),
    selectedBankAccount() {
      return this.bankAccountNumbers
        .find((x) => x.value === this.bankAccountId)?.text;
    },
    bankAccountNumbers() {
      return this.payee.bankAccounts
        .filter((x) => x.accountNumber.includes('_'))
        .map((x) => ({
          value: x.accountNumber,
          text: `${x.name}: ${x.accountNumber}`,
          verified: x.isVerified,
        }));
    },
    formatTypes() {
      return [
        {
          value: 'text',
          text: this.$t('forms.forms.textinput'),
        },
        {
          value: 'checkbox',
          text: this.$t('forms.forms.checkbox'),
        },
        {
          value: 'dropdown',
          text: this.$t('forms.forms.dropdown'),
        },
        {
          value: 'multiselect',
          text: this.$t('forms.forms.multiselect'),
        },
      ];
    },
    format() {
      if (this.type === bankAccountType) {
        return this.bankAccountId;
      }

      if (this.type === otherType) {
        if (this.formatType === 'checkbox') {
          return 'checkbox';
        }
        if (this.formatType === 'dropdown' || this.formatType === 'multiselect') {
          return `${this.formatType}:${this.formatOptions.split('\n').map((x) => x.trim()).join('|')}`;
        }
      }

      return null;
    },
  },
  watch: {
    type(v) {
      if (v === bankAccountType) {
        if (this.bankAccountNumbers.length > 0 && !this.bankAccountId) {
          this.bankAccountId = this.bankAccountNumbers[0].value;
        }
      }

      this.validate();
    },
    name() {
      this.validate();
    },
  },
  methods: {
    ...mapActions([
      'addPayersProperty',
      'updatePayersProperty',
      'removePayersProperty',
    ]),
    updateRule() {
      if (this.allowEdit) {
        this.payerEditAccessRule = 'true';
      } else {
        this.payerEditAccessRule = '';
      }
    },
    validate() {
      if (this.type === bankAccountType && !this.bankAccountId) {
        this.$emit('valid', false);
        return;
      }

      if ((this.name || '').length === 0) {
        this.$emit('valid', false);
        return;
      }

      this.$emit('valid', true);
    },
    submit() {
      if (this.property) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      if (this.type === bankAccountType && !this.bankAccountId) {
        return;
      }

      if (this.allowEdit && !(this.payerEditAccessRule.trim())) {
        return;
      }

      this.$emit('update:pending', true);
      this.updatePayersProperty({
        data: {
          code: this.property.code,
          name: this.name,
          type: this.type,
          format: this.format,
          payerEditAccessRule: this.payerEditAccessRule,
        },
      })
        .then(() => {
          this.name = '';
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    add() {
      if (this.allowEdit && !(this.payerEditAccessRule.trim())) {
        return;
      }

      this.$emit('update:pending', true);
      this.addPayersProperty({
        data: {
          name: this.name,
          code: this.type === bankAccountType ? this.bankAccountId : null,
          type: this.type,
          format: this.format,
          payerEditAccessRule: this.payerEditAccessRule,
        },
      })
        .then(() => {
          this.name = '';
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.$emit('update:pending', true);
      this.removePayersProperty({
        params: {
          code: this.property.code,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.property) {
      this.name = this.property.name;
      this.type = this.property.type;
      this.payerEditAccessRule = this.property.payerEditAccessRule;
      this.allowEdit = this.property.payerEditAccessRule.trim().length > 0;

      if (this.property.type === bankAccountType) {
        this.bankAccountId = this.property.format;
      } else if (this.property.type === otherType) {
        const formatParts = (this.property.format || '').split(':');
        this.formatType = formatParts[0] || 'text';

        if (formatParts.length > 1) {
          this.formatOptions = formatParts[1]
            .split('|')
            .join('\n');
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  .warning {
    background-color: rgba($red, 0.2);
    padding: 10px 20px;
    border-radius: 20px;
  }

  .rule-edit {
    font-size: 0.7rem;
  }

  .selected-bank-account {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 5px;
  }
</style>
