<template>
  <div
    class="d-flex no-gutters"
    style="max-width: 500px; margin-left: auto; margin-right: 0"
  >
    <div class="col pr-1">
      <button
        class="btn btn-sm btn-block btn-secondary text-nowrap"
        @click="$emit('cancel')"
      >
        <i class="fas fa-times pr-2" />
        {{ $t('general.cancel') }}
      </button>
    </div>
    <div class="col pl-1">
      <button
        class="btn btn-sm btn-primary text-nowrap btn-block"
        :disabled="pending || disabled"
        @click="$emit('save')"
      >
        <span v-if="!pending">
          <i class="fas fa-check pr-1" />
          {{ $t('general.save') }}
        </span>
        <span v-else>
          <Loader
            color="#fff"
            size="15px"
          />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pending: Boolean,
    disabled: Boolean,
  },
};
</script>

<style>

</style>
