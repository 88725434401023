<template>
  <div>
    <h4 class="client-page-title mt-3">
      {{ $t('menu.messages') }}
    </h4>
    <div
      v-if="!threadId"
      key="new"
      class="ml-auto"
      style="border-color: transparent; max-width: 200px"
    >
      <RouterLink
        key="new"
        tag="button"
        class="btn btn-sm btn-primary"
        style="border-radius: 20px"
        to="/payer/messages/new"
        data-test="client-new-message"
      >
        <i class="fas fa-plus pr-2" /> {{ $t('messages.newMessage') }}
      </RouterLink>
    </div>
    <hr key="hr">
    <div
      v-if="!threadId"
      key="list"
    >
      <div
        v-if="!pending"
        data-test="messages-list"
      >
        <ThreadRow
          v-for="t in threads"
          :key="t.id"
          :thread="t"
          :unread="t.read === 0"
          @click.native="showThread(t.id, t.read)"
        />
      </div>
      <div v-else>
        <Loader />
      </div>
    </div>
    <div
      v-else
      key="content"
    >
      <ThreadContent
        :threads="threads"
        :thread="thread"
        :unread="unread === 0"
        @back="threadId = null"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ThreadContent from '../components/messages/ThreadContent';
import ThreadRow from '../components/messages/ThreadRow';

export default {
  data: () => ({
    threadId: null,
    unread: 0,
    threads: [],
    pending: false,
  }),
  components: {
    ThreadContent,
    ThreadRow,
  },
  computed: {
    ...mapGetters(['userPayers', 'refreshUserPayers']),
    thread() {
      if (!this.threadId) return null;
      return this.threads.find((el) => el.id === this.threadId);
    },
  },
  watch: {
    threadId(v) {
      if (!v) {
        this.request();
      }
    },
  },
  methods: {
    ...mapActions(['getUserThreads', 'getUserPayers']),
    request() {
      this.pending = true;
      this.getUserThreads({
        params: {
          query: {
            status: 'open',
            from: moment().subtract(1, 'year').utc().format(),
            to: moment().utc().format(),
          },
        },
      })
        .then(({ data }) => {
          this.threads = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    showThread(id, unread) {
      this.unread = unread;
      this.threadId = id;
    },
  },
  created() {
    this.$emit('page', 'messages');
    this.request();
    if (this.refreshUserPayers) {
      this.getUserPayers();
    }
  },
};
</script>

<style>

</style>
