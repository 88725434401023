<template>
  <div />
</template>

<script>
export default {
  created() {
    this.$emit('page', 'settlements');
  },
};
</script>

<style>

</style>
