<template>
  <div style="max-height: 400px; overflow: auto">
    <div
      v-if="showSelectAll"
      class="d-flex align-items-center group-row"
      @click="toggleAll(); update()"
    >
      <Checkbox
        :value="groupCodes.length === groupOptions.length"
        :emit="false"
      />
      {{ $t('groups.selectAll') }}
    </div>

    <GroupSelectList
      :group-options="groupOptions"
      :root-id="'#all'"
      :selected="selected"
      :allow-rule-based-select="allowRuleBasedSelect"
      :inherited="selectedInherited"
      @select="select"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupSelectList from './GroupSelectList';

export default {
  props: {
    groupCodes: Array,
    showSelectAll: Boolean,
    allowRuleBasedSelect: Boolean,
  },
  data: () => ({
    selected: {},
    inherited: {},
  }),
  components: {
    GroupSelectList,
  },
  computed: {
    ...mapGetters(['groups']),
    groupOptions() {
      return (this.groups || [])
        .filter((x) => x.code !== '#all')
        .sort((a, b) => a.path.localeCompare(b.path));
    },
    selectedInherited() {
      return Object.values(this.inherited).flat().reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});
    },
  },
  methods: {
    select({ code, parents }) {
      const value = !this.selected[code];

      if (value) {
        this.$set(this.inherited, code, parents);
      } else {
        this.$delete(this.inherited, code);
      }

      this.$set(this.selected, code, value);

      this.update();
    },
    toggleAll() {
      if (this.groupOptions.length === this.groupCodes.length) {
        this.selected = {};
      } else {
        this.groupOptions.forEach((x) => {
          this.$set(this.selected, x.code, true);
        });
      }
    },
    update() {
      const codes = Object.keys(this.selected).filter((x) => this.selected[x]);
      this.$emit('update:groupCodes', codes);
    },
  },
  created() {
    if (this.groupCodes && this.groupCodes.length > 0) {
      this.groupCodes.forEach((code) => {
        this.$set(this.selected, code, true);
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
