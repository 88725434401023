<template>
  <div>
    <div
      v-for="g in children"
      :key="g.code"
    >
      <div
        class="d-flex align-items-center group-row"
        :class="g.rule && !allowRuleBasedSelect ? 'cursor-blocked' : ''"
        @click="g.rule && !allowRuleBasedSelect ? null : $emit('select', { code: g.code, parents: [] })"
      >
        <Checkbox
          :value="selected[g.code] || inherited[g.code]"
          :emit="false"
          :color="inherited[g.code] ? '#aaa' : undefined"
        />
        <i
          v-if="g.rule"
          v-tippy
          class="fa-solid fa-rectangle-code text-secondary mr-1"
          :content="$t('groups.controlledWithRule')"
        />
        {{ g.name }}
      </div>

      <GroupSelectList
        class="ml-3"
        :group-options="groupOptions"
        :allow-rule-based-select="allowRuleBasedSelect"
        :selected="selected"
        :inherited="inherited"
        :root-id="g.code"
        @select="$emit('select', { ...$event, parents: [...$event.parents, g.code] })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupSelectList',
  props: {
    groupOptions: Array,
    rootId: String,
    selected: Object,
    inherited: Object,
    allowRuleBasedSelect: Boolean,
  },
  computed: {
    children() {
      return this.groupOptions.filter((x) => x.parentCode === this.rootId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .group-row {
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }

    &.cursor-blocked:hover {
      background-color: initial;
    }
  }

  .cursor-blocked {
    cursor: not-allowed;
    opacity: 0.7;

    &:deep(.colorful-custom-checkbox) {
      cursor: not-allowed;
    }
  }
</style>
