<template>
  <EditableList
    ref="list"
    :list="groupOptions"
    :pending="pending"
    :form-add-title="$t('groups.add')"
    :form-edit-title="$t('groups.edit')"
    sticky
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
  >
    <template #list="{ list, toggle, selected }">
      <GroupsList
        :list="list"
        :toggle="toggle"
        :selected="selected"
        :root-id="'#all'"
      />
    </template>

    <template #form="{edited}">
      <PayersGroupForm
        ref="form"
        :pending.sync="pending"
        :group="getGroup(edited)"
        @close="$refs.list.close()"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import PayersGroupForm from '@/components/settings/payers/PayersGroupForm';
import { mapGetters } from 'vuex';
import GroupsList from './GroupsList';

export default {
  data: () => ({
    newGroup: null,
    pending: false,
  }),
  components: {
    PayersGroupForm,
    GroupsList,
    EditableList,
  },
  computed: {
    ...mapGetters(['groups']),
    groupOptions() {
      return this.groups
        .filter((x) => x.code !== '#all')
        .map((x) => ({
          id: x.code,
          name: x.name,
          parentCode: x.parentCode,
        }));
    },
  },
  methods: {
    getGroup(id) {
      return this.groups.find((x) => x.code === id);
    },
  },
  created() {
    this.$emit('page', 'userGroups');
  },
};
</script>
