<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t('time.year') }}
        </div>
        <div
          style="font-size: 14px"
        >
          {{ selected }}
        </div>
      </div>
    </template>

    <div style="min-width: 180px">
      <div
        v-for="(year, i) in years"
        :key="year"
        class="sort-option text-left"
        :class="{ first: i === 0, active: selected == year }"
        @click="select(year)"
      >
        {{ year }}
      </div>
    </div>
  </Tippy>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: String,
  },
  computed: {
    ...mapGetters(['payee']),
    firstYear() {
      if (!this.payee) return this.selected;
      return moment.utc(this.payee.oldestBillingScope).year();
    },
    years() {
      let current = parseInt(moment().format('YYYY'), 10);
      const years = [];

      while (current >= this.firstYear) {
        years.push(current);
        current -= 1;
      }

      return years;
    },
  },
  methods: {
    select(year) {
      this.$emit('update:selected', year.toString());
      this.$refs.popover.tippy().hide();

      this.$router.replace({ query: { year } });
    },
  },
  created() {
    if (this.$route.query && this.$route.query.year) {
      this.$emit('update:selected', this.$route.query.year);
    }
  },
};
</script>

<style lang="scss" scoped>
 @import './payers/style.scss';
</style>
