<template>
  <div ref="cursor" />
</template>

<script>
export default {
  props: {
    // The higher the margin, the later the scroll will trigger
    margin: {
      type: Number,
      default: -20,
    },
  },
  methods: {
    isBottom() {
      if (!this.$refs.cursor) return false;
      const cursorBox = this.$refs.cursor.getBoundingClientRect();
      const bodyHeight = document.body.scrollHeight;
      const margin = (bodyHeight - cursorBox.bottom);

      return margin < this.margin;
    },
    onScroll() {
      if (!this.isBottom()) {
        this.$emit('request');
      }
    },
  },
  mounted() {
    this.ticking = false;
    this.scrollEvent = () => {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.onScroll();
          this.ticking = false;
        });

        this.ticking = true;
      }
    };
    this.parent = this.$refs.cursor.parentElement;

    this.parent.addEventListener('scroll', this.scrollEvent);
  },
  destroyed() {
    if (this.parent) {
      this.parent.removeEventListener('scroll', this.scrollEvent);
    }
  },
};
</script>

<style>

</style>
