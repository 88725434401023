<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ThreadStatusDropdown
          :selected="status"
          class="mr-3 mb-1"
          @update:selected="status = $event; statusUpdated()"
        />
        <PayersFilterDropdown
          class="receivers"
          @selected="payerIds = $event; request()"
        />
      </div>
      <div
        class="card"
        style="border-color: transparent"
      >
        <button
          class="btn btn-sm btn-primary"
          style="border-radius: 20px"
          data-test="company-new-message"
          @click="showNewThread = true; threadId = true"
        >
          <i class="fas fa-plus pr-2" /> {{ $t('messages.newMessage') }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 page-content pt-3">
        <div
          v-if="!pending || threads.length > 0"
          data-test="messages-list"
        >
          <div
            v-for="(monthThreads, m) in threadsGroupedByMonth"
            :key="m"
            class="pb-3"
          >
            <div class="month-name">
              {{ monthName(m) }}
            </div>
            <div
              v-for="thread in monthThreads"
              :key="thread.id"
              class="card mt-1 thread"
              :class="{
                'active' : threadId === thread.id,
              }"
              data-test="thread-box"
              @click="showThread(thread.id)"
            >
              <div
                class="card-body text-truncate"
                data-test="thread-title"
              >
                {{ thread.title }}
                <div class="thread-info d-flex justify-content-between">
                  <div
                    class="text-secondary text-truncate"
                  >
                    <i class="fa-solid fa-paper-plane-top" />
                    {{ getPayersInfo(thread) }}
                  </div>
                  <div class="text-secondary">
                    {{ formatDate(thread.lastMessage) }}
                  </div>
                </div>
              </div>
              <div
                v-if="thread.unreadCount > 0"
                class="unread-bouble"
              >
                {{ thread.unreadCount }}
              </div>
            </div>
          </div>
          <div
            v-if="error"
            class="text-danger text-center"
          >
            {{ $t('messages.error.unableToLoadThreads') }}
          </div>

          <div
            v-else-if="threads && threads.length === 0"
            class="text-center small pt-3"
          >
            {{ $t('messages.noThreads') }}
          </div>
        </div>
        <div
          v-else
          class="mt-3"
        >
          <Loader />
        </div>
      </div>
      <div
        v-if="showNewThread"
        class="col-12 col-md-8"
      >
        <div class="thread-header">
          <div
            class="title pb-1 flex-grow-1"
          >
            {{ $t('messages.newMessage') }}
          </div>
          <hr>
        </div>
        <ThreadForm
          :show-title="true"
          @done="showThread($event); request()"
        />
      </div>
      <div
        v-else-if="threadId"
        class="col-8 page-content"
      >
        <ThreadContent
          :thread-id="threadId"
          @close="threadId = null"
          @read="markAsRead"
          @refresh="request()"
        />
      </div>
      <div
        v-else
        class="col-12 col-md-8 text-center mt-2"
        style="opacity: 0.5"
      >
        <img
          src="@/assets/messages.svg"
        >
        <div>
          {{ $t('messages.selectOrStartNew') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThreadContent from '@/components/messages/ThreadContent';
import ThreadForm from '@/components/messages/ThreadForm';
import ThreadStatusDropdown from '@/components/messages/ThreadStatusDropdown';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    showNewThread: false,
    threadId: null,
    pending: false,
    threads: [],
    payerIds: [],
    status: 'open',
    error: false,
    ready: false,
  }),
  components: {
    ThreadContent,
    ThreadForm,
    PayersFilterDropdown,
    ThreadStatusDropdown,
  },
  computed: {
    threadsGroupedByMonth() {
      return this.threads
        .reduce((acc, curr) => {
          const month = moment(curr.lastMessage).format('MM/YYYY');
          if (acc[month]) {
            acc[month].push(curr);
          } else {
            acc[month] = [curr];
          }
          return acc;
        }, {});
    },
  },
  watch: {
    threadId() {
      if (!this.ready) return;
      this.$router.replace({
        query: {
          status: this.status,
          threadId: this.threadId || undefined,
        },
      }).catch(() => {});
    },
  },
  methods: {
    ...mapActions(['getPayeeThreads']),
    statusUpdated() {
      this.request();
      this.threadId = null;

      this.$router.replace({
        query: {
          status: this.status,
        },
      }).catch(() => {});
    },
    monthName(month) {
      const m = moment(month, 'MM/YYYY');
      return m.format('MMMM YYYY');
    },
    showThread(threadId) {
      this.showNewThread = false;
      this.threadId = threadId;
    },
    markAsRead() {
      const thread = this.threads.find((x) => x.id === this.threadId);
      this.$set(thread, 'unreadCount', 0);
    },
    getPayersInfo({ relatedPayers, payersCount }) {
      if (payersCount > 3 || !relatedPayers) {
        return payersCount;
      }

      return relatedPayers.map((x) => x.name).join(', ');
    },
    formatDate(d) {
      const m = moment(d);
      if (m.year() === moment().year()) {
        return m.format('HH:mm D MMMM');
      }
      return m.format('HH:mm D MMMM YYYY');
    },
    request() {
      this.pending = true;
      this.threads = [];

      this.getPayeeThreads({
        params: {
          query: {
            status: this.status,
            from: moment().subtract(1, 'year').utc().format(),
            to: moment().add(1, 'day').utc().format(),
            pid: this.payerIds.map((x) => x.id),
          },
        },
      })
        .then(({ data }) => {
          this.threads = data;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    if (this.$route.query?.threadId) {
      this.threadId = this.$route.query.threadId;
    }
    if (this.$route.query?.status) {
      this.status = this.$route.query.status;
    }

    this.ready = true;
    this.$emit('page', 'messages');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars';

  .page-content {
    height: calc(100vh - 170px);
    overflow-y: scroll;
    padding-bottom: 0;

    > div {
      padding-bottom: 40px;
    }
  }

  .month-name {
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .thread {
    cursor: pointer;
    border-radius: 10px;

    .card-body {
      padding: 8px 15px;
      font-weight: 500;
    }
    &.active {
      border-color: $blue;
      background-color: rgba($blue, 0.1);
    }
  }

  .unread-bouble {
    top: -3px;
    right: -3px;
    position: absolute;
    width: 15px;
    height: 15px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: white;
    border-radius: 7.5px;
    background-color: $red;
  }

  .thread-info {
    font-size: 12px;
    font-weight: 400;
  }

  .thread-header {
    line-height: 1;

    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .thread:hover {
    box-shadow: 0 0 5px rgba(100,100,100,0.5);
  }
</style>
