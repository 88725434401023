<template>
  <div
    class="info-row d-none d-md-flex w-100"
    :style="{ paddingRight: `${scrollPadding}px` }"
  >
    <div style="width: 35px">
      &nbsp;
    </div>
    <template v-for="c in layout">
      <div
        v-if="c.key === 'name'"
        :key="c.key"
        class="pl-2"
        style="max-width: 350px; min-width: 350px"
      >
        <label class="mb-0 column-header">
          {{ $t('general.name') }}
        </label>
      </div>
      <div
        v-if="c.key === 'clientCode' || c.key === 'code'"
        :key="c.key"
        class="pl-2 column-header-cell"
      >
        <label class="mb-0 column-header">
          {{ $t('client.clientCode') }}
        </label>
      </div>
      <div
        v-if="c.key === 'number'"
        :key="c.key"
        class="pl-2 column-header-cell"
      >
        <label class="mb-0 column-header">
          {{ $t('client.number') }}
        </label>
      </div>
      <div
        v-if="c.type === 'property'"
        :key="c.key"
        class="pl-2 column-header-cell"
      >
        <label class="mb-0 column-header text-truncate">
          {{ getPropertyName(c.key) }}
        </label>
      </div>
      <div
        v-if="c.type === 'group'"
        :key="c.key"
        class="pl-2 column-header-cell"
      >
        <label class="mb-0 column-header text-truncate">
          {{ getGroupName(c.key) }}
        </label>
      </div>
      <div
        v-if="c.key === 'space'"
        :key="c.key"
        class="flex-grow-1"
      >
        &nbsp;
      </div>
      <div
        v-if="(c.key === 'categories' || c.type === 'category') && !hideDetails"
        :key="c.key"
        class="d-flex align-items-center"
      >
        <div
          v-for="b in balanceColumns.filter(y => c.key === 'categories' || y.categoryId === c.key)"
          :key="`${b.categoryId}_${b.currency}`"
          class="balance-column"
        >
          <CategoryInfo
            :category-id="b.categoryId"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import { mapGetters } from 'vuex';

export default {
  props: {
    hideDetails: Boolean,
    layout: Array,
    balanceColumns: Array,
  },
  data: () => ({

  }),
  components: {
    CategoryInfo,
  },
  computed: {
    ...mapGetters([
      'scrollPadding',
      'properties',
      'groups',
    ]),
  },
  methods: {
    getPropertyName(id) {
      return this.properties.find((x) => x.code === id)?.name;
    },
    getGroupName(id) {
      return this.groups.find((x) => x.code === id)?.path;
    },
  },
};
</script>

<style lang="scss" scoped>
  .balance-column {
    width: 150px;
    padding: 0 10px;
    border-left: 1px solid rgba(100, 100, 100, 0.1);
    border-right: 1px solid rgba(100, 100, 100, 0.1);
    align-items: center;
  }

  .column-header-cell {
    min-width: 150px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .column-header {
    width: 100%;
  }

  .balance-column ::v-deep .category-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
</style>
