<template>
  <div
    v-if="!more"
  >
    <div
      v-for="b in balance.filter(x => x.balance !== 0)"
      :key="`${b.categoryId}_${b.currency}`"
      class="d-flex my-1 balance justify-content-between"
    >
      <CategoryInfo
        :category-id="b.categoryId"
        class="font-weight-bold mr-2"
      />
      <div
        class="money-font text-nowrap"
        :class="{
          red: b.balance < 0,
          blue: b.balance > 0
        }"
      >
        <span v-if="b.balance > 0">+ </span>{{ formatMoney(b.balance, b.currency) }}
      </div>
    </div>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import { mapGetters } from 'vuex';

export default {
  props: {
    balance: Array,
    more: Boolean,
  },
  components: {
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['formatMoney']),
  },
};
</script>

<style lang="scss" scoped>
  .balance {
    text-align: right;
    padding: 5px;

    .red {
      color: darken($red, 20);
    }
    .blue {
      color: $blue;
    }
  }
</style>
