<template>
  <div
    v-if="loadingRoles"
    style="width: 370px"
  >
    <Loader />
  </div>
  <EditableList
    v-else
    ref="list"
    :list="rolesList"
    :pending="pending"
    hide-remove
    :form-add-title="$t('settings.roles.add')"
    :form-edit-title="$t('settings.roles.edit')"
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
  >
    <template #listElement="{ row }">
      <span class="text-truncate">
        {{ row.email }}
      </span>
    </template>

    <template #form="{edited}">
      <RoleForm
        ref="form"
        :pending.sync="pending"
        :tenant-id="payee.ownerId"
        :user="getUser(edited)"
        @close="$refs.list.close()"
        @refresh="request()"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import { mapActions, mapGetters } from 'vuex';
import RoleForm from './RoleForm';

export default {
  data: () => ({
    newGroup: null,
    selected: null,
    pending: false,
    loadingRoles: false,
    roles: [],
  }),
  components: {
    RoleForm,
    EditableList,
  },
  computed: {
    ...mapGetters(['payee', 'roleTypes']),
    rolesList() {
      if (!this.roles) return [];
      return Object.entries(this.roles
        .reduce((acc, curr) => {
          if (acc[curr.email]) {
            acc[curr.email].push(curr);
          } else {
            acc[curr.email] = [curr];
          }
          return acc;
        }, {}))
        .map(([key, value]) => ({
          id: key,
          email: key,
          list: value,
        }));
    },
  },
  methods: {
    ...mapActions(['getPayeeAccess']),
    getUser(id) {
      return this.rolesList.find((x) => x.id === id);
    },
    request() {
      this.loadingRoles = true;
      this.getPayeeAccess({
        params: {
          tenantId: this.payee.ownerId,
        },
      })
        .then(({ data }) => {
          this.roles = data;
          this.$nextTick(() => {
            if (this.$refs.form) {
              this.$refs.form.init();
            }
          });
        })
        .finally(() => {
          this.loadingRoles = false;
        });
    },
  },
  created() {
    this.request();
    this.$emit('page', 'userGroups');
  },
};
</script>
