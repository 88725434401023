<template>
  <RouterView @page="$emit('page', $event)" />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    payeeId: String,
  },
  methods: {
    ...mapActions([
      'getPayee',
      'getPayersGroups',
      'getPayersProperties',
      'getCurrencies',
      'getUnreadMessages',
      'getUserPermissions',
    ]),
    loopMessageRequest() {
      if (this.destroyed) return;
      if (process.env.NODE_ENV === 'development') return;

      this.getUnreadMessages();
      this.timeout = setTimeout(() => {
        this.loopMessageRequest();
      }, 20 * 1000);
    },
  },
  created() {
    this.$store.commit('setPayeeId', this.payeeId);

    this.getPayee({
      params: {
        payeeId: this.payeeId,
      },
    });

    this.getUserPermissions();
    this.getPayersGroups();
    this.getPayersProperties();
    this.getCurrencies();
    this.loopMessageRequest();
  },
  destroyed() {
    this.destroyed = true;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
</script>
