<template>
  <div
    v-if="category"
    class="d-flex align-items-center"
  >
    <div
      class="color-box"
      :style="{ 'background-color': categoryColor(categoryId) }"
    />
    <div
      v-if="!colorOnly"
      class="category-name"
      style="line-height: 13px"
    >
      {{ category.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    categoryId: String,
    colorOnly: Boolean,
  },
  computed: {
    ...mapGetters(['payee', 'categoryColor']),
    category() {
      return this.payee?.categories.find((x) => x.id === this.categoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .color-box {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 7px;
    flex-shrink: 0;
  }
</style>
