<template>
  <Component :is="tag">
    <slot />
  </Component>
</template>

<script>
// https://github.com/guanzo/vue-smooth-reflow#smooth-component
import smoothReflow from 'vue-smooth-reflow';

export default {
  name: 'SmoothReflow',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    options: Object,
  },
  mixins: [smoothReflow],
  mounted() {
    this.$smoothReflow(this.options);
  },
};
</script>
