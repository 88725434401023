<template>
  <div class="container-fluid h-100">
    <div class="page-header d-flex align-items-center">
      <div class="pr-3">
        <button
          class="btn btn-outline-secondary icon-btn"
          @click="back"
        >
          <i class="fas fa-angle-left" />
        </button>
      </div>
      <h1>{{ $t('menu.import') }}</h1>
    </div>
    <hr class="my-0">

    <RouterView
      @backUrl="backUrl = $event"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    backUrl: '',
  }),
  methods: {
    back() {
      this.$router.push(this.backUrl);
    },
  },
};
</script>

<style>

</style>
