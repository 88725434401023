<template>
  <nav class="navbar navbar-expand-md navbar-dark d-flex justify-content-between">
    <div
      v-if="payeeId"
      class="company-name"
    >
      {{ payee ? payee.name : '' }}
    </div>
    <div v-else />

    <div class="d-flex navbar-icon align-items-center">
      <!-- <div class="ml-auto mx-4 btn-link">
        <div
          class="user-icon"
          @click="$emit('showAlerts')"
        >
          <i class="far fa-newspaper" />
        </div>
      </div> -->
      <NavbarDropdown class="ml-auto mr-2 navbar-icon" />
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import NavbarDropdown from './NavbarDropdown';

export default {
  components: {
    NavbarDropdown,
  },
  computed: {
    ...mapGetters(['payeeId', 'payee']),
  },
};
</script>

<style lang="scss" scoped>
  @import '~@/styles/vars';

  .company-name ::v-deep .btn-link, .company-name > span {
    color: white;
    font-size: 20px;
    font-weight: 200;
  }
  .company-name ::v-deep .dropdown-menu {
    text-align: center;
  }

  .navbar-icon ::v-deep .btn-link {
    text-decoration: none;
    padding: 0;
    max-height: 40px;
    line-height: 1;
    cursor: pointer;

    i {
      color: #999;
      font-size: 28px;
    }
    &:hover i {
      color: #333;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-icon ::v-deep .dropdown-toggle::after {
    content: none;
  }

  .navbar-brand {
    padding: 0;
  }

  .company-name {
    font-weight: 500;
    text-align: left;
    color: #333;
    font-size: 22px;
  }

  .navbar {
    background-color: transparent;
    margin-bottom: 10px;
    padding: 10px 5px 0;
    min-height: 42px;
  }
</style>
