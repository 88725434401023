<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          v-if="selectedType"
          style="font-size: 0.8rem"
        >
          <i
            :class="selectedType.icon"
            class="pr-1"
          />
          {{ selectedType.name }}
        </div>
        <div v-else>
          ?
        </div>
      </div>
    </template>

    <div style="min-width: 180px">
      <div
        v-for="(t, i) in types"
        :key="t.key"
        class="sort-option text-left d-flex"
        :class="{
          first: i === 0,
          active: selected === t.key
        }"
        @click="$emit('update:selected', t.key); $refs.popover.tippy().hide()"
      >
        <div class="flex-grow-1">
          <i
            :class="t.icon"
            class="pr-1"
          />
          {{ t.name }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: String,
  },
  computed: {
    ...mapGetters(['paymentTypes']),
    selectedType() {
      if (!this.selected) return null;
      return this.paymentTypes.find((x) => x.key === this.selected);
    },
    types() {
      return this.paymentTypes.filter((x) => !x.system);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/components/payers/style.scss";

 .sort-option-selected  {
    background-color: #fff;
    border: 1px solid #ced4da;
    display: flex;
    align-items: center;
    min-height: calc(1.8125rem + 2px);
    border-radius: 1rem;
  }

  .category-row::v-deep .color-box {
    margin-right: 10px;
  }

  .sort-option.active {
    font-weight: 500;
    color: inherit;
  }
</style>
