<template>
  <div>
    <div
      v-if="companyLicense"
      style="cursor: default;"
      class="demo-banner"
    >
      {{ $t("company.isBeingVerified") }}
    </div>
    <div
      v-else
      class="demo-banner"
      @click="$bvModal.show('confirm-activate')"
    >
      {{ $t("company.useDemoAccount") }}
      <span class="pl-2">{{ $t("company.activateAccount") }}</span>
    </div>
    <BModal
      id="confirm-activate"
      hide-footer
    >
      <template #modal-title>
        {{ $t('company.confirmActivate') }}
      </template>
      <div>
        {{ $t('company.confirmToUnlock') }}
      </div>
      <BButton
        v-if="!serverError.uknown"
        class="mt-4"
        variant="primary"
        style="min-width: 150px;"
        @click="submit"
      >
        <span v-if="!pending">{{ $t('general.confirm') }}</span>
        <Loader
          v-else
          size="15px"
          color="white"
        />
      </BButton>
      <div
        v-else
        class="alert alert-danger"
      >
        {{ $t('error.internalError') }}
      </div>
    </BModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    error: false,
    pending: false,
    serverError: {
      unkown: null,
    },
  }),
  computed: {
    ...mapGetters(['companyId', 'companyLicense']),
  },
  methods: {
    ...mapActions(['activateCompany']),
    submit() {
      this.pending = true;
      this.activateCompany({
        params: {
          companyId: this.companyId,
        },
      })
        .then(() => {
          this.pending = false;
          this.$bvModal.hide('confirm-activate');
        })
        .catch(() => {
          this.pending = false;
          this.serverError.unknown = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.demo-banner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  text-align: center;
  background-color: #F0C537;
  font-size: 14px;
  cursor: pointer;
  z-index: 200;

  &:hover span {
    text-decoration: underline;
  }
}
</style>
