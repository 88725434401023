<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
    @show="request"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t("bankStatements.bankStatement") }}
        </div>
        <div
          style="font-size: 14px"
          class="text-truncate"
        >
          {{ selected ? selectedText : $t('billingPlan.select.all') }}
        </div>
      </div>
    </template>

    <div style="min-width: 250px; margin-right: -8px">
      <div v-if="pending">
        <Loader />
      </div>
      <div
        v-else-if="options.length === 0"
        class="py-2 font-weight-bold"
      >
        {{ $t('bankStatements.noStatements', [year]) }}
      </div>
      <div
        v-else
        style="max-height: 500px; overflow-y: auto"
      >
        <div
          class="sort-option text-left font-weight-bold first d-flex align-items-center"
          style="height: 56px"
          @click="select(null)"
        >
          <div>
            {{ $t('billingPlan.select.all') }}
          </div>
        </div>
        <div
          v-for="k in options"
          :key="k.key"
          class="sort-option text-left"
          :class="{ active: selected === k.key }"
          :style="{ opacity: k.percent == 1 ? '0.5' : 1 }"
          @click="select(k.id)"
        >
          <div class="d-flex justify-content-between">
            <div
              class="font-weight-bold text-nowrap pr-2 money-font"
              style="font-size: 12px"
            >
              {{ formatDate(k.transfersFrom) }} - {{ formatDate(k.transfersTo) }}
            </div>
            <div class="text-secondary text-truncate">
              {{ k.fileName }}
            </div>
          </div>
          <div
            class="progress-container d-flex align-items-center"
          >
            <div
              class="progress flex-grow-1"
              style="height: 8px; background-color: #ddd"
            >
              <div
                class="progress-bar"
                :style="{
                  'width': `${100*(k.settledIncomingTransfers/k.totalIncomingTransfers)}%`,
                  'background-color': colors('blue')
                }"
              />
              <div
                class="progress-bar"
                :style="{
                  'width': `${100*(k.skippedIncomingTransfers/k.totalIncomingTransfers)}%`,
                  'background-color': colors('orange')
                }"
              />
              <div
                class="progress-bar"
                :style="{
                  'width': `${100*(k.duplicatedIncomingTransfers/k.totalIncomingTransfers)}%`,
                  'background-color': '#333'
                }"
              />
            </div>
            <div
              style="width: 40px"
              class="text-right"
            >
              {{ Math.round(100 * k.percent) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import colors from '@/utils/colors';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    selected: String,
    year: String,
  },
  data: () => ({
    statements: [],
    pending: false,
    haveData: false,
  }),
  computed: {
    ...mapGetters(['payee']),
    selectedText() {
      return this.options.find((x) => x.id === this.selected)?.fileName;
    },
    options() {
      return this.statements
        .map((x) => ({
          ...x,
          percent: (x.settledIncomingTransfers
            + x.skippedIncomingTransfers
            + x.duplicatedIncomingTransfers) / x.totalIncomingTransfers,
        }))
        .sort((a, b) => moment(b.transfersFrom).unix() - moment(a.transfersFrom).unix());
    },
  },
  watch: {
    year() {
      this.haveData = false;
      this.request();
    },
  },
  methods: {
    ...mapActions(['getBankStatements']),
    colors,
    add(newStatements) {
      this.statements = [...this.statements, ...newStatements];
    },
    formatDate(date) {
      return moment(date).format('DD.MM');
    },
    request() {
      if (this.haveData) return;
      this.pending = true;
      this.getBankStatements({
        params: {
          query: {
            year: this.year,
          },
        },
      })
        .then(({ data }) => {
          this.statements = data;
          this.haveData = true;
          if (this.selected && !this.statements.some((x) => x.id === this.selected)) {
            this.$emit('update:selected', null);
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
    select(key) {
      this.$emit('update:selected', key);
      this.$refs.popover.tippy().hide();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../payers/style.scss";

  .bar-percent {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 1rem;
    color: white;
    text-align: center;

  }

  .progress {
    position: relative;
  }
</style>
