<template>
  <div v-if="pendingPayers">
    <Loader class="mt-3" />
  </div>
  <div v-else>
    <div
      class="d-flex align-items-center mt-3"
    >
      <i
        class="fas fa-chevron-left mr-2 secondary-icon"
        @click="back"
      />
      <div class="flex-grow-1">
        <span class="font-weight-bold pr-2">
          {{ $t('shop.mealOffer') }}:
        </span>
        <span class="text-primary">
          {{ selected.payeeShortName }}
        </span>
        <span class="px-2">|</span>
        {{ selected.payerName }}
      </div>
    </div>
    <hr>
    <template v-if="selected.payerType === 'Person'">
      <div class="mb-3">
        <button
          class="btn btn-sm"
          :class="!showCalendar ? 'btn-secondary' : 'btn-outline-secondary'"
          style="width: 150px"
          @click.prevent="showCalendar = false"
        >
          <i class="fa-regular fa-table-list pr-1" />
          {{ $t('shop.list') }}
        </button>
        <button
          class="btn btn-sm ml-2"
          style="width: 150px"
          :class="showCalendar ? 'btn-secondary' : 'btn-outline-secondary'"
          @click.prevent="showCalendar = true"
        >
          <i class="far fa-calendar-days pr-1" />
          {{ $t('shop.calendar') }}
        </button>
      </div>

      <CalendarOffer
        v-if="showCalendar"
        v-bind="selected"
        :init-at="initAt"
      />
      <ShopSummaryPage
        v-else
        v-bind="selected"
        @calendar="initAt = $event; showCalendar = true"
      />
    </template>
    <template v-else>
      <CompanyCalendarOrder
        v-bind="selected"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CalendarOffer from '../components/shop/CalendarOffer';
import ShopSummaryPage from '../components/shop/ShopSummaryPage';
import CompanyCalendarOrder from '../components/shop/company/CompanyCalendarOrder';

export default {
  props: {
    payerId: String,
  },
  data: () => ({
    showCalendar: false,
    initAt: null,
    pendingPayers: false,
  }),
  components: {
    CalendarOffer,
    ShopSummaryPage,
    CompanyCalendarOrder,
  },
  computed: {
    ...mapGetters(['userPayers']),
    selected() {
      return this.userPayers?.find((x) => x.payerId === this.payerId);
    },
  },
  watch: {
    showCalendar(v) {
      if (v) {
        this.$router.replace({ query: { calendar: true, at: this.initAt } });
      } else {
        this.$router.replace({ query: {} });
      }
    },
  },
  methods: {
    ...mapActions([
      'getUserPayers',
    ]),
    back() {
      this.$router.push('/payer/shop');
    },
  },
  created() {
    if (this.$route.query.calendar) {
      this.initAt = this.$route.query.at;
      this.showCalendar = true;
    }
    if (!this.userPayers || this.userPayers.length === 0) {
      this.pendingPayers = true;
      this.getUserPayers()
        .finally(() => {
          this.pendingPayers = false;
        });
    }
  },
};
</script>
