<template>
  <div class="warning-box">
    <i class="fa-regular fa-triangle-exclamation" />
    <span v-if="type === 'Other'">{{ reference }}</span>
    <span v-else>
      {{ $t(`${translationKey}.${type}`, [reference]) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    reference: String,
    translationKey: {
      type: String,
      default: 'shop.error',
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-box {
  background-color: rgba($orange, 0.1);
  margin-top: 5px;
  border-radius: 5px;
  padding: 10px 15px;

  i {
    color: $orange;
    font-size: 25px;
    padding-right: 10px;
  }
}
</style>
