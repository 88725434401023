<template>
  <div>
    <div class="summary-box row">
      <div class="col-12 col-md-6">
        <h2 class="bill-title">
          {{ bill.title }}
        </h2>
        <div class="my-2">
          {{ bill.description }}
        </div>
        <hr
          style="max-width: 120px; margin-left: 0"
        >
        <div v-if="!bill.isPlan">
          <div
            v-if="bill.from"
            class="d-flex"
          >
            <div style="width: 180px">
              <label> {{ $t('payment.startDate') }}:</label>
            </div>
            <span class="font-weight-bold text-left money-font">
              {{ formatDate(bill.from) }}
            </span>
          </div>
          <div
            v-if="bill.paymentTime"
            class="d-flex"
          >
            <div style="width: 180px">
              <label> {{ $t('payment.to') }}:</label>
            </div>
            <span class="font-weight-bold money-font">
              {{ deadline }}
              <span class="text-secondary pl-1">
                ({{ bill.paymentTime }} {{ bill.paymentTime == '1' ? $t('time.day') : $t('time.days') }})
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="bill.isPlan"
          class="small text-secondary"
        >
          <i class="fas fa-calendar pr-1" />
          <RepeatInfo v-bind="bill" />
        </div>
        <div
          v-if="bankAccount"
          class="d-flex"
        >
          <div style="width: 180px">
            <label>{{ $t('settings.bankAccounts.typeAccNumber') }}:</label>
          </div>
          <span class="font-weight-bold money-font">
            {{ bankAccount.name }}
          </span>
        </div>
        <div v-if="payee && payee.categories.length > 1">
          <hr style="max-width: 100px; margin-left: 0">
          <div class="d-flex align-items-center">
            <div style="width: 180px">
              <label class="mb-0">{{ $t('bill.category') }}:</label>
            </div>
            <div
              class="d-flex align-items-center pl-2"
            >
              <CategoryInfo :category-id="bill.category" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-6"
        style="border-left: 1px solid #eee"
      >
        <div class="d-flex justify-content-between px-2">
          <label>
            {{ $t('client.name') }}
          </label>
          <label class="pr-1">
            {{ $t('payment.amount') }}
          </label>
        </div>
        <hr class="mb-1 mt-0">
        <div class="client-list-summary">
          <div
            v-for="(payer, i) in payersList"
            :key="payer.id"
            class="d-flex justify-content-between client-row"
            :class="{ odd: i % 2 == 1 }"
          >
            <div>
              {{ payer.name }}
            </div>
            <div class="text-right money-font">
              {{ formatCurrency(payer.amount * (payer.count || 1), bill.currency) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="bill.isPlan"
      class="mt-3"
    >
      <BFormCheckbox
        :checked="isEnabled"
        @input="$emit('update:isEnabled', !isEnabled)"
      >
        {{ $t('billingPlan.activate') }}
      </BFormCheckbox>
    </div>

    <hr>

    <div class="text-right pb-4">
      <button
        class="btn btn-primary btn-lg px-5"
        :disabled="pending"
        @click="$emit('save')"
      >
        <i class="fas fa-check pr-2" />
        {{ $t('general.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CategoryInfo from '@/components/CategoryInfo';
import RepeatInfo from './RepeatInfo';

export default {
  props: {
    bill: Object,
    pending: Boolean,
    isEnabled: Boolean,
  },
  components: {
    RepeatInfo,
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['locale', 'payee', 'formatCurrency']),
    bankAccount() {
      return this.payee.bankAccounts.find((x) => x.accountNumber.toString() === this.bill.bankAccountId);
    },
    payersList() {
      return this.bill.payers.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
    deadline() {
      const time = parseInt(this.bill.paymentTime, 10);
      if (Number.isNaN(time)) return null;
      return moment(this.bill.from, 'YYYY-MM-DD').add(time, 'd').format('DD-MM-YYYY');
    },
  },
  methods: {
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
  .summary-box {
    box-shadow: 0 4px 8px #ddd;
    border-radius: 15px;
    padding: 15px 10px;
    margin: 5px;
  }

  .bill-title {
    font-weight: 500;
    font-size: 1.5rem;
  }

  .client-row {
    box-shadow: 0 1px 2px #eee;
    border-radius: 10px;
    padding: 2px 10px;
    &.odd {
      background-color: #eee;
    }
  }

  .client-list-summary {
    max-height: 30vh;
    overflow: auto;
  }
</style>
