<template>
  <div class="pt-4">
    <div
      class="font-weight-bold"
      style="line-height: 1.2"
    >
      {{ $t(`notify.type.${subjectType}`) }}
    </div>
    <div class="light-text small">
      {{ title }}
    </div>

    <template v-if="pending || !details">
      <div class="text-center">
        <Loader />
      </div>
    </template>
    <template v-else>
      <div
        v-if="!delivered && !skipped"
        class="text-right"
      >
        <button
          v-if="details.notifications.length > 0"
          class="btn btn-sm btn-primary mt-3"
          :disabled="sendNotificationPending"
          style="width: 200px"
          @click="sendNow"
        >
          <i class="fas fa-paper-plane pr-2" />
          {{ $t('messages.sendNow') }}
        </button>
        <button
          class="btn btn-sm btn-secondary mt-3 ml-1"
          :disabled="skipNotificationPending"
          style="width: 200px"
          @click="requestNotificationSkip"
        >
          <i class="fas fa-ban pr-2" />
          {{ $t('messages.skip') }}
        </button>
      </div>
      <hr>

      <div
        v-if="!skipped && payersWithDelivery.length == 0"
        class="text-center pt-2"
        style="max-width: 300px; margin: auto"
      >
        <div class="text-center font-weight-bold">
          <i
            class="fas fa-users-slash text-secondary pb-3"
            style="font-size: 2rem"
          />
        </div>
        <strong v-if="!delivered">{{ $t('notify.nooneWillReceiveIt') }}</strong>
        <p
          v-else
          class="pt-2 small"
        >
          {{ $t('notify.nooneReceivedIt') }}
        </p>
        <p
          v-if="!delivered"
          class="pt-2 small"
        >
          {{ $t('notify.nooneWillReceiveItHelp') }}
        </p>
      </div>
      <div
        v-if="skipped"
        class="text-center pt-2"
        style="max-width: 300px; margin: auto"
      >
        <div class="text-center font-weight-bold">
          <i
            class="fas fa-ban text-secondary pb-3"
            style="font-size: 2rem"
          />
        </div>
        <p class="pt-2 small">
          {{ $t('notify.skipped') }}
        </p>
      </div>

      <div
        style="overflow-y: auto; max-width: 100%"
      >
        <NotificationPayerDetails
          v-for="p in payersWithDelivery"
          :key="p.id"
          :class="{ selected: clientDetails === p.id }"
          v-bind="p"
          :delivered="delivered"
          :selected="clientDetails == p.id"
          @toggle="toggle(p.id)"
        />

        <hr class="mb-2">
        <div class="text-right text-secondary small px-2">
          x{{ payersWithDelivery.length }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationPayerDetails from './NotificationPayerDetails';

export default {
  props: {
    subjectType: String,
    subjectId: String,
    timestamp: String,
    status: String,
    title: String,
    selected: Boolean,
    scheduleId: String,
    notifications: Array,
    delivered: Boolean,
    skipped: Boolean,
  },
  data: () => ({
    sendNotificationPending: false,
    skipNotificationPending: false,
    clientDetails: null,
    details: null,
    payers: [],
    pending: false,
  }),
  components: {
    NotificationPayerDetails,
  },
  computed: {
    payersWithDelivery() {
      if (!this.details) return [];

      const receivers = {};

      this.details.notifications.forEach((n) => {
        if (receivers[n.receiverIdentifier]) {
          receivers[n.receiverIdentifier].push(n);
        } else {
          receivers[n.receiverIdentifier] = [n];
        }
      });
      this.details.delivery.forEach((n) => {
        if (receivers[n.receiverIdentifier]) {
          receivers[n.receiverIdentifier].push(n);
        } else {
          receivers[n.receiverIdentifier] = [n];
        }
      });

      return Object.keys(receivers)
        .map((x) => ({
          id: x,
          receiverIdentifier: x,
          payers: receivers[x].map((n) => this.payers.find((p) => p.id === n.receiverId)),
          delivery: this.details.notifications
            .filter((y) => y.receiverIdentifier === x)
            .map((y) => ({
              ...y,
              content: JSON.parse(y.content),
            }))
            .concat(this.details.delivery
              .filter((y) => y.receiverIdentifier === x)),

        }));
    },
  },
  watch: {
    scheduleId() {
      this.request();
    },
  },
  methods: {
    ...mapActions([
      'getNotification',
      'sendNotification',
      'skipNotification',
    ]),
    toggle(receiverId) {
      if (this.clientDetails === receiverId) {
        this.clientDetails = null;
      } else {
        this.clientDetails = receiverId;
      }
    },
    requestNotificationSkip() {
      this.skipNotificationPending = true;
      this.skipNotification({
        data: {
          scheduleId: this.scheduleId,
          subjectType: this.subjectType,
          subjectId: this.subjectId,
        },
      })
        .then(() => {
          this.$emit('refresh');
        })
        .finally(() => {
          this.skipNotificationPending = false;
        });
    },
    sendNow() {
      this.sendNotificationPending = true;
      this.sendNotification({
        data: {
          scheduleId: this.scheduleId,
          subjectType: this.subjectType,
          subjectId: this.subjectId,
        },
      })
        .then(() => {
          this.$emit('refresh');
        })
        .finally(() => {
          this.sendNotificationPending = false;
        });
    },
    request() {
      this.pending = true;
      this.details = null;
      this.payers = [];

      this.getNotification({
        data: {
          scheduleId: this.scheduleId,
          subjectType: this.subjectType,
          subjectId: this.subjectId,
        },
      })
        .then(({ data }) => {
          this.details = data.notification;
          this.payers = data.payers;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
  }

  .light-text {
    color: #888;
  }

  .appear-enter,
  .appear-leave-to { opacity: 0; max-height: 0px; }

  .appear-leave,
  .appear-enter-to { opacity: 1; max-height: 500px; }

  .appear-enter-active { transition: opacity 400ms, max-height 400ms }
  .appear-leave-active { transition: opacity 200ms, max-height 200ms }

</style>
