<template>
  <div>
    <SaveButton
      :pending="pending"
      @cancel="cancel"
      @save="save"
    />
    <hr>
    <div class="mb-3">
      <BFormCheckbox
        v-model="isEnabled"
      >
        {{ $t('billingPlan.activate') }}
      </BFormCheckbox>
    </div>
    <BillTitleAndDescription
      :hide-type="true"
      :bill-is-plan="true"
      :bill-title.sync="billTitle"
      :bill-code.sync="billCode"
      :bill-description.sync="billDescription"
      :bill-payment-time.sync="billPaymentTime"
      :bill-repeat-type.sync="billRepeatType"
      :bill-repeat-number.sync="billRepeatNumber"
      :bill-from.sync="billFrom"
      :bill-to.sync="billTo"
      :bill-category.sync="billCategory"
      :bill-early-execution-days.sync="billEarlyExecutionDays"
    />
  </div>
</template>

<script>
import SaveButton from '@/components/SaveButton';
import moment from 'moment';
import { mapActions } from 'vuex';
import BillTitleAndDescription from '../form/BillTitleAndDescription';

export default {
  props: {
    plan: Object,
    color: String,
  },
  data: () => ({
    pending: false,
    billTitle: '',
    billCode: '',
    billDescription: '',
    billPaymentTime: '',
    billFrom: '',
    billTo: '',
    isEnabled: false,
    billCategory: '',
    billRepeatNumber: 1,
    billEarlyExecutionDays: 1,
    billRepeatType: 'Month',
    error: null,
  }),
  components: {
    BillTitleAndDescription,
    SaveButton,
  },
  methods: {
    ...mapActions([
      'updateBillingPlan',
    ]),
    cancel() {
      this.$emit('close');
    },
    checkAmount() {
      if (!this.amount || this.amount.toString().trim() === '0') {
        this.error = this.$t('error.enterPaymentAmount');
        return false;
      }
      return true;
    },
    save() {
      if (!this.billTitle) {
        this.error = this.$t('error.enterPaymentName');
        return;
      }

      this.pending = true;
      this.updateBillingPlan({
        params: {
          billingPlanId: this.plan.id,
        },
        data: {
          title: this.billTitle,
          code: this.billCode,
          description: this.billDescription,
          categoryId: this.billCategory,
          isEnabled: this.isEnabled,
          masterAmount: this.plan.masterAmount,
          currency: this.plan.currency,
          daysForPayment: this.billPaymentTime,
          timeRule: {
            start: this.billFrom,
            end: this.billTo
              ? this.billTo
              : null,
            repeatType: this.billRepeatType,
            repeatCount: this.billRepeatNumber,
            earlyExecutionDays: this.billEarlyExecutionDays,
          },
        },
      })
        .then(({ data }) => {
          this.pending = false;
          this.$emit('update', data);
        })
        .catch(({ response }) => {
          this.pending = false;
          if (response.data) {
            this.error = response.data;
          }
        });
    },
  },

  created() {
    if (this.plan) {
      this.billTitle = this.plan.title;
      this.billCode = this.plan.code;
      this.billDescription = this.plan.description;
      this.billFrom = moment(this.plan.timeRule.start).format('YYYY-MM-DD');
      this.billTo = this.plan.timeRule.end
        ? moment(this.plan.timeRule.end).format('YYYY-MM-DD')
        : null;
      this.billCategory = this.plan.categoryId;
      this.isEnabled = this.plan.isEnabled;
      this.billPaymentTime = this.plan.daysForPayment;
      this.billRepeatType = this.plan.timeRule.repeatType;
      this.billRepeatNumber = this.plan.timeRule.repeatCount;
      this.billEarlyExecutionDays = this.plan.timeRule.earlyExecutionDays;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
