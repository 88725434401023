<template>
  <div class="container-fluid pt-4">
    <div class="password-box">
      <PasswordUpdate @close="goBack" />
    </div>
    <div class="back-box">
      <a
        href="#"
        @click="goBack"
      >
        <i class="fa-solid fa-chevron-left pr-1" />
        {{ $t('general.back') }}
      </a>
    </div>
  </div>
</template>

<script>
import PasswordUpdate from '@/components/user-settings/PasswordUpdate';

export default {
  components: {
    PasswordUpdate,
  },
  methods: {
    select(name) {
      this.page = name;
    },
    goBack() {
      this.$router.back();
    },
  },
  created() {
    const b = document.getElementsByTagName('body')[0];
    b.style.height = '100%';
  },
  destroyed() {
    const b = document.getElementsByTagName('body')[0];
    b.style.height = null;
  },
};
</script>

<style lang="scss" scoped>
  .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .back-box {
    max-width: 500px;
    margin: auto;
    margin-top: 10px;
  }

  .password-box {
    background-color: white;
    padding: 20px;
    max-width: 500px;
    margin: auto;
    border-radius: 20px;
  }
</style>
