<template>
  <div>
    <div class="settings-box position-relative">
      <div class="position-relative container-small px-0">
        <div class="d-flex settings-title mb-2">
          {{ $t('company.contactInfo') }}

          <TooltipHelp class="pl-3">
            {{ $t('company.contactInfoNotify') }}
          </TooltipHelp>
        </div>

        <label class="control-label mb-0">{{ $t('settings.fullCompanyName') }}:</label>
        <div>
          {{ payee.name }}
        </div>
        <label class="control-label mt-2 mb-0">{{ $t('settings.shortCompanyName') }}:</label>
        <div>
          {{ payee.shortName }}
        </div>

        <template v-if="!edit">
          <label class="control-label mt-2 mb-0">{{ $t('company.contactEmail') }}:</label>
          <div>
            {{ payee.contactEmail || '-' }}
          </div>

          <label class="control-label mt-2 mb-0">{{ $t('company.contactPhone') }}:</label>
          <div>
            {{ payee.contactPhone || '-' }}
          </div>
        </template>
        <template v-else>
          <label class="control-label mt-2 mb-0">{{ $t('company.contactEmail') }}:</label>
          <div>
            <input
              v-model="contactEmail"
              class="form-control"
              :placeholder="$t('company.contactEmail')"
            >
          </div>

          <label class="control-label mt-2 mb-0">{{ $t('company.contactPhone') }}:</label>
          <div>
            <input
              v-model="contactPhone"
              class="form-control"
              :placeholder="$t('company.contactPhone')"
            >
          </div>
        </template>
      </div>

      <div
        v-if="!edit"
        class="mt-2"
      >
        <button
          class="btn btn-sm btn-secondary"
          @click="edit = true"
        >
          <i class="fas fa-pen pr-1" />
          {{ $t('general.edit') }}
        </button>
      </div>
      <div
        v-else
        class="d-flex mt-3"
        style="width: 350px"
      >
        <div class="px-2 w-50">
          <button
            class="btn btn-sm btn-block btn-secondary"
            @click="reset"
          >
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="px-2 w-50">
          <button
            class="btn btn-sm btn-block btn-primary"
            @click="save"
          >
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>
    <div class="settings-box position-relative">
      <div class="d-flex settings-title mb-2">
        {{ $t('company.logo') }}
      </div>

      <div v-show="file">
        <div class="logo-preview-wrapper">
          <img
            ref="preview"
            class="preview-logo"
          >
        </div>

        <div
          v-if="logoError"
          class="text-danger small mt-2"
          style="max-width: 350px;"
        >
          {{ $t('settings.contactData.logoUploadError') }}
        </div>
        <div
          class="d-flex mt-3"
          style="width: 350px"
        >
          <div class="px-2 w-50">
            <button
              class="btn btn-sm btn-block btn-secondary"
              @click="resetLogo"
            >
              {{ $t('general.cancel') }}
            </button>
          </div>
          <div class="px-2 w-50">
            <button
              class="btn btn-sm btn-block btn-primary"
              @click="saveLogo"
            >
              {{ $t('general.save') }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="file" />
      <div v-else-if="payee.logo">
        <img
          :src="`data:image/png;base64,${payee.logo}`"
          class="preview-logo p-2"
        >
      </div>
      <div v-else>
        -
      </div>

      <div
        v-if="!editLogo"
        class="mt-2"
      >
        <button
          class="btn btn-sm btn-secondary"
          @click="editLogo = true"
        >
          <i class="fas fa-pen pr-1" />
          {{ $t('general.edit') }}
        </button>
      </div>
      <div v-else>
        <VueDropzone
          v-if="!file"
          id="drop"
          :options="dropzoneOptions"
          @vdropzone-file-added="uploaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    pending: false,
    edit: false,
    contactPhone: '',
    contactEmail: '',
    file: null,
    logoError: false,
    editLogo: false,
  }),
  components: {
    VueDropzone,
  },
  computed: {
    ...mapGetters(['payee']),
    dropzoneOptions() {
      return {
        url: 'https://api.pagaspot.com',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: this.$t('settings.contactData.uploadLogo'),
        headers: { },
      };
    },
  },
  methods: {
    ...mapActions([
      'setContactInfo',
      'setLogo',
    ]),
    reset() {
      this.edit = false;
      this.contactEmail = this.payee.contactEmail;
      this.contactPhone = this.payee.contactPhone;
    },
    save() {
      this.pending = true;

      this.setContactInfo({
        data: {
          email: this.contactEmail,
          phone: this.contactPhone,
        },
      })
        .then(() => {
          this.reset();
        })
        .finally(() => {
          this.pending = false;
        });
    },
    resetLogo() {
      this.file = null;
      this.logoError = false;
    },
    saveLogo() {
      const formData = new FormData();
      formData.append('logo', this.file);

      this.logoError = false;
      this.pending = true;
      this.setLogo({
        data: formData,
      })
        .then(() => {
          this.resetLogo();
          this.edit = false;
        })
        .catch(() => {
          this.logoError = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    uploaded(file) {
      this.file = file;
      this.logoError = false;

      const reader = new FileReader();
      // it's onload event and you forgot (parameters)
      reader.onload = (e) => {
        const image = this.$refs.preview;
        image.src = e.target.result;
      };
      // you have to declare the file loading
      reader.readAsDataURL(file);
    },
  },
  created() {
    this.$emit('page', 'info');
    this.contactEmail = this.payee.contactEmail;
    this.contactPhone = this.payee.contactPhone;
  },
};
</script>

<style lang="scss" scoped>
  .control-label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .preview-logo {
    max-width: 200px;
    max-height: 100px;
  }

  .logo-preview-wrapper {
    width: 350px;
    text-align: center;
    padding: 10px;
    border: 1px solid #eee;
  }

  #drop {
    border-radius: 20px;
    margin-top: 20px;
    font-family: 'Poppins';
    max-width: 350px;
    height: 90px;
    min-height: 90px;

    :deep(.dz-message) {
      margin: 0;
    }

  }
</style>
