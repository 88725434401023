<template>
  <div class="d-flex">
    <div
      v-for="code in colorCodes"
      :key="code"
      :style="{ 'background-color': colors(code) }"
      class="color-box"
      @click="$emit('update:selected', code)"
    >
      <i
        v-if="selected === code"
        class="fas fa-check"
      />
    </div>
  </div>
</template>

<script>
import colors from '@/utils/colors';

export default {
  props: {
    selected: String,
  },
  data: () => ({
    colorCodes: [
      'default',
      'orange',
      'purple',
      'green',
    ],
  }),
  methods: {
    colors,
  },
};
</script>

<style lang="scss" scoped>
  .color-box {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    box-shadow: 1px 2px 5px #ccc;
    margin-right: 10px;
    text-align: center;
    color: white;
    line-height: 21px;
    border: 3px solid transparent;
    cursor: pointer;

    &:hover {
      border: 3px solid rgba(#333, 0.2);
    }
  }
</style>
