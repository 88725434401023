<template>
  <div class="col-sm-12 px-0">
    <div class="settings-box">
      <div
        class="font-weight-bold d-flex align-items-center"
        style="font-size: 19px;"
      >
        <span>
          {{ $t('settings.billingScope') }}
        </span>
      </div>
      <div class="container-small pt-2">
        <BFormSelect
          v-model="billingScopeType"
          :disabled="true"
          :options="billingScopeTypes"
          @input="setBillingScopeType"
        />
      </div>
    </div>

    <div class="settings-box">
      <div class="section-title">
        <i class="fa-regular fa-box-dollar pr-1" />
        {{ $t('settings.billsCategories') }}
      </div>
      <BillCategories />
    </div>
    <div class="settings-box">
      <div class="section-title">
        <i class="fa-regular fa-building-columns pr-1" />
        {{ $t('settings.bankAccountNumber') }}
      </div>
      <BankAccount />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BillCategories from '@/components/settings/bills/BillCategories';
import BankAccount from '@/components/settings/bills/BankAccount';

export default {
  data: () => ({
    billingScopeType: 'Month',
  }),
  components: {
    BillCategories,
    BankAccount,
  },
  computed: {
    ...mapGetters(['payee']),
    billingScopeTypes() {
      return [
        {
          value: 'Week',
          text: this.$t('time.month'),
        },
        {
          value: 'Month',
          text: this.$t('time.month'),
        },
        {
          value: 'Quarter',
          text: this.$t('time.quarter'),
        },
        {
          value: 'Year',
          text: this.$t('time.year'),
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'setPayeeBillsSetup',
    ]),
    setBillingScopeType(type) {
      this.setPayeeBillsSetup({
        data: {
          billingScopeType: type,
        },
      });
    },
  },
  created() {
    this.$emit('page', 'bills');

    this.billingScopeType = this.payee.billingScopeType;
  },
};
</script>

<style lang="scss" scoped>
.group-border {
  border: 1px solid rgba(#333, 0.1);
  border-radius: 10px;
  padding: 1rem;
}
</style>
