<template>
  <div
    class="bill"
    :class="{ deselected: showAdd }"
  >
    <div class="d-flex">
      <div class="flex-grow-1 align-self-center">
        <div
          class="font-weight-bold"
          :style="{
            'text-decoration': showAdd ? 'line-through' : null
          }"
        >
          {{ name }}
        </div>
        <div class="d-md-flex align-items-center mt-1">
          <div class="bill-payee-name">
            {{ payeeName }}
          </div>
        </div>
      </div>
      <div
        v-if="showRemove"
        class="basket-icon d-flex align-items-center justify-content-center"
        @click="toggle"
      >
        <i class="far fa-trash" />
      </div>
      <div
        v-else-if="showAdd"
        class="basket-icon d-flex align-items-center justify-content-center"
        @click="toggle"
      >
        <i class="far fa-cart-plus" />
      </div>
      <div
        v-else
        class="basket-icon d-flex align-items-center"
        :class="{ selected: basketDonation }"
        @click="toggle"
      >
        <i
          v-if="!basketDonation"
          class="far fa-hand-holding-heart"
        />
        <i
          v-else
          class="far fa-cart-circle-check"
        />
      </div>
    </div>

    <div
      v-if="basketDonation"
      class="d-flex justify-content-end mt-2"
    >
      <div
        class="align-self-center mt-2 d-flex flex-wrap"
        style="max-width: 330px;"
      >
        <div
          v-for="a in amounts"
          :key="a"
          :class="{ selected: basketDonation && basketDonation.value === a && !basketDonation.isCustom }"
          class="amount-box money-font"
          @click="selectDonation({ value: a, isCustom: false })"
        >
          {{ formatCurrency(a, currency) }}
        </div>
        <div
          class="amount-box with-input money-font"
          :class="{ selected: basketDonation && basketDonation.isCustom }"
          @click="selectTextDonation(customValue); focusOnInput()"
        >
          <BFormInput
            v-show="basketDonation.isCustom"
            ref="input"
            v-model="customValue"
          />
          <span
            v-show="basketDonation.isCustom"
            class="pr-1"
          >
            {{ formatCurrency('', currency) }}
          </span>
          <template v-if="!basketDonation.isCustom">
            <span>{{ $t('payment.otherAmount') }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    id: String,
    name: String,
    amounts: Array,
    currency: String,
    gatewayId: String,
    payeeId: String,
    accountNumber: String,
    showRemove: Boolean,
    showAdd: Boolean,
    payeeName: String,
    showOptions: Boolean,
  },
  data: () => ({
    customValue: '',
  }),
  computed: {
    ...mapGetters(['formatCurrency', 'donationInBasket']),
    basketDonation() {
      return this.donationInBasket(this.id);
    },
    donationDefinition() {
      return {
        id: this.id,
        donationId: this.id,
        name: this.name,
        amounts: this.amounts,
        currency: this.currency,
        accountNumber: this.accountNumber,
        payeeId: this.payeeId,
        gatewayId: this.gatewayId,
      };
    },
  },
  watch: {
    customValue(v) {
      this.selectTextDonation(v);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM');
    },
    focusOnInput() {
      setTimeout(() => {
        if (this.$refs.input) {
          this.$refs.input.focus();
        }
      }, 0);
    },
    selectTextDonation(value) {
      const num = Number.parseInt(value, 10);
      if (Number.isNaN(num)) {
        this.selectDonation({ value: 0, isCustom: true });
      } else {
        this.selectDonation({ value: num * 100, isCustom: true });
      }
    },
    selectDonation({ value, isCustom }) {
      this.$store.commit('setDonationBasket', {
        ...this.donationDefinition,
        value,
        isCustom,
      });
    },
    toggle() {
      if (this.basketDonation) {
        this.$store.commit('setDonationBasket', {
          ...this.donationDefinition,
          isCustom: false,
          value: 0,
        });
      } else {
        this.$store.commit('setDonationBasket', {
          ...this.donationDefinition,
          value: this.amounts.length > 0 ? this.amounts[0] : 100,
          isCustom: this.amounts.length === 0,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bill {
  box-shadow: 0 2px 4px #eee, -1px 2px 4px #eee;
  margin-bottom: 10px;
  padding: 10px;
  border-left: 2px solid #888;
  font-size: 14px;
  background-color: white;

  &.selected {
    border-left: 2px solid $blue;
  }

  &.deselected {
    border-left: 2px solid #aaa;
    opacity: 0.6;
  }
}

.amount-box {
  border-radius: 5px;
  border: 1px solid #efefef;
  font-size: 0.75rem;
  width: 108px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 2px;
  margin-right: 2px;
  margin-top: 2px;
  cursor: pointer;

  &.selected {
    border-color: $blue;
    color: $blue;

    input {
      color: $blue;
    }
  }

 &.with-input {
  padding: 0;
 }

  input {
    border: none;
    font-size: 0.75rem;
    height: auto;
    border-radius: 5px;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.bill-payee-name {
  font-size: 12px;
  color: $blue;
  line-height: 1;
}

.bill-payer-name {
  color: #666;
  font-size: 12px;
}

.bill-due-date {
  font-size: 12px;

  &.overdue {
    color: $red;
  }
}

.info-divider {
  padding-left: 10px;
  padding-right: 10px;
  color: #ccc;
}

.basket-icon {
  background-color: #eee;
  padding: 5px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  text-align: center;
  font-size: 20px;
  width: 45px;
  text-align: center;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background-color: #bbb;
  }

  &.selected {
    background-color: $blue;
    color: white;

    &:hover {
      background-color: darken($blue, 10);
    }
  }
}
</style>
