<template>
  <div>
    <div style="margin: 5px">
      <label>
        {{ $t('settings.group.name') }}
      </label>
      <BFormInput
        v-model="name"
        :placeholder="$t('settings.group.name')"
        size="sm"
      />
      <template v-if="!group">
        <label class="mt-2">
          {{ $t('settings.group.parent') }}
        </label>
        <BFormSelect
          v-model="parentCode"
          :state="error ? false : null"
          :options="otherGroups"
          :placeholder="$t('settings.group.parent')"
          size="sm"
        />
      </template>

      <hr>
      <div class="mt-2">
        <div class="d-flex align-items-center">
          <BFormCheckbox
            switch
            class="white-switch white-switch-sm"
            :disabled="pending"
            :checked="controlWithRule"
            @change="controlWithRule = !controlWithRule"
          />
          <div class="ml-n3 mr-2">
            <i class="fas fa-rectangle-code text-secondary" />
          </div>
          <span
            class="small"
            style="line-height: 1.1;"
          >
            {{ $t('groups.controlWithRule') }}
          </span>
        </div>

        <BFormTextarea
          v-if="controlWithRule"
          v-model="script"
          size="sm"
          :rows="1"
          :placeholder="$t('groups.rule')"
          class="money-font mt-3"
        />
        <div
          v-if="controlWithRule"
          class="d-flex justify-content-between align-items-center mt-2"
        >
          <TestRuleExecutor
            :rule="script"
            :group-code="group?.code"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TestRuleExecutor from '@/components/settings/TestRuleExecutor';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    group: Object,
    pending: Boolean,
  },
  data: () => ({
    name: '',
    code: '',
    script: '',
    parentCode: '#all',
    error: false,
    controlWithRule: false,
    ruleResultCount: null,
    testPending: false,
  }),
  components: {
    TestRuleExecutor,
  },
  computed: {
    ...mapGetters(['groups']),
    selectedParentGroup() {
      return this.groups.find((x) => x.code === this.group.parentCode);
    },
    otherGroups() {
      return this.groups
        .filter((x) => !this.group || x.code !== this.group.code)
        .filter((x) => x.code !== '#all')
        .map((x) => ({
          value: x.code,
          text: x.path,
        }))
        .concat([
          {
            value: '#all',
            text: '-',
          },
        ]);
    },
  },
  methods: {
    ...mapActions([
      'removePayersGroup',
      'updatePayersGroup',
      'addPayersGroup',
    ]),
    submit() {
      if (this.group) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      this.$emit('update:pending', true);
      this.error = false;
      this.updatePayersGroup({
        data: {
          name: this.name,
          code: this.group.code,
          rule: this.controlWithRule ? this.script : '',
        },
      })
        .then(() => {
          this.name = '';
          this.parentCode = '';
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    add() {
      this.$emit('update:pending', true);
      this.error = false;
      this.addPayersGroup({
        data: {
          name: this.name,
          parentCode: this.parentCode,
          rule: this.controlWithRule ? this.script : '',
        },
      })
        .then(() => {
          this.name = '';
          this.code = '';
          this.parentCode = '';
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.$emit('update:pending', true);
      this.removePayersGroup({
        params: {
          query: {
            code: this.group.code,
          },
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.group) {
      this.name = this.group.name;
      this.code = this.group.code;
      this.controlWithRule = this.group.rule.length > 0;
      this.script = this.group.rule;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
