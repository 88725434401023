<template>
  <div>{{ name }}</div>
</template>

<script>
export default {
  props: {
    name: String,
  },
};
</script>

<style>

</style>
