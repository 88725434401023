var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"payment-row align-items-center row no-gutters",style:({
      'border-left-color': _vm.color,
      'background-color': _vm.isSelected ? _vm.lightColor : null,
    })},[_c('div',{staticClass:"font-weight-bold",class:_vm.basic ? 'col-sm-6' : 'col-sm-4',staticStyle:{"line-height":"1.2","overflow":"hidden","max-height":"38px"}},[_c('div',[(_vm.bill.recurring)?_c('i',{staticClass:"far fa-calendar pr-1",staticStyle:{"font-size":"12px","position":"relative","top":"-2px"}}):_vm._e(),_c('span',{attrs:{"data-test":"payment-title"}},[_vm._v(" "+_vm._s(_vm.bill.title)+" ")])]),(_vm.category && !_vm.category.isDefault)?_c('div',{staticClass:"bill-details"},[_vm._v(" "+_vm._s(_vm.category.name)+" ")]):_vm._e()]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"progress",style:({
            'background-color': _vm.lightColor
          })},[_c('div',{staticClass:"progress-bar",style:({
              'width': `${_vm.completeRatio}%`,
              'background-color': _vm.color
            })}),_c('div',{staticClass:"bar-percent d-flex align-items-center"},[_vm._v(" "+_vm._s(!_vm.bill.amount ? 100 : _vm.round(100*(_vm.bill.paid / _vm.bill.amount)))+"% ")])])])]),_c('div',{staticClass:"d-none d-md-block col-1 text-right money-font px-2"},[_vm._v(" "+_vm._s(_vm.bill.payersCount)+" ")]),_c('div',{staticClass:"col text-right money-font bill-money"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.bill.amount, _vm.bill.currency))+" ")]),_c('Transition',{attrs:{"name":"column"}},[(!_vm.basic)?_c('div',{key:"sumPaid",staticClass:"col text-right money-font bill-money"},[_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.bill.paid, _vm.bill.currency)))])]):_vm._e()]),_c('Transition',{attrs:{"name":"column"}},[(!_vm.basic)?_c('div',{key:"sumAmount",staticClass:"col text-right money-font bill-money d-none d-md-block"},[_c('span',[_vm._v(_vm._s(_vm.formatMoney(_vm.bill.amount - _vm.bill.paid, _vm.bill.currency)))])]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }