<template>
  <div
    v-if="!add"
    class="d-flex justify-content-end"
  >
    <div
      class="payer-button d-flex align-items-center"
      @click="add = true"
    >
      <i class="fas fa-add ml-2 pr-2" />
      <div class="font-weight-bold">
        {{ $t('transaction.add') }}
      </div>
    </div>
  </div>
  <div
    v-else
    class="payment-inline-form mt-2"
  >
    <div class="font-weight-bold pb-3 pl-2">
      {{ $t('payment.addPayment') }}
    </div>
    <div class="d-flex justify-content-center">
      <div
        class="pr-2"
        style="width: 150px"
      >
        <PaymentTypeDropdown :selected.sync="paymentType" />
      </div>
      <div class="date-time">
        <CtkDateTimePicker
          id="timestamp"
          v-model="timestamp"
          format="YYYY-MM-DD"
          input-size="sm"
          no-clear-button
          auto-close
          formatted="D MMM YYYY"
          :locale="locale"
          no-label
          no-header
          :only-date="true"
        />
      </div>
      <div class="pl-2">
        <MoneyInput
          v-model="paid"
          size="sm"
          :currency="currency"
          style="width: 300px"
          class="money-input"
        />
      </div>
    </div>
    <hr>
    <SaveButton
      :pending="pending"
      style="width: 400px"
      @cancel="cancel"
      @save="save"
    />
  </div>
</template>

<script>
import moment from 'moment';
import SaveButton from '@/components/SaveButton';
import MoneyInput from '@/components/utils/MoneyInput';
import CtkDateTimePicker from 'vue-ctk-date-time-picker';
import { mapActions, mapGetters } from 'vuex';
import PaymentTypeDropdown from './PaymentTypeDropdown';

export default {
  props: {
    payerId: {
      type: String,
      required: true,
    },
    billId: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    toPay: Number,
    billTitle: String,
  },
  data: () => ({
    add: false,
    pending: false,
    paymentType: 'Cash',
    paid: null,
    paymentId: null,
    timestamp: moment().format('YYYY-MM-DD'),
  }),
  components: {
    SaveButton,
    MoneyInput,
    PaymentTypeDropdown,
    CtkDateTimePicker,
  },
  computed: {
    ...mapGetters(['locale']),
  },
  watch: {
    add(v) {
      this.$emit('show', v);
      if (this.toPay > 0) {
        this.paid = this.toPay;
      }
    },
  },
  methods: {
    ...mapActions(['addPayment', 'settlePayment']),
    async save() {
      try {
        this.pending = true;
        if (!this.paymentId) {
          const { data } = await this.addPayment({
            data: {
              type: this.paymentType,
              timestamp: this.timestamp,
              title: `${this.$t('payment.for')} ${this.billTitle}`,
              value: this.paid,
              currency: this.currency,
            },
          });

          this.paymentId = data.id;
        }

        await this.settlePayment({
          params: {
            paymentId: this.paymentId,
          },
          data: {
            bills: [{
              value: this.paid,
              payerId: this.payerId,
              billId: this.billId,
            }],
            overpayments: [],
            refunds: [],
          },
        });

        this.$emit('refresh');
        this.cancel();
      } catch (e) {
        this.error = e;
      }
      this.pending = false;
    },
    cancel() {
      this.paymentId = null;
      this.add = false;
    },
  },
  created() {
    if (this.toPay > 0) {
      this.paid = this.toPay;
    }
  },
};
</script>

<style lang="scss" scoped>
.payment-inline-form {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 10px;
}

.date-time::v-deep #timestamp-input {
  border-radius: 16px !important;
  min-height: auto;
  height: calc(1.8125rem + 2px);
}

.payer-button {
  border: 1px solid #eee;
  background-color: #fbfbfb;
  padding: 2px 15px 2px 5px;
  border-radius: 15px;
  margin-top: 5px;
  cursor: pointer;
  position: relative;
  min-width: 300px;

  &:hover {
   background-color: #efefef;
  }

  &.selected {
    border-color: $blue;
  }
}
</style>
