<template>
  <div>
    <div class="page-title-wrapper">
      <div
        class="page-title"
        :class="{ active: page === 'messages' }"
        @click="showMessages"
      >
        {{ $t('menu.messages') }}
      </div>
      <div
        class="page-title ml-4"
        :class="{ active: page === 'notifications' }"
        @click="showNotifications"
      >
        {{ $t('menu.notifications') }}
      </div>
    </div>

    <div class="mt-2">
      <RouterView
        @page="page = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    page: '',
  }),
  computed: {
    ...mapGetters(['payeeId']),
  },
  methods: {
    showMessages() {
      this.$router.push(`/payee/${this.payeeId}/messages/messages`);
    },
    showNotifications() {
      this.$router.push(`/payee/${this.payeeId}/messages/notifications`);
    },
  },
  created() {
    this.$emit('page', 'messages');
  },
};
</script>

<style lang="scss" scoped>

</style>
