<template>
  <div>
    <div
      v-for="p in (propertiesDefinitions || properties)"
      :key="p.code"
      class="row no-gutters align-items-center property-row"
      :class="{ edited: !readOnly }"
    >
      <div class="col-4 property-name mb-0 small text-secondary d-flex align-items-center">
        <i :class="getIcon(p)" />
        <div>
          {{ p.name }}
        </div>
      </div>
      <div
        v-if="!readOnly"
        class="col-8 d-flex align-items-center"
      >
        <Checkbox
          v-if="formatType(p) === 'checkbox'"
          :value="!!values[p.code] && values[p.code] === '1'"
          @update:value="$set(values, p.code, $event ? '1' : '0'); update()"
        />
        <BFormSelect
          v-else-if="formatType(p) === 'dropdown'"
          v-model="values[p.code]"
          :options="options(p)"
          @input="update"
        />
        <MultiselectDropdown
          v-else-if="formatType(p) === 'multiselect'"
          v-model="values[p.code]"
          :options="options(p)"
          @input="update"
        />

        <BFormInput
          v-else
          v-model="values[p.code]"
          :placeholder="getPlaceholder(p.format, p.name, p.type)"
          :state="validation[p.code]"
          @blur="update"
          @focus="initField(p)"
          @input="input($event, p)"
        />
      </div>
      <div
        v-else
        class="small"
      >
        <PropertyValue
          :code="p.code"
          :value="values[p.code]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import bankAccountValid from '@/utils/bank-account';
import { accountNumber } from '@/utils/filters';
import { mapGetters } from 'vuex';
import PropertyValue from '../PropertyValue';
import MultiselectDropdown from './MultiselectDropdown';

export default {
  props: {
    propertyValues: Array,
    propertiesDefinitions: Array,
    readOnly: Boolean,
    live: Boolean,
  },
  data: () => ({
    values: {},
    validation: {},
  }),
  components: {
    PropertyValue,
    MultiselectDropdown,
  },
  computed: {
    ...mapGetters([
      'properties',
      'payersPropertyTypes',
    ]),
  },
  watch: {
    propertiesDefinitions() {
      this.values = {};

      this.propertyValues.forEach((x) => {
        this.$set(this.values, x.key, x.value);
      });

      (this.propertiesDefinitions || this.properties)
        .forEach((x) => {
          this.formatField(x.code, x.type, this.values[x.code]);
        });
    },
  },
  methods: {
    initField({ code, type, format }) {
      if (!format || this.values[code]) return;
      this.formatField(code, type, format);
    },
    formatType(v) {
      return (v.format || '').split(':')[0];
    },
    options(v) {
      const formatParts = (v.format || '').split(':');
      const formatType = formatParts[0];
      if (formatParts.length > 1
        && (formatType === 'dropdown' || formatType === 'multiselect')) {
        return formatParts[1].split('|');
      }
      return [];
    },
    formatField(code, type, value) {
      if (!value) {
        this.$set(this.values, code, '-');
      }

      if (type === 'BankAccount') {
        this.$set(this.values, code, accountNumber(value));
      }
    },
    getPlaceholder(format, name, type) {
      if (type === 'BankAccount') {
        return accountNumber(format);
      }

      return format || name;
    },
    getIcon({ type, format }) {
      if (format.startsWith('checkbox')) {
        return 'far fa-square-check';
      }
      if (format.startsWith('dropdown')) {
        return 'far fa-square-caret-down';
      }
      if (format.startsWith('multiselect')) {
        return 'far fa-list-check';
      }

      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
    input(value, { code, type, format }) {
      if (type === 'BankAccount') {
        this.$set(this.validation, code, bankAccountValid(format, value));
      }

      if (this.live) {
        this.update();
      }
    },
    update() {
      const values = Object.keys(this.values)
        .map((x) => ({
          key: x,
          value: this.values[x],
        }));

      this.$emit('update:propertyValues', values);
    },
  },
  created() {
    this.propertyValues.forEach((x) => {
      this.$set(this.values, x.key, x.value);
    });

    (this.propertiesDefinitions || this.properties)
      .forEach((x) => {
        this.formatField(x.code, x.type, this.values[x.code]);
      });
  },
};
</script>

<style lang="scss" scoped>

.property-row {
  min-height: 20px;
  padding: 5px;

  &:hover {
    background-color: #f6f6f6;
  }

  &.edited {
    min-height: 38px;
  }

  input {
    font-size: 0.9rem;
    height: 100%;
  }
}

.property-name {
  line-height: 1.1;
  padding-right: 2px;

  i {
    width: 15px;
    margin-right: 6px;
    text-align: center;
  }
}

.payer-property {
  background-color: #fbfbfb;
  border-radius: 10px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
}
</style>
