<template>
  <div>
    <div
      v-for="category in categories"
      :key="category.id"
      class="d-flex align-items-center category-select"
      :class="{
        selected: enabledCategories[category.id],
        readonly,
      }"
      @click="toggle(category.id)"
    >
      <i
        v-if="enabledCategories[category.id]"
        :style="{ color: categoryColor(category.id) }"
        class="fas fa-circle-check mr-2"
      />
      <i
        v-else
        class="far fa-circle mr-2"
      />
      <div
        class="flex-grow-1"
      >
        {{ category.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: Array,
    readonly: Boolean,
  },
  data: () => ({
    enabledCategories: {},
  }),
  computed: {
    ...mapGetters(['payee', 'categoryColor']),
    categories() {
      if (this.readonly) {
        return (this.payee?.categories || [])
          .filter((x) => this.selected.includes(x.id));
      }

      return (this.payee?.categories || [])
        .filter((x) => x.isActive);
    },
  },
  watch: {
    selected(v) {
      v.forEach((x) => {
        if (this.categories.some((c) => c.id === x)) {
          this.$set(this.enabledCategories, x, true);
        }
      });
    },
  },
  methods: {
    toggle(id) {
      if (this.readonly) return;
      this.$set(this.enabledCategories, id, !this.enabledCategories[id]);

      const list = Object.entries(this.enabledCategories)
        .filter((e) => e[1])
        .map(([k]) => k);

      this.$emit('update:selected', list);
    },
  },
  created() {
    this.selected.forEach((x) => {
      if (this.categories.some((c) => c.id === x)) {
        this.$set(this.enabledCategories, x, true);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.category-select {
  border: 2px solid #eee;
  border-radius: 10px;
  padding: 3px 10px;
  margin-top: 5px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: rgba(#aaa, 0.1);
    border-color: #aaa;
  }

  &.readonly {
    cursor: default;
  }
  &.readonly:hover {
    background-color: #fff;
    border: 2px solid #eee;
  }
}
</style>
