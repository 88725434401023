<template>
  <div>
    <hr class="mt-1 mb-2">
    <div
      v-if="orders.length > 0"
      class="d-flex align-items-center"
      style="height: 28px"
    >
      <div
        class="mr-2 checkbox align-self-center"
        @click="checkAll(orders)"
      >
        <Checkbox
          :value="allChecked(orders)"
          :emit="false"
          style="position: relative; margin-left: -5px"
          class="px-0"
        />
      </div>

      <div
        v-if="checkedOrders.length > 0"
        class="flex-grow-1 d-flex align-items-center"
      >
        <Tippy
          ref="archive-popup"
          trigger="click"
          theme="light"
          arrow
          interactive
        >
          <template #trigger>
            <button
              v-tippy
              :content="$t('shop.cancel')"
              class="btn btn-sm btn-outline-primary icon-btn mx-1"
            >
              <i class="fas fa-ban" />
            </button>
          </template>

          <button
            class="btn btn-primary btn-sm btn-block"
            :disabled="pendingCancel"
            @click="cancelCheckedOrders"
          >
            <i class="fas fa-ban pr-2" />
            {{ $t('shop.cancel') }}
          </button>
        </Tippy>
        <div class="pl-2 small text-primary">
          {{ $t('shop.orders') }}: {{ checkedCount }}
        </div>
      </div>
      <div
        v-else
        class="flex-grow-1"
      />
      <div class="money-font pr-2">
        {{ totalCount }}
      </div>
      <div
        class="money-font text-right column-divider pr-2"
        style="margin-right: 42px; width: 150px"
      >
        {{ formatCurrency(totalPrice.price, totalPrice.currency) }}
      </div>
    </div>
    <hr
      v-if="aggregatedOrders.length > 0"
      class="mb-0 mt-2"
    >
    <div
      v-if="aggregatedOrders.length === 0"
      class="text-center py-3 text-secondary"
    >
      {{ $t('shop.noOrders') }}
    </div>
    <div class="orders-list-body">
      <div
        v-for="p in aggregatedOrders"
        :key="p.payerId"
      >
        <div
          class="d-flex set-row no-padding"
        >
          <div
            class="checkbox set-row-hover d-flex align-items-center"
            @click="checkAll(p.orders)"
          >
            <Checkbox
              :value="allChecked(p.orders)"
              :emit="false"
              style="position: relative; margin-left: -5px"
              class="px-0"
            />
          </div>
          <div
            class="d-flex set-row-hover payer-row-content align-items-center flex-grow-1"
            @click="$set(opened, p.id, !opened[p.id])"
          >
            <div
              v-if="groupBy === 'productSets'"
              class="flex-grow-1"
            >
              <i class="fas fa-boxes-stacked text-secondary pr-1" />
              {{ p.set.name }}
            </div>
            <div
              v-else
              class="flex-grow-1"
            >
              <PayerName v-bind="p.payer" />
            </div>
            <div class="money-font px-2">
              {{ p.totalCount }}
            </div>
            <div
              class="money-font text-right column-divider pr-2"
              style="width: 150px"
            >
              {{ formatCurrency(p.totalPrice, p.currency) }}
            </div>
            <div
              class="collapse-icon column-divider pl-2"
            >
              <i
                v-if="opened[p.id]"
                class="fa-solid fa-angle-down"
              />
              <i
                v-else
                class="fa-solid fa-angle-right"
              />
            </div>
          </div>
        </div>
        <template v-if="opened[p.id]">
          <div
            v-for="o in p.orders"
            :key="`${o.day}_${o.payerId}_${o.availabilityId}_${o.orderId}`"
            class="d-flex set-row set-row-hover align-items-center"
            @click="toggle(o, !isChecked(o))"
          >
            <div class="mr-2 checkbox">
              <Checkbox
                :value="isChecked(o)"
                :emit="false"
                style="position: relative; margin-left: -5px"
                class="px-0"
              />
            </div>
            <div
              v-if="!singleOrderSchema"
              style="width: 50px"
              class="money-font small font-weight-normal"
            >
              {{ o.formattedDay }}
            </div>

            <div
              class="flex-grow-1 small d-flex align-items-center"
              :class="{
                'column-divider': !singleOrderSchema
              }"
            >
              <PayerName
                v-if="groupBy === 'productSets'"
                v-bind="o.payer"
                class="pl-2"
              />
              <span v-else>
                {{ o.set.name }}
              </span>

              <i
                v-if="!o.isPaid"
                v-tippy
                :content="$t('shop.notPaid')"
                class="fas fa-hand-holding-dollar text-warning pl-2"
              />
            </div>
            <div class="small pr-2">
              {{ o.comment }}
            </div>
            <div
              class="money-font column-divider text-right px-2"
              style="width: 60px;"
            >
              <span>{{ o.count }}</span>
            </div>
            <div
              class="money-font column-divider text-right pr-2 d-flex align-items-center justify-content-end"
              style="width: 150px"
            >
              <span v-if="o.discountName">
                <Tippy>

                  <template #trigger>
                    <i class="fas fa-badge-percent text-primary pr-2" />
                  </template>

                  {{ o.discountName }}
                  <div class="text-strikethrough">
                    {{ formatCurrency(o.priceBeforeDiscount, o.currency) }}
                  </div>

                </Tippy>
              </span>

              {{ formatCurrency((o.unitPrice * o.count), o.currency) }}
            </div>
            <div class="collapse-icon" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    orders: Array,
    payers: Array,
    productSets: Array,
    dates: Object,
    groupBy: String,
  },
  data: () => ({
    pendingDownload: false,
    selectedProductSetId: null,
    section: 'orders',
    opened: {},
    checked: {},
    pendingCancel: false,
  }),
  components: {
    PayerName,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    ...mapGetters('shop', ['singleOrderSchema', 'catalogDetails']),
    checkedOrders() {
      return this.orders.filter((o) => this.isChecked(o));
    },
    checkedCount() {
      return this.checkedOrders.reduce((acc, curr) => acc + curr.count, 0);
    },
    menuItems() {
      return [
        {
          route: 'sets',
          text: this.$t('shop.productSets'),
        },
        {
          route: 'orders',
          text: this.$t('shop.orders'),
        },
      ];
    },
    totalPrice() {
      return this.orders.reduce((acc, curr) => {
        acc.currency = curr.currency;
        acc.price += (curr.unitPrice * curr.count);
        return acc;
      }, { price: 0, currency: '' });
    },
    totalCount() {
      return this.orders.reduce((acc, curr) => acc + curr.count, 0);
    },
    selected() {
      if (!this.selectedProductSetId) return null;
      return this.ordersAggregated[this.selectedProductSetId];
    },
    aggregatedOrders() {
      let aggregated = this.ordersByPayers;

      if (this.groupBy === 'productSets') {
        aggregated = this.ordersBySets;
      }

      // Group orders by payer, availability and day
      // There maybe multiple orders in one group - order count is added
      return aggregated
        .map((x) => ({
          ...x,
          orders: Object.values(x.orders.reduce((acc, curr) => {
            const key = `${curr.payerId}_${curr.availabilityId}_${curr.day}_${curr.isPaid}`;
            if (acc[key]) {
              acc[key].count += curr.count;
            } else {
              acc[key] = curr;
            }
            return acc;
          }, {})),
        }));
    },
    ordersByPayers() {
      return Object.values(this.orders
        .reduce((acc, curr) => {
          if (!acc[curr.payerId]) {
            acc[curr.payerId] = {
              id: curr.payerId,
              payerId: curr.payerId,
              payer: this.payers.find((x) => x.id === curr.payerId),
              orders: [],
              totalPrice: 0,
              totalCount: 0,
              currency: curr.currency,
            };
          }

          acc[curr.payerId].totalPrice += (curr.unitPrice * curr.count);
          acc[curr.payerId].totalCount += curr.count;
          acc[curr.payerId].orders.push({
            ...curr,
            set: this.productSets.find((x) => x.id === curr.productSetId) || {},
            formattedDay: moment(curr.day, 'YYYY-MM-DD').format('DD/MM'),
          });

          return acc;
        }, {}))
        .sort((a, b) => a.payer.name.localeCompare(b.payer.name));
    },
    ordersBySets() {
      return Object.values(this.orders
        .reduce((acc, curr) => {
          if (!acc[curr.productSetId]) {
            acc[curr.productSetId] = {
              id: curr.productSetId,
              set: this.productSets.find((x) => x.id === curr.productSetId) || {},
              orders: [],
              totalPrice: 0,
              currency: curr.currency,
              totalCount: 0,
            };
          }

          acc[curr.productSetId].totalPrice += (curr.unitPrice * curr.count);
          acc[curr.productSetId].totalCount += curr.count;

          acc[curr.productSetId].orders.push({
            ...curr,
            payer: this.payers.find((x) => x.id === curr.payerId) || {},
            formattedDay: moment(curr.day, 'YYYY-MM-DD').format('DD/MM'),
          });
          return acc;
        }, {}))
        .sort((a, b) => (a.set.name || '').localeCompare(b.set.name || ''));
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateOrders',
    ]),
    isChecked(o) {
      return this.checked[`${o.payerId}_${o.day}_${o.availabilityId}_${o.isPaid}`];
    },
    toggle(o, value) {
      this.$set(this.checked, `${o.payerId}_${o.day}_${o.availabilityId}_${o.isPaid}`, value);
    },
    cancelCheckedOrders() {
      this.pendingCancel = true;
      this.updateOrders({
        data: {
          catalogId: this.catalogDetails.id,
          cancelledItems: this.checkedOrders.map((x) => ({
            day: x.day,
            payerId: x.payerId,
            availabilityId: x.availabilityId,
            count: 1,
          })),
        },
      })
        .then(() => {
          this.$emit('refresh');
        })
        .finally(() => {
          this.pendingCancel = false;
        });
    },
    productSetName(productSetId) {
      return this.productSets.find((x) => x.id === productSetId)?.name || '?';
    },
    allChecked(payerOrders) {
      return payerOrders.every((o) => this.isChecked(o));
    },
    checkAll(payerOrders) {
      const deselect = this.allChecked(payerOrders);

      payerOrders.forEach((o) => {
        this.toggle(o, !deselect);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.set-row {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px 5px 5px;
  cursor: pointer;

  &.no-padding {
    padding: 0px;
  }

  .checkbox {
    border-right: 1px solid rgba(100, 100, 100, 0.2);
  }

  &.first {
    border-top: 1px solid #ddd;
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

.orders-list-body {
  height: calc(100vh - 250px);
  overflow: auto;
  padding-bottom: 50px;
}

.payer-row-content {
  padding: 5px 10px 5px 5px;
}

.column-divider {
  border-left: 1px solid rgba(100, 100, 100, 0.1);
}

.set-row-hover {
  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }
}

.money-font {
  font-size: 0.9rem;
}

.collapse-icon {
  width: 27px;
  text-align: center;
  margin-left: 5px;
  line-height: 30px;
  display: flex;
  align-items: center;

  i {
    background-color: #f6f6f6;
    padding: 5px;
    border-radius: 50%;
    width: 22px;
    min-width: 22px;
    height: 22px;
    line-height: 12px;
    font-size: 15px;
    color: #666;
  }
}

.header-row {
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  color: #999;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
}

.orders-count {
  width: 120px;
  font-weight: 400;
  border-radius: 5px;
  text-align: right;
}

.orders-paid {
  color: $blue;
  text-align: right;
  width: 120px;
}

.orders-unpaid {
  color: $orange;
  text-align: right;
  width: 120px;
}
</style>
