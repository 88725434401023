<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
    @show="opened"
  >
    <template #trigger>
      <slot name="trigger">
        <div class="sort-option-selected">
          <div
            class="font-weight-bold text-secondary"
            style="font-size: 10px; min-width: 180px"
          >
            {{ $t('groups.group') }}
          </div>
          <div
            v-if="group"
            class="first d-flex pr-2"
            style="font-size: 14px"
          >
            <div class="flex-grow-1">
              {{ group.path }}
            </div>
            <span
              v-if="!hideSize"
              class="pl-2 text-secondary"
            >
              {{ group.size }}
            </span>
          </div>
          <div v-else>
            ?
          </div>
        </div>
      </slot>
    </template>

    <div style="padding-bottom: 5.5px">
      <SearchBox
        ref="search"
        v-model="search"
        :height="30"
        constant-width
      />
    </div>
    <div class="dropdown-scroll">
      <PayersGroupFilterList
        :groups="filteredGroupOptions"
        :select="select"
        :selected="selected"
        :root-id="'#all'"
        :hide-size="hideSize"
        :expand="!!search"
      />
    </div>
    <div
      v-if="!hideEdit"
      class="sort-option text-left text-secondary font-weight-bold"
      @click="$emit('edit'); $refs.popover.tippy().hide()"
    >
      <i class="fas fa-pen pr-2" />
      {{ $t('general.edit') }}
    </div>
  </Tippy>
</template>

<script>
import createSearch from '@/utils/search';
import { mapGetters } from 'vuex';
import PayersGroupFilterList from './PayersGroupFilterList';

export default {
  props: {
    selected: String,
    hideSize: Boolean,
    hideEdit: Boolean,
  },
  data: () => ({
    search: '',
  }),
  components: {
    PayersGroupFilterList,
  },
  computed: {
    ...mapGetters(['groups']),
    group() {
      return this.groupOptions.find((x) => x.key === this.selected);
    },
    filteredGroupOptions() {
      if (!this.search) {
        return this.groupOptions;
      }

      const search = createSearch(this.search);
      const filteredGroups = this.groupOptions.filter((x) => search(x.path));

      const foundCodes = filteredGroups
        .flatMap((x) => this.getParents(x.code));

      return this.groupOptions
        .filter((g) => foundCodes.includes(g.code));
    },
    groupOptions() {
      return (this.groups || [])
        .map((x) => ({
          key: x.code,
          code: x.code,
          text: x.code === '#all'
            ? this.$t('company.all')
            : x.name,
          path: x.code === '#all'
            ? this.$t('company.all')
            : x.path,
          size: x.size,
          parentCode: x.parentCode,
        }));
    },
  },
  watch: {
    groups(g) {
      const selected = g.find((x) => x.code === this.selected);
      if (!selected) {
        this.$emit('update:selected', '#all');
      }
    },
  },
  methods: {
    getParents(code) {
      if (!code || code === '#all') {
        return [];
      }

      const group = this.groupOptions.find((x) => x.code === code);
      return [code, ...this.getParents(group.parentCode)];
    },
    select(key) {
      this.$emit('update:selected', key);
      this.$refs.popover.tippy().hide();
    },
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
 @import './style.scss';
</style>
