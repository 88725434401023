<template>
  <div>
    <div
      v-if="count > 0"
      class="text-center mb-2"
    >
      <Loader
        v-if="results.length != count"
      />
      <div
        v-else
        class="text-success"
      >
        <i class="fa-solid fa-circle-check" />
      </div>
      <div class="font-weight-bold">
        {{ Math.floor(100*(results.length / count)) }}%
      </div>
      <div class="small">
        {{ results.length }} / {{ count }}
      </div>
    </div>
    <div
      v-for="r in resultsWithPayers"
      :key="`${r.payerId}_${r.currency}`"
      class="d-flex payer-row mb-1"
    >
      <div class="flex-grow-1">
        <PayerName
          v-bind="r.payer"
        />
      </div>
      <div
        v-if="!r.error"
        class="text-success"
      >
        <i class="fa-solid fa-file-check pr-1" />
        <span class="money-font">
          {{ r.documentId }}
        </span>
      </div>
      <div
        v-else
        class="text-danger"
      >
        <i class="fa-solid fa-circle-exclamation pr-1" />
        <span class="money-font">
          {{ r.documentId }}
        </span>

        {{ $t('error.error') }}
      </div>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import { mapActions } from 'vuex';

export default {
  props: {
    jobId: String,
    count: Number,
    payers: Array,
  },
  data: () => ({
    results: [],
  }),
  components: {
    PayerName,
  },
  computed: {
    resultsWithPayers() {
      return this.results
        .map((x) => ({
          ...x,
          payer: this.payers.find((p) => p.id === x.payerId),
        }))
        .sort((a, b) => a.documentId.localeCompare(b.documentId));
    },
  },
  methods: {
    ...mapActions(['getGeneratedBillingDocuments']),
    refresh() {
      this.getGeneratedBillingDocuments({
        params: {
          query: {
            jobId: this.jobId,
          },
        },
      })
        .then(({ data }) => {
          this.results = data;
        })
        .finally(() => {
          this.timeout = setTimeout(() => {
            this.refresh();
          }, 3000);
        });
    },
  },
  created() {
    this.refresh();
  },
  destroyed() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
</script>

<style lang="scss" scoped>
.payer-row {
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 10px;
}
</style>
