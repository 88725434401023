<template>
  <div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6 form-cell">
        <label>{{ $t('client.firstName') }}</label>
        <BFormInput
          v-model="firstName"
          :placeholder="$t('client.firstName')"
        />
      </div>
      <div class="col-12 col-md-6 form-cell">
        <label>{{ $t('client.lastName') }}</label>
        <BFormInput
          v-model="lastName"
          :placeholder="$t('client.lastName')"
        />
      </div>
      <div class="col-12 col-md-6 form-cell">
        <label>{{ $t('client.clientCode') }}</label>
        <BFormInput
          v-model="code"
          :placeholder="$t('client.clientCode')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: Object,
  },
  data: () => ({
    firstName: '',
    lastName: '',
    code: '',
  }),
  watch: {
    firstName() {
      this.update();
    },
    lastName() {
      this.update();
    },
    code() {
      this.update();
    },
  },
  methods: {
    update() {
      this.$emit('update:description', {
        firstName: this.firstName,
        lastName: this.lastName,
        code: this.code,
      });
    },
  },
  created() {
    if (this.description) {
      this.firstName = this.description.firstName;
      this.lastName = this.description.lastName;
      this.code = this.description.code;
    }
  },
};
</script>

<style lang="scss" scoped>
.form-cell {
  padding: 5px;
}
</style>
