<template>
  <div class="basket-group">
    <div class="d-flex justify-content-between">
      <div class="d-block d-md-flex align-items-center pb-3 header">
        <div class="font-weight-bold">
          {{ payeeName || '?' }}
        </div>
        <div
          v-if="accountNumber && accountNumber.length > 1"
          class="text-secondary px-2 account-number d-none d-md-flex"
        >
          |
        </div>
        <div
          v-if="accountNumber && accountNumber.length > 1"
          class="text-secondary account-number"
        >
          {{ $t('payment.accountNumber') }}:
          <span class="money-font">
            {{ formatBankAccount(accountNumber) }}
          </span>
        </div>
      </div>
      <div>
        <button
          v-if="toggable && gateway && list.length > 1 && !payAllLimitExceeded"
          class="btn btn-sm btn-outline-primary mb-3"
          :disabled="initPending"
          @click="payAll"
        >
          {{ $t('client.payAll') }}
        </button>
      </div>
    </div>
    <div>
      <template
        v-for="(b, i) in sortedList"
      >
        <Bill
          v-if="b.billId"
          :key="i"
          v-bind="b"
          :paid="0"
          :amount="b.value"
          :payer="getPayer(b.payerId)"
          :show-remove="!toggable"
        />
        <Donation
          v-else-if="b.donation"
          v-bind="b"
          :key="b.id"
          :payee-name="payeeName"
          :show-remove="!toggable"
        />
        <CatalogItem
          v-else-if="catalog"
          :key="i"
          :payer="getPayer(b.payerId)"
          v-bind="b"
        />
        <ShopItem
          v-else
          :key="i"
          v-bind="b"
        />
      </template>
      <template v-if="gateway && !gateway.payeePaysCommission">
        <div class="internal-payment-info text-wrap pt-1">
          <span
            v-if="totalValue > 0"
            v-html="$t('client.internalPaymentInfo', { n: commission })"
          />
          <span v-else>
          &nbsp;
          </span>
        </div>
      </template>
    </div>
    <div class="d-flex justify-content-end">
      <div
        v-if="billsSum > 0"
        class="small text-secondary"
      >
        {{ $t('client.bills') }}
        {{ formatCurrency(billsSum, currency) }}
      </div>
    </div>
    <hr class="mb-2">

    <div
      v-if="type === 'shop' && !accepted"
      class="text-right"
    >
      <BasketItemSummary
        :list="list"
        :payee-id="payeeId"
        :single-group="true"
        @topay="toPay = $event"
        @result="$emit('result', $event)"
      />
    </div>
    <div
      v-else-if="gateway"
      class="text-right pt-2"
    >
      <div
        v-if="selectedLimitExceeded"
        class="text-danger mb-2"
        style="font-size: 12px"
        v-html="$tc('client.overLimit', priceLimit)"
      />
      <button
        v-if="!payUrl"
        class="btn btn-sm btn-primary"
        :disabled="initPending || totalValue === 0"
        @click="pay"
      >
        {{ $t('client.pay') }}:
        <span class="money-font ml-1">
          {{ formatCurrency(totalValue + (commission * 100), currency) }}
        </span>
      </button>
      <a
        v-else
        :href="payUrl"
        target="_blank"
        rel="noreferrer noopener"
      >
        <button
          class="btn btn-sm btn-primary"
        >
          {{ $t('client.pay') }}:
          <span class="money-font ml-1">
            {{ formatCurrency(totalValue + (commission * 100), currency) }}
          </span>
          <i class="fa-regular fa-link" />
        </button>
      </a>
    </div>
    <div
      v-else
    >
      <div style="font-size: 14px">
        {{ $t('client.gatewayDisabled') }}
      </div>
      <hr class="my-1">
      <div class="d-flex justify-content-between mt-2 small">
        <div>
          {{ $t('payment.accountNumber') }}:
        </div>
        <div class="money-font">
          {{ formatBankAccount(accountNumber) }}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-1 small">
        <div>
          {{ $t('client.receiver') }}:
        </div>
        <div class="money-font">
          {{ payeeName || '?' }}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-1 small">
        <div>
          {{ $t('payment.amount') }}:
        </div>
        <div class="money-font">
          {{ formatCurrency(totalValue, currency) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { accountNumber } from '@/utils/filters';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Bill from './Bill';
import Donation from './Donation';
import BasketItemSummary from './shop/BasketItemSummary';
import CatalogItem from './shop/CatalogItem';
import ShopItem from './shop/ShopItem';

export default {
  props: {
    payeeId: String,
    accountNumber: String,
    list: Array,
    catalog: Object,
    gatewayId: String,
    currency: String,
    payeeSetup: Object,
    toggable: Boolean,
    notifyPay: Object,
    type: String,
    payers: Array,
    donations: Array,
  },
  data: () => ({
    initPending: false,
    payUrl: null,
    accepted: false,
    orderDetails: null,
    orderPending: false,
    orderError: null,
    toPay: 0,
  }),
  components: {
    BasketItemSummary,
    ShopItem,
    CatalogItem,
    Donation,
    Bill,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'billInBasket', 'userPayers', 'donationInBasket']),
    selectedLimitExceeded() {
      if (!this.gateway || this.gateway.commissionType.toLowerCase() !== 'OneForThousand'.toLowerCase()) {
        return false;
      }

      return this.priceLimit * 100 < this.totalValue + (this.commission * 100);
    },
    payAllLimitExceeded() {
      if (!this.gateway || this.gateway.commissionType.toLowerCase() !== 'OneForThousand'.toLowerCase()) {
        return false;
      }

      return this.priceLimit * 100 < this.billsSum;
    },
    billsSum() {
      return this.sortedList.reduce((acc, curr) => acc + curr.value, 0);
    },
    priceLimit() {
      return this.gateway.commissionType.toLowerCase() === 'OneForThousand'.toLowerCase()
        ? 4600
        : Number.MAX_SAFE_INTEGER;
    },
    sortedList() {
      if (this.type === 'shop') {
        return this.list.slice()
          .sort((a, b) => moment(a.day, 'YYYY-MM-DD').unix() - moment(b.day, 'YYYY-MM-DD').unix());
      }
      return this.list.concat(this.donations.map((x) => ({ ...x, donation: true })));
    },
    totalValue() {
      if (this.type === 'shop' || this.catalog) {
        return this.list.reduce((acc, curr) => curr.value + acc, 0);
      }
      return this.billsToPay.reduce((acc, curr) => acc + curr.value, 0)
        + this.donationsBasket.reduce((acc, curr) => acc + curr.value, 0);
    },
    billsToPay() {
      return this.list.filter((x) => this.billInBasket(x));
    },
    donationsBasket() {
      return this.donations
        .map((x) => ({
          ...x,
          value: this.donationInBasket(x.id)?.value,
        }))
        .filter((x) => x.value && x.value > 0);
    },
    commission() {
      if (!this.gateway || this.gateway.payeePaysCommission || this.totalValue === 0) return 0;

      if (this.gateway.commissionType.toLowerCase() === 'OneForThousand'.toLowerCase()) {
        if (this.totalValue <= 1000 * 100) {
          return 1;
        } if (this.totalValue <= 2000 * 100) {
          return 2;
        }
        return 3;
      }

      const percentage = parseFloat(this.gateway.commissionValue);

      return Math.ceil((this.totalValue * percentage) / (1 - percentage)) / 100;
    },
    payeeName() {
      return this.payeeSetup?.payeeName;
    },
    gateway() {
      return this.payeeSetup.gateways.find((y) => y.gatewayId === this.gatewayId);
    },
  },
  watch: {
    totalValue() {
      this.payUrl = null;
    },
  },
  methods: {
    ...mapActions([
      'paymentInit',
      'notifyPaymentStart',
    ]),
    formatBankAccount: accountNumber,
    getPayer(payerId) {
      if (this.payers) {
        return this.payers.find((x) => x.payerId === payerId);
      }

      return this.userPayers.find((x) => x.payerId === payerId);
    },
    payAll() {
      this.initPending = true;
      this.notifyPaymentStart({
        params: this.notifyPay,
        data: {
          payeeId: this.payeeId,
          basket: this.list.map((x) => ({
            payerId: x.payerId,
            billId: x.billId,
          })),
        },
      })
        .then(({ data }) => {
          this.payUrl = data.url;
          window.open(data.url, '_blank').focus();
        })
        .finally(() => {
          this.initPending = false;
        });
    },
    pay() {
      this.initPending = true;
      const firstPayer = this.payeeSetup.payerId;

      if (this.notifyPay) {
        this.notifyPaymentStart({
          params: this.notifyPay,
          data: {
            payeeId: this.payeeId,
            basket: this.billsToPay.map((x) => ({
              payerId: x.payerId,
              billId: x.billId,
            })),
            donations: this.donationsBasket.map((x) => ({
              payerId: firstPayer,
              donationId: x.id,
              value: x.value,
            })),
          },
        })
          .then(({ data }) => {
            this.payUrl = data.url;
            window.open(data.url, '_blank').focus();
          })
          .finally(() => {
            this.initPending = false;
          });
      } else {
        this.paymentInit({
          data: {
            payeeId: this.payeeId,
            basket: this.billsToPay.map((x) => ({
              payerId: x.payerId,
              billId: x.billId,
            })),
            donations: this.donationsBasket.map((x) => ({
              payerId: firstPayer,
              donationId: x.id,
              value: x.value,
            })),
          },
        })
          .then(({ data }) => {
            this.payUrl = data.url;
            window.open(data.url, '_blank').focus();
          })
          .finally(() => {
            this.initPending = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.header {
  font-size: 16px;
  line-height: 1.3;
}

.basket-group {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-top: 20px;
}

.internal-payment-info {
  max-width: 500px;
  line-height: 1.1;
  font-size: 13px;
  margin-top: 5px;
}

.account-number {
  font-size: 12px;
}
</style>
