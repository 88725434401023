<template>
  <div>
    <div class="d-flex justify-content-between mt-2 mr-1">
      <slot name="navbar">
        <div />
      </slot>

      <div
        v-tippy
        :content="$t('bill.showFuture')"
        class="secondary-icon bill-button px-3"
        style="width: 100px"
        @click="$emit('more')"
      >
        <i class="far fa-arrow-up-to-line mr-2" />
        <span v-if="additionalScopes > 0">
          + {{ additionalScopes }}
        </span>
      </div>
    </div>
    <div
      v-for="scope in billingScopes"
      :key="scope.date"
      class="my-3 scope-group mx-1"
      :class="{
        active: isCurrent(scope),
        closed: !scope.isOpen
      }"
    >
      <div
        class="text-uppercase d-flex m-2"
        style="font-weight: 600"
      >
        <div class="scope-title">
          {{ billingScopeName(scope) }}
        </div>

        <slot
          name="title"
          :scope="scope"
        />
      </div>
      <hr
        class="mt-3 mb-0"
        style="border-color: #eee"
      >
      <slot :scope="scope" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

const dateOnly = 'YYYY-MM-DD';

export default {
  props: {
    billingScopes: Array,
    additionalScopes: Number,
  },
  data: () => ({
    now: moment(),
  }),
  computed: {
    ...mapGetters(['billingScopeName']),
  },
  methods: {
    isCurrent({ from, to }) {
      return moment(from, dateOnly).isBefore(this.now)
          && moment(to, dateOnly).isAfter(this.now);
    },
  },
};
</script>

<style lang="scss" scoped>
  .scope-group {
    box-shadow: 0 2px 7px rgba(150, 150, 150, 0.3);
    border-radius: 20px;
    border: 2px solid transparent;
    padding: 10px;

    &.active {
      border: 2px solid $blue;
      box-shadow: 0 2px 7px rgba($blue, 0.3);;

      .scope-title {
        color: $blue;
      }
    }

    &.closed {
      background-color: #f4f4f4;
      border: 2px solid #ddd;
      box-shadow: none;
    }
  }
</style>
