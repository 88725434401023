<template>
  <div
    v-if="userPayers.length > 0"
    class="d-flex mt-4 payer-box-container"
  >
    <div
      v-for="p in userPayers"
      :key="p.payerId"
      class="payer-box d-flex flex-column justify-content-end"
      :class="{ invite: p.isInvite }"
    >
      <div
        v-if="!p.isInvite"
        class="user-icon"
      >
        <i class="far fa-user" />
      </div>
      <div class="payer-name">
        {{ p.payerName }}
      </div>
      <div class="payee-name">
        {{ p.payeeShortName }}
      </div>
      <template v-if="p.isInvite">
        <button
          class="btn btn-primary btn-sm invite-accept"
          :disabled="pendingActivation[p.payerId]"
          @click="acceptInvite(p)"
        >
          <i class="fas fa-user-plus pr-2" />
          <span class="pr-2">
            {{ $t('general.add') }}
          </span>
        </button>
        <button
          class="btn btn-secondary btn-sm invite-accept"
          :disabled="pendingActivation[p.payerId]"
          @click="rejectInvite(p)"
        >
          <i class="fas fa-xmark pr-2" />
          <span class="pr-2">
            {{ $t('general.cancel') }}
          </span>
        </button>
      </template>

      <template v-else>
        <div class="money-font mt-1">
          <span v-if="p.balance > 0">+</span>
          {{ formatCurrency(Math.abs(p.balance), p.currency) }}
        </div>
      </template>
    </div>
  </div>
  <div
    v-else
    class="mt-4"
  >
    <i
      class="fa-solid fa-id-card text-secondary mb-4 ml-1 pt-3"
      style="font-size: 35px"
    />
    <div>
      <div
        class="font-weight-bold h5"
        v-html="$t('client.noConnectedClients')"
      />
      <div v-html="$t('client.noConnectedClientsInfo')" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    pendingActivation: {},
  }),
  computed: {
    ...mapGetters([
      'formatCurrency',
      'userPayers',
    ]),
  },
  methods: {
    ...mapActions([
      'acceptUserRoleInvite',
      'rejectUserRoleInvite',
    ]),
    acceptInvite({ payeeId, payerId }) {
      this.$set(this.pendingActivation, payerId, true);
      this.acceptUserRoleInvite({
        data: {
          payeeId,
          payerId,
        },
      })
        .then(() => {
          this.$emit('refresh');
        })
        .finally(() => {
          this.$set(this.pendingActivation, payerId, false);
        });
    },
    rejectInvite({ payeeId, payerId }) {
      this.$set(this.pendingActivation, payerId, true);
      this.rejectUserRoleInvite({
        data: {
          payeeId,
          payerId,
        },
      })
        .then(() => {
          this.$emit('refresh');
        })
        .finally(() => {
          this.$set(this.pendingActivation, payerId, false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

  .payer-box-container {
    height: 180px;
    padding: 5px;
    width: 100%;
    overflow-y: auto;
    font-size: 14px;
  }

 .payer-box {
  width: 150px;
  height: 150px;
  min-width: 150px;
  box-shadow: 0px 3px 10px rgb(221, 227, 235);
  border-radius: 10px;
  margin-right: 10px;
  padding: 15px;

  &.invite {
    border: 2px solid $blue;
  }

  .payer-name {
    font-weight: 500;
  }

  .user-icon {
    color: #aaa;
    font-size: 22px;
  }

  .payee-name {
    font-size: 12px;
  }

  .invite-accept {
    box-shadow: none;
    margin-top: 8px;
  }
 }
</style>
