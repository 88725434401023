<template>
  <div class="overpayment d-flex align-items-center justify-content-between">
    <div>
      <div class="font-weight-bold">
        <i class="far fa-circle-dollar-to-slot pr-1 text-secondary" />
        {{ categoryName }}
      </div>
      <div class="d-md-flex align-items-center">
        <div class="bill-payee-name">
          {{ payer.payeeName }}
        </div>
        <span
          v-if="payer.payerName"
          class="info-divider d-none d-md-flex"
        >|</span>
        <div class="bill-payer-name">
          {{ payer.payerName }}
        </div>
      </div>
    </div>
    <div class="money-font text-primary">
      +{{ formatCurrency(balance, currency) }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    balance: Number,
    categoryName: String,
    currency: String,
    payer: Object,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
  },
};
</script>

<style lang="scss" scoped>
.overpayment {
  box-shadow: 0 2px 4px #eee,
    -1px 2px 4px #eee;
  margin-bottom: 10px;
  padding: 10px;
  border-left: 2px solid $blue;

  &.selected {
    border-left: 2px solid $blue;
  }
}

.bill-payee-name {
  font-size: 12px;
  color: $blue;
  line-height: 1;
}

.bill-payer-name {
  color: #666;
  font-size: 12px;
}

.info-divider {
  padding-left: 10px;
  padding-right: 10px;
  color: #ccc;
}
</style>
