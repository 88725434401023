<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    max-width="800"
    placement="bottom"
    @show="request"
    @hidden="selectOnHide"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t("settings.billingScope") }}
        </div>
        <div
          style="font-size: 14px"
          class="text-truncate"
        >
          <span v-if="!selected">
            -
          </span>
          <span v-else-if="selected.from && selected.singleScope">
            {{ formatDate(selected.from || first.from) }}
          </span>
          <span v-else>
            {{ formatDate(selected.from || first.from) || '...' }} - {{ formatDate(selected.to, -1) || '...' }}
          </span>
        </div>
      </div>
    </template>

    <div
      class="row"
      style="width: 550px"
    >
      <div
        class="col-5 pt-1"
        style="width: 200px; max-height: 438px; overflow: auto"
      >
        <div
          v-for="(y, i) in years"
          :key="y"
          class="sort-option text-left"
          :class="{ first: i === 0, active: year == y }"
          @click="year = y"
        >
          {{ y }}
        </div>
      </div>

      <div
        class="col-7 pt-1"
        style="max-width: 350px"
      >
        <div v-if="pending">
          <Loader />
        </div>
        <div
          v-else-if="options.length === 0"
          class="py-2 font-weight-bold"
        >
          {{ $t('bankStatements.noStatements', [year]) }}
        </div>
        <div v-else>
          <div
            v-for="k in options"
            :key="k.id"
            class="sort-option text-left"
            :class="{
              active: (first && first.from === k.from)
                || (selected && (selected.to === k.to || selected.from === k.from)),
              highlight: isHighlighted(k.from),
              greyed: isActive(k.from),
            }"
            @click="select(k)"
            @mouseenter="second = k"
          >
            <div class="font-weight-bold">
              {{ k.name }}
              <template v-if="first && first.from === k.from">
                <i
                  v-if="first.unix < second.unix"
                  class="fa-regular fa-square-arrow-up"
                />
                <i
                  v-else-if="first.unix > second.unix"
                  class="fa-regular fa-square-arrow-down"
                />
                <i
                  v-else
                  class="fa-regular fa-square-arrow-left"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import colors from '@/utils/colors';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    selected: Object,
    single: Boolean,
  },
  data: () => ({
    scopes: [],
    first: null,
    second: null,
    selectFirst: true,
    pending: false,
    haveData: false,
    year: moment().format('YYYY'),
  }),
  computed: {
    ...mapGetters(['payee', 'billingScopeName']),
    selectedText() {
      const scope = this.options.find((x) => x.id === this.selected);
      return this.billingScopeName(scope);
    },
    firstYear() {
      if (!this.payee) return moment().format('YYYY');
      return moment.utc(this.payee.oldestBillingScope).year();
    },
    years() {
      let current = parseInt(moment().format('YYYY'), 10);
      const years = [];

      while (current >= this.firstYear) {
        years.push(current);
        current -= 1;
      }

      return years;
    },
    options() {
      return this.scopes
        .map((x) => ({
          ...x,
          unix: moment(x.from, 'YYYY-MM-DD').unix(),
          name: this.billingScopeName(x),
        }));
    },
    firstFromMoment() {
      return this.getMoment(this.first.from);
    },
    secondFromMoment() {
      return this.getMoment(this.second.from);
    },
  },
  watch: {
    year() {
      this.haveData = false;
      this.request();
    },
  },
  methods: {
    ...mapActions(['getBillingScopes']),
    colors,
    selectOnHide() {
      if (this.first) {
        this.select(this.first);
      }
    },
    formatDate(date, diff) {
      if (!date) return null;
      return moment(date, 'YYYY-MM-DD').add(diff || 0, 'days').format('MMMM YYYY');
    },
    isHighlighted(date) {
      if (!this.second || !this.first) return false;
      const m = this.getMoment(date);
      return (m.isSameOrBefore(this.firstFromMoment) && m.isSameOrAfter(this.secondFromMoment))
        || (m.isSameOrAfter(this.firstFromMoment) && m.isSameOrBefore(this.secondFromMoment));
    },
    isActive(date) {
      if (!this.selected) return false;
      const from = this.getMoment(this.selected.from);
      const to = this.getMoment(this.selected.to);

      const m = this.getMoment(date);
      return m.isSameOrAfter(from) && m.isBefore(to);
    },
    getMoment(date) {
      return moment(date, 'YYYY-MM-DD');
    },
    request() {
      if (this.haveData) return;
      this.pending = true;
      this.getBillingScopes({
        params: {
          from: moment(this.year, 'YYYY').format('YYYY-MM-DD'),
          to: moment(this.year, 'YYYY').add(1, 'year').format('YYYY-MM-DD'),
        },
      })
        .then(({ data }) => {
          this.scopes = data;
          this.haveData = true;

          if (!this.selected && data.length > 0) {
            this.$emit('update:selected', {
              from: data[0].from,
              to: data[0].to,
              singleScope: true,
            });
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
    select(k) {
      if (this.single) {
        this.$emit('update:selected', {
          from: k.from,
          to: k.to,
          singleScope: true,
        });

        this.$refs.popover.tippy().hide();
        return;
      }

      if (this.selectFirst) {
        this.first = k;
        this.second = k;
        this.selectFirst = false;
        this.$emit('update:selected', null);
      } else {
        if (this.firstFromMoment.isBefore(this.getMoment(k.from))) {
          this.$emit('update:selected', {
            from: this.first.from,
            to: k.to,
            singleScope: k.from === this.first.from,
          });
        } else {
          this.$emit('update:selected', {
            from: k.from,
            to: this.first.to,
            singleScope: k.from === this.first.from,
          });
        }
        this.first = null;
        this.second = null;
        this.$refs.popover.tippy().hide();
        this.selectFirst = true;
      }
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  @import "../payers/style.scss";

  .bar-percent {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 1rem;
    color: white;
    text-align: center;
  }

  .sort-option {
    &.greyed {
      background-color: rgba(#333, 0.1);
    }
    &.highlight {
      background-color: rgba($blue, 0.1);
    }
  }

  .progress {
    position: relative;
  }
</style>
