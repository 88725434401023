<template>
  <div
    class="product-set-wrapper"
    :class="{
      ordered,
      paid,
      'not-paid': !paid,
      'is-returned': returned,
    }"
  >
    <div
      v-if="ordered"
      class="status-badge d-flex justify-content-between"
    >
      <div>
        <i
          class="fas pr-2"
          :class="paid ? 'fa-check' : 'fa-exclamation'"
        />
        <span
          v-if="summary"
          class="paid-status pr-2"
        >
          <span v-if="paidCount > 0">
            {{ $t('shop.paidPlural') }}: {{ paidCount }}
          </span>
          <span
            v-if="paidCount > 0 && unpaidCount > 0"
            class="px-2"
          >
            /
          </span>
          <span v-if="unpaidCount > 0">
            {{ $t('shop.notPaidPlural') }}: {{ unpaidCount }}
          </span>
        </span>
        <span
          v-else
          class="paid-status pr-2"
        >
          {{ paid ? $t('shop.paid') : $t('shop.notPaid') }}
        </span>
      </div>
      <div v-if="canCancel && cancelTo">
        {{ $t('shop.cancelTo') }}:
        {{ cancelDeadline }}
      </div>
      <div v-else-if="cancelTo">
        {{ $t('shop.cancellationExpired') }}
      </div>
    </div>

    <div
      class="product-set"
      :class="{
        selected
      }"
    >
      <div
        class="row align-items-center product-set-row"
      >
        <div
          v-if="selected"
          class="count-icon"
        >
          <span v-if="selected > 0">+</span>
          {{ selected }}
        </div>
        <div
          class="flex-grow-1 ml-3"
          style="min-width: 0"
        >
          <div class="position-relative">
            <span
              class="product-set-name"
              style="max-width: 250px"
            >
              {{ productSet.name }}
            </span>
          </div>
          <div
            v-if="returned"
            class="product-set-description text-warning"
          >
            {{ $t('shop.toBeCanceled') }}
          </div>
          <div
            v-else-if="showDescription && productSet.description"
            class="product-set-description"
          >
            {{ productSet.description || '' }}
          </div>
          <div class="font-weight-normal pb-1">
            {{ $t('shop.price') }}:
            <span v-if="minPrice === maxPrice">
              {{ formatCurrency(minPrice, currency) }}
            </span>
            <span v-else>
              {{ formatCurrency(minPrice, currency, true) }} -
              {{ formatCurrency(minPrice, currency) }}
            </span>
            <span class="text-lowercase">
              / szt
            </span>
          </div>
          <div
            v-if="showProducts"
            class="show-products"
            @click="productsVisible = !productsVisible"
          >
            {{ productsVisible ? $t('shop.hideProducts') : $t('shop.showProducts') }}
            <i
              class="fas pr-1"
              :class="productsVisible ? 'fa-chevron-up' : 'fa-chevron-down'"
            />
          </div>
        </div>
        <div
          class="col-12 col-xl-auto"
          style="width: 200px"
        >
          <hr
            class="mb-2 d-xl-none"
          >
          <div
            class="row justify-content-end no-gutters w-100"
          >
            <CounterInput
              :value="parseInt(selected || '0', 10) + parseInt(ordered || '0', 10)"
              :min-value="0"
              :max-value="maxCount || 1000"
              @input="$emit('update', parseInt($event, 10))"
            />
          </div>
        </div>
      </div>
      <template v-if="showProducts && productsVisible">
        <hr class="mt-2 mb-1">
        <div
          v-for="p in products"
          :key="p.id"
          class="d-flex align-items-center mt-2 p-1 product-info"
        >
          <div class="product-icon mr-3">
            <i class="fas fa-pot-food" />
          </div>
          <div class="pt-1">
            <div class="product-title smal">
              {{ p.name }}
            </div>
            <div class="product-description text-secondary">
              {{ p.description }}
            </div>
            <template v-if="p.tags.length > 0">
              <span
                v-for="t in p.tags"
                :key="t"
                class="badge mr-1"
              >{{ t }}</span>
            </template>
          </div>
        </div>
      </template>
      <div
        v-for="(c, i) in comments"
        :key="i"
        class="order-comment"
      >
        {{ c }}
      </div>
    </div>
  </div>
</template>

<script>
import CounterInput from '@/components/utils/CounterInput';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: Number,
    productSet: Object,
    products: Array,
    minPrice: Number,
    maxPrice: Number,
    currency: String,
    ordered: [Number, Boolean],
    paid: Boolean,
    returned: Boolean,
    unpaidCount: Number,
    maxCount: Number,
    paidCount: Number,
    cancelTo: String,
    showProducts: Boolean,
    summary: Boolean,
    showInBasket: Boolean,
    comments: Array,
    showDescription: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    productsVisible: false,
  }),
  components: {
    CounterInput,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    canCancel() {
      if (!this.cancelTo) { return true; }
      return moment(this.cancelTo).isAfter(moment());
    },
    cancelDeadline() {
      if (!this.cancelTo) { return null; }
      return moment(this.cancelTo).format('HH:mm DD/MM');
    },
    setTags() {
      return Object.keys(this.products
        .flatMap((x) => x.tags)
        .reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {}));
    },
  },
};
</script>

  <style lang="scss" scoped>

  .product-set-wrapper {
    background-color: #fafafa;
    border-radius: 10px;
    padding: 3px;
    margin-bottom: 10px;
    border: 2px solid transparent;
  }

  .show-products {
    border-radius: 10px;
    font-size: 10px;
    max-width: 150px;
    color: #666;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .order-comment {
    font-size: 12px;
    padding: 2px;
    padding-left: 5px;
    margin-top: 2px;
    border-left: 2px solid #eee;
  }

  .is-returned {
    .product-set-name {
      text-decoration: line-through;
    }
  }

  .cancelled-info {
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 12px;
    color: $orange;
  }

  .product-set-name {
    font-weight: 600;
  }

  .product-set-description {
    font-size: 12px;
    color: #666;
  }

  .product-info {
    .badge {
      font-size: 10px;
      padding: 3px 8px;
      color: #666;
      border: 1px solid #666;
    }
  }

  .product-icon {
    background-color: #eee;
    border-radius: 10px;
    width: 60px;
    min-width: 60px;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }

  .product-set-icon {
    background-color: #eee;
    border-radius: 10px;
    width: 70px;
    min-width: 70px;
    font-size: 25px;
    height: 70px;
    margin-right: 15px;
    margin-left: 10px;
    line-height: 70px;
    text-align: center;
  }

  .product-title {
    font-weight: 500;
    font-size: 13px;
  }

  .product-description {
    font-size: 12px;
  }

  .product-set {
    box-shadow: 0 2px 4px rgba(#aaa, 0.2), 0 -2px 4px rgba(#aaa, 0.1);
    border-radius: 10px;
    padding: 20px 25px;
    border: 2px solid transparent;
    background-color: white;
    position: relative;

    &.selected {
      border-color: $blue;
    }
  }

  .ordered {
    background-color: $lightblue;
    border-radius: 5px;

    &.not-paid {
      background-color: $orange;
      .product-set {
        border-color: $orange;

        .count-icon {
          background-color: $orange;
        }
      }
      .info-text {
        color: $orange;
      }
    }
    &.paid {
      .product-set {
        border-color: $lightblue;
        .count-icon {
          background-color: $lightblue;
        }
      }
      .info-text {
        color: #aaa;
      }
    }

    .status-badge {
      color: white;
      padding: 1px 5px 2px;
      font-weight: 500;
      font-size: 11px;
    }
  }

  .count-icon {
    background-color: $blue;
    color: white;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 20px;
    line-height: 20px;
    border-radius: 15px;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
  }

  @media screen and (min-width: 1200px) {
    .product-set-row {
      flex-wrap: nowrap;
    }
  }
  </style>
