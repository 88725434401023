<template>
  <div
    class="switch-box d-flex"
    :class="{
      small,
      'align-items-center': !description
    }"
  >
    <div class="pl-2">
      <BFormCheckbox
        switch
        :size="small ? 'md' : 'lg'"
        class="white-switch"
        :class="small ? 'white-switch-sm' : ''"
        :disabled="pending"
        :checked="localValue"
        @change="$emit('switch', !localValue); change()"
      />
    </div>
    <div>
      <div
        class="title"
        style="line-height: 1.2"
      >
        {{ title }}
      </div>
      <div
        v-if="description"
        class="small"
        style="color: #888"
      >
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    description: String,
    pending: Boolean,
    small: Boolean,
  },
  data: () => ({
    localValue: false,
  }),
  watch: {
    value() {
      this.localValue = this.value;
    },
    pending(p) {
      if (!p) {
        this.localValue = this.value;
      }
    },
  },
  methods: {
    change() {
      this.localValue = !this.localValue;
    },
  },
  created() {
    this.localValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
  .switch-box {
    background-color: #f7f7f7;
    padding: 0.4rem;
    border-radius: 15px;
    margin-top: 10px;
    max-width: 600px;

    .title {
      font-weight: 500;
    }

    &.small {
      width: 208px;
      border-radius: 10px;

      .white-switch-sm {
        margin-right: -10px;
      }

      .title {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

</style>
