import { render, staticRenderFns } from "./BillingNoteColumnsEdit.vue?vue&type=template&id=5ff47484&scoped=true&"
import script from "./BillingNoteColumnsEdit.vue?vue&type=script&lang=js&"
export * from "./BillingNoteColumnsEdit.vue?vue&type=script&lang=js&"
import style0 from "./BillingNoteColumnsEdit.vue?vue&type=style&index=0&id=5ff47484&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff47484",
  null
  
)

export default component.exports