<template>
  <div>
    <form
      v-if="activationEmailForm"
      class="d-flex mb-2"
      @submit.prevent="invite(customEmail, true)"
    >
      <BFormInput
        v-model="customEmail"
        size="sm"
        :placeholder="$t('client.email')"
      />
      <div class="ml-2">
        <button
          class="btn btn-primary btn-sm btn-block"
          type="submit"
        >
          {{ $t('general.send') }}
        </button>
      </div>
    </form>
    <div
      v-for="e in emails"
      :key="e.email"
      class="d-flex align-items-center email-row"
    >
      <div
        v-if="e.disconnected"
        class="px-2"
      >
        <i class="fas fa-link-slash" />
      </div>
      <div
        v-else-if="e.accepted"
        class="px-2"
      >
        <i class="fas fa-user text-primary" />
      </div>
      <div
        v-else
        class="px-2"
      >
        <i class="fas fa-paper-plane text-success" />
      </div>
      <div class="small flex-grow-1">
        {{ e.email }}
      </div>
      <div>
        <button
          v-if="e.disconnected"
          class="btn btn-sm invite-button"
          :disabled="pending[e.email]"
          @click="invite(e.email)"
        >
          <span v-if="pending[e.email]">
            <Loader size="10px" />
          </span>
          <span v-else>
            {{ $t('payers.invite') }}
          </span>
        </button>
        <div
          v-else-if="!e.accepted"
          class="small text-success"
        >
          <span class="pr-2">
            {{ $t('client.inviteSent') }}
          </span>
          <button
            v-tippy
            class="btn btn-sm invite-button ml-1 icon-btn"
            :content="$t('client.sendAgain')"
            :disabled="pending[e.userId]"
            @click="resendInvite(e.userId)"
          >
            <i class="fas fa-reply-clock text-secondary" />
          </button>
          <button
            v-tippy
            class="btn btn-sm invite-button ml-1 icon-btn"
            :disabled="pending[e.email]"
            :content="$t('general.cancel')"
            @click="removeInvite(e.email)"
          >
            <i class="fas fa-ban text-secondary" />
          </button>
        </div>
        <div
          v-else
          class="small text-primary"
        >
          <span class="pr-2">
            {{ $t('client.active') }}
          </span>
          <button
            v-tippy
            class="btn btn-sm invite-button ml-1 icon-btn"
            :content="$t('general.remove')"
            :disabled="pending[e.userId]"
            @click="removeUser(e)"
          >
            <i class="far fa-trash-xmark text-secondary" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="emails.length === 0"
      class="text-center small text-secondary"
    >
      {{ $t('payers.noEmails') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    payerId: String,
    propertyValues: Array,
    invites: Array,
    activationEmailForm: Boolean,
  },
  data: () => ({
    pending: {},
    customEmail: '',
  }),
  computed: {
    ...mapGetters(['properties']),
    emails() {
      const disconnected = this.properties
        .filter((p) => p.type === 'Email')
        .map(({ code }) => this.propertyValues.find((p) => p.key === code))
        .filter((x) => x && x.value.includes('@'))
        .filter((x) => !this.invites.some((i) => i.email === x.value))
        .map((x) => ({
          email: x.value,
          disconnected: true,
          accepted: false,
        }));

      return disconnected
        .concat(this.invites)
        .sort((a, b) => a.email.localeCompare(b.email));
    },
  },
  methods: {
    ...mapActions([
      'inviteUserToPayer',
      'removeUserPayerRole',
      'resendUserInvite',
      'withdrawUserInvite',
    ]),
    invite(email, isCustom) {
      if (!email.includes('@')) {
        return;
      }

      this.$set(this.pending, email, true);
      this.inviteUserToPayer({
        data: {
          email,
          payerId: this.payerId,
        },
      })
        .then(({ data }) => {
          this.$emit('invited', data);
          if (isCustom) {
            this.$emit('close-custom');
            this.customEmail = '';
          }
        })
        .finally(() => {
          this.$set(this.pending, email, false);
        });
    },
    removeInvite(email) {
      this.$set(this.pending, email, true);
      this.withdrawUserInvite({
        data: {
          email,
          payerId: this.payerId,
        },
      })
        .then(() => {
          this.$emit('removed', email);
        })
        .finally(() => {
          this.$set(this.pending, email, false);
        });
    },
    removeUser({ userId, email }) {
      this.$set(this.pending, userId, true);
      this.removeUserPayerRole({
        data: {
          userId,
          payerId: this.payerId,
        },
      })
        .then(() => {
          this.$emit('removed', email);
        })
        .finally(() => {
          this.$set(this.pending, userId, false);
        });
    },
    resendInvite(userId) {
      this.$set(this.pending, userId, true);
      this.resendUserInvite({
        data: {
          userId,
          payerId: this.payerId,
        },
      })
        .finally(() => {
          this.$set(this.pending, userId, false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
 .email-row {
  padding: 5px;
  background-color: #fbfbfb;
  margin-top: 5px;
  border-radius: 10px;
 }

 .invite-button {
  box-shadow: none;
  border: 1px solid #eee;
 }
</style>
