<template>
  <div>
    <div
      v-if="error"
      class="settings-box"
    >
      <span
        class="text-danger small"
      >
        {{ $t('error.settingsUnavailable') }}
      </span>
    </div>
    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="section-title">
        {{ $t('menu.accounting') }}
      </div>
      <SwitchWithDescription
        class="mt-2"
        :title="$t('accounting.settings.module')"
        :description="$t('accounting.settings.enabledInfo')"
        :pending="pending || error"
        :value="enabled"
        @switch="updateEnabled($event.toString())"
      />
    </div>
    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="d-flex">
        <div class="section-title">
          {{ $t('settings.billsCategories') }}
        </div>
        <div class="ml-3">
          <div
            v-if="!categoriesEdit"
            class="sort-option-selected page-setting d-flex align-items-center justify-content-center"
            @click="categoriesEdit = true"
          >
            <i class="fa-solid fa-pen-to-square" />
          </div>
          <div v-else>
            <SaveButton
              :pending="categoriesPending"
              @save="saveCategories"
              @cancel="categoriesEdit = false"
            />
          </div>
        </div>
      </div>

      <CategoriesMultiselect
        style="width: 370px"
        :selected.sync="billingCategories"
        :readonly="!categoriesEdit"
      />
    </div>
    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="d-flex">
        <div class="section-title">
          {{ $t('settings.accounting.billingNoteColumns') }}
        </div>
        <div class="ml-3">
          <div
            v-if="!columnsEdit"
            class="sort-option-selected page-setting d-flex align-items-center justify-content-center"
            @click="columnsEdit = true"
          >
            <i class="fa-solid fa-pen-to-square" />
          </div>
          <div v-else>
            <SaveButton
              :pending="columnsPending"
              @save="saveColumns"
              @cancel="columnsEdit = false"
            />
          </div>
        </div>
      </div>

      <BillingNoteColumnsSummary
        v-if="!columnsEdit"
        v-bind="config"
        style="max-width: 900px;"
      />
      <div v-else>
        <BillingNoteColumnsEdit
          :config="config"
          @update="updatedConfig = $event"
        />
      </div>
    </div>

    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="d-flex">
        <div class="section-title">
          {{ $t('settings.accounting.exportRules') }}
        </div>
        <div class="ml-3">
          <div
            v-if="!exportRulesEdit"
            class="sort-option-selected page-setting d-flex align-items-center justify-content-center"
            @click="exportRulesEdit = true"
          >
            <i class="fa-solid fa-pen-to-square" />
          </div>
          <div v-else>
            <SaveButton
              :pending="exportRulesPending"
              @save="saveExportRules"
              @cancel="exportRulesEdit = false"
            />
          </div>
        </div>
      </div>

      <div
        v-if="!exportRulesEdit"
        style="max-width: 900px;"
      >
        <template v-if="config">
          <div
            v-for="(rule, index) in config.exportRules"
            :key="index"
            class="rule-summary d-flex"
          >
            <div
              class="font-weight-bold"
              style="min-width: 250px"
            >
              {{ rule.fieldName }}
            </div>
            <div class="money-font rule">
              {{ rule.fieldRule }}
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <ExportSetup
          v-if="config"
          :config="config"
          @rules="exportRules = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SaveButton from '@/components/SaveButton';
import ExportSetup from '@/components/accounting/ExportSetup';
import CategoriesMultiselect from '@/components/settings/CategoriesMultiselect';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import BillingNoteColumnsEdit from '@/components/settings/accounting/BillingNoteColumnsEdit';
import BillingNoteColumnsSummary from '@/components/settings/accounting/BillingNoteColumnsSummary';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    error: null,
    enabled: false,
    pending: false,
    config: null,
    updatedConfig: null,
    columnsEdit: false,
    columnsPending: false,
    exportRulesPending: false,
    exportRulesEdit: false,
    categoriesPending: false,
    categoriesEdit: false,
    exportRules: [],
    billingCategories: [],
  }),
  components: {
    SwitchWithDescription,
    BillingNoteColumnsSummary,
    BillingNoteColumnsEdit,
    CategoriesMultiselect,
    ExportSetup,
    SaveButton,
  },
  methods: {
    ...mapActions([
      'getAccountingConfig',
      'toggleAccountingEnabled',
      'updateAccountingNoteColumns',
      'updateAccountingExportRules',
      'updateAccountingBillingCategories',
    ]),
    saveCategories() {
      this.categoriesPending = true;
      this.updateAccountingBillingCategories({
        data: {
          categoryIds: this.billingCategories,
        },
      })
        .then(() => {
          this.config.billingCategoryIds = this.billingCategories.slice();
          this.categoriesEdit = false;
        })
        .finally(() => {
          this.categoriesPending = false;
        });
    },
    saveExportRules() {
      this.exportRulesPending = true;

      this.updateAccountingExportRules({
        data: {
          exportRules: this.exportRules,
        },
      })
        .then(() => {
          this.exportRulesEdit = false;
          this.config.exportRules = this.exportRules;
        })
        .finally(() => {
          this.exportRulesPending = false;
        });
    },
    saveColumns() {
      this.columnsPending = true;
      this.updateAccountingNoteColumns({
        data: this.updatedConfig,
      })
        .then(() => {
          this.config = this.updatedConfig;
          this.columnsEdit = false;
        })
        .finally(() => {
          this.columnsPending = false;
        });
    },
    updateEnabled(value) {
      this.pending = true;
      this.toggleAccountingEnabled({
        params: {
          query: {
            enabled: value,
          },
        },
      })
        .then(({ data }) => {
          this.init(data);
          this.pending = false;
        })
        .catch(() => {
          this.init(this.config);
          this.pending = false;
        });
    },
    init(data) {
      this.enabled = data.enabled;
      this.config = data;
      this.billingCategories = data.billingCategoryIds;
    },
  },
  created() {
    this.$emit('page', 'accounting');

    this.getAccountingConfig()
      .then(({ data }) => {
        this.init(data);
      })
      .catch(() => {
        this.error = true;
      });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/payers/style.scss';

.page-setting {
  color: #aaa;
  font-size: 1.2rem;
  padding: 0;
  width: 44px;
  min-height: auto;
  height: 30px;
  text-align: center;
}

.rule-summary {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 2px 10px;
  margin-top: 5px;

  .rule {
    margin-left: 10px;
    font-size: 0.9rem;
    white-space: pre-wrap;
  }
}
</style>
