<template>
  <div
    class="operation-wrapper"
    :class="{
      settlement: isSettlement,
    }"
  >
    <div
      class="d-flex align-items-center audit-row log-row"
      :class="{
        open: showDetails,
      }"
      @click="isSettlement ? (showDetails = !showDetails) : null"
    >
      <div
        class="text-secondary text-nowrap px-1 time"
      >
        {{ formatDateTime(timestamp) }}
      </div>
      <div
        class="d-flex"
      >
        <div class="pl-1">
          <i
            :class="typeInfo.icon"
            :style="{ color: colors(typeInfo.color) }"
          />
        </div>
        <div class="pl-2">
          {{ typeInfo.text }}
        </div>
      </div>
      <div class="flex-grow-1" />
      <div
        v-tippy
        :content="user?.email"
        class="text-secondary px-2"
      >
        <i class="fa-solid fa-user" />
      </div>
    </div>
    <div
      v-if="showDetails"
      class="p-2 border-left"
    >
      <Loader v-if="pending" />
      <template v-else-if="details">
        <div
          v-for="(p, i) in paymentPayers"
          :key="p.id"
        >
          <PaymentPayerSettlement
            :payer="p"
            :bills="bills"
            :payment-id="paymentId"
            :currency="parsedDetails.currency"
            :bill-settlement="parsedDetails.bills.filter(x => x.payerId === p.id)"
            :overpayment-settlement="parsedDetails.overpayments.filter(x => x.payerId === p.id)"
            :refund-settlement="parsedDetails.refunds.filter(x => x.payerId === p.id)"
            :donation-settlement="parsedDetails.donations.filter(x => x.payerId === p.id)"
          />
          <hr
            v-if="i + 1 !== paymentPayers.length"
            class="my-2"
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import colors from '@/utils/colors';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import { mapActions, mapGetters } from 'vuex';
import PaymentPayerSettlement from '../PaymentPayerSettlement';

export default {
  props: {
    billingDay: String,
    operationDetails: String,
    operationType: String,
    operationId: String,
    paymentId: String,
    requesterId: String,
    timestamp: String,
    user: Object,
  },
  data: () => ({
    showDetails: false,
    pending: false,
    details: null,
    bills: [],
  }),
  components: {
    PaymentPayerSettlement,
  },
  computed: {
    ...mapGetters(['formatMoney']),
    lightColor() {
      return tinycolor(colors(this.typeInfo.color)).setAlpha(0.1).toString();
    },
    isSettlement() {
      return this.operationType === 'PaymentSettledEvent';
    },
    parsedDetails() {
      if (!this.details) return null;
      try {
        return JSON.parse(this.details.operationDetails);
      } catch {
        return null;
      }
    },
    paymentPayers() {
      if (!this.isSettlement || !this.parsedDetails) {
        return [];
      }
      const payerIds = this.parsedDetails.bills.map((x) => x.payerId)
        .concat(this.parsedDetails.overpayments.map((x) => x.payerId))
        .concat(this.parsedDetails.refunds.map((x) => x.payerId))
        .concat(this.parsedDetails.donations.map((x) => x.payerId));

      return this.details.payers.filter((p) => payerIds.includes(p.id));
    },
    typeInfo() {
      switch (this.operationType) {
        case 'PaymentAddedEvent':
          return {
            icon: 'fas fa-circle-plus',
            text: 'Utworzono',
            color: 'green',
          };
        case 'PaymentSettledEvent':
          return {
            icon: 'fas fa-circle-dollar',
            text: 'Rozliczono',
            color: 'orange',
          };
        case 'PaymentDeletedEvent':
          return {
            icon: 'fas fa-circle-minus',
            text: 'Usunięto',
            color: 'red',
          };
        default:
          return {
            icon: 'fas fa-circle-question',
            text: '?',
          };
      }
    },
  },
  watch: {
    showDetails(d) {
      if (d) {
        this.request();
      }
    },
  },
  methods: {
    ...mapActions(['getPaymentAuditDetails']),
    colors,
    tinycolor,
    formatDateTime(date) {
      return moment(date).format('HH:mm DD MMM YYYY');
    },
    request() {
      this.pending = true;
      this.getPaymentAuditDetails({
        params: {
          operationId: this.operationId,
          query: {
            billingDay: this.billingDay,
            paymentId: this.paymentId,
            timestamp: this.timestamp,
          },
        },
      })
        .then(({ data }) => {
          this.details = data;

          this.bills = data.bills.map((x) => ({
            billId: x.id,
            categoryId: x.categoryId,
            title: x.title,
          }));
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/components/audit.scss";

.border-left {
  border-left: 2px solid rgba($orange, 0.3) !important;
  margin-left: 5px;
  margin-bottom: 5px;
}

.operation-wrapper {
  font-size: 12px;
}

.audit-row {
  &.open {
    background-color: rgba($orange, 0.1);
  }

  .time {
    width: 120px;
    min-width: 120px;
  }
}

.settlement {
  .audit-row {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
