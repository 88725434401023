<template>
  <div class="activate-form">
    <h3
      class="font-weight-bold"
      style="font-size: 20px"
    >
      {{ $t('activation.activation') }}
    </h3>

    <form @submit.prevent="submit">
      <div
        v-if="filteredClients.length > 0"
      >
        <div
          class="text-success font-weight-bold mb-2"
          style="line-height: 1.1"
        >
          {{ $t('activation.willBeActivated') }}
        </div>
        <div v-if="someStillInactive">
          <hr class="mb-2">
          <BFormCheckbox v-model="sendAgain">
            <span class="font-weight-bold">
              {{ $t('client.sendAgainIfNoResponse') }}
            </span>
          </BFormCheckbox>
          <hr class="mt-2">
        </div>
        <div
          v-for="c in filteredClients"
          :key="c.id"
          class="d-flex justify-content-between align-items-center py-2"
          style="border-bottom: 1px solid rgba(100, 100, 100, 0.2)"
        >
          <div style="width: 25px">
            <i
              v-if="c.isActive"
              class="fas fa-check-circle text-success pr-2"
            />
            <i
              v-else-if="c.invitations && c.invitations.length > 0"
              class="fas fa-paper-plane text-primary pr-2"
            />
          </div>
          <div

            class="font-weight-bold"
            style="width: 300px"
          >
            {{ c.lastName }}
            {{ c.firstName }}
          </div>
          <div class="text-left flex-grow-1 pl-2">
            <div
              v-for="e in c.emails"
              :key="e"
              v-tippy="{
                trigger: isAlreadySent(c, e) ? 'mouseenter' : 'manual',
                placement: 'top',
                arrow: true,
              }"
              :content="isAlreadySent(c, e) ? $t('activation.invitationAlreadySent') : ' '"
            >
              <BFormCheckbox
                v-model="activation[`${c.id}_${e}`]"
                :disabled="isAlreadySent(c, e)"
              >
                {{ e }}
              </BFormCheckbox>
            </div>
          </div>
        </div>
      </div>
      <div v-if="noEmailClients.length > 0">
        <div
          class="text-danger font-weight-bold mt-3 mb-2"
          style="line-height: 1.1"
        >
          {{ $t('activation.noEmail') }}
        </div>
        <div
          v-for="c in noEmailClients"
          :key="c.id"
          class="font-weight-bold py-2"
          style="border-bottom: 1px solid rgba(100, 100, 100, 0.2)"
        >
          {{ c.lastName }}
          {{ c.firstName }}
        </div>
      </div>

      <div class="pt-4 row">
        <div class="col">
          <button
            class="btn btn-block btn-sm btn-secondary"
            type="button"
            @click="$emit('close')"
          >
            <i class="fas fa-times pr-2" />
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-block btn-sm btn-primary"
            :disabled="pending || filteredClients.length === 0"
          >
            <span v-if="!pending">
              <i class="fas fa-paper-plane pr-2" />
              {{ $t('client.activate') }}
            </span>
            <span v-else>
              <Loader
                color="#fff"
                size="20px"
              />
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    clients: Array,
  },
  data: () => ({
    activation: {},
    pending: false,
    sendAgain: false,
  }),
  computed: {
    ...mapGetters(['companyId']),
    ...mapState({
      clientProperties: (state) => state.clients.clientProperties,
    }),
    someStillInactive() {
      return !!this.filteredClients
        .find((c) => c.invitations && c.invitations.length > 0);
    },
    filteredClients() {
      return this.clients
        .filter((c) => c.meta)
        .filter((c) => !c.isActive)
        .filter((c) => this.emailPropertyIds.some((id) => c.meta[id]))
        .sort((a, b) => (a.lastName || a.firstName || '')
          .localeCompare(b.lastName || b.firstName || ''))
        .map((c) => {
          const emails = this.emailPropertyIds
            .map((id) => c.meta[id])
            .filter((x) => x);

          return {
            emails,
            ...c,
          };
        });
    },
    noEmailClients() {
      return this.clients
        .filter((c) => !c.isActive)
        .filter((c) => this.filteredClients.findIndex((fc) => fc.id === c.id) === -1)
        .sort((a, b) => (a.lastName || a.firstName || '')
          .localeCompare(b.lastName || b.firstName || ''));
    },
    emailPropertyIds() {
      return this.clientProperties
        .map((p) => p.id);
    },
  },
  watch: {
    sendAgain(v) {
      this.filteredClients.forEach((c) => {
        c.emails.forEach((e) => {
          if (this.isAlreadySent(c, e)) {
            this.$set(this.activation, `${c.id}_${e}`, v);
          }
        });
      });
    },
  },
  methods: {
    ...mapActions(['activateClients']),
    isAlreadySent(client, email) {
      return client.invitations.some((i) => i.email.toLowerCase() === email.toLowerCase());
    },
    isActive(client, email) {
      return client.users.some((i) => i.email.toLowerCase() === email.toLowerCase());
    },
    submit() {
      const clients = this.filteredClients.flatMap((c) =>
        c.emails
          .filter((em) => this.activation[`${c.id}_${em}`])
          .map((email) => ({ email, clientId: c.id })));

      this.pending = true;
      this.activateClients({
        params: {
          companyId: this.companyId,
        },
        data: {
          clients,
          sendAgain: this.sendAgain,
        },
      })
        .then(() => {
          this.$emit('close');
          this.pending = false;
        })
        .catch(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.filteredClients.forEach((c) => {
      c.emails.forEach((e) => {
        if (!this.isAlreadySent(c, e) && !this.isActive(c, e)) {
          this.activation[`${c.id}_${e}`] = true;
        }
      });
    });
  },
};
</script>
