<template>
  <nav class="navbar navbar-expand-md navbar-dark d-flex justify-content-between">
    <div />
    <div class="d-flex navbar-icon align-items-center">
      <Basket v-if="!hideBasket" />
      <NavbarDropdown
        class="ml-auto ml-2 navbar-icon"
      />
    </div>
  </nav>
</template>

<script>
import NavbarDropdown from '@/components/layout/NavbarDropdown';
import Basket from '../Basket';

export default {
  components: {
    Basket,
    NavbarDropdown,
  },
  computed: {
    hideBasket() {
      return this.$route.name === 'basket';
    },
  },
};
</script>

<style lang="scss" scoped>
  .navbar-brand {
    padding: 0;
  }

  .navbar-icon ::v-deep .btn-link {
    text-decoration: none;
    padding: 0;
    max-height: 40px;
    line-height: 1;
    cursor: pointer;

    i {
      color: #999;
      font-size: 28px;
    }
    &:hover i {
      color: #333;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .navbar {
    background-color: transparent;
    margin-bottom: 10px;
    padding: 10px 10px 0;
    min-height: 42px;
  }

  .navbar-brand {
    color: white;
    font-size: 22px;
    padding: 0;
    font-weight: lighter;
  }

</style>
