<template>
  <div class="position-relative">
    <div class="small">
      Dodaj grupy:
    </div>
    <div
      class="d-flex"
      style="max-height: 60vh; overflow: auto"
    >
      <GroupSelect
        :group-codes.sync="addGroupCodes"
      />
    </div>
    <hr>
    <div class="small">
      Usuń grupy:
    </div>
    <div
      class="d-flex"
      style="max-height: 60vh; overflow: auto"
    >
      <GroupSelect
        :group-codes.sync="removeGroupCodes"
      />
    </div>
    <hr>
    <div
      class="row pt-3"
      style="max-width: 600px; margin: auto"
    >
      <div class="col-6">
        <button
          class="btn btn-secondary btn-sm btn-block"
          @click="$emit('close')"
        >
          <i class="fas fa-times" />
          {{ $t('general.cancel') }}
        </button>
      </div>
      <div class="col-6">
        <button
          class="btn btn-primary btn-sm btn-block"
          :disabled="pending"
          @click="save"
        >
          <i class="ion ion-ios-save" />
          {{ $t('general.save') }}
        </button>
      </div>
    </div>

    <div
      v-if="pending"
      class="overlay d-flex justify-content-center align-items-center"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import GroupSelect from '@/components/payers/form/GroupSelect';
import { mapActions } from 'vuex';

export default {
  props: {
    selected: Object,
  },
  data: () => ({
    addGroupCodes: [],
    removeGroupCodes: [],
    pending: false,
    hover: null,
  }),
  components: {
    GroupSelect,
  },
  methods: {
    ...mapActions(['updateGroupAssignment']),
    save() {
      this.pending = true;
      this.updateGroupAssignment({
        data: {
          payerIds: Object.keys(this.selected),
          addGroups: this.addGroupCodes,
          removeGroups: this.removeGroupCodes,
        },
      })
        .then(() => {
          this.pending = false;
          this.$emit('close');
        })
        .catch(() => {
          this.pending = false;
        });
    },
  },
};
</script>

<style scoped>
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
  }

  .hovered {
    background-color: rgba(0, 0, 0, 0.075);
  }
</style>
