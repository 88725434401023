<template>
  <div class="h-100">
    <div class="page-header d-flex align-items-center justify-content-between">
      <h1>{{ $t('company.companies') }}</h1>

      <div>
        <button
          v-if="isAdmin"
          class="btn btn-outline-secondary pull-right icon-btn mt-2"
          @click="toAdmin()"
        >
          <i class="far fa-lock-keyhole" />
        </button>
        <!-- <RouterLink
          to="/company/add"
          tag="button"
          class="btn btn-success mt-2 ml-2"
        >
          <i class="fas fa-plus mr-1" /> {{ $t('company.addNewCompany') }}
        </RouterLink> -->
      </div>
    </div>
    <hr>

    <div>
      <form @submit.prevent="selectFirst">
        <BFormInput
          ref="search"
          v-model="search"
          autocomplete="off"
          :placeholder="`${$t('general.search')}`"
        />
      </form>
    </div>
    <div
      v-if="error"
      class="text-danger pt-4 text-center"
      style="max-width: 550px; margin: auto"
      v-html="$t('error.internalError')"
    />
    <div
      v-else-if="!pending"
      class="full-height-companies"
    >
      <div
        v-for="tenant in tenantsWithPayees"
        :key="tenant.id"
      >
        <div class="mt-2 font-weight-bold">
          {{ tenant.name }}
        </div>
        <div
          v-for="payee in tenant.payees"
          :key="payee.id"
          class="mt-2"
        >
          <div
            class="card company-card"
            style="border: none; border-radius: 20px"
            @click="goToPayee(payee)"
          >
            <div class="row no-gutters mx-0">
              <div class="col-12 col-md-6">
                <div class="img-wrapper">
                  <h5 class="company-name text-left px-3">
                    {{ payee.name }}
                  </h5>
                </div>
              </div>
              <div class="d-hidden d-md-block col-12 col-md-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="mt-3"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/utils/Loader';
import createSearch from '@/utils/search';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data: () => ({
    search: '',
    error: false,
  }),
  components: {
    Loader,
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState({
      payees: (state) => (state.payee.payees || {}).payees,
      tenants: (state) => (state.payee.payees || {}).tenants,
      pending: (state) => state.payee.pending.payees,
    }),
    tenantsWithPayees() {
      return (this.tenants || [])
        .map((x) => ({
          ...x,
          payees: this.sortedPayees.filter((y) => y.ownerId === x.id),
        }))
        .filter((x) => x.payees.length > 0);
    },
    sortedPayees() {
      const search = createSearch(this.search);

      return (this.payees || []).slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((x) => search(x.name));
    },
  },
  watch: {
    user() {
      if (!this.haveCompanies) {
        this.$router.push('/payer');
      }
    },
    companies() {
      this.autoSelect();
    },
  },
  methods: {
    ...mapActions([
      'getPayees',
    ]),
    goToPayee(payee) {
      this.$router.push(`/payee/${payee.payeeId}/payers`);
    },
    selectFirst() {
      if (this.sortedPayees.length > 0 && !this.$route.query?.stay) {
        this.goToPayee(this.sortedPayees[0]);
      }
    },
    toAdmin() {
      window.location.replace('./admin.html');
    },
    autoSelect() {
      if (this.$route.query.stay) return;
      if (this.sortedPayees && this.sortedPayees.length === 1) {
        this.selectFirst();
      }
    },
  },
  created() {
    this.$store.commit('setPayeeId', null);

    this.getPayees()
      .then(({ data }) => {
        if (data.payees.length === 0) {
          this.$router.push('/payer');
        } else if (data.payees.length === 1 && !this.$route.query?.stay) {
          this.goToPayee(data.payees[0]);
        }
      })
      .catch(() => {
        this.error = true;
      });
  },
  mounted() {
    if (this.$refs.search) {
      this.$refs.search.focus();
    }
    this.$store.dispatch('clear');
  },
};
</script>

<style lang="scss" scoped>

  .img-wrapper {
    background: $blue;
    border: 7px solid white;
    border-radius: 20px;
    text-align: center;

    .company-name {
      color: white;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .company-card {
    box-shadow: 0 3px 6px rgba(150, 150, 150, 0.2);
    transition: box-shadow 300ms, transform 300ms;
    cursor: pointer;

    &:hover {
      box-shadow: 0 3px 6px rgba(150, 150, 150, 0.2),
        0 0 8px rgba($blue, 0.5);
      transform: translateX(5px);
    }
  }

  .full-height-companies {
    height: calc(100vh - 220px);
    overflow: auto;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .card-title {
    font-size: 25px;
    font-weight: 200;
    margin: 0;
  }
  .hoverable {
    transition: box-shadow 300ms;
    cursor: pointer;
  }
  .hoverable:hover {
    box-shadow: 0 0 10px rgba(100,100,100,0.5);
  }
</style>
