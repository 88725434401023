<template>
  <div>
    <div>
      <div class="font-weight-bold">
        {{ $t('shop.reports.name') }}:
      </div>
      <BFormInput v-model="reportName" />
    </div>
    <hr class="mb-0">
    <div
      class="d-flex align-items-center py-2 group-row"
      @click="reportUnpaid = !reportUnpaid"
    >
      <Checkbox
        :value="reportUnpaid"
        :emit="false"
      />
      <span class="font-weight-bold">
        {{ $t('shop.showUnpaid') }}
      </span>
    </div>

    <div class="font-weight-bold mt-2">
      {{ $t('shop.reports.filterGroups') }}:
    </div>
    <GroupSelect
      :group-codes.sync="groups"
      show-select-all
      allow-rule-based-select
    />
    <div class="font-weight-bold mt-3">
      {{ $t('shop.reports.includeProperties') }}:
    </div>
    <div class="row">
      <div class="col-6">
        <div>
          {{ $t('shop.reports.includedProperties') }}
        </div>
        <Draggable
          v-model="includeProperties"
          class="draggable"
          ghost-class="ghost"
          group="columns"
          :class="{ moving }"
          @start="moving = true"
          @end="moving = false"
        >
          <div
            v-for="c in includeProperties"
            :key="c"
            class="column"
          >
            <i :class="icon(c)" />
            {{ name(c) }}
          </div>
        </Draggable>
      </div>
      <div class="col-6">
        <div>
          {{ $t('shop.reports.availableProperties') }}
        </div>
        <Draggable
          v-model="otherProperties"
          class="draggable"
          ghost-class="ghost"
          group="columns"
          :class="{ moving }"
          @start="moving = true"
          @end="moving = false"
        >
          <div
            v-for="c in otherProperties"
            :key="c.key"
            class="column"
          >
            <i :class="icon(c)" />
            {{ name(c) }}
          </div>
        </Draggable>
      </div>
    </div>

    <hr>
    <SwitchWithDescription
      :title="$t('shop.reports.send')"
      small
      class="mb-1"
      style="min-height: 44px; margin-top: 0"
      :value="emailEnabled"
      @switch="emailEnabled = !emailEnabled"
    />

    <div v-if="emailEnabled">
      <div class="font-weight-bold mt-3">
        {{ $t('shop.reports.emails') }} ({{ $t('shop.reports.lineSeparated') }}):
      </div>
      <BFormTextarea
        v-model="emails"
        :placeholder="$t('shop.reports.emailsInLines')"
        rows="3"
      />
      <DeliveryTimeSelect
        :hour.sync="hour"
        :skip-weekend.sync="skipWeekend"
        :day-offset.sync="dayOffset"
      />
    </div>
  </div>
</template>

<script>
import GroupSelect from '@/components/payers/form/GroupSelect';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import Draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import DeliveryTimeSelect from '../DeliveryTimeSelect';

export default {
  props: {
    subscription: Object,
  },
  data: () => ({
    emails: '',
    moving: false,
    groups: [],
    hour: '9:00',
    dayOffset: 0,
    skipWeekend: false,
    includeProperties: [],
    otherProperties: [],
    reportUnpaid: false,
    pending: false,
    sortBy: [],
    reportName: '',
    emailEnabled: false,
  }),
  components: {
    SwitchWithDescription,
    DeliveryTimeSelect,
    Draggable,
    GroupSelect,
  },
  computed: {
    ...mapGetters([
      'payee',
      'userGroups',
      'properties',
      'ordersReportTypes',
    ]),
    selectedReportType() {
      return this.ordersReportTypes.find((x) => x.type === this.reportType);
    },
    extendedProperties() {
      return [
        {
          name: this.$t('general.name'),
          key: 'name',
          type: 'default',
        },
        {
          name: this.$t('client.clientCode'),
          key: 'code',
          type: 'default',
        },
        {
          name: this.$t('client.number'),
          key: 'number',
          type: 'default',
        },
        ...this.properties
          .map((x) => ({
            name: x.name,
            key: x.code,
            type: 'property',
          }))
          .sort((a, b) => a.name.localeCompare(b.name))];
    },
    config() {
      return {
        name: this.reportName,
        properties: this.includeProperties,
        reportUnpaid: this.reportUnpaid,
        hour: this.hour,
        skipWeekend: this.skipWeekend,
        dayOffset: parseInt(this.dayOffset, 10),
        groups: this.groups,
        emailDisabled: !this.emailEnabled,
        sorted: true,
        emails: this.emails.split('\n')
          .filter((x) => x && x.includes('@'))
          .map((x) => x.trim()),
      };
    },
  },
  watch: {
    config(c) {
      this.$emit('update', c);
    },
  },
  methods: {
    name(key) {
      return this.extendedProperties.find((x) => x.key === key)?.name;
    },
    icon(key) {
      const column = this.extendedProperties.find((x) => x.key === key)?.type;
      if (column === 'category') {
        return 'far fa-box-dollar pr-1';
      }
      if (column === 'group') {
        return 'far fa-users pr-1';
      }
      if (column === 'property') {
        return 'far far fa-tag pr-1';
      }

      return null;
    },
  },
  created() {
    if (this.subscription) {
      this.groups = this.subscription.config.groups;
      this.includeProperties = this.subscription.config.properties;
      this.hour = this.subscription.config.hour;
      this.reportName = this.subscription.config.name;
      this.reportType = this.subscription.type;
      this.dayOffset = this.subscription.config.dayOffset || 0;
      this.skipWeekend = this.subscription.config.skipWeekend || false;
      this.reportUnpaid = this.subscription.config.reportUnpaid;
      this.emails = this.subscription.config.emails.join('\n');
      this.emailEnabled = !this.subscription.config.emailDisabled;

      if (!this.subscription.config.sorted) {
        const defaultSort = this.extendedProperties.map((x) => x.key);

        this.includeProperties = this.includeProperties
          .sort((a, b) => defaultSort.indexOf(a) - defaultSort.indexOf(b));
      }
    } else {
      this.includeProperties = ['name'];
    }

    this.otherProperties = this.extendedProperties
      .filter((x) => !this.includeProperties.includes(x.key))
      .map((x) => x.key);
  },
};
</script>

<style lang="scss" scoped>
  .group-row {
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }

  .close-button {
    position: absolute;
  }

  .draggable {
    border: 1px solid transparent;
    border-radius: 10px;
    min-height: 50px;
    background-color: #f8f8f8;
    padding: 5px;
    margin-top: 5px;
    min-height: 200px;
    padding-bottom: 40px;
  }

  .draggable.moving {
    border: 1px solid #aaa;
  }

  .moving * {
    cursor: grabbing;
  }

  .column {
    padding: 0.3rem 0.6rem;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }
  }
</style>
