<template>
  <svg
    viewBox="0 0 40 40"
    class="circular-chart blue"
  >
    <path
      class="circle-bg"
      :style="{ strokeWidth: `${strokeWidth}px` }"
      d="M20 4.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path
      v-if="value > 0"
      class="circle"
      :style="{ strokeWidth: `${strokeWidth}px` }"
      :stroke-dasharray="`${Math.round(value)}, 100`"
      d="M20 4.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <text
      v-if="showText"
      x="20"
      y="22.5"
      class="percentage"
      :style="{ fontSize: `${fontSize}px` }"
    >{{ Math.round(value) }}%</text>
  </svg>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    strokeWidth: {
      type: Number,
      default: 5,
    },
    fontSize: {
      type: Number,
      default: 10,
    },
    showText: Boolean,
  },
};
</script>

<style lang="scss" scoped>

.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.single-chart {
  width: 33%;
  justify-content: space-around ;
}

.circular-chart {
  display: block;
  margin: 0px auto;
}

.circle-bg {
  fill: none;
  stroke: #eee;
}

.circle {
  fill: none;
  stroke-linecap: round;
}

.circular-chart.blue .circle {
  stroke: $blue;
}

.percentage {
  text-anchor: middle;
}
</style>
