<template>
  <div class="d-flex align-items-center justify-content-center">
    <div
      class="counter-btn"
      :class="{ disabled: parseInt(value, 10) === minValue }"
      @click="decrease"
    >
      <i class="fas fa-minus" />
    </div>
    <BFormInput
      :value="value"
      type="number"
      size="sm"
      class="text-right no-arrows"
      style="max-width: 100px"
      @update="$emit('input', $event)"
    />
    <div
      class="counter-btn"
      :class="{ disabled: parseInt(value, 10) === maxValue }"
      @click="increase"
    >
      <i class="fas fa-plus" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    minValue: {
      type: Number,
      default: 1,
    },
    maxValue: {
      type: Number,
      default: 1000,
    },
  },
  methods: {
    decrease() {
      const val = parseInt(this.value, 10);
      if (val - 1 < this.minValue) {
        return;
      }

      this.$emit('input', val - 1);
    },
    increase() {
      const val = parseInt(this.value, 10);
      if (val + 1 > this.maxValue) {
        return;
      }

      this.$emit('input', val + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
  .counter-btn {
    background-color: #eee;
    border-radius: 50%;
    width: 31px;
    min-width: 31px;
    height: 31px;
    line-height: 31px;
    text-align: center;
    margin: 0 10px;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
    }

    &.disabled {
      opacity: 0.3;
      background-color: #e6e6e6;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  .no-arrows::-webkit-outer-spin-button,
  .no-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .no-arrows {
    -moz-appearance: textfield;
    appearance: textfield;
  }
</style>
