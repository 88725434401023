<template>
  <div class="pt-2">
    <div class="pl-1 pb-1 font-weight-bold small">
      {{ $t('bankStatements.transferDetails') }}:
    </div>
    <div class="transfer-header">
      <div class="title flex-grow-1 pl-2">
        <div class="small py-1">
          {{ fromName }}
        </div>
        <div class="small text-secondary">
          {{ fromAccount | accountNumber }} <span v-if="toAccount"> -> {{ toAccount | accountNumber }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fromAccount: String,
    fromName: String,
    toAccount: String,
  },
};
</script>

<style lang="scss" scoped>

.transfer-header {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 15px;
}

.title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1;
}
</style>
