<template>
  <div
    class="my-2 thread-box"
    data-test="thread-box"
  >
    <div
      class="title flex-grow-1 align-self-center pl-2"
      data-test="thread-title"
    >
      {{ thread.title }}
    </div>
    <div class="payee-name pl-2 mt-2">
      {{ payeeName }}
    </div>

    <div
      v-if="thread.unreadCount > 0"
      class="unread-bouble"
    >
      {{ thread.unreadCount }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    thread: Object,
    unread: Boolean,
  },
  computed: {
    ...mapGetters(['userPayers']),
    payeeName() {
      if (this.thread.sender.type === 'PayeeId') {
        return this.userPayers
          .find((x) => x.payeeId === this.thread.sender.id)?.payeeName;
      }
      return this.$t('client.me');
    },
  },
};
</script>

<style lang="scss" scoped>

  .payee-name {
    font-size: 12px;
    color: $blue;
    line-height: 1;
  }

  .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
  }

  .thread-box {
    box-shadow: 0 0 5px rgba(150, 150, 150, 0.5);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    cursor: pointer;
    transition: box-shadow 300ms;

    &:hover {
      box-shadow: 0 0 5px rgba(150, 150, 150, 0.8);
    }
  }

  .unread-bouble {
    top: -2px;
    right: -2px;
    font-weight: 500;
    text-align: center;
    font-size: 10px;
    color: white;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: $red;
  }

</style>
