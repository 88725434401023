<template>
  <div>
    <div
      v-for="p in children"
      :key="p.id"
    >
      <div
        class="client-property d-flex justify-content-between"
        :class="{ selected: selected === p.id }"
        @click="toggle(p.id)"
      >
        <span class="text-truncate">
          {{ p.name }}
        </span>
        <span class="text-secondary">
          <i class="fas fa-users" />
        </span>
      </div>

      <GroupsList
        class="ml-3"
        :list="list"
        :toggle="toggle"
        :selected="selected"
        :root-id="p.id"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupsList',
  props: {
    list: Array,
    toggle: Function,
    selected: [String, Number],
    rootId: String,
  },
  computed: {
    children() {
      return this.list.filter((x) => x.parentCode === this.rootId);
    },
  },
};

</script>

<style lang="scss" scoped>
  .client-property {
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }
    &.selected {
      margin-right: -20px;
      background-color: rgba($blue, 0.1);
      border-color: $darkblue;
    }
  }
</style>
