<template>
  <div>
    <div
      v-if="bill.description"
      class="px-3 py-2 mt-2 bg-light rounded description"
      data-test="bill-details-description"
    >
      {{ bill.description }}
    </div>

    <div class="px-3 py-2 mx-0">
      <div>
        <div class="mx-0 pt-1 text-left">
          <div
            v-if="bill.beginDate"
            class="d-flex align-items-center mb-1"
          >
            <div style="width: 170px">
              <label class="mb-0">{{ $t('payment.startDate') }}:</label>
            </div>
            <span class="font-weight-bold text-left">
              {{ bill.beginDate | format }}
            </span>
          </div>
          <div
            v-if="bill.dueDate"
            class="d-flex align-items-center mb-1"
          >
            <div style="width: 170px">
              <label class="mb-0">{{ $t('payment.to') }}:</label>
            </div>
            <span
              class="font-weight-bold"
              style="line-height: 1.2"
            >
              {{ bill.dueDate | format }}
            </span>
            <span
              v-if="bill.beginDate && bill.dueDate"
              class="small text-secondary pl-2"
            >
              ({{ label }})
            </span>
          </div>

          <div
            v-if="bankAccountName"
            class="d-flex align-items-center mb-1"
          >
            <div style="width: 170px">
              <label class="mb-0">{{ $t('settings.bankAccounts.bankAccount') }}:</label>
            </div>
            <span
              class="font-weight-bold"
              style="line-height: 1.2"
            >
              {{ bankAccountName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    bill: {
      type: Object,
      required: true,
    },
  },
  filters: {
    format(date) {
      return moment(date).format('ddd D MMM YYYY');
    },
  },
  computed: {
    ...mapGetters(['payee']),
    now() {
      return Math.floor(Date.now() / 1000);
    },
    bankAccountName() {
      return this.payee.bankAccounts
        .find((x) => x.accountNumber.toString() === (this.bill.accountNumber || '').toString())?.name;
    },
    label() {
      const { beginDate, dueDate } = this.bill;
      const start = moment(beginDate).unix();
      const end = moment(dueDate).unix();

      if (this.now < start) {
        return `${this.getDays(start - this.now)} ${this.$t('payment.toStart')}`;
      }
      if (!end) return '-';
      if (this.now < end) {
        return `${this.getDays(end - this.now)} ${this.$t('payment.toEnd')}`;
      }
      return `${this.getDays(this.now - end)} ${this.$t('payment.afterDeadline')}`;
    },
  },
  methods: {
    getDays(diff) {
      const days = diff / (60 * 60 * 24);
      if (days < 1) return this.$t('time.lessThanDay');
      if (Math.floor(days) === 1) return `1 ${this.$t('time.day')}`;
      return `${Math.round(days)} ${this.$t('time.days')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .description {
    white-space: pre-line;
  }

  .date-progress ::v-deep .progress span {
    font-weight: 500;
    text-shadow: 0 0 5px rgba(100, 100, 100, 0.8);
    padding-left: 10px;
  }
</style>
