<template>
  <div>
    <div
      class="d-flex align-items-center mt-3"
    >
      <div
        class="d-flex align-items-center"
      >
        <BFormInput
          v-model="value"
          :disabled="pending"
          type="text"
          placeholder="dd"
          class="text-right"
        />
        <div
          class="ml-1 mr-3 font-weight-bold text-lowercase time-text"
          style="line-height: 1"
        >
          {{ $t('settings.info.billingScopeDay') }}
        </div>
      </div>
      <div class="ml-2">
        <button
          class="btn btn-sm btn-primary"
          style="width: 100px"
          :disabled="pending || value === initValue"
          @click="update"
        >
          <span v-if="updateSuccess">
            <i class="fas fa-check" />
          </span>
          <span v-else>
            {{ $t('general.save') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initValue: String,
    pending: Boolean,
  },
  data: () => ({
    value: '',
    updateSuccess: false,
  }),
  watch: {
    initValue(v) {
      this.value = v;
    },
  },
  methods: {
    update() {
      this.$emit('update', this.value);
    },
  },
  created() {
    this.value = this.initValue;
  },
};
</script>
