<template>
  <div
    style="padding-top: 8px"
  >
    <div
      v-if="form"
      class="d-flex"
    >
      <div>
        <a
          :href="`/forms.html?payeeId=${payeeId}&formId=${form.id}`"
          class="link"
          target="_blank"
        >
          <i class="fas fa-arrow-up-right-from-square" />
          {{ $t('forms.open') }}
        </a>
      </div>
      <div
        class="ml-4"
      >
        <Tippy
          interactive
          theme="light"
          trigger="click"
        >
          <template #trigger>
            <a
              href="#"
              class="link"
            >
              <i class="fa-solid fa-copy" />
              {{ $t('forms.clone') }}
            </a>
          </template>

          <button
            class="btn btn-primary btn-sm"
            @click="clone"
          >
            <i class="fa-solid fa-copy" />
            {{ $t('forms.clone') }}
          </button>
        </Tippy>
      </div>
    </div>
    <hr
      v-if="form"
      class="mt-2"
    >

    <label class="mb-1">
      {{ $t('settings.forms.formName') }}
    </label>
    <BFormInput
      v-model="name"
      class="mb-2"
      size="sm"
      :placeholder="$t('settings.forms.formName')"
    />

    <label class="mb-1">
      {{ $t('settings.forms.actionType') }}
    </label>
    <BFormSelect
      v-model="actionType"
      :disabled="!!form"
      :options="actionTypes"
      size="sm"
    />

    <BFormCheckbox
      v-model="isActive"
      style="margin-left: -8px; margin-top: 15px;"
    >
      {{ $t('settings.forms.active') }}
    </BFormCheckbox>

    <hr v-if="form">
    <button
      v-if="form"
      class="btn btn-outline-primary btn-block btn-sm"
      @click="configure"
    >
      {{ $t('settings.forms.configure') }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    form: Object,
    pending: Boolean,
  },
  data: () => ({
    name: '',
    isActive: false,
    actionType: 'PayerCreate',
  }),
  computed: {
    ...mapGetters(['payeeId']),
    actionTypes() {
      return [{
        value: 'PayerCreate',
        text: this.$t('forms.action.payerAdd'),
      }];
    },
  },
  methods: {
    ...mapActions([
      'updateFormDefinition',
      'updateFormDefinitionDetails',
      'createFormDefinition',
      'deleteFormDefinition',
    ]),
    configure() {
      this.$router.push(`/payee/${this.payeeId}/settings/form/${this.form.id}`);
    },
    clone() {
      this.$emit('update:pending', true);

      this.createFormDefinition({
        data: {
          name: `${this.name} - Kopia`,
          actionType: this.form.actionType,
        },
      })
        .then(({ data }) => {
          this.updateFormDefinitionDetails({
            params: {
              formId: data.id,
            },
            data: {
              layout: this.form.layout,
              model: this.form.model,
            },
          })
            .finally(() => {
              this.$emit('update:pending', false);
              this.$emit('refresh');
            });
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.$emit('update:pending', true);
      this.deleteFormDefinition({
        params: {
          formId: this.form.id,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('refresh');
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    submit() {
      this.$emit('update:pending', true);
      this.updateFormDefinition({
        params: {
          formId: this.form.id,
        },
        data: {
          isActive: this.isActive,
          name: this.name,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('refresh');
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.form) {
      this.isActive = this.form.isActive;
      this.name = this.form.name;
    }
  },
};
</script>

<style>
.link {
    font-size: 12px;
}
</style>
