<template>
  <div>
    <BoxSelect
      v-if="!payer"
      :selected.sync="type"
      :fields="types"
    />

    <div class="mt-3">
      <PersonForm
        v-if="type === 'Person'"
        :description.sync="personDescription"
      />
      <OrganizationForm
        v-else-if="type === 'Organization'"
        :description.sync="organizationDescription"
      />
    </div>

    <div class="font-weight-bold mt-3">
      {{ $t('company.clientFields') }}
    </div>
    <hr class="mt-2">

    <PayerProperties
      :property-values.sync="propertyValues"
      class="px-1"
    />

    <div class="font-weight-bold mt-3">
      {{ $t('groups.groups') }}
    </div>
    <hr class="mb-0 mt-2">
    <GroupSelect
      :group-codes.sync="groupCodes"
    />

    <div
      class="d-flex align-items-center justify-content-between no-gutters mt-5"
    >
      <div class="col-auto px-3">
        <RemoveButton
          v-if="payer"
          archive
          :pending="pendingArchive"
          @confirm="remove"
        />
      </div>
      <div
        class="row"
        style="width: 400px"
      >
        <div class="col-6">
          <button
            class="btn btn-secondary btn-block btn-sm"
            @click="$emit('close')"
          >
            <i class="fas fa-times pr-2" />
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="col-6">
          <button
            data-test="save-client-details-button"
            class="btn btn-primary btn-block btn-sm"
            :disabled="pending"
            @click="submit"
          >
            <i class="fas fa-check pr-2" />
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoxSelect from '@/components/BoxSelect';
import RemoveButton from '@/components/utils/RemoveButton';
import { mapActions } from 'vuex';
import GroupSelect from './form/GroupSelect';
import OrganizationForm from './form/OrganizationForm';
import PayerProperties from './form/PayerProperties';
import PersonForm from './form/PersonForm';
import types from './payer-types';

export default {
  props: {
    payer: Object,
  },
  data: () => ({
    type: 'Person',
    pending: false,
    removePopover: false,
    pendingArchive: false,
    personDescription: {},
    organizationDescription: {},
    groupCodes: [],
    propertyValues: [],
  }),
  components: {
    BoxSelect,
    OrganizationForm,
    RemoveButton,
    PersonForm,
    GroupSelect,
    PayerProperties,
  },
  computed: {
    types() {
      return types;
    },
  },
  methods: {
    ...mapActions([
      'addPayer',
      'updatePayer',
      'archivePayers',
    ]),
    submit() {
      if (this.payer) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      this.pending = true;
      this.updatePayer({
        params: {
          payerId: this.payer.id,
        },
        data: {
          type: this.type,
          groupCodes: this.groupCodes,
          properties: this.propertyValues,
          ...(this.type === 'Person'
            ? this.personDescription
            : this.organizationDescription),
        },
      })
        .then(({ data }) => {
          this.$store.dispatch('getPayersGroups');
          this.$emit('updated', data);
          this.$emit('close');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    add() {
      this.pending = true;
      this.addPayer({
        data: {
          type: this.type,
          groupCodes: this.groupCodes,
          properties: this.propertyValues,
          ...(this.type === 'Person'
            ? this.personDescription
            : this.organizationDescription),
        },
      })
        .then(({ data }) => {
          this.$store.dispatch('getPayersGroups');
          this.$emit('added', data);
          this.$emit('close');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove() {
      this.pendingArchive = true;
      this.archivePayers({
        data: {
          payerIds: [this.payer.id],
        },
      })
        .then(() => {
          this.$store.dispatch('getPayersGroups');
          this.$emit('archive');
        })
        .finally(() => {
          this.pendingArchive = false;
        });
    },
  },
  created() {
    if (this.payer) {
      this.code = this.payer.code;
      this.type = this.payer.type;
      this.groupCodes = this.payer.groupCodes.slice();

      this.propertyValues = this.payer.properties
        .map((x) => ({
          key: x.key,
          value: x.value,
        }));

      if (this.type === 'Person') {
        this.personDescription = this.payer.personDescription;
      } else {
        this.organizationDescription = this.payer.organizationDescription;
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
