<template>
  <div>
    <div class="px-3">
      <BillingPlanEnabled
        :is-enabled="plan.isEnabled"
        :color="color"
      />
    </div>
    <div
      v-if="plan.description"
      class="px-3 py-2 mt-2 bg-light rounded description"
      data-test="bill-details-description"
    >
      {{ plan.description }}
    </div>

    <div class="px-3 py-2 mx-0">
      <div>
        <div class="mx-0 pt-1 text-left">
          <div
            v-if="plan.timeRule.end"
            class="d-flex"
          >
            <div style="width: 170px">
              <label> {{ $t('billingPlan.to') }}:</label>
            </div>
            <span
              class="font-weight-bold"
              style="line-height: 1.2"
            >
              {{ plan.timeRule.end | format }}
            </span>
          </div>
          <RepeatInfo
            class="small"
            :repeat-type="plan.timeRule.repeatType"
            :repeat-number="plan.timeRule.repeatCount"
            :from="plan.timeRule.start"
          />
          <div
            v-if="bankAccountName"
            class="d-flex align-items-center mt-2"
          >
            <div style="width: 170px">
              <label class="mb-0">{{ $t('settings.bankAccounts.bankAccount') }}:</label>
            </div>
            <span
              class="font-weight-bold"
              style="line-height: 1.2"
            >
              {{ bankAccountName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import RepeatInfo from '../form/RepeatInfo';
import BillingPlanEnabled from '../../billingPlans/BillingPlanEnabled';

export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
    color: String,
  },
  filters: {
    format(date) {
      return moment(date).format('ddd D MMM YYYY');
    },
  },
  components: {
    RepeatInfo,
    BillingPlanEnabled,
  },
  computed: {
    ...mapGetters(['payee']),
    now() {
      return Math.floor(Date.now() / 1000);
    },
    bankAccountName() {
      return this.payee.bankAccounts
        .find((x) => x.accountNumber.toString() === (this.plan.accountNumber || '').toString())?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
  .description {
    white-space: pre-line;
  }

  .date-progress ::v-deep .progress span {
    font-weight: 500;
    text-shadow: 0 0 5px rgba(100, 100, 100, 0.8);
    padding-left: 10px;
  }
</style>
