<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    max-width="800"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t("settings.billingScope") }}
        </div>
        <div
          style="font-size: 14px"
          class="text-truncate"
        >
          <span v-if="!selected">
            -
          </span>
          <span v-else>
            {{ selected.name }}
          </span>
        </div>
      </div>
    </template>

    <div
      class="row"
      style="width: 550px"
    >
      <div
        class="col-5 pt-1"
        style="width: 200px; max-height: 438px; overflow: auto"
      >
        <div
          v-for="(y, i) in years"
          :key="y"
          class="sort-option text-left"
          :class="{ first: i === 0, active: year == y }"
          @click="year = y;"
        >
          {{ y }}
        </div>
      </div>

      <div
        class="col-7 pt-1"
        style="max-width: 350px"
      >
        <div
          v-for="(m, i) in months"
          :key="m.id"
          class="sort-option text-left"
          :class="{ first: i === 0, active: month && month.id == m.id }"
          @click="month = m; select(m)"
        >
          {{ m.name }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import colors from '@/utils/colors';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: Object,
    futureOnly: Boolean,
    highlightDay: String,
  },
  data: () => ({
    year: moment().format('YYYY'),
    month: {
      id: `${moment().year()}_${moment().month() + 1}`,
      name: moment().format('MMMM YYYY'),
      from: moment().startOf('month').format(),
      to: moment().endOf('month').format(),
    },
  }),
  computed: {
    ...mapGetters(['payee']),
    firstYear() {
      if (!this.payee) return moment().format('YYYY');
      return moment.utc(this.payee.oldestBillingScope).year();
    },
    years() {
      let current = parseInt(moment().format('YYYY'), 10) + 1;
      const years = [];
      const now = parseInt(moment().format('YYYY'), 10);

      while (current >= this.firstYear) {
        if (!this.futureOnly || current >= now) {
          years.push(current);
        }
        current -= 1;
      }

      return years;
    },
    months() {
      const months = [];

      for (let i = 0; i < 12; i += 1) {
        months.push(i);
      }

      return months
        .map((m) => {
          const date = moment().month(m).year(this.year);

          return {
            id: `${date.year()}_${m + 1}`,
            name: date.format('MMMM YYYY'),
            from: date.startOf('month').format(),
            to: date.endOf('month').format(),
          };
        })
        .filter((x) => !this.futureOnly || moment(x.to).isAfter(moment()));
    },
  },
  watch: {
    highlightDay() {
      this.initHighlight();
    },
  },
  methods: {
    colors,
    getMoment(date) {
      return moment(date, 'YYYY-MM-DD');
    },
    select(k) {
      this.$emit('update:selected', k);
      this.$refs.popover.tippy().hide();
    },
    initHighlight() {
      if (!this.highlightDay) return;
      const date = moment(this.highlightDay, 'YYYY-MM-DD');

      this.year = date.year();
      this.month = {
        id: date.month() + 1,
        name: date.format('MMMM YYYY'),
        from: date.startOf('month').format(),
        to: date.endOf('month').format(),
      };
    },
  },
  mounted() {
    this.initHighlight();
    if (this.selected === null) {
      this.$emit('update:selected', this.month);
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "../payers/style.scss";

  .bar-percent {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 1rem;
    color: white;
    text-align: center;
  }

  .sort-option {
    &.greyed {
      background-color: rgba(#333, 0.1);
    }
    &.highlight {
      background-color: rgba($blue, 0.1);
    }
  }

  .progress {
    position: relative;
  }
</style>
