var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-page"},[_c('div',{ref:"scrollMeasure",staticClass:"scrollbar-measure"}),(_vm.payeeDemo)?_c('DemoAccountBanners'):_vm._e(),_c('div',{staticClass:"d-flex"},[(!_vm.isFullScreen)?_c('div',{staticClass:"d-none d-md-flex",staticStyle:{"width":"80px","min-width":"80px"}},[_c('CompanyMenu',{attrs:{"page":_vm.page}})],1):_vm._e(),_c('div',{staticClass:"flex-grow-1 px-1 px-md-3 d-flex flex-column hv-100 full-page-width",style:({
        'margin-top': _vm.payeeDemo ? '30px' : null,
      })},[_c('Navbar',{on:{"showAlerts":function($event){_vm.alerts = true}}}),_c('div',{staticClass:"page-cart content-height mx-auto",class:{
          'mw-100': !_vm.isFullScreen,
          'container full-page form': _vm.isFullScreen,
          'form-expanded': _vm.isFullScreen && _vm.expanded,
        }},[_c('RouterView',{on:{"page":function($event){_vm.page = $event}}})],1),_c('Transition',{attrs:{"name":"fade"}},[(_vm.showOverlay)?_c('div',{staticClass:"overlay"}):_vm._e()])],1)]),_c('CompanyMenu',{staticClass:"d-block d-md-none",attrs:{"page":_vm.page,"mobile":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }