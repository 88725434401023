<template>
  <div class="full-page">
    <div
      ref="scrollMeasure"
      class="scrollbar-measure"
    />
    <DemoAccountBanners v-if="payeeDemo" />
    <div class="d-flex">
      <div
        v-if="!isFullScreen"
        class="d-none d-md-flex"
        style="width: 80px; min-width: 80px"
      >
        <CompanyMenu :page="page" />
      </div>
      <div
        class="flex-grow-1 px-1 px-md-3 d-flex flex-column hv-100 full-page-width"
        :style="{
          'margin-top': payeeDemo ? '30px' : null,
        }"
      >
        <Navbar
          @showAlerts="alerts = true"
        />
        <div
          class="page-cart content-height mx-auto"
          :class="{
            'mw-100': !isFullScreen,
            'container full-page form': isFullScreen,
            'form-expanded': isFullScreen && expanded,
          }"
        >
          <RouterView
            @page="page = $event"
          />
        </div>
        <Transition name="fade">
          <div
            v-if="showOverlay"
            class="overlay"
          />
        </Transition>
      </div>
    </div>
    <CompanyMenu
      class="d-block d-md-none"
      :page="page"
      :mobile="true"
    />
  </div>
</template>
<script>

import CompanyMenu from '@/components/layout/CompanyMenu';
import DemoAccountBanners from '@/components/layout/DemoAccountBanners';
import Navbar from '@/components/layout/Navbar';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    page: null,
    expanded: false,
  }),
  components: {
    DemoAccountBanners,
    CompanyMenu,
    Navbar,
  },
  provide() {
    return {
      pageExpand: this.pageExpand,
    };
  },
  computed: {
    ...mapGetters(['showOverlay', 'payeeDemo']),
    isFullScreen() {
      return this.page === 'form';
    },
  },
  methods: {
    pageExpand(value) {
      this.expanded = !!value;
    },
  },
  mounted() {
    const scrollDiv = this.$refs.scrollMeasure;
    if (scrollDiv) {
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      this.$store.commit('setScrollPadding', scrollbarWidth);
    }
  },
};
</script>

<style lang="scss" scoped>
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
  }

  .content-height {
    height: calc(100vh - #{$navbarHeight});
    padding-bottom: 0;
  }

  .full-page {
    max-width: 100vw;
    max-height: 100vh;
  }

  .container.form {
    max-width: 1200px;
  }

  .container.form.form-expanded {
    max-width: 100%;
  }

  .scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
  }

  .close {
    font-size: 40px;
    position: relative;
    top: -5px;
    right: 10px;
  }

  .mw-100 {
    max-width: 100%;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
  }

  .full-page-width {
    width: calc(100% - 80px);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 500ms;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 500ms;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .hv-100 {
    height: 100vh;
  }

  .full-page {
    padding-bottom: 0;
    overflow: hidden;
  }

  @media screen and (max-width: 767px) {
    .full-page-width {
      width: 100%;
    }
  }

</style>
