<template>
  <div>
    <div
      class="d-flex align-items-center mt-3"
    >
      <div class="d-flex align-items-center">
        <div
          style="width: 262px"
          class="pr-2"
        >
          <CtkDateTimePicker
            v-model="value"
            format="YYYY-MM-DD HH:mm"
            formatted="ddd D MMM YYYY HH:mm"
            :label="$t('settings.notify.startFrom')"
            :locale="locale"
          />
        </div>
        <div class="ml-2">
          <button
            class="btn btn-sm btn-primary"
            style="width: 100px"
            :disabled="pending || value === initValue"
            @click="update"
          >
            <span v-if="updateSuccess">
              <i class="fas fa-check" />
            </span>
            <span v-else>
              {{ $t('general.save') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CtkDateTimePicker from 'vue-ctk-date-time-picker';
import { mapGetters } from 'vuex';

export default {
  props: {
    initValue: String,
    pending: Boolean,
  },
  data: () => ({
    value: '',
    updateSuccess: false,
  }),
  components: {
    CtkDateTimePicker,
  },
  computed: {
    ...mapGetters(['locale']),
  },
  watch: {
    initValue(v) {
      this.value = v;
    },
  },
  methods: {
    update() {
      this.$emit('update', this.value);
    },
  },
  created() {
    this.value = this.initValue || '2024-01-01 18:00';
  },
};
</script>
