<template>
  <Tippy
    ref="popover"
    trigger="manual"
  >
    <template #trigger>
      <a
        href="#"
        class="small"
        style="font-size: 0.6rem"
        @click.stop.prevent="testRule"
      >
        {{ $t('settings.testRuleRun') }}
        <i class="fa-solid fa-play pl-1" />
      </a>
    </template>

    <Loader
      v-if="testPending"
      color="#fff"
    />
    <span v-else-if="error">
      <div class="text-danger">
        <i class="fa-solid fa-triangle-exclamation" />
        {{ $t('error.error') }}
      </div>
      {{ error }}
    </span>
    <span v-else>
      <div class="font-weight-bold">
        {{ result }}
      </div>
      <div>
        {{ $tc('payers.payers', result) }}
      </div>
    </span>
  </Tippy>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    rule: String,
    groupCode: String,
  },
  data: () => ({
    result: null,
    testPending: false,
    error: null,
  }),
  watch: {
    rule() {
      this.$refs.popover.tippy().hide();
      this.result = null;
    },
  },
  methods: {
    ...mapActions([
      'testPayersGroupRule',
    ]),
    testRule() {
      this.testPending = true;
      this.error = null;
      this.$refs.popover.tippy().show();

      this.testPayersGroupRule({
        data: {
          groupCode: this.groupCode || '',
          rule: this.rule,
        },
      })
        .then(({ data }) => {
          this.result = data.result.length;
        })
        .catch((err) => {
          if (err.response.data) {
            this.error = err.response.data.title;
          } else {
            this.error = this.$t('error.unknownError');
          }
        })
        .finally(() => {
          this.testPending = false;
        });
    },
  },
};
</script>
