<template>
  <div
    class="bill d-flex"
    :class="{ selected: inBasket, deselected: showAdd }"
  >
    <div class="flex-grow-1 align-self-center">
      <div
        class="font-weight-bold"
        :style="{
          'text-decoration': showAdd ? 'line-through' : null
        }"
      >
        {{ title }}
      </div>
      <div class="d-md-flex align-items-center mt-1">
        <div class="bill-payee-name">
          {{ payer.payeeName }}
        </div>
        <span
          v-if="payer.payerName"
          class="info-divider d-none d-md-flex"
        >|</span>
        <div class="bill-payer-name">
          {{ payer.payerName }}
        </div>
        <span
          v-if="!hideDate"
          class="info-divider d-none d-md-flex"
        >|</span>
        <div
          v-if="!hideDate"
          class="bill-due-date"
          :class="{ overdue: isOverdue }"
        >
          <i
            v-if="isOverdue"
            class="far fa-calendar-exclamation"
          />
          <i
            v-else
            class="far fa-calendar"
          />
          {{ formatDate(dueDate) }}
        </div>
      </div>
    </div>
    <div class="align-self-center pr-2">
      <div
        v-if="amount > paid"
        class="money-font text-right text-nowrap"
      >
        {{ formatCurrency(Math.max(0, amount - paid), currency) }}
      </div>
      <div
        v-if="paid > 0"
        class="text-secondary small"
      >
        {{ $t('client.payment.paid') }}:
        <span
          v-if="paid > 0 && paid < amount"
          class="money-font"
        >
          {{ formatCurrency(paid, currency, true) }} / {{ formatCurrency(amount, currency) }}
        </span>
        <span
          v-else-if="paid > 0"
          class="money-font"
        >
          {{ formatCurrency(paid, currency) }}
        </span>
      </div>
    </div>
    <div
      v-if="showRemove"
      class="basket-icon d-flex align-items-center justify-content-center"
      @click="toggle"
    >
      <i class="far fa-trash" />
    </div>
    <div
      v-else-if="showAdd"
      class="basket-icon d-flex align-items-center justify-content-center"
      @click="toggle"
    >
      <i class="far fa-cart-plus" />
    </div>
    <div
      v-else-if="showBasket"
      class="basket-icon d-flex align-items-center"
      :class="{ selected: inBasket }"
      @click="toggle"
    >
      <i
        v-if="!inBasket"
        class="far fa-cart-plus"
      />
      <i
        v-else
        class="far fa-cart-circle-check"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    payerId: String,
    billId: String,
    title: String,
    paid: Number,
    amount: Number,
    currency: String,
    dueDate: String,
    gatewayId: String,
    accountNumber: String,
    hideDate: Boolean,
    showRemove: Boolean,
    showAdd: Boolean,
    payer: Object,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'billInBasket']),
    isOverdue() {
      return moment(this.dueDate).isBefore(moment()) && this.amount > this.paid;
    },
    inBasket() {
      return this.billInBasket({ payerId: this.payerId, billId: this.billId });
    },
    showBasket() {
      return !!this.accountNumber && this.amount > this.paid;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM');
    },
    toggle() {
      const bill = {
        payeeId: this.payer.payeeId,
        payerId: this.payerId,
        billId: this.billId,
        title: this.title,
        gatewayId: this.gatewayId,
        accountNumber: this.accountNumber,
        value: Math.max(0, this.amount - this.paid),
        currency: this.currency,
      };

      this.$store.commit('toggleBillBasket', bill);
    },
  },
};
</script>

<style lang="scss" scoped>
.bill {
  box-shadow: 0 2px 4px #eee, -1px 2px 4px #eee;
  margin-bottom: 10px;
  padding: 10px;
  border-left: 2px solid #888;
  font-size: 14px;
  background-color: white;

  &.selected {
    border-left: 2px solid $blue;
  }

  &.deselected {
    border-left: 2px solid #aaa;
    opacity: 0.6;
  }
}

.bill-payee-name {
  font-size: 12px;
  color: $blue;
  line-height: 1;
}

.bill-payer-name {
  color: #666;
  font-size: 12px;
}

.bill-due-date {
  font-size: 12px;

  &.overdue {
    color: $red;
  }
}

.info-divider {
  padding-left: 10px;
  padding-right: 10px;
  color: #ccc;
}

.basket-icon {
  background-color: #eee;
  padding: 5px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  text-align: center;
  font-size: 20px;
  width: 45px;
  text-align: center;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background-color: #bbb;
  }

  &.selected {
    background-color: $blue;
    color: white;

    &:hover {
      background-color: darken($blue, 10);
    }
  }
}
</style>
