<template>
  <div>
    <div
      v-if="topBills.length > 0"
      class="d-flex justify-content-end text-secondary small text-right pb-1"
      style="font-size: 11px; padding: 0 10px"
    >
      <div style="width: 150px">
        {{ $t('report.balance.paid') }}
      </div>
      <div style="width: 150px">
        {{ $t('report.balance.due') }}
      </div>
    </div>
    <div
      v-for="b in topBills"
      :key="b.billId"
    >
      <BillPayerRow
        class="payer-row"
        :name="b.title"
        :paid="b.paid"
        :amount="b.amount"
        :currency="b.currency"
        :color="categoryColor(b.categoryId)"
        @select="showPayerBillDetails(b)"
      >
        <template #prefix>
          <CategoryInfo
            :category-id="b.categoryId"
            color-only
          />
        </template>
      </BillPayerRow>
    </div>
    <div
      v-if="bills.length === 0"
      class="small text-center text-secondary"
    >
      {{ $t('bill.noneToPay') }}
    </div>
    <div
      v-else-if="!more && bills.length > 0"
      class="d-flex justify-content-center"
      @click="$emit('more')"
    >
      <div class="small see-more px-4">
        <i class="fas fa-chevron-down" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BillPayerRow from '@/components/bills/edit/BillPayerRow';
import CategoryInfo from '@/components/CategoryInfo';
import moment from 'moment';

const takeBills = 3;

export default {
  props: {
    payerId: String,
    more: Boolean,
  },
  data: () => ({
    selected: null,
    bills: [],
    pending: false,
  }),
  components: {
    BillPayerRow,
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['formatMoney', 'payee', 'categoryColor']),
    topBills() {
      return this.more
        ? this.bills
        : this.bills.slice(0, takeBills);
    },
  },
  methods: {
    ...mapActions([
      'getOutstandingBills',
    ]),
    showPayerBillDetails(bill) {
      this.$emit('select', {
        billId: bill.billId,
        billTitle: bill.title,
        currency: bill.currency,
        color: this.categoryColor(bill.categoryId),
      });
    },
    select(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
      }
    },
    requestBills() {
      this.pending = true;
      this.getOutstandingBills({
        params: {
          query: {
            payerIds: [this.payerId],
            payment: 0,
            currency: this.payee.baseCurrency,
            billingDay: moment().format('YYYY-MM-DD'),
          },
        },
      })
        .then(({ data }) => {
          this.bills = data.reverse();
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.requestBills();
  },
};
</script>

<style lang="scss" scoped>
  .see-more {
    padding: 2px 15px;
    margin-top: 2px;
    cursor: pointer;
    background-color: #fbfbfb;
    border-radius: 15px;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .payer-bill-row {
    margin: 0 0 5px 0;
    z-index: 2;
    padding: 5px 10px;
    background-color: #fbfbfb;
    border-radius: 10px;
    position: relative;
    transition: transform 500ms;

    .client-name {
      font-weight: 500;
    }
  }

  .payer-row {
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .payer-row::v-deep .money-font {
    font-size: 0.8rem;
  }
</style>
