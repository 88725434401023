<template>
  <Tippy
    theme="light no-padding"
    arrow
    class="test-class"
    :max-width="width"
    :hide-on-click="false"
    :delay="[300, 0]"
    interactive
  >
    <template #trigger>
      <slot name="trigger">
        <i
          class="fas fa-info-circle info-icon"
          :class="{ error, warning }"
        />
      </slot>
    </template>
    <div
      class="setting-description font-weight-bold d-flex"
      style="font-size: 14px"
      :class="{ error, warning }"
    >
      <div>
        <div class="info-wrapper d-flex align-items-center justify-content-center">
          <i class="fas fa-info" />
        </div>
      </div>
      <div class="text-left">
        <slot />
      </div>
    </div>
  </Tippy>
</template>

<script>
import colors from '@/utils/colors';

export default {
  props: {
    text: String,
    error: Boolean,
    warning: Boolean,
    width: {
      type: Number,
      default: 600,
    },
  },
  data: () => ({
    lightGrey: colors('light-gray'),
  }),
};
</script>

<style lang="scss">
.tippy-tooltip.no-padding-theme {
  padding: 0 !important;
  border-radius: 15px;
  opacity: 0.95;
}
</style>

<style lang="scss" scoped>

.setting-description {
  border-radius: 15px;
  border: 2px solid $blue;
  padding: 0.7rem;
  background-color: rgba(lighten($darkblue, 57), 0.9);
  color: $darkblue;

  .info-wrapper {
    background-color: $darkblue;
    color: lighten($darkblue, 57);
    border-radius: 50%;
    text-align: center;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.7rem;

    i {
      font-size: 0.75rem;
    }
  }

  &.warning {
    background-color: rgba(lighten($orange, 40), 0.9);
    border: 2px solid $orange;
    color: $orange;

    .info-wrapper {
      background-color: $orange;
      color: lighten($orange, 57);
    }
  }

  &.error {
    background-color: rgba(lighten($red, 40), 0.9);
    border: 2px solid $red;
    color: $red;

    .info-wrapper {
      background-color: $red;
      color: lighten($red, 57);
    }
  }
}

.info-icon {
  color: $light-gray;
  cursor: pointer;

  &:hover {
    color: #333;
  }

  &.warning {
    color: $orange;

    &:hover {
      color: darken($orange, 10);
    }
  }

  &.error {
    color: $red;

    &:hover {
      color: darken($red, 10);
    }
  }

}
</style>
