<template>
  <div>
    <div class="filter-header">
      {{ $t('report.balance.payerProperties') }}
    </div>

    <div
      class="sort-option-selected"
      @click="modal = true"
    >
      <div class="d-flex align-items-center h-100">
        <i
          class="far fa-tags text-secondary"
          style="font-size: 18px"
        />
        <span
          style="font-size: 14px"
          class="pl-2"
        >{{ layout.length }}</span>
      </div>
    </div>

    <BModal
      v-model="modal"
      hide-footer
      hide-header
      size="lg"
      lazy
    >
      <PayersPropertiesColumns
        :layout.sync="localLayout"
        no-categories
      />

      <hr>
      <div class="d-flex justify-content-end">
        <div class="mx-1">
          <button
            class="btn btn-sm btn-block btn-secondary"
            style="width: 150px"
            @click="modal = false"
          >
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="mx-1">
          <button
            class="btn btn-sm btn-block btn-primary"
            style="width: 150px"
            @click="save"
          >
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import PayersPropertiesColumns from '@/components/payers/PayersPropertiesColumns';
import { mapGetters } from 'vuex';

export default {
  props: {
    layout: Array,
  },
  data: () => ({
    modal: false,
    localLayout: [],
  }),
  components: {
    PayersPropertiesColumns,
  },
  computed: {
    ...mapGetters([
      'payee',
      'payeeProperty',
      'userGroups',
      'properties',
    ]),
  },
  watch: {
    modal() {
      this.localLayout = [...this.layout];
    },
    layout(l) {
      this.localLayout = [...l];
    },
  },
  methods: {
    save() {
      this.$emit('update:layout', [...this.localLayout]);
      this.modal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './small-filter.scss';

.page-setting {
  color: #aaa;
  font-size: 1.2rem;
  padding: 0;
  width: 44px;
  text-align: center;
}

.draggable {
  border: 1px solid transparent;
  border-radius: 10px;
  min-height: 50px;
  background-color: #f8f8f8;
  padding: 5px;
  margin-top: 5px;
  min-height: 200px;
  padding-bottom: 40px;
}

.draggable.moving {
  border: 1px solid #aaa;
}

.moving * {
  cursor: grabbing;
}

.remove-button {
  padding-left: 10px;
  color: #aaa;
  cursor: pointer;
}

.column {
  padding: 0.3rem 0.6rem;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #eee;
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
}
</style>
