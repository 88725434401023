<template>
  <div class="h-100 d-flex flex-column">
    <div class="d-flex mb-3 mt-1">
      <PayersGroupFilterDropdown
        :selected.sync="groupFilter"
        class="mr-3"
        :hide-size="!!excludeBillId"
        hide-edit
      />
      <SearchBox
        v-model="search"
        lazy
        class="mr-3"
      />
    </div>
    <div class="row flex-grow-1 pt-1">
      <div class="col-8 d-flex flex-column h-100">
        <div>
          <div class="row no-gutters align-items-center">
            <div class="col-7 d-flex align-items-center">
              <Checkbox
                :value="allToggled"
                class="pr-1 align-items-center"
                @update:value="toggleAll($event)"
              >
                <div class="mb-0 label sm-label">
                  {{ $t('general.selectAll') }}
                </div>
              </Checkbox>
            </div>
          </div>

          <hr class="mb-1 mt-2">

          <div
            class="info-row d-none d-md-flex"
          >
            <div
              class="pl-2"
              style="margin-left: 32px; width: 350px"
            >
              <label class="mb-0 column-header">
                {{ $t('client.name') }}
              </label>
            </div>
            <div
              class="flex-grow-1"
            >
              <label class="mb-0 column-header">
                {{ $t('client.clientCode') }}
              </label>
            </div>
          </div>
        </div>
        <div
          ref="payersList"
          style="overflow: auto; padding-bottom: 20px"
          :style="{ height: `calc(100vh - ${payersListHeight}px)` }"
        >
          <div
            v-for="payer in payers"
            :key="payer.id"
            data-test="client-list-element"
            class="client-row w-100"
            @click="togglePayer(payer)"
          >
            <SimplePayerRow
              class="list-complete-item"
              v-bind="payer"
              :checked="!!selected.find(el => el.id === payer.id)"
              :disabled="true"
            />
          </div>
          <InfinityScrollCursor
            ref="cursor"
            @request="request"
          />
          <div v-if="busy">
            <Loader />
          </div>
        </div>
      </div>
      <div class="col-4 d-flex flex-column h-100">
        <div class="d-flex justify-content-between">
          <h3 class="column-header">
            {{ $t('form.selected') }} ({{ selected.length }})
          </h3>
          <h3
            class="column-header text-secondary clear-all"
            @click="clearAll"
          >
            <i class="fas fa-trash pr-1" />
            {{ $t('form.clearAll') }}
          </h3>
        </div>
        <div
          class="selected-clients"
          style="overflow: auto"
          :style="{ height: `calc(100vh - ${payersListHeight}px)` }"
        >
          <div
            v-for="(payer, i) in selected"
            :key="payer.id"
            @click="unselectClient(payer)"
          >
            <SimplePayerRow
              class="list-complete-item"
              v-bind="payer"
              :checked="true"
              :disabled="true"
              :style="i + 1 === selected.length ? 'border-bottom: none' : ''"
            >
              <template #select>
                <i
                  class="close-btn far fa-times-circle"
                  style="padding: 0 8px"
                />
              </template>
            </SimplePayerRow>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import PayersGroupFilterDropdown from '@/components/payers/PayersGroupFilterDropdown';
import SimplePayerRow from '@/components/payers/SimplePayerRow';
import Checkbox from '@/components/utils/Checkbox';
import { mapActions } from 'vuex';

export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    excludeBillId: String,
    excludeBillingPlanId: String,
  },
  data: () => ({
    search: '',
    filter: [],
    groupFilter: '#all',
    bottom: false,
    busy: false,
    payers: [],
    take: 50,
    skip: 0,
    requestId: 0,
    payersListHeight: 0,
  }),
  components: {
    PayersGroupFilterDropdown,
    InfinityScrollCursor,
    SimplePayerRow,
    Checkbox,
  },
  computed: {
    allToggled: {
      get() {
        return this.payers.length > 0 && this.payers.every((x) => this.selected.some((y) => x.id === y.id));
      },
      set() {
      },
    },
  },
  watch: {
    search() {
      this.skip = 0;
      this.bottom = false;
      this.busy = false;
      this.payers = [];
      this.requestId += 1;
      this.request();
    },
    groupFilter() {
      this.skip = 0;
      this.bottom = false;
      this.busy = false;
      this.payers = [];
      this.requestId += 1;
      this.request();
    },
  },
  methods: {
    ...mapActions([
      'getPayers',
    ]),
    request(all) {
      if ((this.bottom || this.busy) && !all) return Promise.resolve();
      this.busy = true;
      this.error = null;
      const id = this.requestId;

      return this.getPayers({
        params: {
          query: {
            skip: all ? 0 : this.skip,
            take: all ? -1 : this.take,
            group: this.groupFilter === '#all'
              ? undefined
              : this.groupFilter,
            search: this.search.length === 0
              ? undefined
              : this.search,
            excludeBillId: this.excludeBillId,
            excludeBillingPlanId: this.excludeBillingPlanId,
          },
        },
      })
        .then(({ data }) => {
          if (all) {
            this.payers = data;
            this.bottom = true;
            this.busy = false;
            return;
          }

          if (id !== this.requestId) {
            return;
          }

          this.skip += this.take;

          const uniquePayers = data.filter((x) => !this.payers.some((y) => y.id === x.id));
          this.payers = [...this.payers, ...uniquePayers];

          if (data.length === 0) {
            this.bottom = true;
            this.busy = false;
          }
          setTimeout(() => {
            this.busy = false;
          }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
    clearAll() {
      this.$emit('update:selected', []);
    },
    toggleAll(v) {
      if (v) {
        this.request(true)
          .then(() => {
            const toAdd = this.payers.filter((x) => !this.selected.some((y) => y.id === x.id));
            this.$emit('update:selected', this.selected.concat(toAdd));
          });
      } else {
        const selected = this.selected.filter((x) => !this.payers.some((y) => y.id === x.id));
        this.$emit('update:selected', selected);
      }
    },
    togglePayer(payer) {
      if (this.selected.some((x) => x.id === payer.id)) {
        this.$emit('update:selected', this.selected.filter((x) => x.id !== payer.id));
      } else {
        this.$emit('update:selected', this.selected.concat([payer]));
      }
    },
    unselectClient(payer) {
      this.$emit('update:selected', this.selected.filter((x) => x.id !== payer.id));
    },
  },
  mounted() {
    this.updatePayersListHeight = () => {
      const payersList = this.$refs.payersList.getBoundingClientRect();
      this.payersListHeight = payersList.top;
    };
    this.updatePayersListHeight();
    window.addEventListener('resize', this.updatePayersListHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.updatePayersListHeight);
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .info-row {
    border-bottom: 1px solid #dddddd;
    font-size: 12px;
    font-weight: 400;
    padding-top: 3px;
    padding-bottom: 7px;
  }

  .sm-label {
    font-size: 12px;
  }

  .selected-clients {
    box-shadow: 1px 3px 8px #ddd;
    border-radius: 10px;
    padding: 10px;
  }
  .clear-all {
    cursor: pointer;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
      color: $red !important;
    }
  }

  .selected-clients {

    .list-complete-item:hover {
      .close-btn {
        transition: color 250ms;
      }
    }

    .list-complete-item:hover {
      .close-btn {
        color: $red;
      }
    }
  }
</style>
