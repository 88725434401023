<template>
  <div class="h-100">
    <div class="page-title-wrapper">
      <div
        class="page-title"
        @click="$router.push(`/payee/${payeeId}/bills`)"
      >
        {{ $t('menu.bills') }}
      </div>
      <div class="page-title active ml-4">
        {{ $t('bill.plans') }}
      </div>
      <div
        v-if="accountingEnabled"
        class="page-title ml-4"
        @click="$router.push(`/payee/${payeeId}/bills/documents`)"
      >
        {{ $t('menu.documents') }}
      </div>
    </div>

    <div
      class="bg-white"
      style="margin: 0 -20px; padding: 0 20px;"
    >
      <div
        class="d-flex align-items-center justify-content-between"
        style="height: 44px"
      >
        <div class="d-flex">
          <IsEnabledDropdown
            :selected.sync="enabledFilter"
            class="mr-3"
          />
          <PayersFilterDropdown
            class="mr-3"
            @selected="selectedPayers = $event"
          />
          <SearchBox
            v-if="!focus"
            v-model="search"
            lazy
            class="mr-3"
          />
        </div>
        <div class="d-flex">
          <RouterLink
            v-show="!focus"
            :to="`/payee/${payeeId}/bills/form?plan=1`"
          >
            <button
              class="btn btn-sm btn-primary px-3"
              data-test="toggle-add-bill"
              style="border-radius: 15px"
            >
              <i class="fas fa-add px-1" />
              {{ $t('billingPlan.add') }}
            </button>
          </RouterLink>
        </div>
      </div>
      <hr
        v-show="!focus"
        class="mb-1 mt-2"
      >
    </div>

    <ColumnEdit
      v-if="!pending"
      :focused="focus"
      :edit="!!edited"
    >
      <div class="row no-gutters my-2 px-2 info-row">
        <div class="col-sm-6">
          <label class="column-header">{{ $t('payment.title') }}</label>
        </div>
        <div class="col d-none d-md-block">
          <label class="column-header">{{ $t('payment.repeatEvery') }}</label>
        </div>
        <div class="col d-none d-md-block">
          <label class="column-header">{{ $t('billingPlan.enabled') }}</label>
        </div>
        <div
          v-if="!edited"
          class="col d-none d-md-block text-right column-header"
        >
          <label class="column-header">{{ $t('menu.payers') }}</label>
        </div>
        <div
          v-if="!edited"
          class="col text-right column-header"
        >
          <label class="column-header">{{ $t('payment.due') }}</label>
        </div>
      </div>

      <div class="scroll-plans">
        <div
          v-for="plan in filteredBillingPlans"
          :key="plan.id"
          class="payment-container position-relative"
        >
          <BillingPlanRow
            :plan="plan"
            :basic="!!edited"
            :is-selected="edited && edited.id === plan.id"
            @click.native="toggle(plan)"
          />
        </div>
      </div>

      <template #edit>
        <div
          v-if="edited"
          class="p-2 page-content position-relative"
        >
          <CloseButton
            v-if="!focus"
            style="position: absolute; right: 8px; top: 8px; z-index: 100"
            @click.native.stop="edited = null"
          />
          <Transition
            name="details"
            mode="out-in"
          >
            <BillingPlanDetailsPanel
              :plan="edited"
              :focus="focus"
              class="page-cart px-4"
              @focus="focus = $event"
              @close="edited = null; focus = false"
              @refresh="requestPlans"
            />
          </Transition>
        </div>
      </template>
    </ColumnEdit>
    <div v-if="pending">
      <Loader />
    </div>
    <div
      v-else-if="billingPlans.length === 0"
      class="text-center text-secondary mt-4"
    >
      <div style="font-size: 3rem">
        <i class="far fa-memo-circle-info" />
      </div>
      {{ $t('billingPlan.none') }}
    </div>
  </div>
</template>

<script>
import IsEnabledDropdown from '@/components/IsEnabledDropdown';
import BillingPlanRow from '@/components/bills/BillingPlanRow';
import BillingPlanDetailsPanel from '@/components/bills/edit/BillingPlanDetailsPanel';
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import createSearch from '@/utils/search';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    focus: false,
    billingPlans: [],
    selectedPayers: [],
    search: '',
    edited: null,
    enabledFilter: 'all',
    pending: false,
  }),
  components: {
    BillingPlanDetailsPanel,
    PayersFilterDropdown,
    BillingPlanRow,
    IsEnabledDropdown,
    ColumnEdit,
  },
  computed: {
    ...mapGetters(['payeeId', 'payee']),
    accountingEnabled() {
      return this.payee?.accountingEnabled;
    },
    filteredBillingPlans() {
      const search = createSearch(this.search);

      return this.billingPlans
        .filter((x) => {
          if (this.enabledFilter === 'enabled') {
            return x.isEnabled;
          } if (this.enabledFilter === 'disabled') {
            return !x.isEnabled;
          }
          return true;
        })
        .filter((x) => search(x.title) || search(x.description));
    },
  },
  watch: {
    selectedPayers() {
      this.pending = true;
      this.requestPlans();
    },
  },
  methods: {
    ...mapActions(['getBillingPlans']),
    toggle(e) {
      if (this.edited != null && this.edited?.id === e?.id) {
        this.edited = null;
      } else {
        this.edited = e;
      }
    },
    requestPlans() {
      this.getBillingPlans({
        params: {
          query: {
            payerIds: this.selectedPayers.map((x) => x.id),
          },
        },
      })
        .then(({ data }) => {
          this.billingPlans = data;
          if (this.edited) {
            this.edited = this.billingPlans.find((x) => x.id === this.edited.id);
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'bills');
    this.pending = true;

    this.requestPlans();
  },
};
</script>

<style lang="scss" scoped>
  .page-header {
    position: relative;
  }

  .scroll-plans {
    overflow: auto;
    height: calc(100vh - 220px);
    padding-bottom: 40px;
  }

  .light-button {
    position: absolute;
    top: 20px;
    right: -10px;
  }

  .page-content {
    height: calc(100vh - #{$navbarHeight} - 137px);
    overflow-y: scroll;
  }
</style>
