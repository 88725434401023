<template>
  <div>
    <BFormCheckbox
      v-model="remindInvited"
      switch
      class="white-switch white-switch-sm text-left ml-2 align-items-center"
      :disabled="pending"
    >
      <small class="remind-info">
        {{ $t('activation.sendReminders') }}
      </small>
    </BFormCheckbox>
    <hr class="mt-1">

    <button
      class="btn btn-primary btn-sm btn-block"
      :disabled="pending"
      @click="activateMany"
    >
      <i class="fa-solid fa-paper-plane pr-2" />
      {{ $t('activation.send') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    payerIds: Array,
  },
  data: () => ({
    pending: false,
    remindInvited: false,
  }),
  methods: {
    ...mapActions(['inviteUsersToPayers']),
    activateMany() {
      this.pending = true;

      this.inviteUsersToPayers({
        data: {
          payerIds: this.payerIds,
          remindInvited: this.remindInvited,
        },
      })
        .finally(() => {
          this.pending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.remind-info {
  color: #333;
  text-transform: none;
  font-weight: 500;
}
</style>
