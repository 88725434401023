<template>
  <div style="margin: 5px; padding-top: 10px">
    <BFormInput
      v-model="name"
      :disabled="!isActive"
      :placeholder="$t('settings.categories.name')"
      size="sm"
    />

    <BFormCheckbox
      v-if="isActive"
      v-model="isDefault"
      :disabled="!!(category && category.isDefault)"
      style="line-height: 1.8; margin-top: 10px; margin-left: -8px"
    >
      {{ $t('settings.categories.default') }}
    </BFormCheckbox>

    <hr
      v-if="isActive"
      class="mt-2"
    >

    <ColorPicker
      v-if="isActive"
      :selected.sync="colorCode"
    />

    <div
      v-if="error || errDefault"
      class="small text-danger mt-2"
    >
      <i class="fas fa-info-circle info-icon" />
      {{ error ? error : $t('settings.errBillChannelDefault') }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ColorPicker from './ColorPicker';

const defaultColor = 'default';

export default {
  props: {
    category: Object,
    pending: Boolean,
  },
  data: () => ({
    isDefault: false,
    isActive: true,
    name: '',
    colorCode: defaultColor,
    error: null,
    errDefault: false,
  }),
  components: {
    ColorPicker,
  },
  methods: {
    ...mapActions([
      'addBillsCategory',
      'updateBillsCategory',
      'deleteBillsCategory',
    ]),
    submit() {
      if (this.category) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      this.$emit('update:pending', true);
      this.updateBillsCategory({
        params: {
          categoryId: this.category.id,
        },
        data: {
          name: this.name,
          isDefault: this.isDefault,
          colorCode: this.colorCode,
        },
      })
        .then(({ data }) => {
          this.name = '';
          this.$emit('update:pending', false);
          this.$store.commit('setCategories', data.categories);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    add() {
      this.$emit('update:pending', true);
      this.addBillsCategory({
        data: {
          name: this.name,
          isDefault: this.isDefault,
          colorCode: this.colorCode,
        },
      })
        .then(({ data }) => {
          this.name = '';
          this.$emit('update:pending', false);
          this.$store.commit('setCategories', data.categories);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    remove() {
      if (this.category.isDefault) {
        this.errDefault = true;
        return;
      }
      this.$emit('update:pending', true);
      this.deleteBillsCategory({
        params: {
          categoryId: this.category.id,
        },
      })
        .then(({ data }) => {
          this.$emit('update:pending', false);
          this.$store.commit('setCategories', data.categories);
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          if (data.type) {
            this.error = this.$t(`error.type.${data.type}`);
          }
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.category) {
      this.isDefault = this.category.isDefault;
      this.name = this.category.name;
      this.isActive = this.category.isActive;
      this.colorCode = this.category.colorCode || defaultColor;
    }
  },
};
</script>

<style>

</style>
