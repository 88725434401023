<template>
  <div
    class="listing"
    :class="{
      ordered: !!ordered,
      cancelled: ordered && basketEntry
    }"
  >
    <div class="listing-name">
      {{ listing.name }}
    </div>
    <div class="d-flex justify-content-between">
      <div class="money-font">
        {{ formatCurrency(listing.price, listing.currency) }}
      </div>

      <div class="listing-icon">
        <i
          v-if="ordered && basketEntry"
          class="fas fa-ban text-primary"
        />
        <i
          v-else-if="ordered"
          class="fas fa-circle-check text-primary"
        />
        <i
          v-else-if="!basketEntry"
          class="fas fa-plus"
        />
        <i
          v-else
          class="fas fa-cart-shopping text-primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    listing: Object,
    payerId: String,
    ordered: Object,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
      'catalogBasket',
    ]),
    basketEntry() {
      return this.catalogBasket
        .find((x) => x.payerId === this.payerId && x.offerId === this.listing.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.listing {
  background-color: white;
  border-radius: 15px;
  padding: 10px 12px;
  font-size: 0.9rem;
  border: 1px solid transparent;
  cursor: pointer;

  &.ordered {
    background-color: #D9E9FF;
  }

  &.cancelled {
    .listing-name {
      text-decoration: line-through;
    }
  }

  &:hover, &:active {
    border: 1px solid #aaa;
  }
}

.listing-icon {
  font-size: 18px;
}

.listing-name {
  font-weight: 500;
}
</style>
