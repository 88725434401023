<template>
  <div
    class="d-flex align-items-center payer-row payer-bill-row"
    :class="{ invalid: paid > amount }"
    @click="$emit('select')"
  >
    <slot name="prefix" />
    <div
      class="client-name flex-grow-1"
      :class="{ archived }"
    >
      <i
        v-if="archived"
        v-tippy
        class="far fa-box-archive mr-2"
        :content="$t('payers.archived')"
      />{{ name }}
    </div>
    <slot name="content">
      <div class="position-relative">
        <ProgressBar
          v-if="!isPlan && amount > 0"
          :value="round(100*(paid / amount))"
          class="payer-bar"
          :color="color"
        />
      </div>
      <div style="width: 270px">
        <div class="text-right d-flex no-gutters">
          <div
            v-if="!isPlan"
            class="money-font col"
          >
            {{ formatMoney(paid, currency) }}
          </div>
          <div
            class="money-font col"
            style="min-width: 110px"
          >
            {{ formatMoney(amount, currency) }}
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProgressBar from '@/components/charts/ProgressBar';

export default {
  props: {
    name: String,
    paid: Number,
    amount: Number,
    isPlan: Boolean,
    currency: String,
    color: String,
    archived: Boolean,
  },
  data: () => ({
    pending: false,
    error: null,
    value: null,
    addToExcess: true,
    added: 0,
    chosenCurrency: '',
  }),
  filters: {
    round(v) {
      return Math.round(v * 100) / 100;
    },
  },
  components: {
    ProgressBar,
  },
  computed: {
    ...mapGetters(['formatMoney']),
    progressAdd() {
      if (!this.editing) return 0;
      return this.amount - this.paid;
    },

  },
  watch: {
    editing(v) {
      if (!v) {
        this.added = 0;
      }
    },
  },
  methods: {
    round(v) {
      return Math.floor(v);
    },
    toggle() {
      if (this.template) return;
      if (this.editing) {
        this.$emit('close');
        this.$emit('editing', null);
      } else {
        this.$emit('editing', this.client.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .payment-wrapper {
    transition: all 300ms;
  }

  .payer-bar {
    width: 100px;
    padding-right: 20px;
    height: 16px;
  }

  .payer-row {
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .money-font {
    font-size: 0.9rem;
  }

  .template-row {
    position: relative;
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 2px;
    &:hover {
      background-color: #eee;
    }
  }

  .payer-bill-row {
    margin: 0 0 5px 0;
    z-index: 2;
    padding: 5px 10px;
    background-color: #fbfbfb;
    border-radius: 10px;
    position: relative;
    transition: transform 500ms;

    .client-name {
      font-weight: 500;

      &.archived {
        font-weight: 400;
        text-decoration: line-through;
      }
    }

    &.invalid {
      background-color: rgba($red, 0.1);
    }
  }

  // .gray-row {
  //   opacity: 0.5;
  //   .progress > .progress-bar {
  //     background-color: $gray;
  //   }
  // }

  .checkbox-comment {
    position: absolute;
    bottom: -2px;
    font-size: 10px;
    left: -1px;
  }
  .custom-control-label {
    cursor: pointer;
  }
  .paid-full {
    font-size: 12px;
    position: relative;
    padding-top: 2px;
    padding-right: 10px;
  }
  .paid-full .custom-control-label {
    line-height: 23px;
    font-weight: 500;
  }
</style>
