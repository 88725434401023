import lang from '@/lang';

export const Types = [
  {
    key: 'Person',
    icon: 'fas fa-person-simple',
    text: lang.t('person'),
  },
  {
    key: 'Organization',
    icon: 'fas fa-building',
    text: lang.t('organization'),
  },
];

export default Types;
