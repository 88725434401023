<template>
  <div>
    <div
      v-if="unset"
      style="max-width: 250px"
      class="text-secondary text-center my-2"
    >
      <i class="fa-solid fa-empty-set" />
      <div class="small">
        {{ $t('settings.accounting.notSet') }}
      </div>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col">
        <div class="column-header">
          {{ first.title }}
        </div>
        <div class="properties">
          <div
            v-for="c in first.properties"
            :key="c"
            class="property"
          >
            <i :class="icon(c)" />
            {{ name(c) }}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="column-header">
          {{ second.title }}
        </div>
        <div class="properties">
          <div
            v-for="c in second.properties"
            :key="c"
            class="property"
          >
            <i :class="icon(c)" />
            {{ name(c) }}
          </div>
        </div>
      </div>
      <div class="col">
        <div class="column-header">
          {{ third.title }}
        </div>
        <div class="properties">
          <div
            v-for="c in third.properties"
            :key="c"
            class="property"
          >
            <i :class="icon(c)" />
            {{ name(c) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    first: Object,
    second: Object,
    third: Object,
  },
  data: () => ({
    modal: false,
  }),
  computed: {
    ...mapGetters([
      'payee',
      'payeeProperty',
      'properties',
    ]),
    payerProperties() {
      return this.properties.map((x) => ({
        name: x.name,
        key: x.code,
        type: 'property',
      }));
    },
    defaultColumns() {
      return [{
        name: this.$t('general.name'),
        key: 'name',
      },
      {
        name: this.$t('client.clientCode'),
        key: 'code',
      },
      {
        name: this.$t('client.number'),
        key: 'number',
      },
      ];
    },
    columns() {
      return [
        ...this.defaultColumns,
        ...this.payerProperties,
      ];
    },
    unset() {
      const { first, second, third } = this;

      return (!first || first.properties.length === 0)
        && (!second || second.properties.length === 0)
        && (!third || third.properties.length === 0);
    },
  },
  methods: {
    name(key) {
      return this.columns.find((x) => x.key === key)?.name;
    },
    icon(key) {
      const column = this.columns.find((x) => x.key === key)?.type;
      if (column === 'property') {
        return 'far far fa-tag pr-1';
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.column-header {
  font-weight: 500;
  font-size: 14px;
}

.property {
  background-color: #f6f6f6;
  margin-top: 5px;
  border-radius: 10px;
  padding: 3px 10px;
  font-size: 14px;
}
</style>
