<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header">
          <h1>
            {{ bill.isPlan ? $t('billingPlan.add') : $t('bill.add') }}
          </h1>
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </div>
      </div>
      <hr class="mt-1">
      <div class="row align-items-center">
        <StepSelect
          class="col-9"
          :page="page"
          :enabled="filledPages"
          :list="steps"
          :previous-page="previousPage"
          :previous-bill="previousBill"
          @select="page = $event"
          @restore="restore"
          @cancel-restore="cancelRestore"
        />
        <div class="col-3 text-right">
          <button
            v-if="page !== 'summary'"
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="disabled"
            @click="next"
          >
            {{ $t('general.next') }}
            <i class="fas fa-arrow-right px-2" />
          </button>
        </div>
      </div>
      <hr class="mb-2">
    </div>

    <div
      v-if="page === 'clients'"
      class="flex-grow-1"
      style="overflow: hidden"
    >
      <PayersList
        :bill-id="bill.id"
        :selected.sync="bill.payers"
      />
    </div>
    <div
      v-else-if="page === 'price'"
      style="overflow: auto"
    >
      <PayersAmountList
        :init-amounts="bill.amounts"
        :bill-id="bill.id"
        :init-bank-account-id="(bill.bankAccountId || '').toString()"
        :init-currency="bill.currency"
        :payers="bill.payers"
        :find-default-bank-account="true"
        :init-master-amount="bill.masterAmount"
        class="pb-4"
        @accountName="bill.accountName = $event"
        @payers="bill.payers = $event"
        @masterAmount="bill.masterAmount = $event"
        @bankAccountId="bill.bankAccountId = $event"
        @currency="bill.currency = $event"
      />
    </div>
    <div
      v-else-if="page === 'title'"
      id="title-and-description"
      style="overflow: auto; height: 100%"
    >
      <BillTitleAndDescription
        :bill-is-plan.sync="bill.isPlan"
        :bill-title.sync="bill.title"
        :bill-code.sync="bill.code"
        :bill-description.sync="bill.description"
        :bill-payment-time.sync="bill.paymentTime"
        :bill-repeat-type.sync="bill.repeatType"
        :bill-repeat-number.sync="bill.repeatNumber"
        :bill-from.sync="bill.from"
        :bill-to.sync="bill.to"
        :bill-category.sync="bill.category"
        :bill-early-execution-days.sync="bill.earlyExecutionDays"
        :account-number="bill.bankAccountId"
        class="pb-4"
      />
    </div>
    <div
      v-else-if="page === 'summary'"
      class="overflow"
    >
      <Summary
        :bill="bill"
        :is-enabled.sync="bill.isEnabled"
        :pending="pending"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import BillTitleAndDescription from '@/components/bills/form/BillTitleAndDescription';
import PayersAmountList from '@/components/bills/form/PayersAmountList';
import PayersList from '@/components/bills/form/PayersList';
import StepSelect from '@/components/bills/form/StepSelect';
import Summary from '@/components/bills/form/Summary';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';

const billStorageKey = 'billForm:bill';
const pageStorageKey = 'billForm:page';

export default {
  data: () => ({
    page: 'clients',
    bill: {
      isPlan: false,
      isEnabled: true,
      masterAmount: null,
      accountName: '',
      payers: [],
      currency: 'pln', // todo: make default currency selectable
      title: '',
      code: '',
      description: '',
      repeatType: 'Month',
      bankAccountId: null,
      paymentTime: '14',
      repeatNumber: '1',
      earlyExecutionDays: 1,
      category: null,
      notify: true,
      from: moment.utc().format('YYYY-MM-DD'),
      to: null,
    },
    pending: false,
    previousBill: null,
    previousPage: null,
  }),
  components: {
    StepSelect,
    PayersList,
    PayersAmountList,
    BillTitleAndDescription,
    Summary,
  },
  computed: {
    ...mapGetters(['payeeId', 'selectedPayers']),
    ...mapState({
      bankAccounts: (state) => state.bankAccount.bankAccounts,
    }),
    steps() {
      return [
        {
          key: 'clients',
          text: this.$t('form.selectPayers'),
        },
        {
          key: 'price',
          text: this.$t('form.selectAmount'),
        },
        {
          key: 'title',
          text: this.$t('form.titleAndDescription'),
        },
        {
          key: 'summary',
          text: this.$t('form.summary'),
        },
      ];
    },
    disabled() {
      switch (this.page) {
        case 'clients':
          return this.bill.payers.length === 0;
        case 'price':
          return !this.bill.payers.every((x) => x.amount !== null && x.amount !== undefined) || !this.bill.currency;
        case 'title':
          return !this.bill.title.length > 0 && this.bill.category;
        default:
          return false;
      }
    },
    filledPages() {
      const index = this.steps.findIndex((x) => x.key === this.page);
      return this.steps.flatMap((x, i) => (i <= index ? [x.key] : []));
    },
  },
  watch: {
    bill: {
      deep: true,
      handler(b) {
        localStorage.setItem(billStorageKey, JSON.stringify(b));
      },
    },
    page(p) {
      localStorage.setItem(pageStorageKey, p);
    },
  },
  methods: {
    ...mapActions([
      'addBill',
      'addBillingPlan',
    ]),
    restore() {
      this.bill = this.previousBill;
      this.page = this.previousPage;
    },
    cancelRestore() {
      localStorage.removeItem(billStorageKey);
      localStorage.removeItem(pageStorageKey);
    },
    save() {
      if (this.bill.isPlan) {
        this.createBillingPlan();
      } else {
        this.createBill();
      }
    },
    createBillingPlan() {
      this.addBillingPlan({
        data: {
          title: this.bill.title,
          code: this.bill.code,
          description: this.bill.description,
          currency: this.bill.currency,
          categoryId: this.bill.category,
          daysForPayment: this.bill.paymentTime,
          masterAmount: this.bill.masterAmount,
          accountNumber: this.bill.bankAccountId,
          payers: this.bill.payers.map((x) => ({
            payerId: x.id,
            amount: x.amount * x.count,
            count: x.count,
          })),
          isEnabled: this.bill.isEnabled,
          timeRule: {
            repeatType: this.bill.repeatType,
            repeatCount: this.bill.repeatNumber,
            earlyExecutionDays: this.bill.earlyExecutionDays,
            start: this.bill.from,
            end: this.bill.to,
          },
        },
      })
        .then(() => {
          this.pending = false;
          this.cancelRestore();
          this.$router.push(`/payee/${this.payeeId}/bills/plans`);
        })
        .catch(({ response }) => {
          this.pending = false;
          if (response.data) {
            this.error = response.data.message;
          }
        });
    },
    createBill() {
      this.pending = true;
      this.addBill({
        data: {
          title: this.bill.title,
          code: this.bill.code,
          description: this.bill.description,
          currency: this.bill.currency,
          categoryId: this.bill.category,
          accountNumber: this.bill.bankAccountId,
          billingDay: moment.utc(this.bill.from, 'YYYY-MM-DD').startOf('day').format(),
          beginDate: moment.utc(this.bill.from, 'YYYY-MM-DD').startOf('day').format(),
          dueDate: this.bill.from
            ? moment.utc(this.bill.from, 'YYYY-MM-DD').add(this.bill.paymentTime, 'days')
              .startOf('day')
              .format()
            : null,
          payers: this.bill.payers.map((x) => ({
            payerId: x.id,
            amount: x.amount * x.count,
            count: x.count,
          })),
        },
      })
        .then(() => {
          this.pending = false;
          this.cancelRestore();
          this.$router.push(`/payee/${this.payeeId}/bills`);
        })
        .catch(({ response }) => {
          this.pending = false;
          if (response.data) {
            this.error = response.data.message;
          }
        });
    },
    next() {
      const index = this.steps.findIndex((x) => x.key === this.page);
      this.page = this.steps[index + 1].key;
      this.filledPages.push(this.page);
      this.previousBill = null;
      this.previousPage = null;
      localStorage.setItem(pageStorageKey, this.page);
    },
    cancel() {
      if (this.$route.query.plan) {
        this.$router.push(`/payee/${this.payeeId}/bills/plans`);
      } else {
        this.$router.push(`/payee/${this.payeeId}/bills`);
      }
    },
  },
  created() {
    if (this.$route.query.plan) {
      this.bill.isPlan = true;
    }

    if (this.$route.query.useSelectedPayers) {
      this.bill.payers = this.selectedPayers;
    }

    const previousBill = JSON.parse(localStorage.getItem(billStorageKey) || 'null');
    if (previousBill && previousBill.clientIds && previousBill.clientIds.length > 0) {
      this.previousBill = previousBill;
      this.previousPage = localStorage.getItem(pageStorageKey);
    }
    this.$emit('page', 'form');
  },
};
</script>

<style lang="scss" scoped>
  .close {
    font-size: 40px;
  }

  .overflow {
    overflow: visible;
  }

  h3 {
    font-weight: 500;
  }

  .next-btn {
    border-radius: 25px;
    padding-left: 30px;
    padding-right: 30x;
  }
  @media only screen and (max-width: 768px) {
    .overflow {
      overflow: auto;
    }
  }
</style>
