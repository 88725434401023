<template>
  <div>
    <button
      class="btn btn-sm btn-primary"
      :disabled="selectedDocuments.length === 0"
      @click.stop.prevent="sendModal = true"
    >
      <i class="fa-solid fa-paper-plane pr-2" />
      {{ $t('messages.send') }}
    </button>

    <BModal
      v-model="sendModal"
      hide-footer
      hide-header
      lazy
    >
      <CloseButton
        style="position: absolute; right: -8px; top: -10px; z-index: 100"
        @click.native.stop="sendModal = false"
      />

      <div>
        <p class="font-weight-bold mb-1">
          Powiadomienie zostanie zaplanowane do wysłania.
        </p>
        Możesz zweryfikować jego treść w zakładce<br> "Wiadomości" > "Powiadomienia"
      </div>

      <hr>
      <div class="text-center">
        <button
          class="btn btn-primary"
          :disabled="pending"
          @click="confirm"
        >
          Potwierdź wysłanie {{ selectedDocuments.length }} dokumentów.
        </button>
      </div>
    </BModal>
  </div>
</template>

<script>
import axios from '@/api/axios';

export default {
  props: {
    selectedDocuments: Array,
  },
  data: () => ({
    sendModal: false,
    pending: false,
  }),
  methods: {
    confirm() {
      this.pending = true;
      axios
        .post('accounting/notify/plan-document-delivery', {
          documentIds: this.selectedDocuments,
        })
        .then(() => {
          this.sendModal = false;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
};
</script>
