<template>
  <i
    v-tippy
    class="secondary-icon"
    :class="sharableLinkSuccess ? `fas fa-check` : `fas fa-share-alt`"
    :content="$t('paymentStatus.copyLink')"
    @click="generateAndCopyLink"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    payerId: String,
  },
  data: () => ({
    sharableLinkPending: false,
    sharableLinkSuccess: false,
  }),
  methods: {
    ...mapActions([
      'generatePaymentLink',
    ]),
    generateAndCopyLink() {
      if (this.sharableLinkPending) return;
      this.sharableLinkPending = true;
      this.generatePaymentLink({
        params: {
          query: {
            receiverIds: this.payerId,
          },
        },
      })
        .then(({ data }) => {
          navigator.clipboard.writeText(data.url).then(() => {
            this.sharableLinkSuccess = true;
            setTimeout(() => {
              this.sharableLinkSuccess = false;
            }, 2000);
          });
        })
        .finally(() => {
          this.sharableLinkPending = false;
        });
    },
  },
};
</script>

<style>

</style>
