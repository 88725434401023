<template>
  <div class="thread-content position-relative">
    <div
      v-if="thread"
      class="d-flex align-items-center action-icons"
    >
      <div
        class="action-icon back"
        @click="$emit('close')"
      >
        <i class="fa-solid fa-arrow-left" />
      </div>
      <div
        v-if="isOpen"
        v-tippy
        class="action-icon"
        :content="$t('messages.markAsUnread')"
        @click="markAsUnread"
      >
        <i class="fa-solid fa-envelope-dot" />
      </div>
      <div
        v-tippy
        :content="isOpen
          ? $t('messages.statusChange.close')
          : $t('messages.statusChange.open')"
        class="action-icon"
        @click="changeThreadStatus"
      >
        <i
          v-if="isOpen"
          class="fas fa-circle-check"
        />
        <i
          v-else
          class="fas fa-inbox"
        />
      </div>
    </div>
    <template
      v-if="!pending"
    >
      <div class="thread-header pt-4 pb-3">
        <div
          v-if="thread"
          class="d-flex"
        >
          <div
            class="title pb-1 flex-grow-1"
            data-test="thread-title"
          >
            {{ thread.title }}
          </div>
        </div>
        <Tippy
          theme="light"
          arrow
        >
          <template #trigger>
            <div class="pl-1">
              <span
                v-for="(c, index) in payers.slice(0, 5)"
                :key="c.id"
                class="client pr-1"
              >
                <span>{{ c.name }}</span>
                <span v-if="index != (payers.slice(0, 5).length-1)">,</span>
              </span>
              <span
                v-if="payers.length > 5"
                class="client font-weight-bold"
              >
                + {{ (payers.length - 5) }}
              </span>
            </div>
          </template>
          <div>
            <div
              v-for="c in payers"
              :key="c.id"
              class="client pr-1 text-left"
            >
              <span>{{ c.name }}</span>
            </div>
          </div>
        </Tippy>
      </div>
      <hr>
      <div class="messages">
        <Message
          v-for="message in messagesWithSenders"
          :key="message.id"
          v-bind="message"
          :reply-to="replyTo && replyTo.id === message.id"
          :hide-reply="payers.length == 1"
          @reply="reply(message)"
          @cancel-reply="cancelReply"
          @removed="messageRemoved(message.id)"
        />
      </div>
      <div v-if="isOpen">
        <ThreadForm
          ref="form"
          :thread-id="threadId"
          :reply-to="replyTo"
          :thread-owner-id="thread.sender.id"
          :thread-owner-type="thread.sender.type"
          :thread-recipients="payers"
          @done="messages.push($event); $emit('refresh')"
        />
      </div>
      <div
        v-else
        class="text-center text-secondary"
        style="max-width: 400px; margin: auto"
      >
        <div class="mt-4">
          <i class="fas fa-circle-check" />
        </div>
        <div class="small">
          {{ $t('messages.threadIsClosed') }}
        </div>
      </div>
    </template>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Message from './Message';
import ThreadForm from './ThreadForm';

export default {
  props: {
    threadId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    messages: [],
    thread: null,
    payers: [],
    pending: false,

    initRecipients: [],
    text: null,
    addPending: false,
    sendSms: false,
    replyTo: null,
  }),
  components: {
    ThreadForm,
    Message,
  },
  computed: {
    ...mapGetters(['payee']),
    isOpen() {
      return this.thread && this.thread.status === 'open';
    },
    messagesWithSenders() {
      if (!this.thread || !this.payers || this.payers.length === 0 || !this.payee) return [];
      return this.messages.map((x) => ({
        ...x,
        threadId: this.thread.id,
        threadOwnerId: this.thread.sender.id,
        receivers: x.payerIds && x.payerIds.length === this.payers
          ? null
          : x.payerIds?.map((id) => this.payers.find((y) => y.id === id)),
        payerIds: x.payerIds || this.payers.map((p) => p.id),
        senderNames: this.resolveSender(x.sender, x.payerIds || []),
      }));
    },
  },
  watch: {
    threadId() {
      this.request();
    },
  },
  methods: {
    ...mapActions([
      'getPayeeMessages',
      'markThreadAsRead',
      'markThreadAsUnread',
      'getUnreadMessages',
      'updateThreadStatus',
    ]),
    changeThreadStatus() {
      this.updateThreadStatus({
        params: {
          threadId: this.threadId,
          query: {
            status: this.thread.status === 'open'
              ? 'closed'
              : 'open',
            threadOwnerId: this.thread.sender.id,
          },
        },
      })
        .then(() => {
          this.$emit('refresh');
          this.$emit('close');
        });
    },
    markAsUnread() {
      const lastMessage = this.messages[this.messages.length - 1];

      this.markThreadAsUnread({
        params: {
          threadId: this.thread.id,
          query: {
            threadOwnerId: this.thread.sender.id,
            messageId: lastMessage.id,
          },
        },
      })
        .then(() => {
          this.$emit('refresh');
          this.$emit('close');
        });
    },
    cancelReply() {
      this.replyTo = null;
      this.$refs.form.unsetPayers();
    },
    messageRemoved(messageId) {
      if (this.messages.length <= 1) {
        this.$emit('refresh');
        this.$emit('back');
      }

      this.messages = this.messages.filter((x) => x.id !== messageId);
    },
    reply({ id, sender, payerIds }) {
      this.replyTo = {
        id,
        sender,
      };
      this.$refs.form.setPayers(payerIds);
    },
    resolveSender(agent, payerIds) {
      if (agent.type === 'PayeeId') {
        return [{
          id: agent.id,
          name: this.payee.name,
        }];
      }

      return payerIds.length === 0
        ? this.payers.map((x) => ({ id: x.id, name: x.name }))
        : payerIds.map((id) => ({
          id,
          name: this.payers.find((y) => y.id === id)?.name ?? '?',
        }));
    },
    request() {
      if (!this.threadId) return;
      this.pending = true;
      this.getPayeeMessages({
        params: {
          threadId: this.threadId,
        },
      })
        .then(({ data }) => {
          this.payers = data.payerNames;
          this.thread = data.thread;
          this.messages = data.messages;
          if (data.thread.unreadCount > 0) {
            this.readThread(data);
            this.$emit('read');
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
    readThread({ thread, messages }) {
      const lastMessage = messages[messages.length - 1];

      this.markThreadAsRead({
        params: {
          threadId: thread.id,
          query: {
            threadOwnerId: thread.sender.id,
            lastMessage: lastMessage.timestamp,
          },
        },
      })
        .then(() => {
          this.getUnreadMessages();
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .thread-content {
    height: 100%;
    overflow: auto;
    padding-bottom: 80px;
    padding-left: 20px;
    border-left: 2px solid #eee;
    border-top: 2px solid #eee;
    border-top-left-radius: 20px;
    margin-left: -10px;
  }

  .action-icons {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fafafa;
    margin-left: -20px;
  }

  .action-icon {
    font-size: 20px;
    color: #aaa;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;

    &.back {
      margin-left: 5px;
      margin-right: 10px;
    }

    &:hover {
      background-color: #efefef;
    }
  }

  .thread-header {
    line-height: 1;

    .title {
      font-size: 18px;
      font-weight: 500;
    }
    .client {
      font-size: 13px;
      line-height: 1;
    }
  }
</style>
