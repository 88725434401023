<template>
  <div class="pb-3">
    <div class="each-payer-title">
      <i class="fa-solid fa-person mr-1" />
      {{ $t('payment.eachPayer') }}:
    </div>
    <div
      class="mt-1 pb-3"
    >
      <div
        v-for="cat in payeeCategories"
        :key="cat.id"
        class="d-flex justify-content-between align-items-center settlement-row"
      >
        <CategoryInfo
          :category-id="cat.id"
          style="font-size: 1rem"
        />
        <div
          class="d-flex align-items-center"
          style="width: 220px"
        >
          <MoneyInput
            v-model="settlement[cat.id]"
            placeholder="0.00"
            :highlighted="settlement[cat.id] > 0"
            class="font-money text-right"
            size="sm"
            :currency="currency"
            @input="emit"
          />
          <div class="pl-2">
            <button
              class="btn btn-primary btn-sm icon-btn"
              :class="settlement[cat.id] > 0 ? 'btn-secondary' : 'btn-primary'"
              @click="toggleCategory(cat.id)"
            >
              <i
                v-if="settlement[cat.id] > 0"
                class="fas fa-minus"
              />
              <i
                v-else
                class="fas fa-plus"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        class="d-flex justify-content-between align-items-center settlement-row refund"
        :style="{
          'max-width': showRefund ? null : '300px'
        }"
        :class="{ open: showRefund }"
        style="margin-right: 0; margin-left: auto;"
        @click="showRefund = true"
      >
        <i class="fa-regular fa-arrow-turn-down-left pr-1 small" />
        <div class="flex-grow-1 pl-2">
          {{ $t('payment.creditReturn') }}
        </div>
        <div
          v-if="showRefund"
          class="d-flex align-items-center"
          style="width: 220px"
        >
          <MoneyInput
            v-model="refund"
            placeholder="0.00"
            class="font-money text-right"
            :highlighted="refund > 0"
            size="sm"
            :currency="currency"
            @input="emit"
          />
          <div class="pl-2">
            <button
              class="btn btn-sm icon-btn"
              :class="refund > 0 ? 'btn-secondary' : 'btn-primary'"
              @click.stop="toggleRefund"
            >
              <i
                v-if="refund > 0"
                class="fas fa-minus"
              />
              <i
                v-else
                class="fas fa-plus"
              />
            </button>
          </div>
          <div class="pl-2">
            <button
              class="btn btn-sm btn-secondary icon-btn"
              @click.stop="showRefund = false; refund = 0; emit()"
            >
              <i
                class="fas fa-times"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="(donations || []).length > 0 || hasDonations"
        class="donations-wrapper"
        :class="{ open: showDonations }"
      >
        <div

          class="d-flex justify-content-between align-items-center settlement-row donation mt-0"
          :style="{
            'max-width': showDonations ? null : '300px'
          }"
          :class="{ open: showDonations }"
          style="margin-right: 0; margin-left: auto;"
          @click="showDonations = true"
        >
          <i class="far fa-hand-holding-heart pr-1 small" />
          <div class="flex-grow-1 pl-2">
            {{ $t('payment.donation') }}
          </div>
          <div v-if="showDonations">
            <div class="pl-2">
              <button
                class="btn btn-sm btn-secondary icon-btn"
                @click.stop="showDonations = false; donationSettlement = {}; emit()"
              >
                <i
                  class="fas fa-times"
                />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="showDonations"
            class="donation-row"
          >
            <div
              v-for="d in (donations || [])"
              :key="d.id"
              class="d-flex align-items-center justify-content-end mt-1 mb-1"
            >
              <div class="pr-2">
                {{ d.name }}
              </div>
              <MoneyInput
                v-model="donationSettlement[d.id]"
                style="width: 190px"
                placeholder="0.00"
                class="font-money text-right"
                :highlighted="donationSettlement[d.id] > 0"
                size="sm"
                :currency="currency"
                @input="emit"
              />
              <div class="pl-2">
                <button
                  class="btn btn-sm btn-primary icon-btn"
                  :class="donationSettlement[d.id] > 0 ? 'btn-secondary' : 'btn-primary'"
                  @click.stop="toggleDonation(d.id)"
                >
                  <i
                    v-if="donationSettlement[d.id] > 0"
                    class="fas fa-minus"
                  />
                  <i
                    v-else
                    class="fas fa-plus"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import MoneyInput from '@/components/utils/MoneyInput';
import colors from '@/utils/colors';
import { mapGetters } from 'vuex';

export default {
  props: {
    group: Object,
    toSettle: Number,
    currency: String,
  },
  data: () => ({
    settlement: {},
    refund: 0,
    showRefund: false,
    donationSettlement: {},
    showDonations: false,
  }),
  components: {
    MoneyInput,
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['payee', 'formatCurrency', 'donations']),
    hasDonations() {
      return Object.keys(this.donationSettlement).length;
    },
    payeeCategories() {
      return (this.payee?.categories || [])
        .filter((x) => x.isActive || this.settlement[x.id] > 0);
    },
    toSettleForPerson() {
      return Math.floor(this.toSettle / this.group.size);
    },
  },
  watch: {
    payer() {
      this.init();
    },
  },
  methods: {
    colors,
    toggleRefund() {
      if (this.refund > 0) {
        this.refund = 0;
      } else {
        this.refund = Math.max(0, this.toSettleForPerson);
      }

      this.emit();
    },
    toggleDonation(id) {
      if (this.donationSettlement[id] > 0) {
        this.$set(this.donationSettlement, id, 0);
      } else {
        this.$set(this.donationSettlement, id, Math.max(0, this.toSettleForPerson));
      }

      this.emit();
    },
    toggleCategory(categoryId) {
      if (this.settlement[categoryId] > 0) {
        this.$set(this.settlement, categoryId, 0);
      } else {
        this.$set(this.settlement, categoryId, Math.max(0, this.toSettleForPerson));
      }
      this.emit();
    },
    emit() {
      const overpayments = this.payee.categories
        .map((x) => ({
          value: this.settlement[x.id],
          groupCode: this.group.code,
          categoryId: x.id,
        }))
        .filter((x) => x.value > 0);

      const refunds = this.refund > 0 ? [{
        value: this.refund,
        groupCode: this.group.code,
      }] : [];

      const donations = Object.keys(this.donationSettlement)
        .map((x) => ({
          value: this.donationSettlement[x],
          groupCode: this.group.code,
          donationId: x,
        }))
        .filter((x) => x.value > 0);

      this.$emit('settlement', {
        bills: [],
        overpayments,
        refunds,
        donations,
      });
    },
    getCategoryColor(categoryId) {
      return colors(this.payee?.categories.find((x) => x.id === categoryId).colorCode);
    },
    init() {
      this.settlement = {};
      this.refund = 0;

      if (!this.group.settlement) return;

      this.group.settlement.bills.forEach((b) => {
        this.$set(this.settlement, b.billId, b.value);
      });
      this.group.settlement.overpayments.forEach((b) => {
        this.$set(this.settlement, b.categoryId, b.value);
      });

      this.group.settlement.donations.forEach((b) => {
        this.$set(this.donationSettlement, b.donationId, b.value);
        if (b.value > 0) {
          this.showDonations = true;
        }
      });
      if (this.group.settlement.refunds.length > 0) {
        this.showRefund = true;
        this.refund = this.group.settlement.refunds[0].value;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.settlement-row {
  background-color: #fbfbfb;
  border-radius: 12px;
  padding: 5px 12px;
  margin-top: 5px;
  transition: opacity 200ms;
}

.refund, .donation {
  cursor: pointer;
  height: 41px;
  transition: max-width 400ms;

  &:hover {
    background-color: #eee;
  }

  &.open {
    cursor: default;
    &:hover {
      background-color: #fbfbfb;
    }
  }
}

.each-payer-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.donation-row {
  padding: 5px 12px;
  background-color: #fbfbfb;
}

.donation {
  height: auto;
  min-height: 41px;

  .donation-row {
    height: 41px;
  }
}

.donations-wrapper {
  border-radius: 12px;
  margin-top: 5px;
  &.open {
    border: 1px solid #eee;
  }
}
</style>
