<template>
  <div class="pb-5">
    <div>
      <button
        class="btn btn-outline-secondary icon-btn"
        @click="$emit('back')"
      >
        <i class="fas fa-angle-left" />
      </button>
    </div>
    <div>
      <div class="d-flex align-items-center justify-content-around mt-2">
        <span class="title text-center p-0"> {{ thread.title }} </span>
        <span
          v-if="thread.payment"
          class="title text-center p-0"
        >
          <i class="pr-1 ion ion-md-cash" />
          {{ thread.payment.title }}
        </span>
      </div>
      <hr>
      <div
        v-if="!pending "
        class="messages"
      >
        <Message
          v-for="message in messages"
          :key="message.id"
          v-bind="message"
        />
      </div>
      <div v-else>
        <Loader />
      </div>

      <div
        v-if="!pending"
        class="reply-box"
      >
        <textarea
          v-model="text"
          class="form-control"
          rows="6"
        />
        <div class="text-right actions">
          <button
            v-if="!addPending"
            class="btn btn-primary"
            @click="send"
          >
            <i class="fas fa-paper-plane pr-2" />
            {{ $t('general.send') }}
          </button>
          <div v-else>
            <Loader size="25px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Message from './Message';

export default {
  props: {
    thread: Object,
    threads: Array,
    unread: Boolean,
  },
  data: () => ({
    messages: [],
    pending: false,
    addPending: false,
    text: '',
  }),
  components: {
    Message,
  },
  methods: {
    ...mapActions([
      'getUserThreadMessages',
      'replyToThread',
      'markUserThreadAsRead',
      'getUnreadThreadCount',
    ]),
    request() {
      this.pending = true;
      this.getUserThreadMessages({
        params: {
          threadId: this.thread.id,
        },
      })
        .then(({ data }) => {
          this.pending = false;
          this.messages = data.messages.slice();

          if (data.thread.unreadCount > 0) {
            this.markAsRead(data);
          }
        });
    },
    markAsRead({ thread, messages }) {
      const lastMessage = messages[messages.length - 1];

      this.markUserThreadAsRead({
        params: {
          threadId: thread.id,
          query: {
            threadOwnerId: thread.sender.id,
            lastMessage: lastMessage.timestamp,
          },
        },
      })
        .then(() => {
          this.getUnreadThreadCount();
        });
    },
    send() {
      if (!this.text || this.text.length === 0) return;

      this.addPending = true;
      this.replyToThread({
        params: {
          threadId: this.thread.id,
        },
        data: {
          content: this.text,
        },
      })
        .then(({ data }) => {
          this.text = '';
          this.messages.push(data);
        })
        .finally(() => {
          this.addPending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .title {
    font-weight: 500;
    padding-top: 10px;
  }

  .reply-box {
    box-shadow: 0 0 5px rgba(100,100,100,0.3);
    border-radius: 10px;
    margin-bottom: 40px;

    .actions {
      padding: 5px;
    }

    textarea {
      border: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

</style>
