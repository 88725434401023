<template>
  <div>
    <div class="text-left font-weight-bold h4 mt-2 mb-2">
      {{ $t('userSettings.changePassword') }}
    </div>
    <Transition name="slide">
      <div
        v-if="error"
        class="text-danger text-center"
      >
        {{ error }}
      </div>
      <div
        v-else-if="success"
        class="text-success text-center"
      >
        <i class="fas fa-check-circle-outline" />
        {{ $t('general.saved') }}
      </div>
    </Transition>
    <form @submit.prevent="submit">
      <input
        id="emailfield"
        type="text"
        hidden
        :value="oidcUser.email"
        autocomplete="username"
      >
      <div class="form-title">
        {{ $t('userSettings.currentPassword') }}
      </div>
      <input
        v-model="oldPassword"
        type="password"
        class="form-control my-1"
        :class="{ invalid: !!error }"
        autocomplete="current-password"
        :placeholder="$t('userSettings.currentPassword')"
      >
      <div class="form-title">
        {{ $t('userSettings.newPassword') }}
      </div>
      <input
        v-model="newPassword"
        type="password"
        class="form-control my-1"
        :placeholder="$t('userSettings.newPassword')"
      >
      <div class="form-title">
        {{ $t('login.confirmPassword') }}
      </div>
      <input
        v-model="passwordConfirm"
        type="password"
        class="form-control my-1"
        autocomplete="new-password"
        :placeholder="$t('login.confirmPassword')"
      >
      <hr>
      <button
        v-if="!loading"
        class="btn btn-block btn-primary my-1"
      >
        {{ $t('general.save') }}
      </button>
      <div v-else>
        <Loader />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@/api/axios';
import oidcSettings from '@/api/oidc';

export default {
  data: () => ({
    oldPassword: null,
    newPassword: null,
    passwordConfirm: null,
    error: null,
    loading: false,
    success: false,
  }),
  computed: {
    ...mapGetters('oidc', ['oidcUser']),
  },
  methods: {
    submit() {
      this.error = null;
      if (!this.newPassword || this.newPassword.length < 5) {
        this.error = this.$t('userSettings.tooShortPassword');
        return;
      }
      if (this.newPassword !== this.passwordConfirm) {
        this.error = this.$t('userSettings.passwordsMustBeIdentical');
        return;
      }

      this.loading = true;
      axios.put(`${oidcSettings.authority}/api/password`, {
        oldPassword: this.oldPassword,
        password: this.newPassword,
      })
        .then(() => {
          this.loading = false;
          this.success = true;
          this.newPassword = null;
          this.oldPassword = null;
          this.passwordConfirm = null;
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.loading = false;
          this.error = data[0].description;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

  .form-title {
    font-weight: 500;
    padding-top: 10px;
    font-size: 0.9rem;
  }

  .invalid {
    border-color: $red;
  }

  .slide-enter,
  .slide-leave-to { opacity: 0; max-height: 0px; }

  .slide-leave,
  .slide-enter-to { opacity: 1; max-height: 500px; }

  .slide-enter-active { transition: opacity 400ms, max-height 400ms }
  .slide-leave-active { transition: opacity 200ms, max-height 200ms }
</style>
