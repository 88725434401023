<template>
  <Tippy
    ref="popover"
    theme="light"
    placement="bottom"
    ignore-attributes
    :delay="[null, 5000]"
    :z-index="1030"
    interactive
    :hide-on-click="false"
  >
    <template #trigger>
      <div
        class="d-flex align-items-center basket"
        :class="{ active: !isEmpty }"
        @click="showBasket"
      >
        <i class="far fa-cart-shopping" />
        <span class="money-font basket-total">
          {{ formatCurrency(total, currency) }}
        </span>
      </div>
    </template>

    <div class="font-weight-bold">
      <BasketItemSummary
        v-for="(list, key) in itemList"
        :key="key"
        :payee-id="list[0].payeeId"
        :single-group="shopBasketGroups.length == 1"
        :list="list"
        @topay="$set(itemToPay, key, $event)"
      />
    </div>
  </Tippy>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BasketItemSummary from './shop/BasketItemSummary';

export default {
  data: () => ({
    visible: false,
    itemToPay: {},
  }),
  components: {
    BasketItemSummary,
  },
  computed: {
    ...mapGetters([
      'basketGroups',
      'catalogBasket',
    ]),
    ...mapState({
      basket: (state) => state.userBills.basket,
    }),
    shopBasketGroups() {
      return this.basketGroups.filter((x) => x.type === 'shop');
    },
    noItems() {
      return Object.values(this.basket).length === 0
        && this.catalogBasket.length === 0;
    },
    itemList() {
      return Object.values(this.basket)
        .concat(this.catalogBasket)
        .reduce((acc, curr) => {
          const key = `${curr.payeeId}_${curr.catalogId || '00000000-0000-0000-0000-000000000000'}`;
          if (acc[key]) {
            acc[key].push(curr);
          } else {
            acc[key] = [curr];
          }
          return acc;
        }, {});
    },
    ...mapGetters(['formatCurrency']),
    isEmpty() {
      return Object.values(this.basket).length === 0
        && this.catalogBasket.length === 0;
    },
    total() {
      return Object.keys(this.itemList)
        .reduce((acc, curr) => acc + this.itemToPay[curr], 0);
    },
    currency() {
      return 'pln';
    },
  },
  watch: {
    basket() {
      if (!this.isEmpty) {
        if (!this.visible) {
          this.$refs.popover.tippy().show();
          this.visible = true;
        }

        setTimeout(() => {
          if (this.$refs.popover) {
            this.$refs.popover.tippy().hide();
            this.visible = false;
          }
        }, 4000);
      } else {
        this.$refs.popover.tippy().hide();
        this.visible = false;
      }
    },
  },
  methods: {
    showBasket() {
      if (this.isEmpty) return;
      this.$refs.popover.tippy().hide();
      this.$router.push('/payer/basket');
    },
  },
};
</script>

<style lang="scss" scoped>
.basket {
  color: #999;
  padding: 8px 17px;
  line-height: 1;
  border-radius: 20px;
  margin-right: 15px;
  background-color: #e2e2e2;

  &.active {
    background-color: darken($blue, 5);
    cursor: pointer;
    color: white;

    &:hover {
      background-color: darken($blue, 10);
    }
  }

  i {
    font-size: 20px;
  }

  .basket-total {
    font-size: 14px;
    padding-left: 8px;
  }
}
</style>
