<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ title }}
        </div>

        <div style="font-size: 14px">
          {{ selected.length }} /
          {{ options.length }}
        </div>
      </div>
    </template>

    <div class="dropdown-scroll">
      <div
        v-for="(s, i) in options"
        :key="s.key"
        class="sort-option text-left d-flex align-items-center"
        :class="{
          first: i === 0,
          'font-weight-bold': selected.includes(s.key)
        }"
        @click="toggle(s.key);"
      >
        <Checkbox
          :value="selected.includes(s.key)"
          :emit="false"
          style="margin-left: -0.5rem"
        />
        <div class="flex-grow-1">
          {{ s.text }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: Array,
    options: Array,
    title: String,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters(['payee']),
  },
  methods: {
    toggle(cid) {
      if (this.selected.includes(cid)) {
        this.$emit('update:selected', this.selected.filter((x) => x !== cid));
      } else {
        this.$emit('update:selected', [...this.selected, cid]);
      }
    },
  },
  created() {
    if (!this.selected || this.selected.length === 0) {
      this.$emit('update:selected', this.options.map((x) => x.key));
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/payers/style.scss';
</style>
