<template>
  <div class="h-100">
    <div class="page-title-wrapper">
      <div class="page-title active">
        {{ $t('menu.bills') }}
      </div>
      <div
        class="page-title ml-4"
        @click="$router.push(`/payee/${payeeId}/bills/plans`)"
      >
        {{ $t('bill.plans') }}
      </div>
      <div
        v-if="accountingEnabled"
        class="page-title ml-4"
        @click="$router.push(`/payee/${payeeId}/bills/documents`)"
      >
        {{ $t('menu.documents') }}
      </div>
    </div>
    <div
      class="bg-white"
      style="margin: 0 -20px; padding: 0 20px;"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <YearDropdown
            :selected.sync="year"
            class="mr-3"
          />
          <PayersFilterDropdown
            class="mr-3"
            @selected="selectedPayers = $event"
          />
          <SearchBox
            v-if="!focus"
            v-model="search"
            lazy
            class="mr-3"
          />
        </div>
        <div class="d-flex">
          <RouterLink
            v-show="!focus"
            :to="`/payee/${payeeId}/bills/import`"
          >
            <button
              class="btn btn-sm btn-primary mr-2"
            >
              <i class="fas fa-upload pr-2" />
              {{ $t('menu.import') }}
            </button>
          </RouterLink>

          <RouterLink
            v-show="!focus"
            :to="`/payee/${payeeId}/bills/form`"
          >
            <button
              class="btn btn-sm btn-primary px-3"
              data-test="toggle-add-bill"
              style="border-radius: 15px"
            >
              <i class="fas fa-add px-1" />
              {{ $t('bill.add') }}
            </button>
          </RouterLink>
        </div>
      </div>
      <hr
        v-show="!focus"
        class="mb-1 mt-2"
      >
    </div>

    <ColumnEdit
      v-if="!pending"
      :focused="focus"
      :edit="!!edited"
      :modal="detailsInModal"
      class="h-100"
    >
      <BillsList
        ref="list"
        :filter="filter"
        :search="search"
        :payer-ids="selectedPayerIds"
        :year="year"
        :selected="edited ? edited.id : null"
        class="page-content"
        :basic="edited && !detailsInModal"
        @select="toggle"
      />

      <template #edit>
        <div
          v-if="edited"
          class="p-2 page-content position-relative"
        >
          <CloseButton
            v-if="!focus"
            style="position: absolute; right: 8px; top: 8px; z-index: 100"
            @click.native.stop="edited = null"
          />
          <Transition
            name="details"
            mode="out-in"
          >
            <BillDetailsPanel
              :bill="edited"
              :focus="focus"
              :selected-payer-ids="selectedPayerIds"
              class="page-cart px-4"
              @focus="focus = $event"
              @close="edited = null; focus = false"
              @update="$refs.list.updateBill"
              @remove="$refs.list.removeBill"
            />
          </Transition>
        </div>
      </template>
    </ColumnEdit>

    <div
      v-else
      class="pt-2"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import YearDropdown from '@/components/YearDropdown';
import BillsList from '@/components/bills/BillsList';
import BillDetailsPanel from '@/components/bills/edit/BillDetailsPanel';
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    filter: Array,
  },
  data: () => ({
    formOpened: false,
    pending: false,
    edited: null,
    detailsInModal: false,
    focus: false,
    selectedPayers: [],
    search: '',
    year: moment().format('YYYY'),
  }),
  components: {
    BillDetailsPanel,
    YearDropdown,
    PayersFilterDropdown,
    BillsList,
    ColumnEdit,
  },
  computed: {
    ...mapGetters(['payeeId', 'payee']),
    accountingEnabled() {
      return this.payee?.accountingEnabled;
    },
    selectedPayerIds() {
      return this.selectedPayers.map((x) => x.id);
    },
  },
  methods: {
    toggle(e) {
      if (this.edited != null && this.edited?.id === e?.id) {
        this.edited = null;
      } else {
        this.edited = e;
      }
    },
  },
  created() {
    this.$emit('page', 'bills');
  },
};
</script>

<style lang="scss" scoped>
  .page-content {
    height: calc(100vh - #{$navbarHeight} - 137px);
    overflow-y: scroll;
  }

</style>
