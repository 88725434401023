<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
    :append-to="() => tooltipParent"
    @show="opened"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t('shop.productSet') }}
        </div>
        <div
          class="first"
          style="font-size: 14px"
        >
          <i
            v-if="selected"
            class="fas fa-fork-knife pr-1"
          />
          {{ selected ? selected.name : '-' }}
        </div>
      </div>
    </template>

    <div style="padding-bottom: 5.5px">
      <SearchBox
        ref="search"
        v-model="search"
        :height="30"
        constant-width
      />
    </div>
    <div style="min-width: 180px; max-height: 300px; overflow: auto">
      <div
        v-for="(s, i) in filteredProductSets"
        :key="s.key"
        class="sort-option text-left"
        :class="{ first: i === 0, active: value === s.id }"
        @click="$emit('update:value', s.id); $refs.popover.tippy().hide()"
      >
        <i
          class="fas fa-fork-knife pr-1"
        />
        {{ s.name }}
      </div>
    </div>
  </Tippy>
</template>

<script>
import createSearch from '@/utils/search';

export default {
  props: {
    productSets: Array,
    value: String,
    tooltipParent: {
      type: Element,
      default: () => document.body,
    },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    filteredProductSets() {
      const search = createSearch(this.search);
      return this.productSets
        .filter((x) => search(x.name));
    },
    selected() {
      return this.productSets.find((x) => x.id === this.value);
    },
  },
  methods: {
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
 @import '@/components/payers/style.scss';
</style>
