<template>
  <div>
    <div
      class="payment-row align-items-center row no-gutters"
      :style="{
        'border-left-color': color,
        'background-color': isSelected ? lightColor : null,
      }"
    >
      <div
        class="font-weight-bold col-sm-6"
        style="line-height: 1.2; overflow: hidden; max-height: 38px;"
      >
        <div>
          <span data-test="payment-title">
            {{ plan.title }}
          </span>
        </div>
        <div
          v-if="category && !category.isDefault"
          class="bill-details"
        >
          {{ category.name }}
        </div>
      </div>
      <div class="d-none d-md-block col text-left px-2 small">
        <i class="fa-regular fa-repeat pr-2" />
        {{ plan.timeRule.repeatCount }}&nbsp;{{ getRepeatDesc(plan.timeRule) }}
      </div>
      <div class="d-none d-md-block col text-left px-2">
        <BillingPlanEnabled
          :is-enabled="plan.isEnabled"
          :color="color"
        />
      </div>
      <div
        v-if="!basic"
        class="d-none d-md-block col text-right money-font px-2"
      >
        {{ plan.payersCount }}
      </div>
      <div
        v-if="!basic"
        class="col text-right money-font bill-money"
      >
        {{ formatMoney(plan.totalAmount, plan.currency) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tinycolor from 'tinycolor2';
import colors from '@/utils/colors';
import BillingPlanEnabled from '../billingPlans/BillingPlanEnabled';

export default {
  props: {
    plan: Object,
    isSelected: Boolean,
    basic: Boolean,
  },
  components: {
    BillingPlanEnabled,
  },
  computed: {
    ...mapGetters(['payee', 'formatMoney']),
    category() {
      return this.payee?.categories.find((x) => x.id === this.plan.categoryId);
    },
    color() {
      return colors(this.category?.colorCode || 'default');
    },
    lightColor() {
      return tinycolor(this.color).setAlpha(0.15).toString();
    },
  },
  methods: {
    getRepeatDesc({ repeatType, repeatCount }) {
      if (repeatType === 'Week') {
        return this.$t(`time.weeks.${this.getPluralType(repeatCount)}`);
      }
      if (repeatType === 'Month') {
        return this.$t(`time.months.${this.getPluralType(repeatCount)}`);
      }
      return repeatCount > 1 ? this.$t('time.days') : this.$t('time.day');
    },
    getPluralType(m) {
      const n = parseInt(m, 10);
      if (Number.isNaN(n)) return 'zero';
      if (n === 0) return 'zero';
      if (n === 1) return 'one';
      if (n < 5) return 'few';
      return 'many';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "bill-list-animation";

  .payment-row {
    border: 2px solid rgba(100,100,100, 0.1);
    padding: 8px 10px 5px;
    border-radius: 10px;
    margin-top: 4px;
    margin-left: 0;
    margin-right: 0;
    z-index: 2;
    cursor: pointer;
    height: 54px;
    transition: transform 500ms;

    > div {
      transition: flex 300ms;
    }
    &:hover {
      background-color: rgba(100, 100, 100, 0.1);
    }
  }

  .bill-money {
    font-size: 0.9rem;
  }

  .bill-details {
    font-size: 11px;
    color: #666;
    font-weight: 400;
  }

  .bar-percent {
    position: absolute;
    width: 100%;
    font-weight: 500;
    height: 100%;
    padding-left: 8px;
    color: white;
  }

  @media screen and (max-width: 1000px) {
    .payment-row {
      height: 74px;
    }

    .payment-row ::v-deep .bill-money {
      font-size: 0.8rem;
    }
  }
</style>
