<template>
  <div
    v-if="error"
    class="text-danger text-center font-weight-bold my-3"
  >
    <i
      class="fas fa-times"
      style="font-size: 30px"
    />
    <div>
      {{ $t('error.error') }}
    </div>
  </div>
  <div v-else>
    <div style="max-width: 300px">
      <BillingScopeDropdown
        :selected.sync="billingScope"
        single
      />
    </div>
    <hr>
    <div
      v-if="pending"
      class="text-center mt-3"
    >
      <Loader />
    </div>

    <ColumnEdit
      v-else
      :edit="!!edited"
    >
      <div>
        <div
          v-if="previewsWithPayers.length === 0"
          class="text-center text-secondary mt-3"
        >
          <i class="fa-solid fa-empty-set" />
          <div>
            {{ $t('accounting.noDocuments') }}
          </div>
        </div>
        <template v-else>
          <template v-if="billingNoteCorrections.length > 0">
            <div class="font-weight-bold mb-2">
              <i class="fa-solid fa-file-signature pr-1" />
              {{ $t('accounting.corrections') }}
            </div>
            <div class="d-flex table-header">
              <label class="column-header flex-grow-1">
                {{ $t('client.name') }}
              </label>
              <label class="column-header money-column pr-1">
                {{ $t('accounting.dueBefore') }}
              </label>
              <label class="column-header money-column pr-1">
                {{ $t('accounting.dueAfter') }}
              </label>
            </div>
            <div
              v-for="p in billingNoteCorrections"
              :key="p.payerId"
            >
              <div
                class="d-flex payment-row"
                :class="{ selected: edited && edited.payerId === p.payerId }"
                @click="toggle(p)"
              >
                <div class="flex-grow-1">
                  <PayerName
                    v-if="p.payer"
                    v-bind="p.payer"
                  />
                  <div v-else>
                    ?
                  </div>
                </div>
                <div class="money-font money-column">
                  {{ formatCurrency(p.dueBefore, p.currency) }}
                </div>
                <div class="money-font money-column">
                  {{ formatCurrency(p.due, p.currency) }}
                </div>
              </div>
            </div>
          </template>

          <hr v-if="billingNotes.length > 0 && billingNoteCorrections.length > 0">

          <template v-if="billingNotes.length > 0">
            <div class="font-weight-bold mb-2">
              <i class="fa-solid fa-file-plus pr-1" />
              {{ $t('accounting.billingNotes') }}
            </div>
            <div class="d-flex table-header">
              <label class="column-header flex-grow-1">
                {{ $t('client.name') }}
              </label>
              <label class="column-header money-column pr-1">
                {{ $t('payment.due') }}
              </label>
              <label class="column-header money-column pr-1">
                {{ $t('payers.balance') }}
              </label>
            </div>
            <div
              v-for="p in billingNotes"
              :key="p.payerId"
            >
              <div
                class="d-flex payment-row"
                :class="{ selected: edited && edited.payerId === p.payerId }"
                @click="toggle(p)"
              >
                <div class="flex-grow-1">
                  <PayerName
                    v-if="p.payer"
                    v-bind="p.payer"
                  />
                  <div v-else>
                    ?
                  </div>
                </div>
                <div class="money-font money-column">
                  {{ formatCurrency(p.due, p.currency) }}
                </div>
                <div
                  class="money-font money-column"
                  :class="{ 'text-primary': p.balance > 0}"
                >
                  <span v-if="p.balance > 0">+</span>{{ formatCurrency(p.balance, p.currency) }}
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <template #edit>
        <BillingDocumentFilePreview
          v-if="edited"
          :key="`${edited.payerId}_${edited.currency}`"
          :payer-id="edited.payerId"
          :date="date"
          :currency="edited.currency"
        />
      </template>
    </ColumnEdit>
  </div>
</template>

<script>
import BillingDocumentFilePreview from '@/components/accounting/BillingDocumentFilePreview';
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayerName from '@/components/payers/PayerName';
import { mapActions, mapGetters } from 'vuex';
import BillingScopeDropdown from '../billingScopes/BillingScopeDropdown';

export default {
  props: {
    payers: Array,
    date: String,
  },
  data: () => ({
    previews: [],
    pending: false,
    error: false,
    edited: null,
    billingScope: null,
  }),
  inject: ['pageExpand'],
  components: {
    BillingDocumentFilePreview,
    BillingScopeDropdown,
    ColumnEdit,
    PayerName,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    billingNotes() {
      return this.previewsWithPayers.filter((x) => x.documentType === 'BillingNote');
    },
    billingNoteCorrections() {
      return this.previewsWithPayers.filter((x) => x.documentType === 'BillingNoteCorrection');
    },
    previewsWithPayers() {
      return this.previews
        .map((p) => ({
          ...p,
          payer: this.payers.find((x) => x.id === p.payerId),
        }))
        .sort((a, b) => a.payer?.name.localeCompare(b.payer?.name));
    },
  },
  watch: {
    billingScope(s) {
      if (s) {
        this.request();
        this.$emit('update:date', s.from);
      }
    },
  },
  methods: {
    ...mapActions(['createBillingDocumentsPreview']),
    toggle(p) {
      if (this.edited && this.edited.payerId === p.payerId) {
        this.edited = null;
        this.pageExpand(false);
      } else {
        this.pageExpand(true);
        this.edited = p;
      }
    },
    request() {
      if (!this.billingScope) return;
      this.pending = true;
      this.error = null;

      this.createBillingDocumentsPreview({
        data: {
          date: this.billingScope.from,
          payerIds: this.payers.map((x) => x.id),
        },
      })
        .then(({ data }) => {
          this.previews = data;
          this.$emit('previews', data);
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  destroyed() {
    this.pageExpand(false);
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>

.money-column {
  width: 200px;
  text-align: right;
}

.table-header {
  padding: 0px 15px;
}

.payment-row {
    background-color: #fbfbfb;
    margin-top: 6px;
    padding: 4px 15px;
    border-radius: 12px;
    cursor: pointer;

    .type {
      width: 120px;
      display: flex;
      line-height: 24px;

      i {
        font-size: 16px;
        line-height: 24px;
        padding-right: 2px;
      }
    }

    .value {
      width: 150px;
      min-width: 150px;
      text-align: right;
      border-left: 2px solid #eee;
    }

    .is-settled {
      border-left: 2px solid #eee;
      min-width: 250px;
      max-width: 250px;

      i {
        line-height: 1;
        font-size: 20px;
        padding-right: 5px;
      }

      .payment-payers {
        font-size: 12px;
      }
    }

    .title {
      border-left: 2px solid #eee;
      padding-left: 10px;
      font-size: 14px;
      line-height: 24px;
    }

    .date {
      width: 150px;
      min-width: 150px;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 2px solid #eee;
    }

    &:hover {
      background-color: rgba(100, 100, 100, 0.1);
    }

    &.selected {
      background-color: rgba($blue, 0.2);
    }
  }
</style>
