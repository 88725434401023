<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    class="w-100"
    interactive
    theme="light"
    placement="bottom"
    @show="opened"
  >
    <template #trigger>
      <div class="input form-control">
        {{ value || '-' }}
      </div>
    </template>

    <div class="dropdown-scroll options">
      <div
        v-for="option in options"
        :key="option"
        class="d-flex option-row"
        @click="select(option)"
      >
        <Checkbox
          :value="(value || '').includes(option)"
          :emit="false"
        />
        <div>
          {{ option }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import createSearch from '@/utils/search';

export default {
  props: {
    value: String,
    options: Array,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    parsedValue() {
      return (this.value || '')
        .split(',')
        .map((x) => x.trim())
        .filter((x) => this.options.includes(x));
    },
    filteredGroupOptions() {
      if (!this.search) {
        return this.options;
      }

      const search = createSearch(this.search);
      return this.options.filter((x) => search(x));
    },
  },
  methods: {
    select(key) {
      if (this.parsedValue.includes(key)) {
        this.$emit('input', this.parsedValue
          .filter((x) => x !== key)
          .join(', '));
      } else {
        this.$emit('input', [...this.parsedValue, key]
          .sort((a, b) => {
            const aIndex = this.options.findIndex((o) => a === o);
            const bIndex = this.options.findIndex((o) => b === o);
            return aIndex - bIndex;
          })
          .join(', '));
      }
    },
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
 @import '../style.scss';

.input {
  height: 38px;
  background-color: white;
  height: 100%;
  cursor: pointer;
  font-size: 0.9rem;
  text-wrap: wrap;
  word-wrap: break-word;
}

.options {
  text-align: left;

  .option-row {
    padding: 5px 0px;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }
  }
}
</style>
