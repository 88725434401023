<template>
  <div>
    <BillPayerRow
      v-for="p in overpayments"
      :key="p.paymentId"
      :name="formatDate(p.timestamp)"
      @select="$emit('select', p.paymentId)"
    >
      <template #prefix>
        <CategoryInfo
          :category-id="p.categoryId"
          color-only
        />
      </template>
      <template #content>
        <div>
          <div class="text-right d-flex no-gutters">
            <div
              class="money-font small"
              style="width: 150px"
              :style="{ color: categoryColor(p.categoryId) }"
            >
              + {{ formatMoney(p.value, p.currency) }}
            </div>
          </div>
        </div>
      </template>
    </BillPayerRow>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import BillPayerRow from '@/components/bills/edit/BillPayerRow';
import CategoryInfo from '@/components/CategoryInfo';

export default {
  props: {
    payerId: String,
  },
  data: () => ({
    overpayments: [],
  }),
  components: {
    CategoryInfo,
    BillPayerRow,
  },
  computed: {
    ...mapGetters(['formatMoney', 'categoryColor']),
  },
  methods: {
    ...mapActions([
      'getPayerOverpayments',
    ]),
    formatDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
    request() {
      this.getPayerOverpayments({
        params: {
          payerId: this.payerId,
        },
      })
        .then(({ data }) => {
          this.overpayments = data
            .slice()
            .sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix());

          if (data.length === 0) {
            this.$emit('empty');
          }
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .money-font  {
    font-size: 0.8rem;
  }
</style>
