<template>
  <div
    class="bill d-flex"
  >
    <div>
      <div class="product-set-icon">
        <i class="fa-solid fa-fork-knife" />
      </div>
    </div>
    <div class="flex-grow-1 align-self-center">
      <div
        class="bill-due-date pr-2"
      >
        {{ productSetName }} <span v-if="timeFrameName">- {{ timeFrameName }}</span>
      </div>
      <div
        v-if="days && count"
        class="text-secondary small"
      >
        x {{ count }}
      </div>
      <div>
        <ProductInfo
          v-for="p in products"
          :key="p.id"
          v-bind="p"
        />
      </div>
      <div class="d-md-flex align-items-center">
        <div class="bill-payer-name">
          {{ payer.payerName }}
        </div>
      </div>
    </div>
    <div class="align-self-center pr-2">
      <div class="money-font text-right text-nowrap">
        {{ formatCurrency(value, currency) }}
      </div>
    </div>
    <div
      class="basket-icon d-flex align-items-center justify-content-center"
      @click="toggle"
    >
      <i class="far fa-trash" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ProductInfo from './ProductInfo';

export default {
  props: {
    payerId: String,
    productSetName: String,
    availabilityId: String,
    day: String,
    currency: String,
    accountNumber: String,
    products: Array,
    isPaid: Boolean,
    isReturned: Boolean,
    days: Array,
    timeFrame: String,
    timeFrameName: String,
  },
  components: {
    ProductInfo,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'billInBasket', 'userPayers']),
    payer() {
      return this.userPayers.find((x) => x.payerId === this.payerId);
    },
    showBasket() {
      return !!this.accountNumber;
    },
    value() {
      return this.days.reduce((acc, curr) => acc + curr.price, 0);
    },
    count() {
      return this.days.reduce((acc, curr) => acc + (curr.count || 1), 0);
    },
  },
  methods: {
    formatDate(day) {
      return moment(day, 'YYYY-MM-DD').format('DD MMM');
    },
    toggle() {
      this.$store.commit('removeItemBasket', {
        payerId: this.payerId,
        availabilityId: this.availabilityId,
        day: this.day,
        isPaid: this.isPaid,
        isReturned: this.isReturned,
      });

      this.$store.commit('removeProductSetFromBasket', {
        payerId: this.payerId,
        availabilityId: this.availabilityId,
        isPaid: this.isPaid,
        isReturned: this.isReturned,
        timeFrame: this.timeFrame,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bill {
  box-shadow: 0 2px 4px #eee, -1px 2px 4px #eee;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  background-color: white;

  &.selected {
    border-left: 2px solid $blue;
  }

  &.deselected {
    border-left: 2px solid #aaa;
    opacity: 0.6;
  }
}

.bill-payee-name {
  font-size: 12px;
  color: $blue;
  line-height: 1;
}

.product-set-icon {
  background-color: #eee;
  border-radius: 10px;
  font-size: 20px;
  width: 50px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 15px;
  margin-left: 10px;
  text-align: center;
}

.bill-payer-name {
  color: #666;
  font-size: 12px;
}

.bill-due-date {
  font-size: 12px;
  font-weight: 500;

  &.overdue {
    color: $red;
  }
}

.info-divider {
  padding-left: 10px;
  padding-right: 10px;
  color: #ccc;
}

.basket-icon {
  background-color: #eee;
  padding: 5px 10px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  text-align: center;
  font-size: 20px;
  width: 45px;
  text-align: center;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background-color: #bbb;
  }

  &.selected {
    background-color: $blue;
    color: white;

    &:hover {
      background-color: darken($blue, 10);
    }
  }
}
</style>
