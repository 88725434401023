<template>
  <div
    class="row my-3"
  >
    <div class="col">
      <div>
        <BFormInput
          v-model="firstColumn.title"
          size="sm"
          :placeholder="`${$t('accounting.settings.columnTitle')} 1`"
        />
      </div>
      <Draggable
        v-model="firstColumn.properties"
        class="draggable"
        ghost-class="ghost"
        group="columns"
        :class="{ moving }"
        @start="moving = true"
        @end="moving = false"
      >
        <div
          v-for="c in firstColumn.properties"
          :key="c.key"
          class="column"
        >
          <i :class="icon(c.key)" />
          {{ name(c.key) }}
        </div>
      </Draggable>
    </div>
    <div class="col">
      <div>
        <BFormInput
          v-model="secondColumn.title"
          size="sm"
          :placeholder="`${$t('accounting.settings.columnTitle')} 2`"
        />
      </div>
      <Draggable
        v-model="secondColumn.properties"
        class="draggable"
        ghost-class="ghost"
        group="columns"
        :class="{ moving }"
        @start="moving = true"
        @end="moving = false"
      >
        <div
          v-for="c in secondColumn.properties"
          :key="c.key"
          class="column"
        >
          <i :class="icon(c.key)" />
          {{ name(c.key) }}
        </div>
      </Draggable>
    </div>
    <div class="col">
      <div>
        <BFormInput
          v-model="thirdColumn.title"
          size="sm"
          :placeholder="`${$t('accounting.settings.columnTitle')} 3`"
        />
      </div>
      <Draggable
        v-model="thirdColumn.properties"
        class="draggable"
        ghost-class="ghost"
        group="columns"
        :class="{ moving }"
        @start="moving = true"
        @end="moving = false"
      >
        <div
          v-for="c in thirdColumn.properties"
          :key="c.key"
          class="column"
        >
          <i :class="icon(c.key)" />
          {{ name(c.key) }}
        </div>
      </Draggable>
    </div>
    <div class="col">
      <Draggable
        v-model="hiddenColumns"
        class="draggable"
        ghost-class="ghost"
        group="columns"
        :class="{ moving }"
        @start="moving = true"
        @end="moving = false"
      >
        <div
          v-for="c in hiddenColumns"
          :key="c.key"
          class="column"
        >
          <i :class="icon(c.key)" />
          {{ name(c.key) }}
        </div>
      </Draggable>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

export default {
  props: {
    config: Object,
  },
  data: () => ({
    moving: false,
    firstColumn: {
      title: '',
      properties: [],
    },
    secondColumn: {
      title: '',
      properties: [],
    },
    thirdColumn: {
      title: '',
      properties: [],
    },
    hiddenColumns: [],
  }),
  components: {
    Draggable,
  },
  computed: {
    ...mapGetters([
      'payee',
      'payeeProperty',
      'properties',
    ]),
    payerProperties() {
      return this.properties.map((x) => ({
        name: x.name,
        key: x.code,
        type: 'property',
      }));
    },
    defaultColumns() {
      return [{
        name: this.$t('general.name'),
        key: 'name',
        type: 'default',
      },
      {
        name: this.$t('client.clientCode'),
        key: 'code',
        type: 'default',
      },
      {
        name: this.$t('client.number'),
        key: 'number',
        type: 'default',
      },
      ];
    },
    columns() {
      return [
        ...this.defaultColumns,
        ...this.payerProperties,
      ];
    },
    allUsedColumns() {
      return this.firstColumn.properties
        .concat(this.secondColumn.properties)
        .concat(this.thirdColumn.properties);
    },
    updatedConfig() {
      return {
        first: {
          title: this.firstColumn.title,
          properties: this.firstColumn.properties.map((x) => x.key),
        },
        second: {
          title: this.secondColumn.title,
          properties: this.secondColumn.properties.map((x) => x.key),
        },
        third: {
          title: this.thirdColumn.title,
          properties: this.thirdColumn.properties.map((x) => x.key),
        },
      };
    },
  },
  watch: {
    updatedConfig(v) {
      this.$emit('update', v);
    },
  },
  methods: {
    name(key) {
      return this.columns.find((x) => x.key === key)?.name;
    },
    icon(key) {
      const column = this.columns.find((x) => x.key === key)?.type;
      if (column === 'property') {
        return 'far far fa-tag pr-1';
      }

      return null;
    },
    init() {
      this.firstColumn.title = this.config.first.title;
      this.firstColumn.properties = this.config.first.properties
        .map((x) => {
          const def = this.columns.find((c) => c.key === x);
          return { key: x, type: def.type };
        });

      this.secondColumn.title = this.config.second.title;
      this.secondColumn.properties = this.config.second.properties
        .map((x) => {
          const def = this.columns.find((c) => c.key === x);
          return { key: x, type: def.type };
        });

      this.thirdColumn.title = this.config.third.title;
      this.thirdColumn.properties = this.config.third.properties
        .map((x) => {
          const def = this.columns.find((c) => c.key === x);
          return { key: x, type: def.type };
        });

      this.hiddenColumns = [];
      this.defaultColumns.forEach((c) => {
        if (!this.allUsedColumns.some(({ key }) => key === c.key)) {
          this.hiddenColumns.push({ key: c.key, type: 'default' });
        }
      });

      this.payerProperties.forEach((c) => {
        if (!this.allUsedColumns.some(({ key }) => key === c.key)) {
          this.hiddenColumns.push({ key: c.key, type: 'property' });
        }
      });
    },
  },
  created() {
    this.init();
    this.$emit('update', this.updatedConfig);
  },
};
</script>

<style lang="scss" scoped>
.draggable {
  border: 1px solid transparent;
  border-radius: 10px;
  min-height: 50px;
  background-color: #f8f8f8;
  padding: 5px;
  margin-top: 5px;
  min-height: 200px;
  padding-bottom: 40px;
}

.draggable.moving {
  border: 1px solid #aaa;
}

.moving * {
  cursor: grabbing;
}
.column {
  padding: 0.3rem 0.6rem;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #eee;
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
}
</style>
