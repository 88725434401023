<template>
  <div class="order-screen">
    <div class="order-header">
      <div
        class="d-flex align-items-center mt-3"
      >
        <i
          class="fas fa-chevron-left mr-2 secondary-icon"
          @click="back"
        />
        <div
          v-if="catalogDetails"
          class="flex-grow-1"
        >
          <span class="font-weight-bold pr-2">
            {{ catalogDetails.catalog.name }}:
          </span>
          <span class="text-primary">
            {{ payeeName }}
          </span>
        </div>
      </div>
      <hr>
      <div class="d-flex mb-2">
        <div
          v-for="p in catalogPayers"
          :key="p.payerId"
          class="payer-bubble"
          :class="{ selected: selectedPayerId === p.payerId }"
          @click="selectedPayerId = p.payerId"
        >
          <i class="far fa-user" />
          <div class="payer-name">
            {{ p.payerName }}
          </div>
        </div>
      </div>
      <div
        v-if="firstLayout"
        class="horizontal-menu"
      >
        <div
          v-for="n in firstLayout.nodes"
          :key="n.id"
          class="menu-node"
          :class="{ selected: selectedFirstNodeId === n.id }"
          @click="selectedFirstNodeId = n.id"
        >
          {{ n.name }}
        </div>
      </div>
    </div>

    <BModal
      :visible="!!selected"
      hide-footer
      hide-header
      size="lg"
      @hide="selected = null"
    >
      <div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          style="font-size: 30px"
          @click="selected = null"
        >
          ×
        </button>
      </div>
      <ListingInfo
        v-if="selected"
        :payer-id="selectedPayerId"
        :catalog="catalog"
        :listing="selected"
        :ordered="ordered?.orders
          .find(o => o.payerId === selectedPayerId && o.productSetId === selected.id)"
        @close="selected = null"
      />
    </BModal>

    <div class="offer-content">
      <div
        v-for="entry in listContent"
        :key="entry.id"
      >
        <div
          v-if="entry.name"
          class="menu-title"
        >
          {{ entry.name }}
        </div>
        <div
          v-for="listing in entry.listings"
          :key="listing.id"
          class="p-2"
        >
          <PayerListing
            :listing="listing"
            :payer-id="selectedPayerId"
            :ordered="ordered?.orders
              .find(o => o.payerId === selectedPayerId && o.productSetId === listing.id)"
            @click.native="selected = listing"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ListingInfo from '../components/shop/ListingInfo';
import PayerListing from '../components/shop/PayerListing';

export default {
  props: {
    catalogId: String,
    payeeId: String,
  },
  data: () => ({
    selectedPayerId: null,
    catalogDetails: null,
    selectedFirstNodeId: null,
    selected: null,
    ordered: null,
  }),
  components: {
    PayerListing,
    ListingInfo,
  },
  computed: {
    ...mapGetters([
      'userPayers',
    ]),
    catalog() {
      return this.catalogDetails?.catalog;
    },
    payeeName() {
      return this.userPayers.find((x) => x.payeeId === this.catalog?.payeeId)?.payeeName;
    },
    catalogPayers() {
      if (!this.catalog) return [];
      return this.catalog.payerIds
        .map((id) => this.userPayers?.find((up) => up.payerId === id))
        .filter((x) => x);
    },
    firstLayout() {
      if (!this.catalogDetails) return undefined;
      return this.catalogDetails.layout['1'];
    },
    secondLayout() {
      if (!this.catalogDetails) return undefined;
      return this.catalogDetails.layout['2'];
    },
    listContent() {
      const secondaryNodes = this.secondLayout?.nodes || [];

      return secondaryNodes
        .map((n) => ({
          ...n,
          listings: this.filteredListings.filter((x) => x.nodeIds.includes(n.id)),
        }))
        .concat([
          {
            id: '',
            name: this.catalogDetails?.layout['2'] ? 'Inne' : '',
            listings: this.filteredListings.filter(
              (x) =>
                !this.catalogDetails?.layout['2']
            || !this.catalogDetails.layout['2'].nodes.some((n) => x.nodeIds.includes(n.id)),
            ),
          },
        ])
        .filter((x) => x.listings.length > 0);
    },
    filteredListings() {
      if (!this.catalogDetails) return [];
      if (this.firstLayout) {
        return this.catalogDetails.listings
          .filter((x) => x.nodeIds.some((id) => this.selectedFirstNodeId === id))
          .filter((x) => x.payerIds.some((id) => this.selectedPayerId === id));
      }

      return this.catalogDetails.listings
        .filter((x) => x.payerIds.some((id) => this.selectedPayerId === id));
    },
  },
  watch: {
    catalogPayers(ps) {
      if (ps && ps.length > 0) {
        this.selectedPayerId = ps[0].payerId;
      }
    },
    firstLayout(l) {
      if (l && l.nodes.length > 0) {
        this.selectedFirstNodeId = l.nodes[0].id;
      }
    },
  },
  methods: {
    ...mapActions([
      'getUserPayers',
      'getCatalogDetails',
      'getOrdered',
    ]),
    back() {
      this.$router.push('/payer/shop');
    },
  },
  created() {
    if (!this.userPayers || this.userPayers.length === 0) {
      this.getUserPayers();
    }

    this.refresh = () => {
      this.getCatalogDetails({
        params: {
          query: {
            catalogId: this.catalogId,
            payeeId: this.payeeId,
          },
        },
      })
        .then(({ data }) => {
          this.catalogDetails = data;

          this.getOrdered({
            params: {
              query: {
                catalogId: this.catalogId,
                payerId: data.catalog.payerIds,
              },
            },
          })
            .then((res) => {
              this.ordered = res.data;
            });
        });
    };

    this.$root.$on('order-created', this.refresh);
    this.refresh();
  },
  destroyed() {
    this.$root.$off('order-created', this.refresh);
  },
};
</script>

<style lang="scss" scoped>
.payer-bubble {
  text-align: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  line-height: 1.2;
  color: #666;
  cursor: pointer;
  margin-right: 10px;

  .payer-name {
    font-size: 0.7rem;
    margin-top: 8px;
  }

  &:hover {
    background-color: rgba(#666, 0.1);
  }

  &.selected {
    color: $blue;
    background-color: #D9E9FF;
  }
}

.close {
  margin-right: 5px;
  font-size: 20px;
}

.order-screen {
  background-color: #FAFAFA;
  height: calc(100vh - 56px);
  overflow-y: auto;
  margin-left: -40px;
  margin-right: -40px;
  padding-left: 40px;
  padding-right: 40px;
}

.order-header {
  background-color: #fff;
  margin-top: -12px;
  padding-top: 12px;

  margin-left: -40px;
  margin-right: -40px;
  padding-left: 40px;
  padding-right: 40px;

  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
}

@media screen and (max-width: 767px) {
  .order-header {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .order-screen {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.offer-content {
  background-color: #FAFAFA;
  margin-top: 15px;
}

.horizontal-menu {
  display: flex;
  flex-wrap: wrap;
}

.menu-title {
  font-weight: 500;
  font-size: 18px;
}

.menu-node {
  padding: 5px 13px;
  background-color: rgba(#aaa, 0.1);
  margin-right: 6px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  margin-top: 7px;

  &:hover {
    background-color: rgba(#666, 0.1);
  }

  &.selected {
    background-color: #D9E9FF;
  }
}
</style>
