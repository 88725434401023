const normalize = (str) =>
  str.normalize('NFKD')
    .replace(/\u0142/g, 'l')
    .replace(/[\u0300-\u036F]/g, '');

export default (search) => {
  if (!search) return () => true;
  const regex = new RegExp(normalize(search), 'gi');
  return (str) => str && normalize(str.toString()).search(regex) !== -1;
};
