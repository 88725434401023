<template>
  <div>
    <div
      v-if="previewError"
      class="text-danger text-center font-weight-bold my-3"
    >
      <i
        class="fas fa-times"
        style="font-size: 30px"
      />
      <div>
        {{ $t('error.error') }}
      </div>
    </div>
    <div class="preview-image-wrapper">
      <img
        v-show="!pending && !previewError"
        ref="preview"
        class="preview-image"
      >
    </div>
    <div v-if="pending">
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    payerId: String,
    currency: String,
    date: String,
  },
  data: () => ({
    preview: null,
    pending: false,
    previewError: false,
  }),
  methods: {
    ...mapActions(['createBillingDocumentFilePreview']),
    request() {
      this.pending = true;
      this.createBillingDocumentFilePreview({
        data: {
          date: this.date,
          payerId: this.payerId,
          currency: this.currency,
        },
      })
        .then(({ data }) => {
          this.$refs.preview.src = `data:image/webp;base64,${data.fileBase64}`;
        })
        .catch(() => {
          this.previewError = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  max-width: 100%;
  box-shadow: 0 2px 4px #aaa;
  max-height: 80vh;
  margin-left: auto;
  margin-right: auto;
}

.preview-image-wrapper {
  padding: 15px;
  text-align: center;
}
</style>
