<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="filter-header">
        {{ $t('settings.currencies.currency') }}
      </div>
      <div class="sort-option-selected">
        <div style="font-size: 14px">
          {{ selected.toUpperCase() }}
        </div>
      </div>
    </template>

    <div class="dropdown-scroll">
      <div
        v-for="(c, i) in currencies"
        :key="c.code"
        class="sort-option text-left d-flex align-items-center"
        :class="{
          first: i === 0,
          'font-weight-bold': selected === c.code
        }"
        @click="$emit('update:selected', c.code); $refs.popover.tippy().hide()"
      >
        <div class="flex-grow-1">
          {{ c.code.toUpperCase() }}
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: String,
  },
  computed: {
    ...mapGetters(['currencies']),
  },
};
</script>

<style lang="scss" scoped>
 @import './small-filter.scss';
</style>
