<template>
  <div class="position-relative">
    <div
      v-if="showBillHeader"
      class="d-flex align-items-center py-3 px-2"
    >
      <div class="bill-icon flex-shrink-0">
        <i class="icon-bill" />
      </div>
      <div class="title">
        {{ billTitle }}
      </div>
    </div>

    <div
      style="position: absolute; top: 0; right: 0;"
      @click="$emit('cancel')"
    >
      <i class="fas fa-times secondary-icon" />
    </div>
    <div class="pt-2">
      <PayerName
        v-if="!showBillHeader"
        v-bind="payer"
      />
      <hr>
      <div
        v-if="details && details.payer"
        class="d-flex"
      >
        <div>
          <div class="pl-2 mt-2 d-flex justify-content-between">
            <div
              class="text-secondary small"
              style="width: 150px"
            >
              {{ $t('report.balance.paid') }}:
            </div>
            <div class="money-font text-right">
              {{ formatMoney(details.payer.paid, currency) }}
            </div>
          </div>
          <div class="pl-2 mt-2 d-flex justify-content-between">
            <div
              class="text-secondary small"
              style="width: 150px"
            >
              {{ $t('report.balance.due') }}:
            </div>
            <div class="money-font">
              {{ formatMoney(details.payer.amount, currency) }}
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <CircleChart
            :value="(details.payer.paid / details.payer.amount) * 100"
            show-text
            :font-size="8"
            :stroke-width="2"
            style="width: 80px; font-size: 12px; margin-top: -5px; margin-bottom: -5px"
          />
        </div>
      </div>
      <div
        v-else-if="details"
        class="text-center small text-secondary"
      >
        {{ $t('bill.notAssigned') }}
      </div>
    </div>

    <div
      v-if="details"
      class="px-2"
    >
      <div
        class="d-flex justify-content-between align-items-center mt-3"
      >
        <h5 class="payer-section mb-0">
          {{ $t('menu.payments') }}
        </h5>
      </div>
      <hr class="mt-2">

      <BillPayerRow
        v-for="p in details.payments"
        :key="p.paymentId"
        :name="formatDate(p.timestamp)"
        @select="paymentId = p.paymentId; showPayment = true"
      >
        <template #prefix>
          <i
            :class="getIcon(p.paymentType)"
            class="pr-2"
          />
        </template>
        <template #content>
          <div>
            <div class="text-right d-flex no-gutters">
              <div
                class="money-font small"
                style="width: 150px"
                :style="{ color }"
              >
                + {{ formatMoney(p.billPaid, p.billCurrency) }}
              </div>
            </div>
          </div>
        </template>
      </BillPayerRow>
      <PaymentInlineForm
        v-if="details.payer"
        class="mb-2"
        :currency="currency"
        :payer-id="details.payer.payerId"
        :bill-id="billId"
        :bill-title="billTitle"
        :to-pay="details.payer.amount - details.payer.paid"
        @refresh="refresh"
        @show="showAudit = !$event"
      />
      <template v-if="showAudit">
        <div
          class="d-flex justify-content-between align-items-center mt-3"
        >
          <h5 class="payer-section mb-0">
            {{ $t('bill.changeHistory') }}
          </h5>
        </div>
        <hr class="mt-2">

        <div class="change-log">
          <div
            v-if="details.audit.length > 0"
            class="d-flex justify-content-end text-secondary small text-right py-1"
            style="font-size: 12px"
          >
            <div
              style="width: 120px"
              class="pr-1"
            >
              {{ $t('report.balance.paid') }}
            </div>
            <div
              style="width: 120px"
              class="pr-1"
            >
              {{ $t('report.balance.due') }}
            </div>
          </div>
          <BillAuditRow
            v-for="(p, index) in details.audit"
            :key="index"
            :bill-currency="currency"
            v-bind="p"
            class="log-row"
          />
        </div>
      </template>
    </div>
    <div v-else>
      <Loader />
    </div>

    <PModal v-model="showPayment">
      <PaymentDetails
        :payment-id="paymentId"
        @removed="refresh(); showPayment = false; paymentId = null"
        @update="refresh"
      />
    </PModal>
  </div>
</template>

<script>
import CircleChart from '@/components/charts/CircleChart';
import PayerName from '@/components/payers/PayerName';
import PaymentDetails from '@/components/payments/PaymentDetails';
import PaymentInlineForm from '@/components/payments/PaymentInlineForm';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import BillAuditRow from './audit/BillAuditRow';
import BillPayerRow from './edit/BillPayerRow';

export default {
  props: {
    billId: {
      type: String,
      required: true,
    },
    payer: {
      type: Object,
      required: true,
    },
    billTitle: String,
    color: String,
    currency: String,
    showBillHeader: Boolean,
  },
  data: () => ({
    details: null,
    showAudit: true,
    showPayment: false,
    paymentId: null,
  }),
  components: {
    PayerName,
    CircleChart,
    BillPayerRow,
    BillAuditRow,
    PaymentDetails,
    PaymentInlineForm,
  },
  computed: {
    ...mapGetters(['formatMoney', 'paymentTypes']),
  },
  watch: {
    billId() {
      this.refresh();
    },
  },
  methods: {
    ...mapActions(['getBillPayerDetails']),
    getIcon(type) {
      return this.paymentTypes.find((y) => y.key === type)?.icon || 'fas fa-question';
    },
    formatDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
    refresh() {
      this.details = null;
      this.showAudit = true;
      this.$emit('refresh');
      this.request();
    },
    request() {
      this.getBillPayerDetails({
        params: {
          payerId: this.payer.id,
          billId: this.billId,
        },
      })
        .then(({ data }) => {
          this.details = data;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/components/audit.scss";

  .payer-section {
    font-size: 16px;
    font-weight: 600 !important;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
  }

</style>
