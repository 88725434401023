<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header">
          <h1>
            {{ $t('settings.forms.updateForm') }}
          </h1>
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </div>
      </div>
      <hr class="mt-1">

      <div class="row align-items-center">
        <StepSelect
          class="col-9"
          :page="page"
          :enabled="filledPages"
          :list="steps"
          @select="page = $event"
        />
        <div class="col-3 text-right">
          <button
            v-if="page == 'layout'"
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="disabled"
            @click="next"
          >
            {{ $t('general.next') }}
            <i class="fas fa-arrow-right px-2" />
          </button>
          <button
            v-else
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="disabled || pending"
            @click="save"
          >
            {{ $t('general.save') }}
            <i class="fas fa-check px-2" />
          </button>
        </div>
      </div>

      <hr class="mb-2">

      <div v-if="!ready">
        <Loader />
      </div>
      <LayoutEditor
        v-else-if="page === 'layout'"
        class="row"
        :value.sync="layout"
      />

      <div
        v-else
        class="scroll-form"
      >
        <div
          v-for="p in payerFields"
          :key="p.code"
          class="row no-gutters align-items-center mt-2"
        >
          <div class="col-4">
            <span class="pr-2 mb-0 small text-secondary">
              {{ p.name }}
            </span>
          </div>
          <div class="col-8 pr-1">
            <BFormTextarea
              v-model="mapping[p.code]"
              size="sm"
              :rows="1"
              class="money-font"
            />
          </div>
        </div>
        <hr>
        <div
          v-for="p in properties"
          :key="p.code"
          class="row no-gutters align-items-center mt-2"
        >
          <div class="col-4">
            <span class="pr-2 mb-0 small text-secondary">
              <i :class="`${getIcon(p.type)} pr-2`" />
              {{ p.name }}
            </span>
          </div>
          <div class="col-8 pr-1">
            <BFormTextarea
              v-model="mapping[p.code]"
              size="sm"
              :rows="1"
              class="money-font"
            />
          </div>
        </div>
        <hr>
        <div
          v-for="p in userGroups"
          :key="p.code"
          class="row no-gutters align-items-center mt-2"
        >
          <div class="col-4">
            <span class="pr-2 mb-0 small text-secondary">
              <i class="far fa-users pr-2" />
              {{ p.path }}
            </span>
          </div>
          <div class="col-8 pr-1">
            <BFormTextarea
              v-model="mapping[p.code]"
              size="sm"
              :rows="1"
              class="money-font"
            />
          </div>
        </div>
        <hr>

        <div
          class="row no-gutters align-items-center mt-2"
        >
          <div class="col-4">
            <span class="pr-2 mb-0 small text-secondary">
              {{ $t('client.activate') }}
            </span>
          </div>
          <div class="col-8 pr-1">
            <BFormTextarea
              v-model="activate"
              size="sm"
              :rows="1"
              class="money-font"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepSelect from '@/components/bills/form/StepSelect';
import LayoutEditor from '@/components/forms/LayoutEditor';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    id: String,
  },
  data: () => ({
    disabled: false,
    pending: false,
    layout: [],
    page: 'layout',
    mapping: {},
    activate: 'false',
    ready: false,
  }),
  components: {
    StepSelect,
    LayoutEditor,
  },
  computed: {
    ...mapGetters([
      'payeeId',
      'properties',
      'userGroups',
      'payersPropertyTypes',
    ]),
    steps() {
      return [
        {
          key: 'layout',
          text: this.$t('forms.forms.layout'),
        },
        {
          key: 'mapping',
          text: this.$t('forms.forms.mapping'),
        },
      ];
    },
    payerFields() {
      return [
        {
          name: this.$t('client.lastName'),
          code: 'lastName',
        },
        {
          name: this.$t('client.firstName'),
          code: 'firstName',
        },
        {
          name: this.$t('client.clientCode'),
          code: 'code',
        }];
    },
    model() {
      return {
        inputFields: this.layout.map((x) => ({
          code: x.code,
          validationScript: x.validationScript,
          validationMessage: x.validationMessage,
        })),
        firstName: this.mapping.firstName,
        lastName: this.mapping.lastName,
        code: this.mapping.code,
        activate: this.activate,
        properties: this.properties
          .map((x) => ({
            key: x.code,
            value: this.mapping[x.code],
          }))
          .filter((x) => x.value),
        groups: this.userGroups
          .map((x) => ({
            key: x.code,
            value: this.mapping[x.code],
          }))
          .filter((x) => x.value),
      };
    },
    filledPages() {
      const index = this.steps.findIndex((x) => x.key === this.page);
      return this.steps.flatMap((x, i) => (i <= index ? [x.key] : []));
    },
  },
  methods: {
    ...mapActions([
      'updateFormDefinitionDetails',
      'getFormDefinition',
    ]),
    getIcon(type) {
      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
    save() {
      this.pending = true;
      this.updateFormDefinitionDetails({
        params: {
          formId: this.id,
        },
        data: {
          layout: JSON.stringify(this.layout),
          model: JSON.stringify(this.model),
        },
      })
        .then(() => {
          this.pending = true;
          this.$router.push(`/payee/${this.payeeId}/settings/forms`);
        })
        .catch(() => {
          this.pending = true;
        });
    },

    next() {
      this.page = 'mapping';
    },
    cancel() {
      this.$router.back();
    },
    request() {
      this.getFormDefinition({
        params: {
          formId: this.id,
        },
      })
        .then(({ data }) => {
          this.layout = JSON.parse(data.layout || '[]');
          const model = JSON.parse(data.model || '{}');

          this.$set(this.mapping, 'firstName', model.firstName);
          this.$set(this.mapping, 'lastName', model.lastName);
          this.$set(this.mapping, 'code', model.code);
          this.activate = model.activate;

          this.properties.forEach((x) => {
            const prop = (model.properties || []).find((y) => y.key === x.code);
            if (prop && prop.value) {
              this.$set(this.mapping, prop.key, prop.value);
            }
          });

          this.userGroups.forEach((x) => {
            const g = (model.groups || []).find((y) => y.key === x.code);
            if (g && g.value) {
              this.$set(this.mapping, g.key, g.value);
            }
          });

          this.ready = true;
        });
    },
  },
  created() {
    this.$emit('page', 'form');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .close {
    font-size: 40px;
  }

  .scroll-form {
    height: calc(100vh - 220px);
    padding-bottom: 40px;
    overflow-y: auto;
  }
</style>
