<template>
  <div v-if="bill">
    <div
      class="d-flex no-gutters"
      style="max-width: 500px; margin-left: auto; margin-right: 0"
    >
      <div class="col pr-1">
        <button
          v-if="addPayers || edit"
          class="btn btn-secondary btn-block btn-sm"
          @click="$emit('cancel')"
        >
          <i class="fas fa-times pr-2" />
          {{ $t('general.cancel') }}
        </button>
        <button
          v-else
          class="btn btn-secondary btn-block btn-sm"
          :disabled="pending"
          @click="addPayers = true; editAmounts = false"
        >
          <i class="fas fa-arrow-left pl-1" />
          {{ $t('general.back') }}
        </button>
      </div>
      <div class="col pl-1">
        <button
          v-if="addPayers && !edit"
          class="btn btn-primary btn-block btn-sm"
          :disabled="pending"
          @click="addPayers = false; editAmounts = true;"
        >
          {{ $t('general.next') }}
          <i class="fas fa-arrow-right pl-1" />
        </button>
        <button
          v-else
          data-test="save-client-details-button"
          class="btn btn-primary btn-block btn-sm"
          :disabled="pending"
          @click="submit"
        >
          <i class="fas fa-check pr-2" />
          {{ $t('general.save') }}
        </button>
      </div>
    </div>
    <hr>
    <div>
      <div v-if="addPayers && !edit">
        <PayersList
          :selected.sync="payers"
          :exclude-bill-id="bill.id"
        />
      </div>
      <div v-else>
        <PayersAmountList
          v-if="!loadingPayers"
          :bill-id="bill.id"
          :init-bank-account-id="(bill.accountNumber || '').toString()"
          :init-currency="bill.currency"
          :selected-payer-ids="selectedPayerIds"
          :payers="payers"
          :removed.sync="removed"
          :allow-remove="true"
          :init-master-amount="masterAmount"
          class="pb-4"
          @payers="payers = $event"
          @removed="removed = $event"
          @bankAccountId="bankAccountId = $event"
          @masterAmount="masterAmount = $event"
        />
        <Loader v-else />
      </div>
    </div>

    <PModal v-model="showResettlementInfo">
      <div
        v-if="validation"
        class="mb-2"
      >
        <div class="font-weight-bold mb-2">
          {{ $t('bill.resettlementNeeded', [validation.overpaidCount]) }}
        </div>

        {{ $t('bill.resettleForCategory') }}:
      </div>
      <div
        v-for="category in categories"
        :key="category.id"
        class="category-row py-2"
        style="max-width: 350px"
        :class="{ selected: category.id === resettlementCategoryId }"
        @click="resettlementCategoryId = category.id"
      >
        <CategoryInfo :category-id="category.id" />
      </div>

      <hr>

      <SaveButton
        :pending="pending"
        @cancel="showResettlementInfo = false"
        @save="submitBill"
      />
    </PModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SaveButton from '@/components/SaveButton';
import CategoryInfo from '@/components/CategoryInfo';
import PayersList from '../form/PayersList';
import PayersAmountList from '../form/PayersAmountList';

export default {
  props: {
    bill: Object,
    edit: Boolean,
    selectedPayerIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    payers: [],
    removed: [],
    addPayers: true,
    pending: false,
    bankAccountId: null,
    masterAmount: null,
    loadingPayers: false,
    resettlementCategoryId: null,
    showResettlementInfo: false,
    validation: null,
  }),
  components: {
    PayersList,
    SaveButton,
    CategoryInfo,
    PayersAmountList,
  },
  computed: {
    ...mapGetters(['payee']),
    categories() {
      return this.payee?.categories || [];
    },
  },
  methods: {
    ...mapActions([
      'addBillPayers',
      'getBillPayers',
      'updateBillPayers',
      'validateBillPayersUpdate',
    ]),
    cancel() {
      this.addPayers = false;
      this.$emit('focus', false);
    },
    async submit() {
      this.validation = null;

      try {
        const response = await this.validateBillPayersUpdate({
          params: {
            billId: this.bill.id,
          },
          data: {
            masterAmount: this.masterAmount,
            removePayers: this.removed,
            updatePayers: this.payers.map((x) => ({
              amount: Math.round(x.amount * x.count),
              count: x.count,
              payerId: x.id,
            })),
          },
        });

        this.validation = response.data;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Validation error', e);
      }

      // No resettlement needed
      if (!this.validation || this.validation.overpaidCount === 0) {
        await this.submitBill();
      } else {
        this.showResettlementInfo = true;
      }
    },
    submitBill() {
      this.pending = true;
      if (this.edit) {
        this.updateBillPayers({
          params: {
            billId: this.bill.id,
          },
          data: {
            masterAmount: this.masterAmount,
            removePayers: this.removed,
            accountNumber: this.bankAccountId,
            updatePayers: this.payers.map((x) => ({
              amount: Math.round(x.amount * x.count),
              count: x.count,
              payerId: x.id,
            })),
            resettlementCategoryId: this.resettlementCategoryId,
          },
        })
          .then(({ data }) => {
            this.$emit('update', data);
          })
          .finally(() => {
            this.pending = false;
          });
      } else {
        this.addBillPayers({
          params: {
            billId: this.bill.id,
          },
          data: {
            payers: this.payers.map((x) => ({
              amount: x.amount * x.count,
              count: x.count,
              payerId: x.id,
            })),
          },
        })
          .then(({ data }) => {
            this.$emit('update', data);
          })
          .finally(() => {
            this.pending = false;
          });
      }
    },
    loadPayers() {
      this.loadingPayers = true;
      this.getBillPayers({
        params: {
          billId: this.bill.id,
          query: {
            sort: 'name',
          },
        },
      })
        .then(({ data }) => {
          this.payers = data.map((x) => ({
            ...x,
            count: x.count,
            amount: x.amount / x.count,
          }));
        })
        .finally(() => {
          this.loadingPayers = false;
        });
    },
  },
  created() {
    this.masterAmount = this.bill.masterAmount;
    this.bankAccountId = this.bill.accountNumber;
    this.payers = [];
    if (this.edit && this.bill) {
      this.resettlementCategoryId = this.bill.categoryId;
      this.loadPayers();
    }
  },
};
</script>

<style lang="scss" scoped>

  .category-row {
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }
    &.selected {
      margin-right: -20px;
      background-color: rgba($blue, 0.1);
      border-color: $darkblue;
    }
  }

  .sort-option-selected  {
    border: 1px solid rgba(100, 100, 100, 0.1);
    cursor: pointer;
    padding: 2px 10px;
    border-radius: 10px;
    &:hover {
      background-color: rgba(100, 100, 100, 0.1);
    }
  }

  .sort-option {
    font-size: 14px;
    padding: 8px 10px;
    border-top: 1px solid #efefef;
    cursor: pointer;

    &.active {
      font-weight: 500;
      color: $blue;
    }

    &.first {
      border-top: none;
    }

    &:hover {
      background-color: #efefef;
    }
  }

  .fixed-height {
    max-height: 500px;
    height: 500px;
    overflow: auto;
    padding: 0 15px 0 15px;
    margin-left: -25px;
    margin-right: -25px;
  }

  .verify {
    height: 18px;
    width: 18px;
    font-size: 18px;
    text-align: center;
    background-color: $blue;
    border-radius: 50%;
    color: white;
    position: absolute;
    top: 5px;
    right: -10px;
    line-height: 1;
    z-index: 100;
    i {
      padding-left: 1px;
    }
  }

</style>
