<template>
  <div>
    <div
      v-for="(rule, i) in rules"
      :key="i"
      class="rule-row"
    >
      <BFormInput
        v-model="rule.fieldName"
        placeholder="Nazwa pola"
        class="my-2"
      />

      <BFormTextarea
        v-model="rule.fieldRule"
        placeholder="Reguła"
        class="my-2 money-font small"
      />
    </div>

    <div class="mt-4">
      <button
        class="btn btn-sm btn-primary"
        @click="rules.push({ fieldName: '', fieldRule: ''})"
      >
        <i class="fas fa-plus" />
        Dodaj pole
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
  },
  data: () => ({
    rules: [],
  }),
  watch: {
    rules: {
      deep: true,
      handler(v) {
        this.$emit('rules', v);
      },
    },
  },
  mounted() {
    this.rules = this.config.exportRules.map((x) => ({ ...x }));
  },
};

</script>

<style lang="scss" scoped>
.rule-row {
  padding: 10px;
  border: 2px solid #aaa;
  border-radius: 10px;
}
</style>
